import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/QuartzMonitor'

// GET
interface LoadQuartzMonitor {
    type: 'LOAD_QUARTZMONITOR'
}
interface LoadedQuartzMonitor {
    type: 'LOADEDQUARTZMONITOR'
    response: Models.ResponseQuartzMonitor
    status: number,
    statusMessage: string
}
interface FailLoadedQuartzMonitor {
    type: 'LOADQUARTZMONITORFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedQuartzMonitor {
    type: 'CLEARLOADEDQUARTZMONITOR'
}


type KnownAction = LoadQuartzMonitor | LoadedQuartzMonitor | FailLoadedQuartzMonitor | ClearLoadedQuartzMonitor

export const actionCreators = {
    requestGetQuartzMonitor: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'GET') {
            console.log('call actionCreators : requestGetQuartzMonitorStore')
            fetch(`/v1/lis-server/quartz`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADQUARTZMONITORFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDQUARTZMONITOR', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADDMSCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_QUARTZMONITOR' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDQUARTZMONITOR' });
        }
    },
}

const unloadedStateGet: Models.QuartzMonitorState = { isLoadQuartzMonitor: false };


const reducerGet: Reducer<Models.QuartzMonitorState> = (state: Models.QuartzMonitorState | undefined, incomingAction: Action): Models.QuartzMonitorState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_QUARTZMONITOR':
            return {
                isLoadQuartzMonitor: false,
            }

        case 'LOADEDQUARTZMONITOR':
            return {
                isLoadQuartzMonitor: true,
                responseQuartzMonitor: action.response as any,
                statusQuartzMonitor: action.status
            }

        case 'LOADQUARTZMONITORFAIL':
            return {
                isLoadQuartzMonitor: true,
                messageQuartzMonitor: action.statusMessage,
                statusQuartzMonitor: action.status
            }

        case 'CLEARLOADEDQUARTZMONITOR':
            return {
                isLoadQuartzMonitor: false,
                messageQuartzMonitor: "",
                statusQuartzMonitor: 400
            }
        default: return state;
    }
}
export const rootReducer = combineReducers({ GetQuartzMonitor: reducerGet })