import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';

import { connect } from "react-redux";
import { ApplicationState } from "../../store";

import * as AppState from '../../store/AppState'
import * as Models from '../../models/AppState'
type store = Models.AppState & typeof AppState.actionCreators

class SignIn extends React.Component<any, { username: string, password: string, currentUser: any, message: string, isLoginSuccess: boolean } , store> {
    constructor(props: any) {
        super(props)

        this.state = {
            username: '',
            password: '',
            currentUser: null,
            message: '',
            isLoginSuccess: false
        }

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
    }

    componentDidMount() {
        console.log("this.props.updateEnv(true, 'GET')")
        //console.log(this.props.location.state.from)
        this.props.updateEnv(true, 'GET')
    }

    private onChangeUserName(e: any) {
        const { name, value } = e.target
        //console.log('username:' + value)
        this.setState({
            username: value
        })
    }

    private onChangePassword(e: any) {
        const { name, value } = e.target
        //console.log('password:' + value)
        this.setState({
            password: value
        })
    }

    public onLoginSuccess() {
        this.setState({ isLoginSuccess: true });
    }

    render() {
        let loginObject = this.state;
        if (this.state.isLoginSuccess) {
            if (this.props.AnonymousMode == true) {
                this.props.blindLogin(true)
            } else {
                return <Redirect to='/' />
            }
        }
        console.log(this.props.ssoEnv)
        var state = ''
        if (this.props.location !== undefined && this.props.location.state !== undefined) {
            state = this.props.location.state.from
        }

        return (
            <Container>
                <Row className="h-100">
                    <Col>
                        {/*<iframe src="https://scg-sso.sonarinno.com/auth/signin" />*/}
                        {this.props.ssoEnv != undefined &&
                            <iframe style={{ WebkitWritingMode: "vertical-lr", overflow: "none" }} src={this.props.ssoEnv.ssO_WEB + btoa(state) + "&token=" + this.props.ssoEnv.ssO_REDIRECT_TOKEN} name="myIframe" height="100%" width="100%" title="Iframe Example" frameBorder="0" ref="iframe"></iframe>
                        }
                    </Col>
                </Row>
            </Container>
            //</Loader>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState }), ({ ...AppState.actionCreators }))(SignIn)