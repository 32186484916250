import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/ApiConfig'

// GET
interface LoadApiConfig {
    type: 'LOAD_APICONFIG'
}
interface LoadedApiConfig {
    type: 'LOADEDAPICONFIG'
    response: Models.ResponseApiConfig
    status: number,
    statusMessage: string
}
interface FailLoadedApiConfig {
    type: 'LOADAPICONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedApiConfig {
    type:'CLEARLOADEDAPICONFIG'
}

// ADD
interface AddApiConfig {
    type: 'ADD_APICONFIG'
}
interface AddedApiConfig {
    type: 'ADDEDAPICONFIG'
    response: Models.ResponseApiConfig
    status: number,
    statusMessage: string
}
interface FailAddedApiConfig {
    type: 'ADDAPICONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddApiConfig {
    type: 'CLEARADDAPICONFIG'
}

// Delete
interface DeleteApiConfig {
    type: 'Delete_APICONFIG'
}
interface DeletedApiConfig {
    type: 'DELETEDAPICONFIG'
    status: number,
    statusMessage: string
}
interface FailDeletedApiConfig {
    type: 'DELETEAPICONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeleteApiConfig {
    type: 'CLEARDELETEAPICONFIG'
}

// Edit
interface EditApiConfig {
    type: 'EDIT_APICONFIG'
}
interface EditedApiConfig {
    type: 'EDITDAPICONFIG'
    response: Models.ResponseApiConfig
    status: number,
    statusMessage: string
}
interface FailEditdApiConfig {
    type: 'EDITAPICONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditApiConfig {
    type: 'CLEAREDITAPICONFIG'
}

// Search
interface SearchApiConfig {
    type: 'SEARCH_APICONFIG'
}
interface SearchedApiConfig {
    type: 'SEARCHAPICONFIG'
    response: Models.ResponseApiConfig
    status: number,
    statusMessage: string
}
interface FailSearchdApiConfig {
    type: 'SEARCHAPICONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchApiConfig {
    type: 'CLEARSEARCHAPICONFIG'
}

type KnownAction = LoadedApiConfig | FailLoadedApiConfig | LoadApiConfig | ClearLoadedApiConfig | AddApiConfig | AddedApiConfig | FailAddedApiConfig | ClearAddApiConfig | DeleteApiConfig | DeletedApiConfig | FailDeletedApiConfig | ClearDeleteApiConfig | EditApiConfig | EditedApiConfig | FailEditdApiConfig | FailEditdApiConfig | ClearEditApiConfig | SearchApiConfig | SearchedApiConfig | FailSearchdApiConfig | ClearSearchApiConfig

export const actionCreators = {
    requestGetApiConfig: (check: boolean, method: string, sid: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'POST') {
            console.log('call actionCreators : requestGetApiConfig')
            fetch(`/v1/config/api-server?sid=` + sid + '&page=' + page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_APICONFIG' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDAPICONFIG'})
        }
    },

    requestAddApiConfig: (check: boolean, method: string, Formbody: Models.ApiConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'POST') {
            console.log('call actionCreators : requestAddApiConfig')
            fetch(`/v1/config/api-server/add`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDAPICONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'ADDEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                    //dispatch({ type: 'ADDEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'ADDAPICONFIGFAIL', status: 204, statusMessage: error.message });
                })
            dispatch({ type: 'ADD_APICONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDAPICONFIG' });
        }
    },

    requestDeleteApiConfig: (check: boolean, method: string, sid: string, api_server_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteApiConfig')
            fetch(`/v1/config/api-server/delete?sid=` + sid + '&api_server_id=' + api_server_id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETEAPICONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'DELETEDAPICONFIG', status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'DELETEDAPICONFIG', status: 200, statusMessage: '' });
            //dispatch({ type: 'DELETEAPICONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'Delete_APICONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEAPICONFIG' });
        }
    },

    requestEditApiConfig: (check: boolean, method: string, Formbody: Models.ApiConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'PUT') {
            console.log('call actionCreators : requestEditApiConfig')
            console.log(Formbody)
            fetch(`/v1/config/api-server/edit`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'EDITAPICONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'EDITDAPICONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'EDITDAPICONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'EDITAPICONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'EDIT_APICONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITAPICONFIG' });
        }
    },

    requestSearchApiConfig: (check: boolean, method: string, sid: string, api_server_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'GET') {
            console.log('call actionCreators : requestSearchApiConfig')
            fetch(`/v1/config/api-server?sid=` + sid + '&api_server_id=' + api_server_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'SEARCHAPICONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'SEARCHAPICONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'SEARCHAPICONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'SEARCHAPICONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'SEARCH_APICONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHAPICONFIG' });
        }
    },
}

const unloadedState: Models.GetApiConfigState = { isLoadApiConfig: false };
const unloadedAddState: Models.AddApiConfigState = { isLoadAddApiConfig: false };
const unloadedDeleteState: Models.DeleteApiConfigState = { isLoadDeleteApiConfig: false };
const unloadedEditState: Models.EditApiConfigState = { isLoadEditApiConfig: false };
const unloadedSearchState: Models.SearchApiConfigState = { isLoadSearchApiConfig: false };

const reducerGet: Reducer<Models.GetApiConfigState> = (state: Models.GetApiConfigState | undefined, incomingAction: Action): Models.GetApiConfigState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_APICONFIG':
            return {
                isLoadApiConfig: false,
            }

        case 'LOADEDAPICONFIG':
            return {
                isLoadApiConfig: true,
                responseApiConfig: action.response as any,
                statusApiConfig: action.status
            }

        case 'LOADAPICONFIGFAIL':
            return {
                isLoadApiConfig: true,
                messageApiConfig: action.statusMessage,
                statusApiConfig: action.status
            }
        case 'CLEARLOADEDAPICONFIG':
            return {
                isLoadApiConfig: false,
                messageApiConfig: '',
                statusApiConfig: 0
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddApiConfigState> = (state: Models.AddApiConfigState | undefined, incomingAction: Action): Models.AddApiConfigState => {
    if (state == undefined) {
        return unloadedAddState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_APICONFIG':
            return {
                isLoadAddApiConfig: false,
            }

        case 'ADDEDAPICONFIG':
            return {
                isLoadAddApiConfig: true,
                responseAddApiConfig: action.response as any,
                statusAddApiConfig: action.status
            }

        case 'ADDAPICONFIGFAIL':
            return {
                isLoadAddApiConfig: true,
                messageAddApiConfig: action.statusMessage,
                statusAddApiConfig: action.status
            }

        case 'CLEARADDAPICONFIG':
            return {
                isLoadAddApiConfig: false,
                messageAddApiConfig: "",
                statusAddApiConfig: 0
            }

        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteApiConfigState> = (state: Models.DeleteApiConfigState | undefined, incomingAction: Action): Models.DeleteApiConfigState => {
    if (state == undefined) {
        return unloadedDeleteState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'Delete_APICONFIG':
            return {
                isLoadDeleteApiConfig: false,
            }

        case 'DELETEDAPICONFIG':
            return {
                isLoadDeleteApiConfig: true,
                statusDeleteApiConfig: action.status
            }

        case 'DELETEAPICONFIGFAIL':
            return {
                isLoadDeleteApiConfig: true,
                messageDeleteApiConfig: action.statusMessage,
                statusDeleteApiConfig: action.status
            }

        case 'CLEARDELETEAPICONFIG':
            return {
                isLoadDeleteApiConfig: false,
                messageDeleteApiConfig: "",
                statusDeleteApiConfig: 0
            }

        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditApiConfigState> = (state: Models.EditApiConfigState | undefined, incomingAction: Action): Models.EditApiConfigState => {
    if (state == undefined) {
        return unloadedEditState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'EDIT_APICONFIG':
            return {
                isLoadEditApiConfig: false,
            }

        case 'EDITDAPICONFIG':
            return {
                isLoadEditApiConfig: true,
                responseEditApiConfig: action.response as any,
                statusEditApiConfig: action.status
            }

        case 'EDITAPICONFIGFAIL':
            return {
                isLoadEditApiConfig: true,
                messageEditApiConfig: action.statusMessage,
                statusEditApiConfig: action.status
            }

        case 'CLEAREDITAPICONFIG':
            return {
                isLoadEditApiConfig: false,
                messageEditApiConfig: "",
                statusEditApiConfig: 0
            }

        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchApiConfigState> = (state: Models.SearchApiConfigState | undefined, incomingAction: Action): Models.SearchApiConfigState => {
    if (state == undefined) {
        return unloadedSearchState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEARCH_APICONFIG':
            return {
                isLoadSearchApiConfig: false,
            }

        case 'SEARCHAPICONFIG':
            return {
                isLoadSearchApiConfig: true,
                responseSearchApiConfig: action.response as any,
                statusSearchApiConfig: action.status
            }

        case 'SEARCHAPICONFIGFAIL':
            return {
                isLoadSearchApiConfig: true,
                messageSearchApiConfig: action.statusMessage,
                statusSearchApiConfig: action.status
            }

        case 'CLEARSEARCHAPICONFIG':
            return {
                isLoadSearchApiConfig: false,
                messageSearchApiConfig: "",
                statusSearchApiConfig: 0
            }

        default: return state;
    }
}

export const rootReducer = combineReducers({ GetAPI: reducerGet, AddAPI: reducerAdd, DeleteAPI: reducerDelete, EditAPI: reducerEdit, SearchAPI: reducerSearch });