import * as React from 'react';
import { withRouter, RouteProps } from 'react-router-dom';
import queryString from 'query-string';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FaRegListAlt, FaSlidersH, FaUserCircle, FaEllipsisH } from "react-icons/fa";
import { checkExp, parseJwt, getRedirectAppPoolUrl } from '../modules/common';
import Swal from 'sweetalert2';

import { ApplicationState } from "../store";
import { connect } from "react-redux";

import * as lisCommandModels from '../models/liscommand/liscommand'
import * as lisCommandStore from '../store/liscommand/liscommand'

var imgFile = require('../image/f1-new-logo.png');
type store = lisCommandModels.GetEnvState & typeof lisCommandStore.actionCreators

interface State {
    isOpen: boolean,
    version: string,
    user: string,
    checkTypeUser: string,
    urlPathDisableMenu: string[],
}

interface Props extends RouteProps {
    [key: string]: any;
}

class NavMenu extends React.Component<Props, State, store> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            version: '',
            user: '',
            checkTypeUser: "user",
            urlPathDisableMenu: ['/legacyreport-manual'],
        };
    }

    componentDidMount() {
        //localStorage.setItem('LG_APPLICATION', '{"user_id":"555","access_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFwbXVveDhJTjdoZEcwV3pJUFZxTXdiMnhUZSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXhwIjoxOTMxMzcwMDI0LCJpYXQiOjE2MTU4MDA3NjQsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.l2T9zbgSVrmJ9v89KNBhLAcSsKjrWzfyOgyAEtDwKrwytyU8F6acVz72ocZyeEoo0JJ8zyWKtNYVBNLZtVXYlgwfXb7nXlMcnK8fmPzykH4nYBdoSvoEvbR4I5RilYgLBTaWkT3P2rqu2G1_LWFfAfEAEFoDvj8Y5dhS4LUfKkM","company_code" : "2580"}');
        var ls: any = localStorage.getItem("LG_APPLICATION");
        var userId = "";
        var checkUser = "";

        if (ls) {
            var jls: any = JSON.parse(ls)
            var accessToken = jls.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);
            checkExp(jwt)

            if (extra.username) {
                userId = extra.username
                checkUser = extra.username
            }
            else if (extra.first_name) {
                userId = extra.first_name + " " + extra.last_name
                checkUser = extra.citizen_id
            }
            else if (extra.citizen_id) {
                userId = extra.citizen_id
                checkUser = extra.citizen_id
            }

            if (userId == "") {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่พบ user ใน token',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน',
                }).then((result) => { getRedirectAppPoolUrl() })
            }
            this.setState({ user: userId })
        }

        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                    console.log(error)
                }
            )

        fetch("/v1/user?id=" + checkUser + '&role_access=admin',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        this.setState({
                            checkTypeUser: data[0].list_company[0].role_access
                        });
                    } else {
                        if (result.message == "No such host is known." || result.message == "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ยืนยัน',
                            }).then((result) => { window.location.reload() })
                        }
                    }
                },
                (error) => {
                    console.log(error)
                }
            )

        if (checkUser != "") {
            fetch("/v1/user?id=" + checkUser,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + ls
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        var data = result.total_count
                        if (data == 0) {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่พบ user นี้ในระบบ',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ยืนยัน',
                            }).then((result) => { getRedirectAppPoolUrl() })
                        }
                    },
                    (error) => {
                    }
                )
        }

        this.props.requestGetEnvApiUrl(true, "GET")
    }

    private toggleIsOpen = () => this.setState({ isOpen: !this.state.isOpen });

    public render() {
        const params = queryString.parse(this.props.location?.search);
        const isNewTab = params._blank; // Thx BrownGame!

        return (
            <header>
                <Navbar
                    className="bg-navbar border-bottom box-shadow mb-3"
                    expand="lg"
                    light
                >
                    <NavbarBrand className="d-flex align-items-center">
                        <div className="trapezoid-logo d-none d-sm-block" style={{ color: '#adb5bd' }}>
                            {/*<img src={logo} style={{ width: '96px', height: '40px', cursor: 'pointer', marginTop: '4px;}} >*/}
                            {/*<a href={'/legacyreport'}>*/}
                                {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />*/}
                                < img src={imgFile} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />
                            {/*</a>*/}
                            {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} width="96px" height="40" style={{marginTop:'4px'}} />
                                            <img src={imgFile} alt="file" width="25" height="25" />*/}
                        </div>
                        <div
                            className="d-flex flex-column justify-content-center align-items-start text-nowrap text-left pl-2
                                        flex-sm-row justify-content-sm-start align-items-sm-center"
                            style={{ gap: '0 5px' }}
                        >
                            <span>FocusOne Legacy Interface Scheduler</span>
                            <span style={{ fontSize: '12px' }}>V {this.state.version}</span>
                        </div>
                    </NavbarBrand>
                    <NavbarToggler className="mr-2" onClick={() => this.toggleIsOpen()} />
                    <Collapse isOpen={this.state.isOpen} className="flex-row-reverse" navbar>
                        <Nav className="d-flex flex-row justify-content-end px-3" style={{ gap: '0 12px' }} navbar>
                            <NavItem>
                                <NavLink className="text-dark font-navbar" >{this.state.user}</NavLink>
                            </NavItem>
                            {this.props.location &&
                                !this.state.urlPathDisableMenu.includes(this.props.location.pathname) &&
                                (isNewTab === 'false' || isNewTab === undefined) &&
                                <DropdownButton id="dropdown-basic-button" title={<FaRegListAlt />} variant="outline-dark" size="sm" >
                                    <Dropdown.Item><Link to="/legacyreport">Report Legacy Monitoring</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/legacyreport-manual">Report Legacy Manual</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/legacytask">Report Legacy Task Monitoring</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/legacylog">System Log</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/quartzmonitor">Quartz Monitor</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/uploadfileconfig">Create Structure</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/downloadsourceandresponsefile">Download Source And Response File</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/applicationlog">Application Log</Link></Dropdown.Item>
                                    {/*<Dropdown.Item><Link to="/daily-report">Daily Report</Link></Dropdown.Item>*/}
                                </DropdownButton>
                            }
                            {this.props.location &&
                                !this.state.urlPathDisableMenu.includes(this.props.location.pathname) &&
                                (isNewTab === 'false' || isNewTab === undefined) &&
                                this.state.checkTypeUser == 'admin' &&
                                <DropdownButton id="dropdown-basic-button" title={<FaSlidersH />} variant="outline-dark" size="sm">
                                    <Dropdown.Item><Link to="/masterconfig">Job Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/pulljobconfig">Pull Job Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/pulljobconfig-log">Pull Job Config Log</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/companyconfig">API Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/sftpconfig">sFTP Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/dmsconfig">DMS Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/emailcontent">Email Content</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/user-registration">User Registration</Link></Dropdown.Item>
                                </DropdownButton>
                            }
                            {this.props.location &&
                                !this.state.urlPathDisableMenu.includes(this.props.location.pathname) &&
                                (isNewTab === 'false' || isNewTab === undefined) &&
                                this.state.checkTypeUser == 'super_admin' &&
                                <DropdownButton id="dropdown-basic-button" title={<FaSlidersH />} variant="outline-dark" size="sm">
                                    <Dropdown.Item><Link to="/masterconfig">Job Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/pulljobconfig">Pull Job Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/pulljobconfig-log">Pull Job Config Log</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/companyconfig">API Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/sftpconfig">sFTP Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/dmsconfig">DMS Config</Link></Dropdown.Item>
                                    {/*<Dropdown.Item><Link to="/quartzmonitor">Quartz Monitor</Link></Dropdown.Item>*/}
                                    {/*<Dropdown.Item><Link to="/uploadfileconfig">Upload file Config</Link></Dropdown.Item>*/}
                                    <Dropdown.Item><Link to="/employee">Employee Config</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/emailcontent">Email Content</Link></Dropdown.Item>
                                    <Dropdown.Item><Link to="/user-registration">User Registration</Link></Dropdown.Item>
                                </DropdownButton>
                            }
                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar" to="/logout">Back to Portal</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.lisCommand }), ({ ...lisCommandStore.actionCreators }))(withRouter(NavMenu as any));