import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/testConnect/FappConnection'

interface LoadFappTest {
    type: 'LOAD_FAPPTEST'
}

interface LoadedFappTest {
    type: 'LOADEDFAPPTEST'
    response: Models.ResponseTestFapp
    status: number,
    statusMessage: string
}
interface FailLoadedFappTest {
    type: 'LOADFAPPTESTFAIL'
    status: number,
    statusMessage: any
}
interface ClearFappTest {
    type: 'CLEARFAPPTEST'
}


type KnownAction = LoadedFappTest | FailLoadedFappTest | LoadFappTest | ClearFappTest

export const actionCreators = {
    requestGetFappTest: (check: boolean, method: string, apiUrl : string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.TestFapp && check && method == 'GET') {
            console.log('call actionCreators : requestGetFappTest')
            fetch(`/v1/test/fapp/connection?apiUrl=` + apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADFAPPTESTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDFAPPTEST', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
                    //dispatch({ type: 'LOADEDFAPPTEST', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'LOADFAPPTESTFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'LOAD_FAPPTEST' });

        } else if (appState && appState.TestFapp && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARFAPPTEST' });
        }

    }
}

const unloadedState: Models.TestFappState = { isLoadTestFapp: false };

export const reducer: Reducer<Models.TestFappState> = (state: Models.TestFappState | undefined, incomingAction: Action): Models.TestFappState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_FAPPTEST':
            return {
                isLoadTestFapp: false,
            }

        case 'LOADEDFAPPTEST':
            return {
                isLoadTestFapp: true,
                responseTestFapp: action.response as any,
                statusTestFapp: action.status
            }

        case 'LOADFAPPTESTFAIL':
            return {
                isLoadTestFapp: true,
                messageTestFapp: action.statusMessage,
                statusTestFapp: action.status
            }

        case 'CLEARFAPPTEST':
            return {
                isLoadTestFapp: false,
                messageTestFapp: '',
                statusTestFapp: 400
            }
        default: return state;
    }
}