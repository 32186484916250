import * as React from 'react';
import { Row, Col, Button, Table, Spinner, ButtonGroup, Modal, Form, Dropdown, DropdownButton, InputGroup, FormControl } from 'react-bootstrap';
import { FaPlayCircle, FaCloudDownloadAlt, FaCloudUploadAlt, FaTimesCircle, FaFile, FaListUl, FaEye, FaSearch, FaDownload } from "react-icons/fa";
import Swal from 'sweetalert2'
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage, getBase64, format_time, splitFilePath, resetFormatDMY, getLegacyIDWithPath } from '../../modules/Common'
import * as LegacyReportModels from '../../../models/report/LegacyReport'
import * as LegacyReportStore from '../../../store/report/LegacyReport'
import * as LoadingStore from '../../../store/Loading'
import * as LoadingModels from '../../../models/config/Loading'

type Store = LegacyReportModels.GetLegacyReportState
    & typeof LegacyReportStore.actionCreators
    | LoadingModels.LoadingState
    & typeof LoadingStore.actionCreators

interface State {
    response: Array<LegacyReportModels.LegacyReport>,
    isready: boolean,
    userId: any,
    companyCode: any,
    checkready: boolean,
    formData: Array<LegacyReportModels.LegacyReport>,
    checkActive: boolean,
    checkReload: boolean,
    checkFilter: boolean,
    checkError: boolean,
    checkSuspend: boolean,
    keyFilter: string,
    isshowListFile: boolean,
    isshowListFileView: boolean,
    fileListType: string,
    fileListTypeDes: string,
    checkTypeUser: any,
    isShowTempModal: boolean,
    isSelectVal: any,
    dataSftpFile: any,
    isShowErrorFile: boolean,
    isShowOption: boolean,
    isShowAlertConfirmModal: boolean,
    fileListDes: string,
    fileListKey: string,
    getFileDataTable: LegacyReportModels.GetFileListRes,
    checkGetFileDataTable: boolean,
    fileSearchKey: string,
    passwordFromApi: string,
    uploadfileReq: any,
    username: string,
}

class LegacyReportManualTable extends React.Component<any, State, Store>{
    //uploadFileRef = React.createRef<HTMLInputElement>();
    private uploadFileRef: React.RefObject<HTMLInputElement>;
    constructor(props: any) {
        super(props);
        this.state = {
            response: [],
            isready: true,
            userId: '',
            companyCode: [],
            checkready: true,
            formData: [],
            checkActive: false,
            checkFilter: false,
            checkError: false,
            checkSuspend: false,
            checkReload: true,
            keyFilter: '',
            isshowListFile: false,
            isshowListFileView: false,
            fileListType: '',
            fileListTypeDes: '',
            checkTypeUser: undefined,
            isShowTempModal: false,
            isShowAlertConfirmModal: false,
            isSelectVal: null,
            dataSftpFile: {
                legacy_id: "",
                company_code: "",
                type_id: "",
                job_instance_no: ""
            },
            isShowErrorFile: false,
            isShowOption: true,
            fileListDes: "",
            fileListKey: "",
            getFileDataTable: {
                total_count: 0,
                result_list: []
            },
            checkGetFileDataTable: false,
            fileSearchKey: "",
            passwordFromApi: "",
            uploadfileReq: {},
            username: "",
        }
        this.uploadFileRef = React.createRef<HTMLInputElement>();
        this.runImmediatelyHandleClick = this.runImmediatelyHandleClick.bind(this);
        this.continuteHandleClick = this.continuteHandleClick.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleShowListFile = this.handleShowListFile.bind(this);
        this.handleShowListFileView = this.handleShowListFileView.bind(this);
    }

    componentDidMount() {
        const { isready, userId, companyCode } = this.state
        const userIdUse = this.getUser();

        fetch("/v1/user?id=" + userIdUse,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        if (result.result_list[0].list_company[0].company_code == "*") {
                            this.getLegacyReport([])
                        } else {
                            this.getLegacyReport(result.result_list[0].list_company)
                        }
                    } else {
                        this.props.requestGetLegacyReportV2(true, "NOUSER", "", "", userIdUse)
                    }
                },
                (error) => {
                }
            )

        fetch("/v1/user?id=" + userIdUse + '&role_access=admin',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem("LG_APPLICATION")
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        this.setState({
                            checkTypeUser: data[0].list_company[0].role_access
                        });
                    }
                },
                (error) => {
                }
            )
    }

    getUser(): string {
        var ls: any = localStorage.getItem("LG_APPLICATION");
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        var jwt = parseJwt(accessToken);
        var extra = JSON.parse(jwt.extra);
        var userIdUse = ""

        if (extra.username) {
            userIdUse = extra.username
        }
        else if (extra.citizen_id) {
            userIdUse = extra.citizen_id
        }

        this.setState({ username: userIdUse });

        return userIdUse;
    }

    getLegacyReport(companyCodeUse) {
        var dataset: any = []

        companyCodeUse.map((item) => {
            dataset.push(item.company_code)
        })

        this.setState({
            companyCode: dataset
        })

        const { isready, userId, companyCode } = this.state

        if (isready) {
            this.props.requestGetLegacyReportV2(isready, "POST", "", dataset, this.state.username)
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { isLoadLegacyReport, responseLegacyReport, statusLegacyReport, GetReport, checkkey, GetFile, DeleteFile, PutFile, search } = this.props
        const { isready, checkready, companyCode } = this.state
        if (!isready && checkready && GetReport.isLoadLegacyReport && GetReport.statusLegacyReport == 200 && GetReport.responseLegacyReport != undefined) {
            this.setState({
                response: GetReport.responseLegacyReport['result_list'],
                checkready: false
            })
            this.props.callbackkey()
        }

        if (search == "Search") {
            this.handleSearchDataReport()
        }

        if (checkkey == "reset" && checkkey != undefined && !this.state.checkready) {
            this.props.requestGetLegacyReportV2(true, "CLEAR", "", "", this.state.username)
            this.setState({
                formData: [],
                checkready: true,
                isready: true,
            })
            this.props.requestGetLegacyReportV2(true, "POST", "", companyCode, this.state.username)
        }

        if (checkkey == "Active") {
            this.setState({
                checkReload: false,
                checkActive: true
            })
            this.handleActive()
        } else if (checkkey == "UnActive") {
            this.props.callbackkey()
            this.setState({
                formData: GetReport.responseLegacyReport['result_list'],
                checkActive: false,
                checkReload: true
            })
            if (this.state.checkFilter) {
                this.handleFilter()
            }
            this.forceUpdate()
        } else if (checkkey == "CheckFilter") {
            this.setState({
                keyFilter: this.props.filterKey,
                checkReload: false,
                checkFilter: true
            })
            this.handleFilter()
        } else if (checkkey == "UnCheckFilter") {
            this.props.callbackkey()
            this.setState({
                formData: GetReport.responseLegacyReport['result_list'],
                checkFilter: false,
                checkReload: true
            })
            //if (this.state.checkFilter) {
            //    this.handleActive()
            //}
            this.forceUpdate()
        } else if (checkkey == "Suspend") {
            this.setState({
                checkReload: false,
                checkSuspend: true
            })
            this.handleSuspend()
        } else if (checkkey == "UnSuspend") {
            this.props.callbackkey()
            this.setState({
                formData: GetReport.responseLegacyReport['result_list'],
                checkSuspend: false,
                checkReload: true
            })
        } else if (checkkey == "Error") {
            this.setState({
                checkReload: false,
                checkError: true
            })
            this.handleError()
        } else if (checkkey == "UnError") {
            this.props.callbackkey()
            this.setState({
                formData: GetReport.responseLegacyReport['result_list'],
                checkError: false,
                checkReload: true
            })
        }

        if (GetReport.statusLegacyReport == 200 && GetReport.isLoadLegacyReport && GetReport.responseLegacyReport['result_list'] != null && this.state.isready) {
            var activeArr: any = []
            var N_activeArr: any = []
            for (let i = 0; i < GetReport.responseLegacyReport['result_list'].length; i++) {
                if (GetReport.responseLegacyReport['result_list'][i]['isactive'] == "Y") {
                    activeArr.push(GetReport.responseLegacyReport['result_list'][i])
                } else {
                    N_activeArr.push(GetReport.responseLegacyReport['result_list'][i])
                }
            }
            activeArr.sort(function (a, b) {
                var keyA = a.job_started_on,
                    keyB = b.job_started_on;
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });

            N_activeArr.sort(function (a, b) {
                var keyA = a.job_started_on,
                    keyB = b.job_started_on;
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });

            var dataArr = activeArr.concat(N_activeArr)
            //var dataArr = GetReport.responseLegacyReport['result_list']
            this.setState({
                formData: dataArr,
                isready: false,
                checkReload: true
            })
        }

        if ((this.props.filterKey || this.props.filterCompany || this.props.reportStatus.Active || this.props.reportStatus.Suspend || this.props.reportStatus.Error || this.props.reportStatus.Running || this.props.reportStatus.Finished || this.props.reportStatus.Warning) && this.state.formData.length != 0 && this.state.checkReload) {
            this.setState({
                checkReload: false,
                formData: []
            })
            this.handleSearchDataReport()
        }

        /*if ((this.state.checkFilter || this.state.checkActive || this.state.checkSuspend || this.state.checkError) && this.state.formData.length != 0 && this.state.checkReload) {
            this.setState({
                checkReload: false,
                formData: []
            })
            this.ChangeRefresh()
        }*/

        if (this.state.checkFilter && this.props.filterKey != this.state.keyFilter && !this.state.checkready && this.props.filterKey != undefined && this.props.filterKey != "") {
            this.setState({
                keyFilter: this.props.filterKey
            })
            this.handleFilter()
        }

        if (GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && !this.state.isshowListFile) {
            this.props.UseLoading(false)
            this.setState({
                isshowListFile: true
            })
        } else if (GetFile.isLoadGetFileList && GetFile.statusGetFileList != 200) {
            var msg = JSON.parse(GetFile.messageGetFileList)
            this.props.requestGetFileList(true, "CLEAR", "", "")
            this.props.UseLoading(false)
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        isshowListFile: false
                    })
                }
            })
        }

        if (DeleteFile.isLoadDeleteSftpFile && DeleteFile.statusDeleteSftpFile == 200) {
            this.props.requestDeleteSftpFile(true, "CLEAR", [])
            this.props.requestGetFileList(true, "POST", this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['type_id'], this.state.dataSftpFile['company_code'], this.state.dataSftpFile['job_instanceno'])
            this.props.UseLoading(false)
        } else if (DeleteFile.isLoadDeleteSftpFile && DeleteFile.statusDeleteSftpFile != 200) {
            this.props.requestDeleteSftpFile(true, "CLEAR", [])
            this.props.requestGetFileList(true, "POST", this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['type_id'], this.state.dataSftpFile['company_code'], this.state.dataSftpFile['job_instanceno'])
            this.props.UseLoading(false)
            var msg = JSON.parse(DeleteFile.messageDeleteSftpFile)
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }

        if (PutFile.isLoadPutSftpFile && PutFile.statusPutSftpFile == 200) {
            this.props.requestAddSftpFile(true, "CLEAR", [])
            this.props.requestGetFileList(true, "POST", this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['type_id'], this.state.dataSftpFile['company_code'], this.state.dataSftpFile['job_instanceno'])
            this.props.UseLoading(false)
        } else if (PutFile.isLoadPutSftpFile && PutFile.statusPutSftpFile != 200) {
            this.props.requestAddSftpFile(true, "CLEAR", [])
            this.props.requestGetFileList(true, "POST", this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['type_id'], this.state.dataSftpFile['company_code'], this.state.dataSftpFile['job_instanceno'])
            this.props.UseLoading(false)
            var msg = JSON.parse(PutFile.messagePutSftpFile)
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }

        //search file
        if (GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && this.state.getFileDataTable.total_count == 0 && !this.state.checkGetFileDataTable) {
            this.setState({
                getFileDataTable: GetFile.responseGetFileList,
                checkGetFileDataTable: true
            })
        }
    }

    async handleSearchDataReport() {
        const { search, resetSearch, filterKey, filterCompany, reportStatus, filterLegacyName } = this.props
        var data: any = this.props.GetReport.responseLegacyReport['result_list'];
        var Arrdata: any = []
        if (reportStatus.Active != "") {
            for (let i = 0; i < data.length; i++) {
                if (data[i].isactive == 'Y') {
                    Arrdata.push(data[i])
                }
            }
        }
        if (filterKey != "") {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    var upper: number = Arrdata[i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                    var lower: number = Arrdata[i].legacyid.indexOf(this.props.filterKey.toLowerCase())
                    if (upper > -1 || lower > -1 || Arrdata[i].legacyid.indexOf(this.props.filterKey) > -1) {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    var upper: number = data[i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                    var lower: number = data[i].legacyid.indexOf(this.props.filterKey.toLowerCase())
                    if (upper > -1 || lower > -1 || data[i].legacyid.indexOf(this.props.filterKey) > -1) {
                        Arrdata.push(data[i])
                    }
                }
            }
        }
        if (filterLegacyName != "") {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    var upper: number = Arrdata[i].legacyname.indexOf(this.props.filterLegacyName.toUpperCase())
                    var lower: number = Arrdata[i].legacyname.indexOf(this.props.filterLegacyName.toLowerCase())
                    if (upper > -1 || lower > -1 || Arrdata[i].legacyname.indexOf(this.props.filterLegacyName) > -1) {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    var upper: number = data[i].legacyname.indexOf(this.props.filterLegacyName.toUpperCase())
                    var lower: number = data[i].legacyname.indexOf(this.props.filterLegacyName.toLowerCase())
                    if (upper > -1 || lower > -1 || data[i].legacyname.indexOf(this.props.filterLegacyName) > -1) {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (filterCompany != "") {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    var upper: number = Arrdata[i].companycode.indexOf(this.props.filterCompany.toUpperCase())
                    var lower: number = Arrdata[i].companycode.indexOf(this.props.filterCompany.toLowerCase())
                    if (upper > -1 || lower > -1 || Arrdata[i].companycode.indexOf(this.props.filterCompany) > -1) {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    var upper: number = data[i].companycode.indexOf(this.props.filterCompany.toUpperCase())
                    var lower: number = data[i].companycode.indexOf(this.props.filterCompany.toLowerCase())
                    if (upper > -1 || lower > -1 || data[i].companycode.indexOf(this.props.filterCompany) > -1) {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (reportStatus.Suspend != "") {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].hassuspend == 'Y') {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].hassuspend == 'Y') {
                        Arrdata.push(data[i])
                    }
                }
            }
        }


        var ArrdataFilter: any = []
        if (reportStatus.Error != "") {
            if (reportStatus.Active == "" && filterKey == "" && filterLegacyName == "" && filterCompany == "" && reportStatus.Suspend == "") {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "ERROR" || data[i].step2_status == "ERROR" || data[i].step3_status == "ERROR" || data[i].step4_status == "ERROR") {
                        ArrdataFilter.push(data[i])
                    }
                }
            }
            else if (Arrdata != null && Arrdata != "") {
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].step1_status == "ERROR" || Arrdata[i].step2_status == "ERROR" || Arrdata[i].step3_status == "ERROR" || Arrdata[i].step4_status == "ERROR") {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "ERROR" || data[i].step2_status == "ERROR" || data[i].step3_status == "ERROR" || data[i].step4_status == "ERROR") {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (reportStatus.Running != "") {
            if (reportStatus.Active == "" && filterKey == "" && filterLegacyName == "" && filterCompany == "" && reportStatus.Suspend == "") {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "RUNNING" || data[i].step2_status == "RUNNING" || data[i].step3_status == "RUNNING" || data[i].step4_status == "RUNNING") {
                        ArrdataFilter.push(data[i])
                    }
                }
            }
            else if (Arrdata != null && Arrdata != "") {
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].step1_status == "RUNNING" || Arrdata[i].step2_status == "RUNNING" || Arrdata[i].step3_status == "RUNNING" || Arrdata[i].step4_status == "RUNNING") {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "RUNNING" || data[i].step2_status == "RUNNING" || data[i].step3_status == "RUNNING" || data[i].step4_status == "RUNNING") {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (reportStatus.Warning != "") {
            if (reportStatus.Active == "" && filterKey == "" && filterLegacyName == "" && filterCompany == "" && reportStatus.Suspend == "") {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "WARNING" || data[i].step2_status == "WARNING" || data[i].step3_status == "WARNING" || data[i].step4_status == "WARNING") {
                        ArrdataFilter.push(data[i])
                    }
                }
            }
            else if (Arrdata != null && Arrdata != "") {
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].step1_status == "WARNING" || data[i].step2_status == "WARNING" || data[i].step3_status == "WARNING" || data[i].step4_status == "WARNING") {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "WARNING" || data[i].step2_status == "WARNING" || data[i].step3_status == "WARNING" || data[i].step4_status == "WARNING") {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (reportStatus.Finished != "") {
            if (reportStatus.Active == "" && filterKey == "" && filterLegacyName == "" && filterCompany == "" && reportStatus.Suspend == "") {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].status == "FINISHED") {
                        ArrdataFilter.push(data[i])
                    }
                }
            }
            else if (Arrdata != null && Arrdata != "") {
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].status == "FINISHED") {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].status == "FINISHED") {
                        Arrdata.push(data[i])
                    }
                }
            }
        }

        if (ArrdataFilter.length > 0) {
            Arrdata = ArrdataFilter;
        }

        if (reportStatus.Active == "" && reportStatus.Suspend == "" && reportStatus.Error == "" && reportStatus.Running == "" && reportStatus.Finished == "" && reportStatus.Warning == "" && filterKey == "" && filterCompany == "" && filterLegacyName == "") {
            this.setState({
                formData: data,
            })
        } else {
            this.setState({
                formData: Arrdata,
            })
        }


        resetSearch()
        this.props.callbackkey()
    }

    async handleActive() {
        var PRDdata: any = []
        if (this.state.checkFilter) {
            for (let i = 0; i < this.state.formData.length; i++) {
                if (this.state.formData[i].isactive == 'Y') {
                    PRDdata.push(this.state.formData[i])
                }
            }
        } else {
            this.setState({
                formData: [],
            })
            for (let i = 0; i < this.props.GetReport.responseLegacyReport['result_list'].length; i++) {
                if (this.props.GetReport.responseLegacyReport['result_list'][i]['isactive'] == 'Y') {
                    PRDdata.push(this.props.GetReport.responseLegacyReport['result_list'][i])
                }
            }
        }
        this.setState({
            formData: PRDdata,
            checkActive: true
        })

        this.props.callbackkey()
        //this.forceUpdate()
    }

    async handleSuspend() {
        var PRDdata: any = []
        for (let i = 0; i < this.state.formData.length; i++) {
            if (this.state.formData[i].hassuspend == 'Y') {
                PRDdata.push(this.state.formData[i])
            }
        }
        this.setState({
            formData: PRDdata,
            checkSuspend: true
        })

        this.props.callbackkey()
        //this.forceUpdate()
    }

    async handleError() {
        var Arrdata: any = []
        for (let i = 0; i < this.state.formData.length; i++) {
            if (this.state.formData[i].step1_status == "ERROR" || this.state.formData[i].step2_status == "ERROR" || this.state.formData[i].step3_status == "ERROR" || this.state.formData[i].step4_status == "ERROR") {
                Arrdata.push(this.state.formData[i])
            }
        }
        this.setState({
            formData: Arrdata,
            checkError: true
        })

        this.props.callbackkey()
        //this.forceUpdate()
    }

    async handleFilter() {
        var Arrdata: any = []
        if (!this.state.checkActive) {
            for (let i = 0; i < this.state.formData.length; i++) {
                var upper: number = this.state.formData[i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                var lower: number = this.state.formData[i].legacyid.toLowerCase().indexOf(this.props.filterKey.toLowerCase())
                if (upper > -1 || lower > -1 || this.state.formData[i].legacyid.indexOf(this.props.filterKey) > -1) {
                    Arrdata.push(this.state.formData[i])
                }
            }
        } else {
            for (let i = 0; i < this.props.GetReport.responseLegacyReport['result_list'].length; i++) {
                var upper: number = this.props.GetReport.responseLegacyReport['result_list'][i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                var lower: number = this.props.GetReport.responseLegacyReport['result_list'][i].legacyid.indexOf(this.props.filterKey.toLowerCase())
                if (upper > -1 || lower > -1 || this.props.GetReport.responseLegacyReport['result_list'][i].legacyid.indexOf(this.props.filterKey) > -1) {
                    Arrdata.push(this.props.GetReport.responseLegacyReport['result_list'][i])
                }
            }
        }
        this.setState({
            formData: Arrdata,
            checkFilter: true
        })
        this.props.callbackkey()
    }


    async ChangeRefresh() {
        var data: any = this.props.GetReport.responseLegacyReport['result_list'];
        var Arrdata: any = []
        if (this.state.checkActive) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].isactive == 'Y') {
                    Arrdata.push(data[i])
                }
            }
        }
        if (this.state.checkFilter) {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    var upper: number = Arrdata[i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                    var lower: number = Arrdata[i].legacyid.indexOf(this.props.filterKey.toLowerCase())
                    if (upper > -1 || lower > -1 || Arrdata[i].legacyid.indexOf(this.props.filterKey) > -1) {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    var upper: number = data[i].legacyid.indexOf(this.props.filterKey.toUpperCase())
                    var lower: number = data[i].legacyid.indexOf(this.props.filterKey.toLowerCase())
                    if (upper > -1 || lower > -1 || data[i].legacyid.indexOf(this.props.filterKey) > -1) {
                        Arrdata.push(data[i])
                    }
                }
            }

        }
        if (this.state.checkSuspend) {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].hassuspend == 'Y') {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].hassuspend == 'Y') {
                        Arrdata.push(data[i])
                    }
                }
            }
        }
        if (this.state.checkError) {
            if (Arrdata != null && Arrdata != "") {
                var ArrdataFilter: any = []
                for (let i = 0; i < Arrdata.length; i++) {
                    if (Arrdata[i].step1_status == "ERROR" || Arrdata[i].step2_status == "ERROR" || Arrdata[i].step3_status == "ERROR" || Arrdata[i].step4_status == "ERROR") {
                        ArrdataFilter.push(Arrdata[i])
                    }
                }
                Arrdata = ArrdataFilter;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step1_status == "ERROR" || data[i].step2_status == "ERROR" || data[i].step3_status == "ERROR" || data[i].step4_status == "ERROR") {
                        Arrdata.push(data[i])
                    }
                }
            }

        }

        this.setState({
            formData: Arrdata,
        })
        this.props.callbackkey()
    }

    continuteHandleClick(sid: string, companycode: string, legacyid: string) {
        this.setState({
            isready: true,
            formData: []
        })
        this.props.requestGetLegacyReportV2(true, "CLEAR", "", "", this.state.username)
        this.props.UseLoading(true)
        this.props.callbackStopAutoRefresh()
        fetch(`/v1/job/resume?sid=` + sid + '&company_code=' + companycode + '&legacy_id=' + legacyid, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                this.props.requestGetLegacyReportV2(true, "POST", "", this.state.companyCode, this.state.username)
                this.props.UseLoading(false)
                if (!response.ok) {
                    alert(new Error(response.json() as any))
                } else {
                    Swal.fire({
                        title: 'สำเร็จ',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .then((data) => {
                console.log(data)
                //alert(data)
            })
            .catch(error => {
                console.log('errorrrr')
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
    }

    runImmediatelyHandleClick(sid: string, companycode: string, legacyid: string, legacytype: string, important_job: string) {
        Swal.fire({
            title: 'ต้องการรีรันหรือไม่',
            text: 'ยืนยันคำสั่งรีรันหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.isConfirmed) {
                if (important_job && important_job.toLowerCase() != 'true') {
                    let job_password = '1q2w3e4r';
                    Swal.fire({
                        title: 'กรุณาระบุรหัสผ่าน',
                        input: 'password',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        showLoaderOnConfirm: true,
                        preConfirm: (password) => {
                            if (password == null || password == "") {
                                Swal.showValidationMessage(
                                    'กรุณากรอกรหัสผ่าน'
                                )
                            }
                            else if (password == job_password) {
                                this.runImmediatelyHandle(sid, companycode, legacyid, legacytype)
                            }
                            else {
                                Swal.showValidationMessage(
                                    'รหัสผ่านไม่ถูกต้อง'
                                )
                            }
                        }
                    })
                }
                else {
                    this.runImmediatelyHandle(sid, companycode, legacyid, legacytype)
                }
            }
        })
    }

    runImmediatelyHandle(sid: string, companycode: string, legacyid: string, legacytype: string) {
        this.setState({
            isready: true,
            formData: []
        })
        this.props.requestGetLegacyReportV2(true, "CLEAR", "", "", this.state.username)
        this.props.UseLoading(true)
        this.props.callbackStopAutoRefresh()
        fetch(`/v1/lis-server/run-immediate?sid=` + sid + '&company_code=' + companycode + '&legacy_id=' + legacyid + '&legacy_type=' + legacytype, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                this.props.requestGetLegacyReportV2(true, "POST", "", this.state.companyCode, this.state.username)
                this.props.UseLoading(false)
                if (!response.ok) {
                    alert(new Error(response.json() as any))
                } else {
                    Swal.fire({
                        title: 'สำเร็จ',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .then((data) => {
                console.log(data)
                //alert(data)
            })
            .catch(error => {
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
    }

    checkPassword() {
        let password: string = (document.getElementById("passwordconfirm") as HTMLInputElement).value;
        if (this.state.passwordFromApi == password) {
            Swal.fire({
                title: 'สำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                    //this.props.callbackStartAutoRefresh()
                    this.setState({
                        isShowAlertConfirmModal: false
                    })
                    return;
                }
            })
        }
        else {
            Swal.fire({
                title: 'พาสเวิร์ดไม่ถูกต้อง',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                return;
            })
        }
    }

    CancelHandleClick(sid: string, companycode: string, legacyid: string, job_instance_no: string) {
        this.setState({
            isready: true,
            formData: []
        })
        this.props.requestGetLegacyReportV2(true, "CLEAR", "", "", this.state.username)
        this.props.UseLoading(true)
        this.props.callbackStopAutoRefresh()
        fetch(`/v1/lis-server/cancel?sid=` + sid + '&company_code=' + companycode + '&legacy_id=' + legacyid + '&job_instance_no=' + job_instance_no, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                this.props.requestGetLegacyReportV2(true, "POST", "", this.state.companyCode, this.state.username)
                this.props.UseLoading(false)

                return response
            })
            .then((data) => {
                if (!data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
    }

    runStepHandleClick(sid: string, companycode: string, legacytype: string, legacyid: string, job_instance_no: string, step: string, important_job: string) {
        Swal.fire({
            title: 'ต้องการรีรันหรือไม่',
            text: 'ยืนยันคำสั่งรีรันหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.isConfirmed) {
                if (important_job && important_job.toLowerCase() != 'true') {
                    let job_password = '1q2w3e4r';
                    Swal.fire({
                        title: 'กรุณาระบุรหัสผ่าน',
                        input: 'password',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        showLoaderOnConfirm: true,
                        preConfirm: (password) => {
                            if (password == null || password == "") {
                                Swal.showValidationMessage(
                                    'กรุณากรอกรหัสผ่าน'
                                )
                            }
                            else if (password == job_password) {
                                this.runStepHandle(sid, companycode, legacytype, legacyid, job_instance_no, step)
                            }
                            else {
                                Swal.showValidationMessage(
                                    'รหัสผ่านไม่ถูกต้อง'
                                )
                            }
                        }
                    })
                }
                else {
                    this.runStepHandle(sid, companycode, legacytype, legacyid, job_instance_no, step)
                }
            }
        })
    }

    runStepHandle(sid: string, companycode: string, legacytype: string, legacyid: string, job_instance_no: string, step: string) {
        //Swal.fire({
        //    title: 'ต้องการรีรันหรือไม่',
        //    text: 'คุณต้องการรีรัน Legacy ID นี้หรือไม่ ?',
        //    icon: 'warning',
        //    showCancelButton: true,
        //    confirmButtonColor: '#3085d6',
        //    confirmButtonText: 'ตกลง',
        //    cancelButtonText: 'ไม่ตกลง',
        //    cancelButtonColor: '#FF0000',
        //    allowOutsideClick: false
        //}).then((result) => {
        //    if (result.value) {
        this.setState({
            isready: true,
            formData: []
        })
        this.props.requestGetLegacyReportV2(true, "CLEAR", "", "", this.state.username)
        this.props.UseLoading(true)
        //this.props.callbackStopAutoRefresh()
        fetch(`/v1/lis-server/run-step?sid=` + sid + '&company_code=' + companycode + '&legacy_type=' + legacytype + '&legacy_id=' + legacyid + '&job_instance_no=' + job_instance_no + '&step=' + step, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                this.props.requestGetLegacyReportV2(true, "POST", "", this.state.companyCode, this.state.username)
                this.props.UseLoading(false)

                return response
            })
            .then((data) => {
                if (!data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
        //    }
        //})
    }

    handleOpenPath = (id: string, company_code: string, key: string, job_instance_no: string) => {
        var showOption: any = true;
        var keys: string = "";

        //check user
        if (this.state.checkTypeUser == undefined || this.state.checkTypeUser == "") {
            showOption = false
        } else {
            showOption = true
        }

        if (key == "sftp_source_server") {
            keys = 'sFTP source server File'
        } else if (key == "sftp_return_server") {
            keys = 'sFTP return Server File'
        } else if (key == "step4_error") {
            keys = 'Step 4 Error Source File'
        } else if (key == "sftp_source_backup_main_page") {
            keys = 'sFTP Source Backup'
        }

        var dataReq: any = this.state.dataSftpFile
        dataReq['legacy_id'] = id;
        dataReq['company_code'] = company_code;
        dataReq['type_id'] = key;
        dataReq['job_instance_no'] = job_instance_no;

        this.setState({
            fileListType: key,
            fileListTypeDes: keys,
            dataSftpFile: dataReq,
            isShowOption: showOption,
            fileListDes: id
        })
        this.props.requestGetFileList(true, "POST", id, key, company_code, job_instance_no)
        this.props.UseLoading(true)
    }

    handleShowListFile = () => {
        if (this.state.isshowListFile == true) {
            this.setState({
                isshowListFile: false,
                dataSftpFile: {
                    legacy_id: "",
                    company_code: "",
                    type_id: "",
                    job_instance_no: ""
                },
                fileListDes: "",
                getFileDataTable: {
                    total_count: 0,
                    result_list: []
                },
                checkGetFileDataTable: false,
                uploadfileReq: {}
            })
            this.props.requestGetFileList(true, "CLEAR", "", "", "")
        } else {
            this.setState({
                isshowListFile: true
            })
        }
    }

    handleShowListFileView = () => {
        if (this.state.isshowListFileView == true) {
            this.setState({
                isshowListFileView: false,
            })
            this.props.requestViewFile(true, "CLEAR")
        } else {
            this.setState({
                isshowListFileView: true
            })
        }
    }

    handleCloseTemModal = () => {
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true
            })

        } else {
            this.setState({
                isShowTempModal: false
            })
        }
    }

    handleCloseAlertConfirmModal = () => {
        if (this.state.isShowAlertConfirmModal == false) {
            this.setState({
                isShowAlertConfirmModal: true
            })

        } else {
            this.setState({
                isShowAlertConfirmModal: false
            })
        }
    }

    handleClickTemModal = (id) => {
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true,
                isSelectVal: id
            })

        }
    }

    handleDeleteSftpFileV2 = (legacy_id: string, company_code: string, fileName: string) => {
        Swal.fire({
            title: 'ต้องการลบไฟล์หรือไม่ ?',
            text: 'กดปุ่มตกลงเพื่อลบไฟล์นี้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                var dataReq: any = {}
                dataReq['file_name'] = fileName
                dataReq['legacy_id'] = legacy_id
                dataReq['list_type'] = this.state.fileListType
                dataReq['company_code'] = company_code

                this.props.requestDeleteSftpFileV2(true, "DELETE", dataReq)
            }
        })

    }

    handleDeleteSftpFileV3 = (legacy_id: string, company_code: string, fileName: string) => {
        Swal.fire({
            title: 'ต้องการลบไฟล์หรือไม่ ?',
            text: 'กดปุ่มตกลงเพื่อลบไฟล์นี้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                const job_password = '1q2w3e4r';

                Swal.fire({
                    title: 'กรุณาระบุรหัสผ่าน',
                    input: 'password',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก',
                    showLoaderOnConfirm: true,
                    preConfirm: (password) => {
                        if (password == null || password == "") {
                            Swal.showValidationMessage(
                                'กรุณากรอกรหัสผ่าน'
                            )
                        }
                        else if (password == job_password) {
                            var dataReq: any = {}
                            dataReq['file_name'] = fileName
                            dataReq['legacy_id'] = legacy_id
                            dataReq['list_type'] = this.state.fileListType
                            dataReq['company_code'] = company_code

                            this.props.requestDeleteSftpFileV2(true, "DELETE", dataReq)
                        }
                        else {
                            Swal.showValidationMessage(
                                'รหัสผ่านไม่ถูกต้อง'
                            )
                        }
                    }
                });
            }
        });
    }

    handleAddSftpFile = (e: any) => {
        const fileUploaded: any = getBase64(e.target.files[0]);
        const filename: string = e.target.files[0]['name']
        fileUploaded.onload = e => {
            var subFile: string = (e.target.result).split(",")[1]
            var dataReq: any = {}
            dataReq['legacy_id'] = this.state.dataSftpFile['legacy_id']
            dataReq['company_code'] = this.state.dataSftpFile['company_code']
            dataReq['data'] = subFile
            dataReq['file_name'] = filename
            dataReq['list_type'] = this.state.fileListType

            this.setState({
                uploadfileReq: dataReq
            })
        };
    }

    handleClickAddSftpFile = () => {
        if (this.state.uploadfileReq["legacy_id"] != undefined) {
            Swal.fire({
                title: 'ต้องการอัพโหลดไฟล์หรือไม่ ?',
                text: 'กดปุ่มตกลงเพื่ออัพโหลดไฟล์นี้',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddSftpFileV2(true, "PUT", this.state.uploadfileReq)
                    this.props.UseLoading(true)
                }
            })
        } else {
            Swal.fire({
                title: 'กรุณาเลือกไฟล์ก่อนอัพโหลดไฟล์',
                text: 'กรุณาเลือกไฟล์ก่อนอัพโหลดไฟล์',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
            })
        }
    }

    handleClickView = (companycode: string, legacyid: string, job_instanceno: string) => {
        fetch(`/v1/config/url-file?sid=555&company_code=` + companycode + '&legacy_id=' + legacyid, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.props.requestGetLegacyReportV2(true, "POST", "", this.state.companyCode, this.state.username)
                if (data.total_count == 0) {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        //if (result.value) {
                        //    this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                        //    this.props.callbackStartAutoRefresh()
                        //    return;
                        //}
                    })
                } else {
                    var dataSet: any = {}
                    dataSet['url'] = data.result_list[0].dms_url
                    dataSet['token'] = data.result_list[0].dms_token
                    dataSet['bucket_id'] = data.result_list[0].dms_bucket
                    dataSet['job_instance_no'] = job_instanceno
                    //var dataSet: any = {}
                    //dataSet['url'] = "https://scg-dms-api.sonarinno.com"
                    //dataSet['token'] = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjF4VUVUUWkyQ3dYVGk5ckxBeDNtNjBYbFhNcCIsInNlciI6IlMwMDAyIiwiYXBwIjoiMXhVRTAza2RmNGRLOU03V1p1RnZlZjBpMFpMIiwia2V5IjoiMXhVRVRLT3g2bHRHbk5SWGVBT2tmbXlCQktnIiwiZXhwIjoxOTQ1OTcwNTMzLCJpYXQiOjE2MzA0MDEyNzMsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.YNKJfc2NSyXhbisSqi-TSARFAMrgSwCUpfCn8pf5g7wyWTSDKQPSmPXR0651LkcgFtl5ml0X-EvVxVT9cB52nwAA-80msye4iQXjE_yrTNOohW2OmoKy4q7x6k1Dr2IucL3wX7c4ALSjwmV_c6-PRMYGNZoVHPKwFdVfDZi7Hlc"
                    //dataSet['bucket_id'] = "S-II-IAPI"
                    //dataSet['job_instance_no'] = "Intf-QMIX11_419_20210909010000"
                    this.props.requestViewFile(true, "GET", dataSet)
                    this.setState({
                        isshowListFileView: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
    }

    handleshowErrorFile = () => {
        if (this.state.isShowErrorFile == true) {
            this.setState({
                isShowErrorFile: false,
            })
            /*            this.props.requestViewFile(true, "CLEAR")*/
        } else {
            this.setState({
                isShowErrorFile: true
            })
        }
    }

    handleDownloadAll = () => {
        if (this.state.fileListTypeDes == "Step 4 Error Source File") {
            this.props.UseLoading(true)
            var body = {
                "legacy_id": this.state.dataSftpFile.legacy_id,
                "company_code": this.state.dataSftpFile.company_code,
                "job_instance_no": this.state.dataSftpFile.job_instance_no
            }
            fetch(`/v1/lis-server/get-source-files-when-step4-error`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    this.props.UseLoading(false)
                    if (!data.ok) {
                        data.json().then(err => {
                            Swal.fire({
                                title: 'ไม่สำเร็จ',
                                text: err.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {

                                }
                            })
                        })
                    } else {
                        data.blob().then(result => {
                            const downloadUrl = window.URL.createObjectURL(result);
                            const link = document.createElement('a');
                            link.setAttribute('href', downloadUrl);
                            link.setAttribute('download', this.state.dataSftpFile.job_instance_no + `_error.zip`);
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(link.href);
                            document.body.removeChild(link);
                        })
                    }
                })
        }
    }

    handleDownloadSourceDestinationAll = (path: any) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile.legacy_id,
            "company_code": this.state.dataSftpFile.company_code,
            "sid": this.state.userId,
            "path": path
        }
        fetch(`/v1/lis-server/get-all-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    data.blob().then(result => {
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', this.state.dataSftpFile.job_instance_no + '_' + this.state.fileListKey + '.zip');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadBackupAll = (path: any) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile.legacy_id,
            "company_code": this.state.dataSftpFile.company_code,
            "sid": this.state.userId,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-all-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    data.blob().then(result => {
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', this.state.dataSftpFile.job_instance_no + '_' + this.state.fileListKey + '.zip');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadSourceDestinationFile = (path: string, filename: string) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile['legacy_id'],
            "company_code": this.state.dataSftpFile['company_code'],
            "sid": this.state.userId,
            "path": path
        }
        /*fetch(`/v1/lis-server/get-backup-file-with-legacyid`, {*/
        fetch(`/v1/lis-server/get-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    data.blob().then(result => {
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', filename);
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadBackupFile = (path: string, filename: string) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile['legacy_id'],
            "company_code": this.state.dataSftpFile['company_code'],
            "sid": this.state.userId,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    data.blob().then(result => {
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', filename);
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleSearchSftpFile = (e: any) => {
        var key: string = (e.target.value).toUpperCase();
        this.setState({
            fileSearchKey: key
        })
    }

    searchFile = () => {
        var results: any
        var key: string = this.state.fileSearchKey
        if (key != "") {
            results = this.props.GetFile.responseGetFileList.result_list.filter(function (item: LegacyReportModels.GetFile) {
                return item.name.toUpperCase().indexOf(key) !== -1;
            });
            var dataObjFile: LegacyReportModels.GetFileListRes = {
                total_count: results.length,
                result_list: results
            }
            this.setState({
                getFileDataTable: dataObjFile
            })
        } else {
            this.setState({
                getFileDataTable: this.props.GetFile.responseGetFileList
            })
        }
    }

    render() {
        const { GetReport, GetFile, GetFileView } = this.props
        return (
            <div style={{ overflowX: 'scroll' }}>
                <Table className="table-bordered table-hover custom-table-list table-responsive" size="sm" style={{ width: '100%', overflow: 'inherit' }}>
                    <thead>
                        <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                            <th style={{ width: '6%', borderRight: '2px solid' }} className="border-bold-right">sFTP</th>
                            <th style={{ width: '2%' }}>Menu</th>
                            <th style={{ width: '2%' }}>รายงาน</th>
                            <th style={{ width: '4%' }}>Company Code</th>
                            <th style={{ width: '8%' }}>Legacy ID </th>
                            <th style={{ width: '8%' }}>Legacy Name</th>
                            <th style={{ width: '10%' }}>Job Instanceno</th>
                            <th style={{ width: '6%' }}>Legacy Type</th>
                            <th style={{ width: '5%' }}>Status (lastest)</th>
                            <th style={{ width: '6%' }}>Job Start</th>
                            <th style={{ width: '6%' }}>Job Finished On</th>
                            <th style={{ width: '6%' }}>Next Fire</th>
                            <th style={{ width: '5%' }}>Step 1 status</th>
                            {/* <th style={{ width: '7%' }}>Step 1 started on</th> */}
                            <th style={{ width: '5%' }}>Step 2 status</th>
                            {/* <th style={{ width: '7%' }}>Step 2 started on</th> */}
                            <th style={{ width: '5%' }}>Step 3 status</th>
                            {/* <th style={{ width: '7%' }}>Step 3 started on</th> */}
                            <th style={{ width: '7%' }}>Function status</th>
                            <th style={{ width: '2%' }}>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!GetReport.isLoadLegacyReport ? <tr style={{ width: '100%' }}><td colSpan={18} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {GetReport.isLoadLegacyReport && GetReport.statusLegacyReport != 200 ? <tr><td colSpan={18} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                        {this.state.formData.length != 0 && this.state.formData.map((item: LegacyReportModels.LegacyReport, idx: any) => {
                            var srcMonitor = "/legacydetail?company_code=" + item.companycode + "&legacy_id=" + item.legacyid + "&job_no=" + item.job_instanceno + "&legacytype=" + item.legacytype + "&_blank=true"
                            return (
                                <tr key={idx} style={{ backgroundColor: item.isactive == "N" && item.hassuspend == "Y" ? '#E6F1EF' : item.hassuspend == "Y" ? '#E4B1AB' : '' }}>
                                    <td style={{ borderRight: '2px solid' }}>
                                        <div className="text-center">
                                            <Button variant="success" size="sm" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(item.legacyid, item.companycode, 'sftp_source_server', item.job_instanceno)} onFocus={(e) => this.props.callbackStopAutoRefresh()}><FaCloudDownloadAlt /></Button>
                                            <Button variant="warning" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="return path" onClick={(e) => this.handleOpenPath(item.legacyid, item.companycode, 'sftp_return_server', item.job_instanceno)} onFocus={(e) => this.props.callbackStopAutoRefresh()}><FaCloudUploadAlt /></Button>
                                            {/*<Button variant="primary" size="sm" data-toggle="tooltip" data-placement="bottom" title="Backup path" onClick={(e) => this.handleOpenPath(item.legacyid, item.companycode, 'sftp_source_backup_main_page', item.job_instanceno)} onFocus={(e) => this.props.callbackStopAutoRefresh()}><FaCloudUploadAlt /></Button>*/}
                                        </div>
                                    </td>
                                    <td className='text-center'>
                                        <DropdownButton id="dropdown-basic-button" title={<FaListUl />} variant="info" size='sm' disabled={this.state.checkTypeUser == undefined ? true : false} onFocus={(e) => this.props.callbackStopAutoRefresh()}>
                                            <Dropdown.Header>{item.legacyid}</Dropdown.Header>
                                            <Dropdown.Divider />
                                            <Dropdown.Item>
                                                <ButtonGroup>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={(_event) => window.open(srcMonitor, '_blank', 'noopener,noreferrer')}
                                                    >
                                                        <FaEye />
                                                    </Button>
                                                    {/* <Link to={srcMonitor} target="_blank" className="btn btn-primary btn-sm"><FaEye /></Link> */}
                                                    <Button variant={item.hassuspend == 'N' ? "primary" : "secondary"} size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Run immediately" disabled={item.hassuspend == 'N' ? false : true} onClick={(e) => this.runImmediatelyHandleClick(item.sid, item.companycode, item.legacyid, item.legacytype, item.important_job)}><FaPlayCircle /></Button>
                                                    {/*<Button variant={item.hassuspend == 'Y' ? "info" : "secondary"} size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Continue" disabled={item.hassuspend == 'Y' ? false : true} onClick={(e) => this.continuteHandleClick(item.sid, item.companycode, item.legacyid)}><FaForward /></Button>*/}
                                                    {/*<Button variant={item.step2_status == 'RUNNING' ? "danger" : "secondary"} size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Stop" onClick={(e) => this.CancelHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno)}><FaStopCircle /></Button>*/}
                                                    {/*{(item.legacytype == "DMS_WAIT" || item.legacytype == "DMS") &&*/}
                                                    {/*    <Button variant="dark" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="view" onClick={(e) => this.handleClickView(item.companycode, item.legacyid, item.job_instanceno)} ><FaFile /></Button>*/}
                                                    {/*}*/}
                                                </ButtonGroup>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td className="text-center">{idx + 1}</td>
                                    <td className="text-center">{item.companycode}</td>
                                    <td>{item.legacyid}</td>
                                    <td>{item.legacyname}</td>
                                    <td>{item.job_instanceno}</td>
                                    <td className="text-center">{item.legacytype}</td>
                                    <td className={taskStatusColour(String(item.status)) + ' text-center'}>{item.status}</td>
                                    <td className="text-center">{formatYMDToDMY(String(item.job_started_on))}</td>
                                    <td className="text-center">{formatYMDToDMY(String(item.job_finished_on))}</td>
                                    <td className="text-center">{resetFormatDMY(String(item.next_fire))}</td>
                                    {/*<td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status == 'ERROR' ? <div><Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacytype, item.legacyid, item.job_instanceno, '1')}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button></div> : ''}{item.step1_status}</td>*/}
                                    <td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status}</td>
                                    {/* <td className="text-center">{formatYMDToDMY(String(item.step1_started_on))}</td> */}
                                    <td className={taskStatusColour(String(item.step2_status)) + ' text-center'}>
                                        {/*{item.step2_status == 'ERROR'*/}
                                        {/*    ? <div>*/}
                                        {/*        <Button*/}
                                        {/*            variant="primary"*/}
                                        {/*            size="sm"*/}
                                        {/*            className="mx-1"*/}
                                        {/*            data-toggle="tooltip"*/}
                                        {/*            data-placement="bottom"*/}
                                        {/*            title="Refresh"*/}
                                        {/*            onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacytype, item.legacyid, item.job_instanceno, '2', item.important_job)}>*/}
                                        {/*            <FaRedoAlt style={{ cursor: 'pointer' }} color="white" />*/}
                                        {/*        </Button>*/}
                                        {/*    </div>*/}
                                        {/*    : ''}*/}
                                        {item.step2_status}
                                    </td>
                                    {/* <td className="text-center">{formatYMDToDMY(String(item.step2_started_on))}</td> */}
                                    <td className={taskStatusColour(String(item.step3_status)) + ' text-center'}>
                                        {/*{item.step3_status == 'ERROR'*/}
                                        {/*    ? <div>*/}
                                        {/*        <Button*/}
                                        {/*            variant="primary"*/}
                                        {/*            size="sm"*/}
                                        {/*            className="mx-1"*/}
                                        {/*            data-toggle="tooltip"*/}
                                        {/*            data-placement="bottom"*/}
                                        {/*            title="Refresh"*/}
                                        {/*            onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacytype, item.legacyid, item.job_instanceno, '3', item.important_job)}>*/}
                                        {/*            <FaRedoAlt style={{ cursor: 'pointer' }} color="white" />*/}
                                        {/*        </Button>*/}
                                        {/*    </div>*/}
                                        {/*    : ''}*/}
                                        {item.step3_status}
                                    </td>
                                    {/* <td className="text-center">{formatYMDToDMY(String(item.step3_started_on))}</td> */}
                                    <td className={taskStatusColour(String(item.step4_status)) + ' text-center'}>
                                        <div className="d-inline-block">
                                            {
                                                item.step4_status == 'ERROR' ?
                                                    //<DropdownButton id="dropdown-basic-button" title={<FaListUl />} variant="outline-light" size='sm' onFocus={(e) => this.props.callbackStopAutoRefresh()}>
                                                    //    <Dropdown.Header>{item.legacyid}</Dropdown.Header>
                                                    //    <Dropdown.Divider />
                                                    //    <Dropdown.Item>

                                                    //    </Dropdown.Item>
                                                    //</DropdownButton>
                                                    <div>
                                                        {/*<Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacytype, item.legacyid, item.job_instanceno, '4', item.important_job)}><FaRedoAlt color="white" style={{ cursor: 'pointer' }} /></Button>*/}
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            className="mx-1"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="View Error File"
                                                            onClick={(e) => this.handleOpenPath(item.legacyid, item.companycode, 'step4_error', item.job_instanceno)}>
                                                            <FaFile color="white" style={{ cursor: 'pointer' }} onFocus={(e) => this.props.callbackStopAutoRefresh()} />
                                                        </Button>
                                                    </div>
                                                    : ''}
                                            {item.step4_status}
                                        </div>
                                    </td>
                                    <td data-toggle="tooltip" data-placement="bottom" title={item.message}>&nbsp;&nbsp;
                                        {item.message != "" && item.message != null &&
                                            <div className="text-center">
                                                <Button variant="info" className="btn-sm" onClick={() => this.handleClickTemModal(idx)}><FaSearch /></Button>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                <Modal enforceFocus={false} show={this.state.isshowListFile} onHide={() => this.handleShowListFile()} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>{this.state.fileListTypeDes}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {/*<div className="blockquote-footer">{this.state.fileListDes}{GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && GetFile.responseGetFileList.result_list[0].file_path}</div>*/}
                                <div className="bg-light text-dark">
                                    <table>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>Legacy ID :</td>
                                            <td style={{ width: '80%' }}>{this.state.fileListDes}</td>
                                        </tr>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>File Path :</td>
                                            <td style={{ width: '80%' }}>{GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 ? splitFilePath(GetFile.responseGetFileList.result_list[0].file_path) : "-"}</td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg='8'></Col>
                            <Col lg='4'>
                                <div className="d-flex justify-content-end">
                                    <InputGroup className="mb-3" size="sm" aria-describedby="basic-addon1">
                                        <FormControl
                                            placeholder="ค้นหาไฟล์"
                                            onChange={(e) => this.handleSearchSftpFile(e)}
                                        />
                                        <Button variant="outline-secondary" size="sm" id="button-addon1" onClick={() => this.searchFile()}>
                                            <FaSearch />
                                        </Button>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="scroll-table">
                                    <Table className="table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50%" }}>File Name</th>
                                                <th style={{ width: "15%" }}>Legacy ID</th>
                                                <th style={{ width: "20%" }}>Date Modified</th>
                                                <th style={{ width: "15%" }}>File Size</th>
                                                {this.state.isShowOption &&
                                                    <th style={{ width: "10%" }}>option</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!GetFile.isLoadGetFileList && this.state.isshowListFile && <tr style={{ width: '100%' }}><td colSpan={5} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr>}
                                            {GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count == 0 && <tr style={{ width: '100%' }}><td colSpan={5} style={{ background: "rgba(0, 0, 0, 0.075)", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No such file...</td></tr>}
                                            {GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && this.state.getFileDataTable.result_list.map((item: LegacyReportModels.GetFile, idx: any) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.name}</td>
                                                        <td>{getLegacyIDWithPath(item.file_path)}</td>
                                                        <td>{formatYMDToDMY(item.last_modify + 'z')}</td>
                                                        <td>{item.size + ' B.'}</td>
                                                        {this.state.isShowOption &&
                                                            <td>
                                                                <ButtonGroup>
                                                                    {this.state.fileListType == "sftp_source_server" || this.state.fileListType == "sftp_return_server" ?
                                                                        <Button variant="info" size="sm" onClick={() => this.handleDownloadSourceDestinationFile(item.file_path, item.name)}><FaDownload /></Button>
                                                                        :
                                                                        <Button variant="info" size="sm" onClick={() => this.handleDownloadBackupFile(item.file_path, item.name)}><FaDownload /></Button>
                                                                    }

                                                                    {this.state.fileListType != "sftp_source_backup" &&
                                                                        <Button variant="danger" size="sm" onClick={(event) => this.handleDeleteSftpFileV3(this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['company_code'], item.name)}><FaTimesCircle /></Button>
                                                                    }
                                                                </ButtonGroup>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        {this.state.isShowOption && this.state.fileListType != "sftp_source_backup" &&
                            <Row>
                                <Col>
                                    {/*<Form.Control className="custom-file-input" type="file" ref={this.uploadFileRef} onChange={(e) => this.handleAddSftpFile(e)} />*/}
                                    {this.state.isShowOption &&
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Form.Control type="file" ref={this.uploadFileRef} onChange={(e) => this.handleAddSftpFile(e)} className="custom-file-input" id="inputGroupFile02" />
                                                <label className="custom-file-label" aria-describedby="inputGroupFileAddon02">{this.state.uploadfileReq["file_name"] != undefined ? this.state.uploadfileReq["file_name"] : 'Choose file'}</label>
                                            </div>

                                            <div className="input-group-append">
                                                <span style={{ float: 'right', cursor: 'pointer' }} className="input-group-text" onClick={() => this.handleClickAddSftpFile()} title="Confirm Upload File"><FaCloudUploadAlt style={{ color: 'green', fontSize: '20px' }} /></span>
                                            </div>

                                        </div>
                                    }
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.isShowOption &&
                            <div>
                                {this.state.fileListType == "sftp_source_server" || this.state.fileListType == "sftp_return_server" ?
                                    <Button variant="primary" style={{ float: 'left' }} onClick={() => this.handleDownloadSourceDestinationAll(GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && splitFilePath(GetFile.responseGetFileList.result_list[0].file_path))}>
                                        <FaDownload /> Download All
                                    </Button>
                                    :
                                    <Button variant="primary" style={{ float: 'left' }} onClick={() => this.handleDownloadBackupAll(GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && splitFilePath(GetFile.responseGetFileList.result_list[0].file_path))}>
                                        <FaDownload /> Download All
                                    </Button>
                                }
                            </div>
                        }
                        <Button variant="danger" onClick={() => this.handleShowListFile()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Modal Message Error*/}
                <Modal show={this.state.isShowTempModal} onHide={this.handleCloseTemModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>
                            {this.state.formData.length != 0 && this.state.isSelectVal != null &&
                                this.state.formData[this.state.isSelectVal].job_instanceno
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table-bordered table-hover table-responsive" size="sm" style={{ width: '100%', overflow: 'auto' }}>
                            {/*<thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th style={{ width: '20%' }}>Name</th>
                                    <th style={{ width: '700px' }}>Data</th>
                                </tr>
                            </thead>*/}
                            <tbody>
                                {this.state.formData.length != 0 && this.state.isSelectVal != null &&
                                    <>
                                        <tr>
                                            <td style={{ width: '800px' }}>{this.state.formData[this.state.isSelectVal].message}</td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Modal View DMS*/}
                <Modal show={this.state.isshowListFileView} onHide={() => this.handleShowListFileView()} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>{this.state.fileListTypeDes + " : " + this.state.dataSftpFile.job_instance_no} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            {/* <th style={{ width: "20%" }}>option</th> */}
                                            <th style={{ width: "80%" }}>File Path</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!GetFileView.isLoadGetFileListView && this.state.isshowListFileView && <tr style={{ width: '100%' }}><td colSpan={2} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr>}
                                        {GetFileView.isLoadGetFileListView && GetFileView.statusGetFileListView == 200 && GetFileView.responseGetFileListView['total_count'] == 0 && <tr style={{ width: '100%' }}><td colSpan={2} style={{ background: "rgba(0, 0, 0, 0.075)", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No such file...</td></tr>}
                                        {GetFileView.isLoadGetFileListView && GetFileView.statusGetFileListView == 200 && GetFileView.responseGetFileListView['total_count'] != 0 && GetFileView.responseGetFileListView['result_list'].length != 0 && GetFileView.responseGetFileListView['result_list'].map((item: LegacyReportModels.GetFileListViewData, idx: any) => {
                                            return (
                                                <tr>
                                                    {/* <td>
                                                        <ButtonGroup>
                                                            <Button variant="info" data-id={item.id} size="sm"><FaCloudDownloadAlt /></Button>
                                                        </ButtonGroup> 
                                                    </td> */}
                                                    <td>{item.file_name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.handleShowListFileView()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Modal Alert Confirm*/}
                <Modal show={this.state.isShowAlertConfirmModal} onHide={this.handleCloseAlertConfirmModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">Password</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="passwordconfirm" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.checkPassword()}>
                            <FaTimesCircle /> ยืนยัน
                        </Button>
                        <Button variant="danger" onClick={this.handleCloseAlertConfirmModal}>
                            <FaTimesCircle /> ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) =>
    ({ ...state.legacyReport, ...state.Loading, ...state.lisCommand }),
    ({ ...LegacyReportStore.actionCreators, ...LoadingStore.actionCreators })
)(LegacyReportManualTable)