import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Table, Spinner, ButtonGroup, Modal, Form, Badge, Dropdown, DropdownButton, Card, InputGroup, FormControl } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { FaPlayCircle, FaForward, FaStopCircle, FaCloudDownloadAlt, FaCloudUploadAlt, FaTimesCircle, FaFile, FaListUl, FaRedoAlt, FaPlusCircle, FaSearch, FaDownload, FaTheRedYeti } from "react-icons/fa";
import Swal from 'sweetalert2'
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage, getBase64, format_time, splitFilePath, kbyteToByte } from '../modules/Common'
import * as QueryString from "query-string"
import { CardBody } from 'reactstrap';

import * as LegacyLogModels from '../../models/report/SystemLog'
import * as LegacyLogStore from '../../store/report/SystemLog'

import * as LegacyTaskModels from '../../models/report/LegacyTask'
import * as LegacyTasktStore from '../../store/report/LegacyTask'

import * as LegacyReportModels from '../../models/report/LegacyReport'
import * as LegacyReportStore from '../../store/report/LegacyReport'

import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'

// import { NotificationLog } from '../../models/report/NotificationLog';
// import { ResponseList } from '../../models/common/Response';
// import { CompanyMaster } from '../../models/companymaster/CompanyMaster';

type store = LegacyLogModels.GetSystemLogState & typeof LegacyLogStore.actionCreators | LegacyTaskModels.GetLegacyTaskState & typeof LegacyTasktStore.actionCreators

class ReportDetail extends React.Component<any, {
    initialCheck: boolean, company_code_Req: string, legacy_id_Req: string,
    job_no_Req: string, isShowErrorFile: boolean, legacyname: string,
    legacytype: string, message: string, isready: boolean, fileListType: string,
    dataSftpFile: any, isShowOption: boolean, fileListDes: string, checkTypeUser: string,
    isshowListFile: boolean, isShowTempModal: boolean, checkLogin: boolean,
    srcHistory: string, sid: string, fileListKey: string, isshowDMSListFile: boolean,
    reqDMSListFile: LegacyReportModels.ReqDmsFileList, legacy_type_Req: string,
    isShowAlertConfirmModal: boolean, passwordFromApi: string
}, store>{

    constructor(props: any) {
        super(props);

        this.state = {
            initialCheck: true,
            company_code_Req: "",
            legacy_id_Req: "",
            job_no_Req: "",
            isShowErrorFile: false,
            legacyname: "",
            legacytype: "",
            message: "",
            isready: true,
            fileListType: "",
            dataSftpFile: {
                legacy_id: "",
                company_code: "",
                type_id: "",
                job_instance_no: ""
            },
            isShowOption: false,
            fileListDes: '',
            checkTypeUser: '',
            isshowListFile: false,
            isShowTempModal: false,
            checkLogin: false,
            srcHistory: '',
            sid: "",
            fileListKey: "",
            isshowDMSListFile: false,
            reqDMSListFile: {
                sid: "",
                company_code: "",
                legacy_id: "",
                job_instance_no: "",
            },
            legacy_type_Req: "",
            isShowAlertConfirmModal: false,
            passwordFromApi: ''
        }

        this.runStepHandleClick = this.runStepHandleClick.bind(this);
    }

    componentDidMount() {
        this.props.UseLoading(true)
        console.log(this.props.location)
        this.setState({
            srcHistory: window.document.location.href
        })

        var ls: any = localStorage.getItem("LG_APPLICATION");
        if (ls) {
            var jls: any = JSON.parse(ls)
            var accessToken = jls.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);
            var userIdUse = ""

            if (extra.username) {
                userIdUse = extra.username
            }
            else if (extra.citizen_id) {
                userIdUse = extra.citizen_id
            }
            this.setState({
                sid: userIdUse
            })
            if (this.props.location.search) {
                var { company_code, legacy_id, job_no, legacytype } = QueryString.parse(this.props.location.search);
                this.setState({
                    company_code_Req: company_code,
                    legacy_id_Req: legacy_id,
                    job_no_Req: job_no,
                    legacy_type_Req: legacytype
                })
                var dataset: any = []
                dataset.push(company_code)
                this.props.requestGetSystemLog(true, "POST", "", dataset, null, null, '', '', job_no)
                this.props.requestGetLegacyTask(true, "POST", "", dataset, 1, 25, job_no, legacy_id, '', '', '', '')
            }

            fetch("/v1/user?id=" + userIdUse + '&role_access=admin',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + ls
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        var data = result.result_list
                        if (data != null && data.length > 0) {
                            this.setState({
                                checkTypeUser: data[0].list_company[0].role_access
                            });
                        } else {
                            if (result.message == "No such host is known." || result.message == "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ยืนยัน',
                                }).then((result) => { window.location.reload() })
                            }
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        } else {
            console.log('<Redirect to="/Login" />')
            this.props.UseLoading(false)
            //return <Redirect to="/Login" />
            //this.props.history.push("/Login");
            //return <Redirect to={{ pathname: '/auth/signin', state: { from: this.state.srcHistory } }} />;
            this.setState({
                checkLogin: true
            })
        }
    }

    componentWillUnmount() {
        this.props.requestGetSystemLog(true, "CLEAR", "", [], 1, 25, '', '', '')
        this.props.requestGetLegacyTask(true, "CLEAR", "", [], 1, 25, '', '', '', '', '', '', '')
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { GetTask, GetFile, GetDMSFile } = this.props
        if (GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && GetTask.responseLegacyTask['result_list'] != null && this.state.isready) {
            var dataArr: Array<LegacyTaskModels.LegacyTask> = JSON.parse(JSON.stringify(GetTask.responseLegacyTask['result_list']))

            this.setState({
                legacyname: dataArr[0].legacyname,
                legacytype: dataArr[0].legacytype,
                message: dataArr[0].message,
                isready: false
            })
        }

        //sFTP File List
        if (GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && !this.state.isshowListFile) {
            this.props.UseLoading(false)
            this.setState({
                isshowListFile: true
            })
        } else if (GetFile.isLoadGetFileList && GetFile.statusGetFileList != 200) {
            var msg = JSON.parse(GetFile.messageGetFileList)
            this.props.requestGetFileList(true, "CLEAR", "", "")
            this.props.UseLoading(false)
            if (msg.message == 'No Such Directory') {
                Swal.fire({
                    title: 'Warning',
                    text: msg.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.setState({
                            isshowListFile: false
                        })
                    }
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: msg.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.setState({
                            isshowListFile: false
                        })
                    }
                })
            }
        }

        //DMS File List
        if (GetDMSFile.isLoadGetFileDMSListView && GetDMSFile.statusGetFileDMSListView == 200 && !this.state.isshowDMSListFile) {
            this.props.UseLoading(false)
            this.setState({
                isshowDMSListFile: true
            })
        } else if (GetDMSFile.isLoadGetFileDMSListView && GetDMSFile.statusGetFileDMSListView != 200) {
            var msg = JSON.parse(GetFile.messageGetFileDMSListView)
            this.props.requestGetFileListV2(true, "CLEAR", "", "")
            this.props.UseLoading(false)
            if (msg.message == 'No Such Directory') {
                Swal.fire({
                    title: 'Warning',
                    text: msg.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.setState({
                            isshowDMSListFile: false
                        })
                    }
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: msg.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.setState({
                            isshowDMSListFile: false
                        })
                    }
                })
            }
        }

        if (this.state.initialCheck && GetTask.isLoadLegacyTask && this.props.isLoadSystemLog) {
            this.setState({
                initialCheck: false
            })

            this.props.UseLoading(false)
        }
    }

    handleCloseAlertConfirmModal = () => {
        if (this.state.isShowAlertConfirmModal == false) {
            this.setState({
                isShowAlertConfirmModal: true
            })

        } else {
            this.setState({
                isShowAlertConfirmModal: false
            })
        }
    }

    checkPassword() {
        let password: string = (document.getElementById("passwordconfirm") as HTMLInputElement).value;
        if (this.state.passwordFromApi == password) {
            Swal.fire({
                title: 'สำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                    //this.props.callbackStartAutoRefresh()
                    this.setState({
                        isShowAlertConfirmModal: false
                    })
                    return;
                }
            })
        }
        else {
            Swal.fire({
                title: 'พาสเวิร์ดไม่ถูกต้อง',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                return;
            })
        }
    }

    runStepHandleClick(sid: string, companycode: string, legacyid: string, job_instance_no: string, legacytype: string, step: string, important_job: string) {
        Swal.fire({
            title: 'ต้องการรีรันหรือไม่',
            text: 'ยืนยันคำสั่งรีรันหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            //console.log(result.isConfirmed);
            if (result.isConfirmed) {
                if (important_job && important_job.toLowerCase() == 'true') {
                    let job_password = '1q2w3e4r';
                    Swal.fire({
                        title: 'กรุณาระบุรหัสผ่าน',
                        input: 'password',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        showLoaderOnConfirm: true,
                        preConfirm: (password) => {
                            if (password == null || password == "") {
                                Swal.showValidationMessage(
                                    'กรุณากรอกรหัสผ่าน'
                                )
                            }
                            else if (password == job_password) {
                                this.runStepHandle(sid, companycode, legacyid, job_instance_no, legacytype, step)
                            }
                            else {
                                Swal.showValidationMessage(
                                    'รหัสผ่านไม่ถูกต้อง'
                                )
                            }
                        }
                    })
                }
                else {
                    this.runStepHandle(sid, companycode, legacyid, job_instance_no, legacytype, step)
                }
            }
        })
    }

    runStepHandle(sid: string, companycode: string, legacyid: string, job_instance_no: string, legacytype: string, step: string) {
        //this.setState({
        //    isready: true,
        //    formData: []
        //})
        //this.props.requestGetLegacyReport(true, "CLEAR", "", "")
        //this.props.UseLoading(true)
        //this.props.callbackStopAutoRefresh()
        //Swal.fire({
        //    title: 'ต้องการรีรันหรือไม่',
        //    text: 'คุณต้องการรีรัน Legacy ID นี้หรือไม่ ?',
        //    icon: 'warning',
        //    showCancelButton: true,
        //    confirmButtonColor: '#3085d6',
        //    confirmButtonText: 'ตกลง',
        //    cancelButtonText: 'ไม่ตกลง',
        //    cancelButtonColor: '#FF0000',
        //    allowOutsideClick: false
        //}).then((result) => {
        this.props.UseLoading(true);
        //if (result.value) {
        fetch(`/v1/lis-server/run-step?sid=` + sid + '&company_code=' + companycode + '&legacy_type=' + legacytype + '&legacy_id=' + legacyid + '&job_instance_no=' + job_instance_no + '&step=' + step, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                var dataset: any = []
                dataset.push(this.state.company_code_Req)
                this.props.requestGetSystemLog(true, "POST", "", dataset, null, null, '', '', this.state.job_no_Req)
                this.props.requestGetLegacyTask(true, "POST", "", dataset, 1, 25, this.state.job_no_Req, this.state.legacy_id_Req, '', '', '', '')

                this.props.UseLoading(false)

                //this.props.requestGetLegacyReport(true, "POST", "", this.state.company_code_Req)
                console.log(response)
                return response
            })
            .then((data) => {
                console.log(data)
                if (!data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .catch(error => {
                console.log('errorrrr')
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
        //}
        //})
    }

    handleshowErrorFile = () => {
        if (this.state.isShowErrorFile == true) {
            this.setState({
                isShowErrorFile: false,
            })
            /*            this.props.requestViewFile(true, "CLEAR")*/
        } else {
            this.setState({
                isShowErrorFile: true
            })
        }
    }

    handleOpenPath = (id: string, company_code: string, key: string, job_instance_no: string) => {
        console.log(id)
        var showOption: any = true;
        var keys: string = "";

        //check user
        if (this.state.checkTypeUser == undefined || this.state.checkTypeUser == "") {
            showOption = false
        } else {
            showOption = true
        }

        var keys: string = ""
        if (key == "sftp_source_backup") {
            keys = 'sFTP source Backup File'
            showOption = true
        } else if (key == "sftp_return_backup") {
            keys = 'sFTP Return Backup File'
            showOption = true
        } else if (key == "api_request_backup") {
            keys = 'Api Request Backup'
            showOption = true
        } else if (key == "step4_error") {
            key = 'sftp_source_backup'
            keys = 'Step 4 Error Source File'
            showOption = true
        } else {
            keys = 'Api Response Backup'
            showOption = true
        }

        var dataReq: any = this.state.dataSftpFile
        dataReq['legacy_id'] = id;
        dataReq['company_code'] = company_code;
        dataReq['type_id'] = key;
        dataReq['job_instance_no'] = job_instance_no;

        this.setState({
            fileListKey: key,
            fileListType: keys,
            dataSftpFile: dataReq,
            isShowOption: showOption,
            fileListDes: id
        })
        this.props.requestGetFileList(true, "POST", id, key, company_code, job_instance_no)
        this.props.UseLoading(true)
    }

    handleShowListFile = () => {
        if (this.state.isshowListFile == true) {
            this.setState({
                isshowListFile: false,
                dataSftpFile: {
                    legacy_id: "",
                    company_code: "",
                    type_id: "",
                    job_instance_no: ""
                },
                fileListDes: ""
            })
            this.props.requestGetFileList(true, "CLEAR", "", "", "")
        } else {
            this.setState({
                isshowListFile: true
            })
        }
    }

    handleShowListFileDMS = () => {
        if (this.state.isshowDMSListFile == true) {
            this.setState({
                isshowDMSListFile: false,
                reqDMSListFile: {
                    sid: "",
                    company_code: "",
                    legacy_id: "",
                    job_instance_no: "",
                },
                fileListDes: ""
            })
            this.props.requestViewFileV2(true, "CLEAR", "", "", "")
        } else {
            var reqDMSListFile = {
                sid: this.state.sid,
                company_code: this.state.company_code_Req,
                legacy_id: this.state.legacy_id_Req,
                job_instance_no: this.state.job_no_Req,
            }
            this.props.requestViewFileV2(true, "GET", reqDMSListFile)
            this.setState({
                fileListType: "DMS File",
                fileListDes: this.state.legacy_id_Req,
                isshowDMSListFile: true
            })
        }
    }

    handleCloseTemModal = () => {
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true
            })

        } else {
            this.setState({
                isShowTempModal: false
            })
        }
    }

    handleDownloadFile = (path: string, filename: string) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.legacy_id_Req,
            "company_code": this.state.company_code_Req,
            "sid": this.state.sid,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', filename);
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadAll = (path: any) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.legacy_id_Req,
            "company_code": this.state.company_code_Req,
            "sid": this.state.sid,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-all-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', this.state.dataSftpFile.job_instance_no + '_' + this.state.fileListKey + '.zip');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadFileDMS = (id: string, filename: string) => {
        this.props.UseLoading(true)
        fetch(`/v2/dms/file?sid=` + this.state.sid + '&company_code=' + this.state.company_code_Req + '&legacy_id=' + this.state.legacy_id_Req + '&id=' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION')
            },
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', filename);
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    // public async getFiles(reqOptions: { legacy_id: string, list_type: string, company_code: string, job_instance_no: string }): Promise<LegacyReportModels.GetFile[]> {
    //     const response = await fetch('/v3/lis-server/files/sftp/path', {
    //         method: 'POST',
    //         headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(reqOptions)
    //     });

    //     if (!response.ok) {
    //         const { message } = await response.json();
    //         throw new Error(message);
    //     }

    //     const data: ResponseList<LegacyReportModels.GetFile> = await response.json();

    //     return (data.total_count > 0) ? data.result_list : [];
    // }

    // public async getCompany(options: { company_code: string }): Promise<CompanyMaster | null> {
    //     const response = await fetch('/v1/company_master', {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
    //             'Content-Type': 'application/json'
    //         }
    //     });

    //     if (!response.ok) {
    //         const { message } = await response.json();
    //         throw new Error(message);
    //     }

    //     const data: ResponseList<CompanyMaster> = await response.json();

    //     if (data.total_count > 0) {
    //         return data.result_list.find(company => company.company_code === options.company_code) || null;
    //     }

    //     return null;
    // }

    // public async testNotificationMessage() {
    //     try {
    //         const { GetFile } = this.props;
    //         const { legacy_id_Req, company_code_Req, legacyname, job_no_Req, legacytype } = this.state;
    //         const { job_finished_on } = this.props.GetTask.responseLegacyTask.result_list[0];

    //         const getCompanyAsync = this.getCompany({ company_code: company_code_Req });
    //         const getSourceFilesAsync = this.getFiles({
    //             legacy_id: legacy_id_Req,
    //             list_type: 'sftp_source_backup',
    //             company_code: company_code_Req,
    //             job_instance_no: job_no_Req
    //         });
    //         const getReturnFilesAsync = this.getFiles({
    //             legacy_id: legacy_id_Req,
    //             list_type: 'sftp_return_backup',
    //             company_code: company_code_Req,
    //             job_instance_no: job_no_Req
    //         });

    //         const [sourceFiles, returnFiles, company] = await Promise.all([getSourceFilesAsync, getReturnFilesAsync, getCompanyAsync]);
    //         const systemLogs: LegacyLogModels.SystemLog[] = this.props?.responseSystemLog?.result_list || [];

    //         const notification: NotificationLog = {
    //             legacy_message: '?',
    //             message_datetime: job_finished_on,
    //             company: `${company_code_Req} : ${company?.company_name}?`,
    //             legacy_id: legacy_id_Req,
    //             legacy_name: legacyname,
    //             job_instance_no: job_no_Req,
    //             legacy_type: legacytype,
    //             source_files: sourceFiles,
    //             return_files: returnFiles,
    //             system_logs: systemLogs
    //         };

    //         const response = await fetch('/v1/legacy/email-notification', {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(notification)
    //         });
    //         console.log('ngame email sent', notification, response);
    //     } catch (error) {
    //         console.log('ngame error', error);
    //     }
    // }

    public formatSystemLogMessage(message: string): JSX.Element | JSX.Element[] {
        const splitedMessage = message
            .replace('\r', '')
            .split('\n');

        const jsxMessage = splitedMessage
            .map(message => {
                if (message === '' || message === null) {
                    return <br />
                }

                if (message.includes('success') || message.includes('warning') || message.includes('error')) {
                    return <span className={(message.includes('success') ? 'text-success' : (message.includes('warning') ? 'text-warning' : 'text-danger'))}>{message}</span>
                }

                return <p className={`${(message.toLowerCase().includes('processstep')) && 'text-primary'}`}>{message}</p>
            })

        return jsxMessage;
    }

    render() {
        const { GetTask, GetFile, GetDMSFile } = this.props
        if (this.state.checkLogin) {
            console.log('redirect to auth/signin')
            return <Redirect to={{ pathname: '/auth/signin', state: { from: this.state.srcHistory } }} />;
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px', display: 'flex' }}>
                                <span>
                                    Monitor Detail
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <table className="table table-borderless">
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Company Code :</td>
                                                                <td style={{ width: '80%' }}>{this.state.company_code_Req}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Legacy ID :</td>
                                                                <td style={{ width: '80%' }}>{this.state.legacy_id_Req}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Legacy Name :</td>
                                                                <td style={{ width: '80%' }}>{this.state.legacyname}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Job Instance No :</td>
                                                                <td style={{ width: '80%' }}>{this.state.job_no_Req}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Legacy Type :</td>
                                                                <td style={{ width: '80%' }}>{this.state.legacytype}</td>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <Col>
                                                        <table className="table table-borderless">
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Source Files :</td>
                                                                <td style={{ width: '80%' }}>
                                                                    <Button variant="info" size="sm" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_source_backup', this.state.job_no_Req)}><FaSearch /></Button>
                                                                    {/*{this.state.checkTypeUser == 'admin' &&*/}
                                                                    {/*    <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_source_server', this.state.job_no_Req)} disabled><FaCloudDownloadAlt /></Button>*/}
                                                                    {/*}*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>Destination Files :</td>
                                                                <td style={{ width: '80%' }}>
                                                                    <Button variant="info" size="sm" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_return_backup', this.state.job_no_Req)}><FaSearch /></Button>
                                                                    {/*{this.state.checkTypeUser == 'admin' &&*/}
                                                                    {/*    <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_return_server', this.state.job_no_Req)} disabled><FaCloudDownloadAlt /></Button>*/}
                                                                    {/*}*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>API Request Files :</td>
                                                                <td style={{ width: '80%' }}>
                                                                    <Button variant="info" size="sm" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'api_request_backup', this.state.job_no_Req)}><FaSearch /></Button>
                                                                    {/*{this.state.checkTypeUser == 'admin' &&*/}
                                                                    {/*    <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_return_server', this.state.job_no_Req)} disabled><FaCloudDownloadAlt /></Button>*/}
                                                                    {/*}*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-weight-bold' style={{ width: '20%' }}>API Response Files :</td>
                                                                <td style={{ width: '80%' }}>
                                                                    <Button variant="info" size="sm" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'api_response_backup', this.state.job_no_Req)}><FaSearch /></Button>
                                                                    {/*{this.state.checkTypeUser == 'admin' &&*/}
                                                                    {/*    <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_return_server', this.state.job_no_Req)} disabled><FaCloudDownloadAlt /></Button>*/}
                                                                    {/*}*/}
                                                                </td>
                                                            </tr>
                                                            {((this.state.legacytype).indexOf("DMS") != -1) &&
                                                                <tr>
                                                                    <td className='font-weight-bold' style={{ width: '20%' }}>DMS File :</td>
                                                                    <td style={{ width: '80%' }}>
                                                                        <Button variant="info" size="sm" data-toggle="tooltip" data-placement="bottom" title="DMS File" onClick={(e) => this.handleShowListFileDMS()}><FaSearch /></Button>
                                                                        {/*{this.state.checkTypeUser == 'admin' &&*/}
                                                                        {/*    <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="source path" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'sftp_return_server', this.state.job_no_Req)} disabled><FaCloudDownloadAlt /></Button>*/}
                                                                        {/*}*/}
                                                                    </td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td className='font-weight-bold'>Message :</td>
                                                                <td style={{ width: '80%' }}>
                                                                    {this.state.message != "" && this.state.message != null &&
                                                                        <div>
                                                                            <Button variant="info" className="btn-sm" onClick={() => this.handleCloseTemModal()}><FaSearch /></Button>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col lg={12}>
                                        <div style={{ overflowX: 'scroll' }}>
                                            <Table className="table-bordered table-hover" size="sm">
                                                <thead>
                                                    <tr className="text-center table-active thead-light font-weight-bold require-field">
                                                        <th>Status (lastest)</th>
                                                        <th>Job Start</th>
                                                        <th>Job Finished On</th>
                                                        <th>Load Files Status</th>
                                                        <th>Conversion Status</th>
                                                        <th>Call Function Status</th>
                                                        <th>Return Files Status</th>
                                                        <th>Function Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!GetTask.isLoadLegacyTask ? <tr><td colSpan={16} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                    {GetTask.isLoadLegacyTask && (GetTask.statusLegacyTask != 200 || GetTask.responseLegacyTask['result_list'] == null) ? <tr><td colSpan={16} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                    {GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && GetTask.responseLegacyTask['result_list'] != null && GetTask.responseLegacyTask['result_list'].map((item: LegacyTaskModels.LegacyTask, idx: any) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td className={taskStatusColour(String(item.status)) + ' text-center'}>{item.status}</td>
                                                                <td className="text-center">{formatYMDToDMY(String(item.job_started_on))}</td>
                                                                <td className="text-center">{formatYMDToDMY(String(item.job_finished_on))}</td>
                                                                {/*<td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status == 'ERROR' ? <span className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white"/></span> : ''}{item.step1_status}</td>*/}
                                                                <td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status}</td>
                                                                {/*<td className="text-center">{formatYMDToDMY(String(item.step1_started_on))}</td>*/}
                                                                <td className={taskStatusColour(String(item.step5_status)) + ' text-center'}>{item.step5_status == 'ERROR' ? <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '5', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button> : ''}{item.step5_status}</td>
                                                                <td className={taskStatusColour(String(item.step2_status)) + ' text-center'}>{item.step2_status == 'ERROR' ? <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '2', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button> : ''}{item.step2_status}</td>
                                                                {/*<td className="text-center">{formatYMDToDMY(String(item.step2_started_on))}</td>*/}
                                                                <td className={taskStatusColour(String(item.step3_status)) + ' text-center'}>{item.step3_status == 'ERROR' ? <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '3', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button> : ''}{item.step3_status}</td>
                                                                {/*<td className="text-center">{formatYMDToDMY(String(item.step3_started_on))}</td>*/}
                                                                <td className={taskStatusColour(String(item.step4_status)) + ' text-center'}>
                                                                    <div className="d-inline-block">
                                                                        {item.step4_status == 'ERROR' ?
                                                                            <div>
                                                                                <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '4', item.important_job)}><FaRedoAlt color="white" style={{ cursor: 'pointer' }} /></Button>
                                                                                <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="View Error File" onClick={(e) => this.handleOpenPath(this.state.legacy_id_Req, this.state.company_code_Req, 'step4_error', this.state.job_no_Req)}><FaFile color="white" style={{ cursor: 'pointer' }} /></Button>
                                                                            </div>
                                                                            : ''}
                                                                        {item.step4_status}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col>
                                        <Table className="table-bordered table-hover custom-table-list table-responsive-lg" size="md">
                                            <thead className="">
                                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                                    <th style={{ width: '8%' }}>Log Type</th>
                                                    <th style={{ width: '40%' }}>Message</th>
                                                    <th style={{ width: '10%' }}>Created On</th>
                                                    <th style={{ width: '15%' }}>Msg Source</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.props.isLoadSystemLog ? <tr><td colSpan={4} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                {this.props.isLoadSystemLog && this.props.statusSystemLog != 200 ? <tr><td colSpan={4} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                {this.props.statusSystemLog == 200 && this.props.isLoadSystemLog && this.props.responseSystemLog['result_list'] != null && this.props.responseSystemLog['result_list'].map((item: LegacyLogModels.SystemLog, idx: any) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="text-center">{item.log_type}</td>
                                                            <td>{this.formatSystemLogMessage(item.message)}</td>
                                                            <td className="text-center">{formatYMDToDMY(String(item.created_on))}</td>
                                                            <td>{item.msg_source}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </Col>
                </Row>
                {/*sFTP List File*/}
                <Modal show={this.state.isshowListFile} onHide={() => this.handleShowListFile()} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>{this.state.fileListType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {/*<div className="blockquote-footer">{this.state.fileListDes}{GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && GetFile.responseGetFileList.result_list[0].file_path}</div>*/}
                                <div className="bg-light text-dark">
                                    <table>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>Legacy ID :</td>
                                            <td style={{ width: '80%' }}>{this.state.fileListDes}</td>
                                        </tr>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>File Path :</td>
                                            <td style={{ width: '80%' }}>{GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && splitFilePath(GetFile.responseGetFileList.result_list[0].file_path)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "55%" }}>File Name</th>
                                            <th style={{ width: "20%" }}>Date Modified</th>
                                            <th style={{ width: "15%" }}>File Size</th>
                                            {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                <th style={{ width: "10%" }}>Download</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!GetFile.isLoadGetFileList && this.state.isshowListFile && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr>}
                                        {GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count == 0 && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(0, 0, 0, 0.075)", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No such file...</td></tr>}
                                        {GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && GetFile.responseGetFileList.result_list.map((item: LegacyReportModels.GetFile, idx: any) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.name}</td>
                                                    <td>{formatYMDToDMY(item.last_modify + 'z')}</td>
                                                    <td>{item.size + ' B.'}</td>
                                                    {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                        <td>
                                                            <ButtonGroup>
                                                                <Button variant="info" size="sm" onClick={() => this.handleDownloadFile(item.file_path, item.name)}><FaDownload /></Button>
                                                                {/*<Button variant="danger" size="sm" onClick={() => this.handleDeleteSftpFile(this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['company_code'], item)}><FaTimesCircle /></Button>*/}
                                                            </ButtonGroup>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Control type="file" ref={this.uploadFileRef} onChange={(e) => this.handleAddSftpFile(e)} hidden />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') && this.state.fileListKey != "api_request_backup" && this.state.fileListKey != "api_response_backup" &&
                            <Button variant="primary" style={{ float: 'left' }} onClick={() => this.handleDownloadAll(GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && splitFilePath(GetFile.responseGetFileList.result_list[0].file_path))}>
                                <FaDownload /> Download All
                            </Button>
                        }
                        <Button variant="danger" onClick={() => this.handleShowListFile()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*DMS List File*/}
                <Modal show={this.state.isshowDMSListFile} onHide={() => this.handleShowListFileDMS()} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>{this.state.fileListType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {/*<div className="blockquote-footer">{this.state.fileListDes}{GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && GetFile.responseGetFileList.result_list[0].file_path}</div>*/}
                                <div className="bg-light text-dark">
                                    <table>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>Legacy ID :</td>
                                            <td style={{ width: '80%' }}>{this.state.fileListDes}</td>
                                        </tr>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>File Path :</td>
                                            <td style={{ width: '80%' }}>{GetDMSFile.isLoadGetFileDMSListView && GetDMSFile.statusGetFileDMSListView == 200 && GetDMSFile.responseGetFileDMSListView.total_count != 0 && splitFilePath(GetDMSFile.responseGetFileDMSListView.result_list[0].file_path)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "55%" }}>File Name</th>
                                            <th style={{ width: "20%" }}>Date Modified</th>
                                            <th style={{ width: "15%" }}>File Size</th>
                                            {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                <th style={{ width: "10%" }}>Download</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!GetDMSFile.isLoadGetFileDMSListView && this.state.isshowDMSListFile && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr>}
                                        {GetDMSFile.isLoadGetFileDMSListView && GetDMSFile.statusGetFileDMSListView == 200 && GetDMSFile.responseGetFileDMSListView.total_count == 0 && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(0, 0, 0, 0.075)", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No such file...</td></tr>}
                                        {GetDMSFile.isLoadGetFileDMSListView && GetDMSFile.statusGetFileDMSListView == 200 && GetDMSFile.responseGetFileDMSListView.total_count != 0 && GetDMSFile.responseGetFileDMSListView.result_list.map((item: LegacyReportModels.GetFileListViewData, idx: any) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.file_name}</td>
                                                    <td>{formatYMDToDMY(item.created_on + 'z')}</td>
                                                    <td>{kbyteToByte(item.file_size) + ' B.'}</td>
                                                    {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                        <td>
                                                            <ButtonGroup>
                                                                <Button variant="info" size="sm" onClick={() => this.handleDownloadFileDMS(item.id, item.file_name)}><FaDownload /></Button>
                                                                {/*<Button variant="danger" size="sm" onClick={() => this.handleDeleteSftpFile(this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['company_code'], item)}><FaTimesCircle /></Button>*/}
                                                            </ButtonGroup>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Control type="file" ref={this.uploadFileRef} onChange={(e) => this.handleAddSftpFile(e)} hidden />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        {/*{(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') && this.state.fileListKey != "api_request_backup" && this.state.fileListKey != "api_response_backup" &&*/}
                        {/*    <Button variant="primary" style={{ float: 'left' }} onClick={() => this.handleDownloadAll(GetFile.isLoadGetFileList && GetFile.statusGetFileList == 200 && GetFile.responseGetFileList.total_count != 0 && splitFilePath(GetFile.responseGetFileList.result_list[0].file_path))}>*/}
                        {/*        <FaDownload /> Download All*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        <Button variant="danger" onClick={() => this.handleShowListFileDMS()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Modal Message Error*/}
                <Modal show={this.state.isShowTempModal} onHide={this.handleCloseTemModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>
                            {this.state.job_no_Req}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table-bordered table-hover table-responsive" size="sm" style={{ width: '100%', overflow: 'inherit' }}>
                            {/*<thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th style={{ width: '20%' }}>Name</th>
                                    <th style={{ width: '700px' }}>Data</th>
                                </tr>
                            </thead>*/}
                            <tbody>
                                {this.state.message != "" &&
                                    <>
                                        <tr>
                                            <td style={{ width: '800px' }}>{this.state.message}</td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isShowAlertConfirmModal} onHide={this.handleCloseAlertConfirmModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">Password</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="passwordconfirm" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.checkPassword()}>
                            <FaTimesCircle /> ยืนยัน
                        </Button>
                        <Button variant="danger" onClick={this.handleCloseAlertConfirmModal}>
                            <FaTimesCircle /> ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.systemLog, ...state.legacyTask, ...state.lisCommand, ...state.legacyReport, ...state.Loading }), ({ ...LegacyLogStore.actionCreators, ...LegacyTasktStore.actionCreators, ...LegacyReportStore.actionCreators, ...LoadingStore.actionCreators }))(ReportDetail);