import * as React from 'react';
import { connect } from "react-redux";
import { ApplicationState } from '../../store';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPlusCircle, FaEye, FaTimesCircle, FaFileAlt } from "react-icons/fa";
import Swal from 'sweetalert2'
import { FaCode, FaDownload } from "react-icons/fa";
import Dropzone from 'react-dropzone'
const languageEncoding = require("detect-file-encoding-and-language");

const dropzoneStyle = {
    width: "100%",
    height: "20%",
    border: "1px solid black"
};

class UploadfileConfig extends React.Component<any, { formatFileJson: any, fileType: any, acceptedFiles: any, delimiter: string }>{

    constructor(props: any) {
        super(props);
        this.state = {
            fileType: '',
            acceptedFiles: [],
            delimiter: '',
            formatFileJson: undefined
        }
    }

    handleChangeInput = (e: any, key: any) => {
        if (key == 'filetype') {
            this.setState({
                fileType: e.target.value
            })
        } else if (key == "delimiter") {
            this.setState({
                delimiter: e.target.value,
                 formatFileJson: undefined
            })
        }
    }

    onDrop = (acceptedFiles: any) => {
        console.log(acceptedFiles);
        var file: any = this.state.acceptedFiles
        file.push(...acceptedFiles)
        this.setState({
            acceptedFiles: file
        })
        console.log(this.state.acceptedFiles)
        //const url = (window.URL || window.webkitURL).createObjectURL(file[0])
        //this.readTextFile(url)
    }

    handleDeleteFile = (id: any) => {
        Swal.fire({
            title: 'ลบไฟล์ ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                var dataArr = this.state.acceptedFiles
                dataArr.splice(id, 1)
                this.setState({
                    acceptedFiles: dataArr,
                    formatFileJson: undefined
                })
            }
        })
    }

    handleCreate = async () => {
        var responeJson: any = []
        var JsonFileTotal: any = {}
        var JsonDataList: any = []
        var typeEncode: string = ""

        for (let id = 0; id < this.state.acceptedFiles.length; id++) {

            var checkTypeEncode = await languageEncoding(this.state.acceptedFiles[id]);
            console.log(checkTypeEncode)

            const url = (window.URL || window.webkitURL).createObjectURL(this.state.acceptedFiles[id])

            responeJson.push(this.readTextFile(url, this.state.delimiter, this.state.acceptedFiles[id], this.state.acceptedFiles[id].name, checkTypeEncode.encoding))

            JsonFileTotal['total_count'] = this.state.acceptedFiles.length
            JsonFileTotal['data_list'] = responeJson
        }
        
        if (responeJson.length > 0) {
            this.setState({ formatFileJson: JsonFileTotal })
        } else {
            this.setState({ formatFileJson: undefined })
        }
    }

    saveFile = () => {
        const json_format = document.createElement("a");
        const json_string_format = document.createElement("a");

        json_format.href = URL.createObjectURL(new Blob([JSON.stringify(this.state.formatFileJson, null, 2)], {
            type: "text/plain"
        }));
        json_format.setAttribute("download", "json_format.txt");
        document.body.appendChild(json_format);
        json_format.click();
        document.body.removeChild(json_format);

        json_string_format.href = URL.createObjectURL(new Blob([JSON.stringify(JSON.stringify(this.state.formatFileJson, null, 2))], {
            type: "text/plain"
        }));
        json_string_format.setAttribute("download", "json_string_format.txt");
        document.body.appendChild(json_string_format);
        json_string_format.click();
        document.body.removeChild(json_string_format);
    }

    render() {
        const { formatFileJson } = this.state
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Upload File Config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded" style={{ height: '800px' }}>
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    Upload Setting
                                                </span>
                                            </div>
                                            <div className="mat-box">
                                                <Row>
                                                    <Col>
                                                        {/* <div key='inline-radio' className="mt-2">
                                                            <Form.Check inline label="FixRange" name="FileType" type='radio' id='FileTypeFixRange' value="FixRange" onChange={(e) => this.handleChangeInput(e, "filetype")} />
                                                            <Form.Check inline label="Delimiter (,)" name="FileType" type='radio' id='FileTypeDelimiter(,)' className="mx-3" value="Delimiter (,)" onChange={(e) => this.handleChangeInput(e, "filetype")} />
                                                            <Form.Check inline label="Delimiter (|)" name="FileType" type='radio' id='FileTypeDelimiter(|)' className="mx-3" value="Delimiter (|)" onChange={(e) => this.handleChangeInput(e, "filetype")} />
                                                            <Form.Check inline label="File" name="FileType" type='radio' id='FileTypeFile' className="mx-3" value="File" onChange={(e) => this.handleChangeInput(e, "filetype")} />
                                                            <Form.Check inline label="DMS" name="FileType" type='radio' id='FileTypeDMS' className="mx-3" value="DMS" onChange={(e) => this.handleChangeInput(e, "filetype")} />
                                                        </div> */}
                                                        <Form.Label className="font-weight-bold">raw, fix_range, delimiter character or json</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <FormControl id="delimiter" aria-describedby="basic-addon3" value={this.state.delimiter} onChange={(e) => this.handleChangeInput(e, "delimiter")} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex justify-content-end">
                                                            {formatFileJson != undefined ?
                                                                <Button variant="primary" size="sm" onClick={this.saveFile} ><FaDownload size="11" /> Download</Button>
                                                                :
                                                                <Button variant="secondary" size="sm" disabled><FaDownload size="11" /> Download</Button>
                                                            }
                                                            <Button variant="info" size="sm" className="mx-2" onClick={this.handleCreate}><FaCode /> Create</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col className="mx-4 rounded" style={{ border: '0.5px solid black', height: '120px' }}>
                                        <div className="text-center mt-5">
                                            <Dropzone
                                                onDrop={this.onDrop}
                                                minSize={0}
                                                maxSize={5242880}
                                                multiple={true}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        Drag 'n' drop some files here, or click to select files
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Table className="table-bordered table-hover rounded custom-table-list table-responsive-lg mx-4" size="lg">
                                        <thead className="rounded">
                                            <tr style={{ width: '100%' }} className="text-center table-active thead-light require-field">
                                                <th style={{ width: '5%' }} className='text-center'>ID</th>
                                                <th style={{ width: '5%' }}><FaFileAlt /></th>
                                                <th style={{ width: '10%' }}>File Size</th>
                                                <th style={{ width: '40%' }}>File Name</th>
                                                <th style={{ width: '25%' }}>File Path</th>
                                                <th style={{ width: '10%' }}>File Type</th>
                                                <th style={{ width: '10%' }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.acceptedFiles.length > 0 && this.state.acceptedFiles.map((acceptedFile, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td className="text-center">{idx + 1}</td>
                                                        <td className="text-center"><FaFileAlt /></td>
                                                        <td>{acceptedFile.size}</td>
                                                        <td>{acceptedFile.name}</td>
                                                        <td>{acceptedFile.path}</td>
                                                        <td className="text-center">{acceptedFile.type}</td>
                                                        <td className="text-center"><Button className="btn btn-outline-danger" size="sm" onClick={() => this.handleDeleteFile(idx)}><FaTimesCircle /></Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    //
    readTextFile(filePath: string, checkCreate: string, file: any, nameFile: string, typeEncode: string) {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", filePath, false);

        if (typeEncode === "UTF-8") {
            rawFile.overrideMimeType('text/xml; charset=utf-8');
        } else {
            rawFile.overrideMimeType('text/xml; charset=windows-874');
        }

        var JsonFile: any = {}
        var CreateWithJson = false;
        rawFile.onreadystatechange = function () {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status == 0) {
                    var allText = rawFile.responseText;
                    var lines = allText.split(/\r\n|\r|\n/);

                    var DataList: any = []

                    if (checkCreate == "fix_range" || checkCreate == "") {
                        lines.forEach(line => {
                            let data = {}
                            data['col_0001'] = line
                            DataList.push(data)
                        });
                    } else if (checkCreate == "json" || checkCreate == "") {
                        const dataJSON = JSON.parse(allText);
                        if (dataJSON[0] != null) {
                            for (var i = 0; i < dataJSON.length;i++) {
                                DataList.push(dataJSON[i])
                            } 
                        } else {
                            CreateWithJson = true;
                            DataList.push(dataJSON)
                        }  
                    }
                    else {
                        lines.forEach(line => {
                            let data = {}
                            line.split(checkCreate).map((item, id) => {
                                data['col_' + (id + 1).toString().padStart(4, '0')] = item
                            })
                            DataList.push(data)
                        });
                    }

                    JsonFile['file_name'] = nameFile
                    if (CreateWithJson) {
                        JsonFile['data'] = DataList[0]
                    } else {
                        JsonFile['data'] = DataList
                    }
                }
            }
        }
        rawFile.send(null);
        return JsonFile
    }

}
export default UploadfileConfig