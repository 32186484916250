import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";
import Swal from 'sweetalert2'
import * as ApiConfigModels from '../../models/config/ApiConfig'
import * as ApiConfigStore from '../../store/config/ApiConfig'

type conditionProps = ApiConfigModels.GetApiConfigState & typeof ApiConfigStore.actionCreators

export interface inititem {
    value: "",
    label: "",
    display: ""
};

class AutocompleteApiServerID extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: Array<inititem>, checkSearch: boolean, checkValue: boolean, companycodereq: string, sidreq: string }, conditionProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: [],
            checkSearch: true,
            checkValue: true,
            companycodereq: '',
            sidreq: ''
        }
    }

    componentDidMount() {
        const { sid, companycode } = this.props
        if (sid != "") {
            this.props.requestGetApiConfig(true, "POST", sid, "", "")
            this.setState({
                sidreq: this.props.sid,
                companycodereq: this.props.companycode,
                checkValue: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { sid, companycode, GetAPI, valueSearch } = this.props
        if (sid != this.state.sidreq) {
            this.props.requestGetApiConfig(true, "POST", sid, "", "")
            this.setState({
                sidreq: this.props.sid,
                companycodereq: this.props.companycode,
                checkValue: false
            })
        }

        if (GetAPI.statusApiConfig == 200 && GetAPI.isLoadApiConfig && GetAPI.responseApiConfig['result_list'] != undefined && !this.state.checkValue) {
            var dataArr: any = []
            var dataFindArr = ""
            var dataFindLabel = ""
            for (let i = 0; i < GetAPI.responseApiConfig['result_list'].length; i++) {
                var dataReq = {}
                dataReq['value'] = GetAPI.responseApiConfig['result_list'][i].api_serverid
                dataReq['display'] = GetAPI.responseApiConfig['result_list'][i].api_url_callfunction + ' , ' + GetAPI.responseApiConfig['result_list'][i].api_serverid
                dataReq['label'] = GetAPI.responseApiConfig['result_list'][i].api_serverid + '|' + GetAPI.responseApiConfig['result_list'][i].api_url_callfunction
                dataArr.push(dataReq)

                if (GetAPI.responseApiConfig['result_list'][i].api_serverid == valueSearch && valueSearch != "") {
                    dataFindArr = GetAPI.responseApiConfig['result_list'][i].api_url_callfunction + ' , ' + GetAPI.responseApiConfig['result_list'][i].api_serverid
                    dataFindLabel = GetAPI.responseApiConfig['result_list'][i].api_serverid + '|' + GetAPI.responseApiConfig['result_list'][i].api_url_callfunction
                }
            }
            var dataSearchArr: any = []
            var dataSearch = {}
            dataSearch['value'] = valueSearch
            dataSearch['display'] = dataFindArr
            dataSearch['label'] = dataFindLabel
            dataSearchArr.push(dataSearch)

            this.setState({
                dataResult: dataArr,
                checkValue: true,
                dataSearch: dataSearchArr
            })
            console.log(dataSearch)
        }
    }

    render() {
        return (
            <Select
                options={this.state.dataResult}
                searchBy="display"
                labelField="display"
                valueField="value"
                values={this.state.dataSearch}
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageApiServerID(value[0].value, this.props.keyReq , value[0].label)}
                loading={this.state.checkValue ? false : true}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.ApiConfig }), ({ ...ApiConfigStore.actionCreators }))(AutocompleteApiServerID)
