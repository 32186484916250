import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/ApplicationLog'

interface LoadApplicationLog {
    type: 'LOAD_APPLICATIONLOG'
}

interface LoadedApplicationLog {
    type: 'LOADEDAPPLICATIONLOG'
    response: Models.ApplicationLog
    status: number,
    statusMessage: string
}
interface FailLoadedApplicationLog {
    type: 'LOADAPPLICATIONLOGFAIL'
    status: number,
    statusMessage: any
}
interface ClearApplicationLog {
    type: 'CLEARAPPLICATIONLOG'
}
interface NoUserApplicationLog {
    type: 'NOUSERAPPLICATIONLOG'
}

type KnownAction = LoadApplicationLog | LoadedApplicationLog | FailLoadedApplicationLog | ClearApplicationLog | NoUserApplicationLog

export const actionCreators = {
    requestGetApplicationLog: (check: boolean, method: string, sid: string, company_codes: string[], page: any, page_size: any, legacy_id: any, transaction_date_from: any, transaction_date_to: any, source_id1: any, source_id2: any, source_id3: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'GET') {
            var company_code = ""
            company_codes.forEach(element => {
                company_code += "&company_code=" + element
            });
            fetch(`/v1/application/log?sid=` + sid + company_code + '&page=' + page + '&page_size=' + page_size + '&legacy_id=' + legacy_id + '&transaction_date_from=' + transaction_date_from + '&transaction_date_to=' + transaction_date_to + '&source_id1=' + source_id1 + '&source_id2=' + source_id2 + '&source_id3=' + source_id3, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.ApplicationLog>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDAPPLICATIONLOG', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADAPPLICATIONLOGFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_APPLICATIONLOG' });

        } else if (appState && appState.legacyTask && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARAPPLICATIONLOG' });
        }
        else if (appState && appState.legacyTask && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSERAPPLICATIONLOG' });
        }

    }
}

const unloadedState: Models.GetApplicationLogState = {
    isLoadingApplicationLog: false,
    isLoadApplicationLog: false
};

export const reducer: Reducer<Models.GetApplicationLogState> = (state: Models.GetApplicationLogState | undefined, incomingAction: Action): Models.GetApplicationLogState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_APPLICATIONLOG':
            return {
                isLoadingApplicationLog: true,
                isLoadApplicationLog: false,
            }

        case 'LOADEDAPPLICATIONLOG':
            return {
                isLoadingApplicationLog: false,
                isLoadApplicationLog: true,
                responseApplicationLog: action.response as any,
                statusApplicationLog: action.status
            }

        case 'LOADAPPLICATIONLOGFAIL':
            return {
                isLoadApplicationLog: true,
                messageApplicationLog: action.statusMessage,
                statusApplicationLog: action.status
            }

        case 'CLEARAPPLICATIONLOG':
            return {
                isLoadApplicationLog: false,
                messageApplicationLog: '',
                statusApplicationLog: 400
            }

        case 'NOUSERAPPLICATIONLOG':
            return {
                isLoadApplicationLog: true,
                statusApplicationLog: 400
            }
        default: return state;
    }
}