import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/liscommand/liscommand'

// ADD SOURCE
interface LoadsFTPExistSource {
    type: 'LOAD_SFTPEXISTSOURCE'
}
interface LoadedsFTPExistSource {
    type: 'LOADEDSFTPEXISTSOURCE'
    response: Models.ResponseTestSftpExist
    status: number,
    statusMessage: string
}
interface FailLoadedsFTPExistSource {
    type: 'LOADSFTPEXISTSOURCEFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedsFTPExistSource {
    type: 'CLEARLOADEDSFTPEXISTSOURCE'
}

// ADD DESTINATION
interface LoadsFTPExistDest {
    type: 'LOAD_SFTPEXISTDEST'
}
interface LoadedsFTPExistDest {
    type: 'LOADEDSFTPEXISTDEST'
    response: Models.ResponseTestSftpExist
    status: number,
    statusMessage: string
}
interface FailLoadedsFTPExistDest {
    type: 'LOADSFTPEXISTDESTFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedsFTPExistDest {
    type: 'CLEARLOADEDSFTPEXISTDEST'
}

// GET ENV
interface Request_Env {
    type: 'LOAD_ENV'
}
interface GetEnv {
    type: 'LOADEDGETENV'
    response: string
    status: number,
    statusMessage: string
}
interface GetEnvFail {
    type: 'LOADGETENVFAIL'
    status: number,
    statusMessage: any
}
interface ClearGetEnv {
    type: 'CLEARLOADEDGETENV'
}

type KnownAction = LoadsFTPExistSource | LoadedsFTPExistSource | FailLoadedsFTPExistSource | ClearLoadedsFTPExistSource | LoadsFTPExistDest | LoadedsFTPExistDest | FailLoadedsFTPExistDest | ClearLoadedsFTPExistDest | Request_Env | GetEnv | GetEnvFail | ClearGetEnv

export const actionCreators = {
    requestGetSftpSource: (check: boolean, method: string, Formbody: Models.ReqTestSftp): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.lisCommand && check && method == 'POST') {
            console.log('call actionCreators : requestSftpSource')
            console.log(Formbody)
            fetch(`/v1/lis-server/exist/sftp/path`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSFTPEXISTSOURCEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDSFTPEXISTSOURCE', response: data, status: 200, statusMessage: '' });
                        })
                    }
                    //dispatch({ type: 'ADDEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'ADDAPICONFIGFAIL', status: 204, statusMessage: error.message });
                })
            dispatch({ type: 'LOAD_SFTPEXISTSOURCE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDSFTPEXISTSOURCE' });
        }
    },

    requestGetSftpDest: (check: boolean, method: string, Formbody: Models.ReqTestSftp): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.lisCommand && check && method == 'POST') {
            console.log('call actionCreators : requestSftpDest')
            console.log(Formbody)
            fetch(`/v1/lis-server/exist/sftp/path`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSFTPEXISTDESTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDSFTPEXISTDEST', response: data, status: 200, statusMessage: '' });
                        })
                    }
                    //dispatch({ type: 'ADDEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'ADDAPICONFIGFAIL', status: 204, statusMessage: error.message });
                })
            dispatch({ type: 'LOAD_SFTPEXISTDEST' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDSFTPEXISTDEST' });
        }
    },

    requestGetEnvApiUrl: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.lisCommand && check && method == 'GET') {
            console.log('call actionCreators : requestGetEnvApiUrl')
            fetch(`/v1/get-env`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADGETENVFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.text().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDGETENV', response: data, status: 200, statusMessage: '' });
                        })
                    }
                    //dispatch({ type: 'ADDEDAPICONFIG', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'ADDAPICONFIGFAIL', status: 204, statusMessage: error.message });
                })
            dispatch({ type: 'LOAD_ENV' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDGETENV' });
        }
    }
}

const unloadedSftpSourceState: Models.TestSftpSourceState = { isLoadSftpSource: false };
const unloadedSftpDestState: Models.TestSftpDestState = { isLoadSftpDest: false };
const unloadedGetEnv: Models.GetEnvState = { isLoadGetEnv: false };

const reducerSftpSource: Reducer<Models.TestSftpSourceState> = (state: Models.TestSftpSourceState | undefined, incomingAction: Action): Models.TestSftpSourceState => {
    if (state == undefined) {
        return unloadedSftpSourceState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SFTPEXISTSOURCE':
            return {
                isLoadSftpSource: false,
            }

        case 'LOADEDSFTPEXISTSOURCE':
            return {
                isLoadSftpSource: true,
                responseSftpSource: action.response as any,
                statusSftpSource: action.status
            }

        case 'LOADSFTPEXISTSOURCEFAIL':
            return {
                isLoadSftpSource: true,
                messageSftpSource: action.statusMessage,
                statusSftpSource: action.status
            }
        case 'CLEARLOADEDSFTPEXISTSOURCE':
            return {
                isLoadSftpSource: false,
                messageSftpSource: '',
                statusSftpSource: 0
            }
        default: return state;
    }
}

const reducerSftpDest: Reducer<Models.TestSftpDestState> = (state: Models.TestSftpDestState | undefined, incomingAction: Action): Models.TestSftpDestState => {
    if (state == undefined) {
        return unloadedSftpDestState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SFTPEXISTDEST':
            return {
                isLoadSftpDest: false,
            }

        case 'LOADEDSFTPEXISTDEST':
            return {
                isLoadSftpDest: true,
                responseSftpDest: action.response as any,
                statusSftpDest: action.status
            }

        case 'LOADSFTPEXISTDESTFAIL':
            return {
                isLoadSftpDest: true,
                messageSftpDest: action.statusMessage,
                statusSftpDest: action.status
            }

        case 'CLEARLOADEDSFTPEXISTDEST':
            return {
                isLoadSftpDest: false,
                messageSftpDest: "",
                statusSftpDest: 400
            }

        default: return state;
    }
}

const reducerGetEnv: Reducer<Models.GetEnvState> = (state: Models.GetEnvState | undefined, incomingAction: Action): Models.GetEnvState => {
    if (state == undefined) {
        return unloadedGetEnv;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ENV':
            return {
                isLoadGetEnv: false,
            }

        case 'LOADEDGETENV':
            return {
                isLoadGetEnv: true,
                responseGetEnv: action.response as any,
                statusGetEnv: action.status
            }

        case 'LOADGETENVFAIL':
            return {
                isLoadGetEnv: true,
                messageGetEnv: action.statusMessage,
                statusGetEnv: action.status
            }
        case 'CLEARLOADEDGETENV':
            return {
                isLoadGetEnv: false,
                messageGetEnv: '',
                statusGetEnv: 0
            }
        default: return state;
    }
}
export const rootReducer = combineReducers({ GetSftpSource: reducerSftpSource, GetSftpDest: reducerSftpDest, GetEnv: reducerGetEnv });