import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/LegacyReport'

interface LoadLegacyReport {
    type: 'LOAD_LEGACYREPORT'
}
interface LoadedLegacyReport {
    type: 'LOADEDLEGACYREPORT'
    response: Models.LegacyReport
    status: number,
    statusMessage: string
}
interface FailLoadedLegacyReport {
    type: 'LOADLEGACYREPORTFAIL'
    status: number,
    statusMessage: any
}
interface ClearLegacyReport {
    type: 'CLEAR_LEGACYREPORT'
}

interface NoUserLegacyReport {
    type: 'NOUSER_LEGACYREPORT'
}

interface LoadFileList {
    type: 'LOAD_FILELIST'
}
interface LoadedFileList {
    type: 'LOADEDFILELIST'
    response: Models.GetFileListRes
    status: number,
    statusMessage: string
}
interface FailLoadedFileList {
    type: 'LOADFILELISTFAIL'
    status: number,
    statusMessage: any
}
interface ClearFileList {
    type: 'CLEAR_FILELIST'
}
/////////////////////
interface LoadDeleteSftpFile {
    type: 'LOAD_DELETE_FILE'
}
interface DeleteSftpFile {
    type: 'DELETE_SFTPFILE',
    status: number,
}
interface FailDeleteSftpFile {
    type: 'DELETE_SFTPFILE_FAIL',
    status: number,
    statusMessage: any
}
interface ClearDeleteSftpFile {
    type: 'CLEAR_DELETE_FILE'
}
////////////////////
interface LoadAddSftpFile {
    type: 'LOAD_ADD_FILE'
}
interface AddSftpFile {
    type: 'ADD_SFTPFILE',
    status: number,
}
interface FailAddSftpFile {
    type: 'ADD_SFTPFILE_FAIL',
    status: number,
    statusMessage: any
}
interface ClearAddSftpFile {
    type: 'CLEAR_ADD_FILE'
}

interface LoadViewFile {
    type: 'LOAD_VIEWWILE'
}
interface LoadedViewFile {
    type: 'LOADEDVIEWFILE'
    response: Models.GetFileListRes
    status: number,
    statusMessage: string
}
interface FailViewFile {
    type: 'LOADVIEWFILE_FAIL'
    status: number,
    statusMessage: any
}
interface ClearViewFile {
    type: 'CLEAR_VIEWFILE'
}

interface LoadViewDMSFile {
    type: 'LOAD_VIEWDMSFILE'
}
interface LoadedViewDMSFile {
    type: 'LOADEDVIEWDMSFILE'
    response: Models.GetFileListDMS
    status: number,
    statusMessage: string
}
interface FailViewDMSFile {
    type: 'LOADVIEWDMSFILE_FAIL'
    status: number,
    statusMessage: any
}
interface ClearViewDMSFile {
    type: 'CLEAR_VIEWDMSFILE'
}

interface LoadLegacyReportByID {
    type: 'LOAD_LEGACYREPORTBYID'
}
interface LoadedLegacyReportByID {
    type: 'LOADEDLEGACYREPORTBYID'
    response: Models.LegacyReport
    status: number,
    statusMessage: string
}
interface FailLoadedLegacyReportByID {
    type: 'LOADLEGACYREPORTBYIDFAIL'
    status: number,
    statusMessage: any
}
interface ClearLegacyReportByID {
    type: 'CLEAR_LEGACYREPORTBYID'
}


type KnownAction = LoadedLegacyReport
    | FailLoadedLegacyReport
    | LoadLegacyReport
    | ClearLegacyReport
    | LoadFileList
    | LoadedFileList
    | FailLoadedFileList
    | ClearFileList
    | NoUserLegacyReport
    | LoadDeleteSftpFile
    | DeleteSftpFile
    | ClearDeleteSftpFile
    | FailDeleteSftpFile
    | LoadAddSftpFile
    | AddSftpFile
    | FailAddSftpFile
    | ClearAddSftpFile
    | LoadViewFile
    | LoadedViewFile
    | FailViewFile
    | ClearViewFile
    | LoadLegacyReportByID
    | LoadedLegacyReportByID
    | FailLoadedLegacyReportByID
    | ClearLegacyReportByID
    | LoadViewDMSFile
    | LoadedViewDMSFile
    | FailViewDMSFile
    | ClearViewDMSFile

export const actionCreators = {
    requestGetLegacyReport: (check: boolean, method: string, sid: string, company_codes: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'POST') {
            console.log('call actionCreators : requestGetLegacyReport')
            var company_code = ""
            company_codes.forEach(element => {
                company_code += "&company_code=" + element
            });
            fetch(`/v1/legacy/report?sid=` + sid + company_code, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.LegacyReport>
                    }
                })
                .then((data) => {
                    console.log(data)
                    var arr = data['result_list']
                    var arrSort = arr.sort(function (a, b) {
                        return b.job_finished_on - a.job_finished_on;
                    });
                    ////var arrSort = arr.sort(function (a, b) {
                    ////    return (parseInt(b.job_finished_on) >= parseInt(a.job_finished_on)) ? -1 : 1;
                    ////});
                    console.log(JSON.stringify(arrSort))
                    data['result_list'] = arrSort
                    dispatch({ type: 'LOADEDLEGACYREPORT', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADLEGACYREPORTFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_LEGACYREPORT' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_LEGACYREPORT' });
        } else if (method == 'NOUSER') {
            dispatch({ type: 'NOUSER_LEGACYREPORT' });
        }
    },

    requestGetLegacyReportV2: (check: boolean, method: string, sid: string, company_codes: string[], user: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'POST') {
            let company_code = ""
            company_codes.forEach(element => {
                company_code += "&company_code=" + element
            });
            fetch(`/v2/legacy/report?sid=` + sid + company_code + `&username=${user}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.LegacyReport>
                    }
                })
                .then((data) => {
                    console.log(data)
                    var arr = data['result_list']
                    var arrSort = arr.sort(function (a, b) {
                        return b.job_finished_on - a.job_finished_on;
                    });
                    //var arrSort = arr.sort(function (a, b) {
                    //    return (parseInt(b.job_finished_on) >= parseInt(a.job_finished_on)) ? -1 : 1;
                    //});
                    //console.log(JSON.stringify(arrSort))
                    data['result_list'] = arrSort
                    dispatch({ type: 'LOADEDLEGACYREPORT', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    dispatch({ type: 'LOADLEGACYREPORTFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_LEGACYREPORT' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_LEGACYREPORT' });
        } else if (method == 'NOUSER') {
            dispatch({ type: 'NOUSER_LEGACYREPORT' });
        }
    },

    requestGetFileList: (check: boolean, method: string, legacy_id: string, key: string, company_code: string, job_instance_no: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators')
        if (appState && appState.legacyReport && check && method == 'POST') {
            console.log('call actionCreators : requestGetFileList')
            var dataReq = {};
            var url = "";
            console.log(key)
            if (key == "sftp_source_backup_main_page") {
                dataReq = {
                    legacy_id: legacy_id,
                    list_type: 'sftp_source_backup',
                    company_code: company_code,
                }
                url = "/v1/lis-server/files/sftp/path-recursive";
            } else {
                dataReq = {
                    legacy_id: legacy_id,
                    list_type: key,
                    company_code: company_code,
                    job_instance_no: job_instance_no,
                }
                url = "/v3/lis-server/files/sftp/path";
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataReq)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADFILELISTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        if (data.status == 200) {
                            data.json().then(data => {
                                console.log(data)
                                dispatch({ type: 'LOADEDFILELIST', response: data, status: 200, statusMessage: '' });
                            })
                        } else {
                            dispatch({ type: 'LOADFILELISTFAIL', status: 204, statusMessage: JSON.stringify({ message: 'No Such Directory' }) });
                        }
                    }
                });
            dispatch({ type: 'LOAD_FILELIST' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_FILELIST' });
        }
    },

    requestDeleteSftpFile: (check: boolean, method: string, req: Models.ReqFileList): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteSftpFile')
            fetch(`/v1/lis-server/rm-file`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETE_SFTPFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        console.log(data)
                        dispatch({ type: 'DELETE_SFTPFILE', status: 200 });
                    }
                });
            dispatch({ type: 'LOAD_DELETE_FILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_DELETE_FILE' });
        }
    },

    requestDeleteSftpFileV2: (check: boolean, method: string, req: Models.ReqFileListV2): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteSftpFileV2')
            fetch(`/v2/lis-server/rm-file`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETE_SFTPFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        console.log(data)
                        dispatch({ type: 'DELETE_SFTPFILE', status: 200 });
                    }
                });
            dispatch({ type: 'LOAD_DELETE_FILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_DELETE_FILE' });
        }
    },

    requestAddSftpFile: (check: boolean, method: string, req: Models.ReqFileList): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'PUT') {
            console.log('call actionCreators : requestAddSftpFile')
            fetch(`/v1/lis-server/put-file`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADD_SFTPFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        console.log(data)
                        dispatch({ type: 'ADD_SFTPFILE', status: 200 });
                    }
                });
            dispatch({ type: 'LOAD_ADD_FILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_ADD_FILE' });
        }
    },

    requestAddSftpFileV2: (check: boolean, method: string, req: Models.ReqAddFileListV2): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'PUT') {
            console.log('call actionCreators : requestAddSftpFile')
            fetch(`/v2/lis-server/put-file`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADD_SFTPFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        console.log(data)
                        dispatch({ type: 'ADD_SFTPFILE', status: 200 });
                    }
                });
            dispatch({ type: 'LOAD_ADD_FILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_ADD_FILE' });
        }
    },

    requestViewFile: (check: boolean, method: string, req: Models.ReqFileList): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'GET') {
            console.log('call actionCreators : requestViewFile')
            //console.log(req)

            fetch(`/v1/dms/file/list?url=` + req.url + "&token=" + req.token + "&bucket_id=" + req.bucket_id + "&job_instance_no=" + req.job_instance_no, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADVIEWFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDVIEWFILE', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_VIEWWILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_VIEWFILE' });
        }
    },

    requestViewFileV2: (check: boolean, method: string, req: Models.ReqDmsFileList): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'GET') {
            console.log('call actionCreators : requestViewFileV2')
            //console.log(req)

            fetch(`/v2/dms/file/list?sid=` + req.sid + "&company_code=" + req.company_code + "&legacy_id=" + req.legacy_id + "&job_instance_no=" + req.job_instance_no, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADVIEWDMSFILE_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDVIEWDMSFILE', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_VIEWDMSFILE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_VIEWDMSFILE' });
        }
    },

    requestGetLegacyReportByID: (check: boolean, method: string, sid: string, company_code: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'POST') {
            console.log('call actionCreators : requestGetLegacyReportByID')
            fetch(`/v1/legacy/report?sid=` + sid + '&company_code=' + company_code, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.LegacyReport>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDLEGACYREPORTBYID', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADLEGACYREPORTBYIDFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_LEGACYREPORTBYID' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_LEGACYREPORTBYID' });
        }
    },
}

const unloadedState: Models.GetLegacyReportState = { isLoadLegacyReport: false };
const unloadedStateFileList: Models.GetFileListState = { isLoadGetFileList: false };
const unloadedStateDeleteFile: Models.DeleteSftpFileState = { isLoadDeleteSftpFile: false };
const unloadedStateAddFile: Models.PutSftpFileState = { isLoadPutSftpFile: false };
const unloadedStateViewFile: Models.GetFileListViewState = { isLoadGetFileListView: false };
const unloadedStateViewFileV2: Models.GetFileDMSListViewState = { isLoadGetFileDMSListView: false };
const unloadedStateByID: Models.GetLegacyReportByIDByIDState = { isLoadLegacyReportByID: false };


const reducerGet: Reducer<Models.GetLegacyReportState> = (state: Models.GetLegacyReportState | undefined, incomingAction: Action): Models.GetLegacyReportState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_LEGACYREPORT':
            return {
                isLoadLegacyReport: false,
            }

        case 'LOADEDLEGACYREPORT':
            return {
                isLoadLegacyReport: true,
                responseLegacyReport: action.response as any,
                statusLegacyReport: action.status
            }

        case 'LOADLEGACYREPORTFAIL':
            return {
                isLoadLegacyReport: true,
                messageLegacyReport: action.statusMessage,
                statusLegacyReport: action.status
            }

        case 'CLEAR_LEGACYREPORT':
            return {
                isLoadLegacyReport: false,
                messageLegacyReport: '',
                statusLegacyReport: 400,
            }

        case 'NOUSER_LEGACYREPORT':
            return {
                isLoadLegacyReport: true,
                statusLegacyReport: 400,
            }
        default: return state;
    }
}

const reducerFile: Reducer<Models.GetFileListState> = (state: Models.GetFileListState | undefined, incomingAction: Action): Models.GetFileListState => {
    if (state == undefined) {
        return unloadedStateFileList;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_FILELIST':
            return {
                isLoadGetFileList: false,
            }

        case 'LOADEDFILELIST':
            return {
                isLoadGetFileList: true,
                responseGetFileList: action.response as any,
                statusGetFileList: action.status
            }

        case 'LOADFILELISTFAIL':
            return {
                isLoadGetFileList: true,
                messageGetFileList: action.statusMessage,
                statusGetFileList: action.status
            }

        case 'CLEAR_FILELIST':
            return {
                isLoadGetFileList: false,
                messageGetFileList: '',
                statusGetFileList: 400,
            }
        default: return state;
    }
}

const reducerDeleteFile: Reducer<Models.DeleteSftpFileState> = (state: Models.DeleteSftpFileState | undefined, incomingAction: Action): Models.DeleteSftpFileState => {
    if (state == undefined) {
        return unloadedStateDeleteFile;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_DELETE_FILE':
            return {
                isLoadDeleteSftpFile: false,
            }

        case 'DELETE_SFTPFILE':
            return {
                isLoadDeleteSftpFile: true,
                statusDeleteSftpFile: action.status
            }

        case 'DELETE_SFTPFILE_FAIL':
            return {
                isLoadDeleteSftpFile: true,
                messageDeleteSftpFile: action.statusMessage,
                statusDeleteSftpFile: action.status
            }

        case 'CLEAR_DELETE_FILE':
            return {
                isLoadDeleteSftpFile: false,
                messageDeleteSftpFile: '',
                statusDeleteSftpFile: 400,
            }
        default: return state;
    }
}

const reducerAddFile: Reducer<Models.PutSftpFileState> = (state: Models.PutSftpFileState | undefined, incomingAction: Action): Models.PutSftpFileState => {
    if (state == undefined) {
        return unloadedStateAddFile;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ADD_FILE':
            return {
                isLoadPutSftpFile: false,
            }

        case 'ADD_SFTPFILE':
            return {
                isLoadPutSftpFile: true,
                statusPutSftpFile: action.status
            }

        case 'ADD_SFTPFILE_FAIL':
            return {
                isLoadPutSftpFile: true,
                messagePutSftpFile: action.statusMessage,
                statusPutSftpFile: action.status
            }

        case 'CLEAR_ADD_FILE':
            return {
                isLoadPutSftpFile: false,
                messagePutSftpFile: '',
                statusPutSftpFile: 400,
            }
        default: return state;
    }
}

const reducerViewFile: Reducer<Models.GetFileListViewState> = (state: Models.GetFileListViewState | undefined, incomingAction: Action): Models.GetFileListViewState => {
    if (state == undefined) {
        return unloadedStateViewFile;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_VIEWWILE':
            return {
                isLoadGetFileListView: false,
            }

        case 'LOADEDVIEWFILE':
            return {
                isLoadGetFileListView: true,
                responseGetFileListView: action.response as any,
                statusGetFileListView: action.status
            }

        case 'LOADVIEWFILE_FAIL':
            return {
                isLoadGetFileListView: true,
                messageGetFileListView: action.statusMessage,
                statusGetFileListView: action.status
            }

        case 'CLEAR_VIEWFILE':
            return {
                isLoadGetFileListView: false,
                messageGetFileListView: '',
                statusGetFileListView: 400,
            }
        default: return state;
    }
}

const reducerViewFileV2: Reducer<Models.GetFileDMSListViewState> = (state: Models.GetFileDMSListViewState | undefined, incomingAction: Action): Models.GetFileDMSListViewState => {
    if (state == undefined) {
        return unloadedStateViewFileV2;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_VIEWDMSFILE':
            return {
                isLoadGetFileDMSListView: false,
            }

        case 'LOADEDVIEWDMSFILE':
            return {
                isLoadGetFileDMSListView: true,
                responseGetFileDMSListView: action.response as any,
                statusGetFileDMSListView: action.status
            }

        case 'LOADVIEWDMSFILE_FAIL':
            return {
                isLoadGetFileDMSListView: true,
                messageGetFileDMSListView: action.statusMessage,
                statusGetFileDMSListView: action.status
            }

        case 'CLEAR_VIEWDMSFILE':
            return {
                isLoadGetFileDMSListView: false,
                messageGetFileDMSListView: '',
                statusGetFileDMSListView: 400,
            }
        default: return state;
    }
}

const reducerGetByID: Reducer<Models.GetLegacyReportByIDByIDState> = (state: Models.GetLegacyReportByIDByIDState | undefined, incomingAction: Action): Models.GetLegacyReportByIDByIDState => {
    if (state == undefined) {
        return unloadedStateByID;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_LEGACYREPORTBYID':
            return {
                isLoadLegacyReportByID: false,
            }

        case 'LOADEDLEGACYREPORTBYID':
            return {
                isLoadLegacyReportByID: true,
                responseLegacyReportByID: action.response as any,
                statusLegacyReportByID: action.status
            }

        case 'LOADLEGACYREPORTBYIDFAIL':
            return {
                isLoadLegacyReportByID: true,
                messageLegacyReportByID: action.statusMessage,
                statusLegacyReportByID: action.status
            }

        case 'CLEAR_LEGACYREPORTBYID':
            return {
                isLoadLegacyReportByID: false,
                messageLegacyReportByID: '',
                statusLegacyReportByID: 400,
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetReport: reducerGet, GetFile: reducerFile, DeleteFile: reducerDeleteFile, PutFile: reducerAddFile, GetFileView: reducerViewFile, GetDMSFile: reducerViewFileV2 ,GetReportByID: reducerGetByID });

