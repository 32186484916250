import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPlusCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2'
import { FaCheckCircle, FaTimesCircle, FaPlayCircle } from "react-icons/fa";

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import CompanyConfigTable from './CompanyConfigTable'
import * as ApiConfigModels from '../../models/config/ApiConfig'
import * as ApiConfigStore from '../../store/config/ApiConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import * as TestFappStore from '../../store/testConnect/FappConnection'
import * as TestFappModels from '../../models/testConnect/FappConnection'
type Store = ApiConfigModels.GetApiConfigState & typeof ApiConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators | TestFappModels.TestFappState & typeof TestFappStore.actionCreators

class CompanyConfig extends React.Component<any, {
    isShowModal: boolean,
    sid: string,
    companycode: string,
    companyname: string,
    api_serverid: string,
    api_url_callfunction: string,
    api_username: string,
    api_password: string,
    editMode: boolean,
    key: any,
    show_password: boolean,
    check_fapp: boolean,
    fappModal: boolean,
    userId: string,
}, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            userId: '',
            isShowModal: false,
            sid: "555",
            companycode: "",
            companyname: "",
            api_serverid: "",
            api_url_callfunction: "",
            api_username: "",
            api_password: "",
            editMode: false,
            key: "",
            show_password: false,
            check_fapp: false,
            fappModal: false,
        }
        this.handleEdit = this.handleEdit.bind(this)
        this.handleShowPass = this.handleShowPass.bind(this)
        this.handleTestConnect = this.handleTestConnect.bind(this)
        this.handleCloseFapp = this.handleCloseFapp.bind(this)
    }

    componentDidMount() {
        var appStorage = localStorage.getItem('LG_APPLICATION')
        if (appStorage) {
            var lis = JSON.parse(appStorage)
            console.log(lis)

            this.setState({
                userId: lis.user_id
            })
        }
    }

    handleClose = () => {
        console.log(this.state.isShowModal)
        if (this.state.isShowModal == false) {
            this.setState({
                isShowModal: true
            })
        } else {
            this.setState({
                isShowModal: false,
                sid: "555",
                companycode: "",
                companyname: "",
                api_serverid: "",
                api_url_callfunction: "",
                api_username: "",
                api_password: "",
                editMode: false
            })
        }
    }

    handleChangeInput = (e: any, key: any) => {
        if (key == "sid") {
            this.setState({
                sid: e.target.value
            })
        } else if (key == "companycode") {
            this.setState({
                companycode: e.target.value
            })
        } else if (key == "companyname") {
            this.setState({
                companyname: e.target.value
            })
        } else if (key == "api_serverid") {
            this.setState({
                api_serverid: e.target.value
            })
        } else if (key == "api_url_callfunction") {
            this.setState({
                api_url_callfunction: e.target.value
            })
        } else if (key == "api_username") {
            this.setState({
                api_username: e.target.value
            })
        } else {
            this.setState({
                api_password: e.target.value
            })
        }
    }

    handleSave = () => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.UseLoading(true)
        const { sid, companycode, companyname, api_serverid, api_url_callfunction, api_username, api_password, editMode } = this.state
        var dataSet: any = {
            sid: sid,
            //companycode: companycode,
            companyname: companyname,
            api_serverid: api_serverid,
            api_url_callfunction: api_url_callfunction,
            api_username: api_username,
            api_password: api_password
        }

        console.log(dataSet)
        // save
        if (!editMode) {
            this.props.requestAddApiConfig(true, "POST", dataSet);
        }
        // edit
        else {
            this.props.requestEditApiConfig(true, "PUT", dataSet);
        }
    }

    Reset() {
        this.props.requestAddApiConfig(true, "CLEAR", []);
        //this.props.requestGetFappTest(true, "CLEAR", this.state.api_serverid);
        this.setState({
            key: 'Reset'
        })
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    public handleEdit = (sid: string, api_server_id: string) => {
        //document.getElementsByClassName('Loader__background')[0].classList.add('Loader__background show');
        this.props.UseLoading(true)
        this.props.requestSearchApiConfig(true, "GET", sid, api_server_id)
    }

    handleShowPass(e: any) {
        var x: any = document.getElementById("api_password");
        if (x.type === "password") {
            x.type = "text";
            this.setState({
                show_password: true
            })
        } else {
            x.type = "password";
            this.setState({
                show_password: false
            })
        }
    }

    handleTestConnect() {
        Swal.fire({
            title: 'FAPP Test ?',
            text: 'คุณต้องการทดสอบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                this.setState({
                    check_fapp: true,
                    isShowModal: false
                })
                this.props.UseLoading(true)
                this.props.requestGetFappTest(true, "GET", this.state.api_url_callfunction);
            }
        })
    }

    handleCloseFapp() {
        if (!this.state.fappModal) {
            this.setState({
                fappModal: true
            })
        } else {
            this.props.requestGetFappTest(true, "CLEAR", this.state.api_serverid);
            this.setState({
                fappModal: false,
                sid: "",
                companycode: "",
                companyname: "",
                api_serverid: "",
                api_url_callfunction: "",
                api_username: "",
                api_password: ""
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { GetAPI, AddAPI, DeleteAPI, EditAPI, SearchAPI, isLoadTestFapp, statusTestFapp, messageTestFapp } = this.props
        if (AddAPI.isLoadAddApiConfig && AddAPI.statusAddApiConfig == 200) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestAddApiConfig(true, "CLEAR", []);
            this.props.requestEditApiConfig(true, "CLEAR", []);
            this.props.UseLoading(false)
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sid: "",
                        companycode: "",
                        companyname: "",
                        api_serverid: "",
                        api_url_callfunction: "",
                        api_username: "",
                        api_password: "",
                        editMode: false,
                    })
                }
            })
        } else if (AddAPI.isLoadAddApiConfig && AddAPI.statusAddApiConfig == 204) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestAddApiConfig(true, "CLEAR", []);
            this.props.requestEditApiConfig(true, "CLEAR", []);
            this.props.UseLoading(false)
            var msg = JSON.parse(AddAPI.messageAddApiConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        sid: "",
                        companycode: "",
                        companyname: "",
                        api_serverid: "",
                        api_url_callfunction: "",
                        api_username: "",
                        api_password: "",
                        editMode: false,
                    })
                }
            })
        }

        if (SearchAPI.isLoadSearchApiConfig && SearchAPI.statusSearchApiConfig == 200) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.UseLoading(false)
            var dataArr: ApiConfigModels.ApiConfig = SearchAPI.responseSearchApiConfig['result_list'][0]
            this.setState({
                isShowModal: true,
                editMode: true,
                sid: dataArr.sid,
                //companycode: dataArr.companycode,
                companyname: dataArr.companyname,
                api_serverid: dataArr.api_serverid,
                api_url_callfunction: dataArr.api_url_callfunction,
                api_username: dataArr.api_username,
                api_password: dataArr.api_password
            })
            this.props.requestSearchApiConfig(true, "CLEAR")

        } else if (SearchAPI.isLoadSearchApiConfig && (SearchAPI.statusSearchApiConfig == 204 || SearchAPI.statusSearchApiConfig == 400)) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(SearchAPI.messageSearchApiConfig)
            this.props.UseLoading(false)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false
                    })
                }
            })
        }

        if (EditAPI.isLoadEditApiConfig && EditAPI.statusEditApiConfig == 200) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.UseLoading(false)
            this.props.requestEditApiConfig(true, "CLEAR");
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        isShowModal: false,
                        sid: "",
                        companycode: "",
                        companyname: "",
                        api_serverid: "",
                        api_url_callfunction: "",
                        api_username: "",
                        api_password: "",
                        editMode: false,
                    })
                    this.Reset()
                }
            })
        } else if (EditAPI.isLoadEditApiConfig && EditAPI.statusEditApiConfig != 200) {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(EditAPI.messageEditApiConfig)
            this.props.UseLoading(false)
            this.props.requestEditApiConfig(true, "CLEAR");
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        isShowModal: false,
                        sid: "",
                        companycode: "",
                        companyname: "",
                        api_serverid: "",
                        api_url_callfunction: "",
                        api_username: "",
                        api_password: "",
                        editMode: false
                    })
                    this.Reset()
                }
            })
        }

        if (isLoadTestFapp && statusTestFapp == 200 && this.state.check_fapp) {
            this.props.UseLoading(false)
            this.setState({
                isShowModal: false,
                fappModal: true,
                check_fapp: false
            })
        } else if (isLoadTestFapp && statusTestFapp != 200 && this.state.check_fapp) {
            var msg = JSON.parse(messageTestFapp)
            this.props.UseLoading(false)
            console.log(msg)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                /*text: JSON.parse(messageTestFapp)['message'],*/
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetFappTest(true, "CLEAR", this.state.api_serverid);
                    this.setState({
                        fappModal: false,
                        sid: "",
                        companycode: "",
                        companyname: "",
                        api_serverid: "",
                        api_url_callfunction: "",
                        api_username: "",
                        api_password: ""
                    })
                }
            })
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    API config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='trapezoid trapezoid-purple' style={{ width: 'fit-content', display: 'inline-block' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        API config Table
                                                    </span>
                                                </div>
                                                <div style={{ display: 'inline-block', float: 'right' }}>
                                                    <Button variant="info" size="sm" onClick={this.handleClose}>
                                                        <FaPlusCircle />
                                                        &nbsp;Add API config
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <CompanyConfigTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} callbackEdit={this.handleEdit.bind(this)} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={this.state.isShowModal} onHide={this.handleClose} size="lg" dialogClassName="custom-modal">
                    <Modal.Header translate closeButton>
                        <Modal.Title>Add API Config</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/*<Col>
                                <Form.Label className="font-weight-bold">SID :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="sid" aria-describedby="basic-addon3" value={this.state.sid} onChange={(e) => this.handleChangeInput(e, "sid")} disabled={this.state.editMode} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label className="font-weight-bold">Company Name :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="companyname" aria-describedby="basic-addon3" value={this.state.companyname} onChange={(e) => this.handleChangeInput(e, "companyname")} />
                                </InputGroup>
                            </Col>*/}
                            {/*<Col>*/}
                            {/*    <Form.Label className="font-weight-bold">Company Code :</Form.Label>*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl className="require-field" id="companycode" aria-describedby="basic-addon3" value={this.state.companycode} onChange={(e) => this.handleChangeInput(e, "companycode")}/>*/}
                            {/*    </InputGroup>*/}
                            {/*</Col>*/}
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Label className="font-weight-bold">Company Name :</Form.Label>*/}
                        {/*        <InputGroup className="mb-3">*/}
                        {/*            <FormControl className="require-field" id="companyname" aria-describedby="basic-addon3" value={this.state.companyname} onChange={(e) => this.handleChangeInput(e, "companyname")} />*/}
                        {/*        </InputGroup>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">API Server ID :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="api_url_callfunction" aria-describedby="basic-addon3" value={this.state.api_serverid} onChange={(e) => this.handleChangeInput(e, "api_serverid")} disabled={this.state.editMode} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">API URL :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="api_serverid" aria-describedby="basic-addon3" value={this.state.api_url_callfunction} onChange={(e) => this.handleChangeInput(e, "api_url_callfunction")} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">API Username:</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="api_username" aria-describedby="basic-addon3" value={this.state.api_username} onChange={(e) => this.handleChangeInput(e, "api_username")} autoComplete="new-password" />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label className="font-weight-bold">API Password :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="api_password" type="password" aria-describedby="basic-addon3" value={this.state.api_password} onChange={(e) => this.handleChangeInput(e, "api_password")} autoComplete="new-password" />
                                    {/*<InputGroup.Checkbox aria-label="Show Password" onChange={this.handleShowPass} />*/}
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary" onClick={this.handleShowPass}>{!this.state.show_password ? <FaEye /> : <FaEyeSlash />}</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-start">
                                    <Button variant="info" onClick={this.handleTestConnect}>
                                        <FaPlayCircle /> Test Connection
                                    </Button>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={this.handleClose}>
                                        <FaTimesCircle /> Close
                                    </Button>
                                    <Button variant="primary" className="mx-1" onClick={this.handleSave}>
                                        <FaCheckCircle /> Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.fappModal} onHide={this.handleCloseFapp} size="lg" dialogClassName="custom-modal" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Response FAPP TEST</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {this.props.statusTestFapp == 200 &&
                                    <Table className="table-bordered table-hover rounded custom-table-list table-responsive-lg" size="lg">
                                        <tbody>
                                            {/*{this.props.statusTestFapp == 200 && this.props.isLoadTestFapp && this.props.responseTestFapp != null && this.props.responseTestFapp.map((item: TestFappModels.ResponseTestFapp, idx: any)}*/}
                                            <tr style={{ width: '100%' }}>
                                                <td>Percent Total CPU Usage :</td>
                                                <td>{this.props.responseTestFapp['percentTotalCPUUsage']}</td>
                                            </tr >
                                            <tr style={{ width: '100%' }}>
                                                <td>Percent Process CPU Usage :</td>
                                                <td>{this.props.responseTestFapp['percentProcessCPUUsage']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>MegaByte Total Memory :</td>
                                                <td>{this.props.responseTestFapp['megaByteTotalMemory']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>MegaByte Free Memory :</td>
                                                <td>{this.props.responseTestFapp['megaByteFreeMemory']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>MegaByte Process Memory Usage :</td>
                                                <td>{this.props.responseTestFapp['megaByteProcessMemoryUsage']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Server Version :</td>
                                                <td>{this.props.responseTestFapp['serverVersion']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Server OS :</td>
                                                <td>{this.props.responseTestFapp['serverOS']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Server Time :</td>
                                                <td>{this.props.responseTestFapp['serverTime']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Server Session :</td>
                                                <td>{this.props.responseTestFapp['serverSession']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Function Usage :</td>
                                                <td>{this.props.responseTestFapp['functionUsage']}</td>
                                            </tr>
                                            <tr style={{ width: '100%' }}>
                                                <td>Client UUID :</td>
                                                <td>{this.props.responseTestFapp['clientUUID']}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={this.handleCloseFapp}>
                                        <FaTimesCircle /> Close
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.ApiConfig, ...state.Loading, ...state.TestFapp }), ({ ...ApiConfigStore.actionCreators, ...LoadingStore.actionCreators, ...TestFappStore.actionCreators }))(CompanyConfig)