import React from 'react';

import '../../assets/style/LoadingScreen.css';

interface Props {
    loading: boolean
}

const LoadingScreen = (props: Props) => {

    return (
        <div className="container">
            {props.loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : <></>}
        </div>
    );
};

export default LoadingScreen;