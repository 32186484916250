import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, ButtonGroup, Table, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaTimesCircle } from "react-icons/fa";
import Swal from 'sweetalert2'
import Pagination from '@material-ui/lab/Pagination';

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as SftpConfigModels from '../../models/config/SftpConfig'
import * as SftpConfigStore from '../../store/config/SftpConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
type Store = SftpConfigModels.GetSftpConfigState & typeof SftpConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class SftpServerConfigTable extends React.Component<any, { isready: boolean, userId: any, companyCode: any, checkready: boolean, pageSize: any, pageBtn: any, checkPageSize: boolean }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            userId: '',
            companyCode: '',
            checkready: true,
            pageSize: 25,
            pageBtn: ['', '', ''],
            checkPageSize: true
        }

        this.handleChangePage = this.handleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        const { isready, userId, companyCode } = this.state
        const { checkkey } = this.props

        var appStorage = localStorage.getItem('LG_APPLICATION')
        if (appStorage) {
            var lis = JSON.parse(appStorage)
            console.log(lis)

            if (isready) {
                this.props.requestGetSftpConfig(isready, "GET", "", 1, this.state.pageSize)
                this.setState({
                    isready: false,
                    userId: lis.user_id
                })
            }
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { checkkey, job_started_on_from, job_started_on_to, DeleteSftp, SearchSftp, GetSftp } = this.props
        if (checkkey == "Reset" && checkkey != undefined) {
            console.log(checkkey)
            this.handleSearch()
        }

        if (DeleteSftp.isLoadDeleteSftpConfig && DeleteSftp.statusDeleteSftpConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteSftpConfig(true, "CLEAR");
                    this.handleSearch()
                }
            })
        } else if (DeleteSftp.isLoadDeleteSftpConfig && (DeleteSftp.statusDeleteSftpConfig == 204 || DeleteSftp.statusDeleteSftpConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(DeleteSftp.messageDeleteSftpConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteSftpConfig(true, "CLEAR");
                    this.handleSearch()
                }
            })
        }

        if (GetSftp.statusSftpConfig == 200 && GetSftp.isLoadSftpConfig && GetSftp.responseSftpConfig['result_list'] != undefined && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetSftp.responseSftpConfig['total_count'] / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false
            })
        }
    }

    public handleSearch() {
        const { checkkey, job_started_on_from, job_started_on_to } = this.props
        this.props.requestGetSftpConfig(true, "GET", "", 1, this.state.pageSize)
        this.props.callbackkey()
        this.setState({
            checkPageSize: true
        })
    }

    handleDelete = (sid: string, sftp_server_id: string) => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                this.props.UseLoading(true)
                this.props.requestDeleteSftpConfig(true, "DELETE", "555", sftp_server_id);
            }
        })
    }

    handleUpdate = (sid: string, sftp_server_id: string) => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.callbackEdit(sid, sftp_server_id)
        //this.props.requestSearchSftpConfig(true, "GET", sid, companycode, sftp_server_id)
    }

    handleChangePage = (event: any, size: any) => {
        const { checkkey, job_started_on_from, job_started_on_to } = this.props
        console.log(size);
        this.setState({
            isready: true
        })
        this.props.requestGetSftpConfig(true, "GET", "", size, this.state.pageSize)
    }

    handlePageSize(e: any) {
        const { checkkey, job_started_on_from, job_started_on_to } = this.props
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse,
            isready: true
        });
        this.props.requestGetSftpConfig(true, "GET", "", 1, parse)
    }

    render() {
        return (
            <React.Fragment>
                <Row className='mt-2'>
                    <Col md={6}>
                        <Container>
                            <Form className="d-flex justify-content-end justify-content-md-start align-items-center text-nowrap" style={{ gap: '0 12px' }}>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    style={{ width: 'fit-content', margin: 0 }}
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </Container>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} onChange={this.handleChangePage} shape="rounded" defaultPage={1} showFirstButton showLastButton />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Table className="table-bordered table-hover rounded custom-table-list table-responsive-lg" size="sm">
                            <thead className="rounded">
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light require-field">
                                    <th style={{ width: '5%' }} className='text-center'></th>
                                    {/*<th style={{ width: '5%' }}>SID</th>*/}
                                    {/*<th style={{ width: '5%' }}>Company Code</th>*/}
                                    <th style={{ width: '30%' }}>SFTP Server ID</th>
                                    <th style={{ width: '25%' }}>SFTP IP</th>
                                    <th style={{ width: '15%' }}>SFTP Port</th>
                                    <th style={{ width: '25%' }}>SFTP Username</th>
                                    {/*<th style={{ width: '20%' }}>Private Key Path</th>*/}
                                    {/*<th style={{ width: '10%' }}>Status</th>*/}
                                    {/*<th style={{ width: '25%' }}>Message</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {!this.props.GetSftp.isLoadSftpConfig ? <tr><td colSpan={9} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {this.props.GetSftp.isLoadSftpConfig && this.props.GetSftp.statusSftpConfig != 200 ? <tr><td colSpan={9} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {this.props.GetSftp.statusSftpConfig == 200 && this.props.GetSftp.isLoadSftpConfig && this.props.GetSftp.responseSftpConfig != null && this.props.GetSftp.responseSftpConfig['result_list'].map((item: SftpConfigModels.SftpConfig, idx: any) => {
                                    return (
                                        <tr key={idx}>
                                            <td className='text-center'>
                                                <ButtonGroup>
                                                    <Button variant="info" size="sm" onClick={() => this.handleUpdate(item.sid, item.sftp_server_id)}><FaRegEdit /></Button>
                                                    <Button variant="danger" size="sm" onClick={() => this.handleDelete(item.sid, item.sftp_server_id)}><FaTimesCircle /></Button>
                                                </ButtonGroup>
                                            </td>
                                            {/*<td className="text-center">{item.sid}</td>*/}
                                            {/*<td className="text-center">{item.companycode}</td>*/}
                                            <td>{item.sftp_server_id}</td>
                                            <td>{item.sftp_ip}</td>
                                            <td className="text-center">{item.sftp_port}</td>
                                            <td>{item.sftp_username}</td>
                                            {/*<td>{item.privatekey_path}</td>*/}
                                            {/*<td>{item.status}</td>*/}
                                            {/*<td>{item.message}</td>*/}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.SftpConfig, ...state.Loading }), ({ ...SftpConfigStore.actionCreators, ...LoadingStore.actionCreators }))(SftpServerConfigTable)