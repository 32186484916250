import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/LegacyTask'

interface LoadLegacyTask {
    type: 'LOAD_LEGACYTASK'
}

interface LoadedLegacyTask {
    type: 'LOADEDLEGACYTASK'
    response: Models.LegacyTask
    status: number,
    statusMessage: string
}
interface FailLoadedLegacyTask {
    type: 'LOADLEGACYTASKFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadedLegacyTask {
    type: 'CLEARLEGACYTASKFAIL'
}

interface NoUserLoadedLegacyTask {
    type: 'NOUSERLEGACYTASKFAIL'
}

interface LoadFileList {
    type: 'LOAD_FILELIST'
}
interface LoadedFileList {
    type: 'LOADEDFILELIST'
    response: Models.LegacyTaskGetFileListRes
    status: number,
    statusMessage: string
}
interface FailLoadedFileList {
    type: 'LOADFILELISTFAIL'
    status: number,
    statusMessage: any
}
interface ClearFileList {
    type: 'CLEAR_FILELIST'
}

type KnownAction = LoadedLegacyTask | FailLoadedLegacyTask | LoadLegacyTask | ClearLoadedLegacyTask | NoUserLoadedLegacyTask
                   | LoadFileList | LoadedFileList | FailLoadedFileList | ClearFileList

export const actionCreators = {
    requestGetLegacyTask: (check: boolean, method: string, sid: string, company_codes: string[], page: any, page_size: any, job_instance_no: any, legacy_id: any, status: string[], job_started_on_from: any, job_started_on_to: any , legacy_name : any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyTask && check && method == 'POST') {
            //console.log('call actionCreators : requestGetLegacyTask | /v1/legacy/task?sid='+ sid + '&company_code=' + company_code + '&page=' + page + '&page_size=' + page_size + '&job_instance_no=' + job_instance_no + '&legacy_id=' + legacy_id + '&status=' + status + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to )
            var company_code = ""
            var status_ = ""
            if (company_codes.length != 0) {
                company_codes.forEach(element => {
                    company_code += "&company_code=" + element
                });
            }
            if (status.length != 0) {
                status.forEach(element => {
                    status_ += "&status=" + element
                });
            }
            console.log("Task status")
            console.log(status)
            console.log(status_)
            fetch(`/v1/legacy/task?sid=` + sid + company_code + status_ + '&page=' + page + '&page_size=' + page_size + '&job_instance_no=' + job_instance_no + '&legacy_id=' + legacy_id + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to + '&legacy_name=' + legacy_name, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.LegacyTask>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDLEGACYTASK', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADLEGACYTASKFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_LEGACYTASK' });

        } else if (appState && appState.legacyTask && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARLEGACYTASKFAIL' });
        }
        else if (appState && appState.legacyTask && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSERLEGACYTASKFAIL' });
        }
    },
    requestGetFileList: (check: boolean, method: string, legacy_id: string, key: string, company_code: string, job_instance_no: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators')
        if (appState && appState.legacyReport && check && method == 'POST') {
            console.log('call actionCreators : requestGetFileList')
            var dataReq = {};
            var url = "";
            console.log(key)
            if (key == "sftp_source_backup_main_page") {
                dataReq = {
                    legacy_id: legacy_id,
                    list_type: 'sftp_source_backup',
                    company_code: company_code,
                }
                url = "/v1/lis-server/files/sftp/path-recursive";
            } else {
                dataReq = {
                    legacy_id: legacy_id,
                    list_type: key,
                    company_code: company_code,
                    job_instance_no: job_instance_no,
                }
                url = "/v3/lis-server/files/sftp/path";
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataReq)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADFILELISTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        if (data.status == 200) {
                            data.json().then(data => {
                                console.log(data)
                                dispatch({ type: 'LOADEDFILELIST', response: data, status: 200, statusMessage: '' });
                            })
                        } else {
                            dispatch({ type: 'LOADFILELISTFAIL', status: 204, statusMessage: JSON.stringify({ message: 'No Such Directory' }) });
                        }
                    }
                });
            dispatch({ type: 'LOAD_FILELIST' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAR_FILELIST' });
        }
    }
}

const unloadedState: Models.GetLegacyTaskState = { isLoadLegacyTask: false };
const unloadedStateFileList: Models.LegacyTaskGetFileListState = { isLoadGetFileList: false };

const reducerGet: Reducer<Models.GetLegacyTaskState> = (state: Models.GetLegacyTaskState | undefined, incomingAction: Action): Models.GetLegacyTaskState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_LEGACYTASK':
            return {
                isLoadLegacyTask: false,
            }

        case 'LOADEDLEGACYTASK':
            return {
                isLoadLegacyTask: true,
                responseLegacyTask: action.response as any,
                statusLegacyTask: action.status
            }

        case 'LOADLEGACYTASKFAIL':
            return {
                isLoadLegacyTask: true,
                messageLegacyTask: action.statusMessage,
                statusLegacyTask: action.status
            }

        case 'CLEARLEGACYTASKFAIL':
            return {
                isLoadLegacyTask: false,
                messageLegacyTask: "",
                statusLegacyTask: 400,
            }

        case 'NOUSERLEGACYTASKFAIL':
            return {
                isLoadLegacyTask: true,
                statusLegacyTask: 400,
            }
        default: return state;
    }
}

const reducerFile: Reducer<Models.LegacyTaskGetFileListState> = (state: Models.LegacyTaskGetFileListState | undefined, incomingAction: Action): Models.LegacyTaskGetFileListState => {
    if (state == undefined) {
        return unloadedStateFileList;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_FILELIST':
            return {
                isLoadGetFileList: false,
            }

        case 'LOADEDFILELIST':
            return {
                isLoadGetFileList: true,
                responseGetFileList: action.response as any,
                statusGetFileList: action.status
            }

        case 'LOADFILELISTFAIL':
            return {
                isLoadGetFileList: true,
                messageGetFileList: action.statusMessage,
                statusGetFileList: action.status
            }

        case 'CLEAR_FILELIST':
            return {
                isLoadGetFileList: false,
                messageGetFileList: '',
                statusGetFileList: 400,
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetTask: reducerGet, LegacyTaskGetFile: reducerFile });