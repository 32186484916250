import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/ReScheduler'

// GET
interface LoadReSchedulerConfig {
    type: 'LOAD_ReSchedulerCONFIG'
}
interface LoadedReSchedulerConfig {
    type: 'LOADEDReSchedulerCONFIG'
    response: Models.ResponseReSchedulerConfig
    status: number,
    statusMessage: string
}
interface FailLoadedReSchedulerConfig {
    type: 'LOADReSchedulerCONFIGFAIL'
    status: number,
    statusMessage: any
}

type KnownAction = LoadReSchedulerConfig | LoadedReSchedulerConfig | FailLoadedReSchedulerConfig

export const actionCreators = {
    requestGetReScheduler: (check: boolean, method: string, Formbody: Models.ReSchedulerConfigRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ApiConfig && check && method == 'POST') {
            console.log('call actionCreators : requestPositiongroup')
            console.log(JSON.stringify(Formbody))
            fetch(`/v1/lis-server/re-scheduler`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADReSchedulerCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDReSchedulerCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
                    //dispatch({ type: 'LOADEDReSchedulerCONFIG', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'LOADReSchedulerCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'LOAD_ReSchedulerCONFIG' });
        }
    },
}

const unloadedState: Models.ReSchedulerConfigState = { isLoadReSchedulerConfig: false };

const reducerReScheduler: Reducer<Models.ReSchedulerConfigState> = (state: Models.ReSchedulerConfigState | undefined, incomingAction: Action): Models.ReSchedulerConfigState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ReSchedulerCONFIG':
            return {
                isLoadReSchedulerConfig: false,
            }

        case 'LOADEDReSchedulerCONFIG':
            return {
                isLoadReSchedulerConfig: true,
                responseReSchedulerConfig: action.response as any,
                statusReSchedulerConfig: action.status
            }

        case 'LOADReSchedulerCONFIGFAIL':
            return {
                isLoadReSchedulerConfig: true,
                messageReSchedulerConfig: action.statusMessage,
                statusReSchedulerConfig: action.status
            }
        default: return state;
    }
}
export const rootReducer = combineReducers({ GetReScheduler: reducerReScheduler})