import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPlusCircle, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import SftpServerConfigTable from './SftpServerConfigTable'
import * as SftpConfigModels from '../../models/config/SftpConfig'
import * as SftpConfigStore from '../../store/config/SftpConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import * as SftpTestModels from '../../models/testConnect/SftpConnection'
import * as SftpTestStore from '../../store/testConnect/SftpConnection'
type Store = SftpConfigModels.GetSftpConfigState & typeof SftpConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators | SftpTestModels.TestSftpState & typeof SftpTestStore.actionCreators

class SftpServerConfig extends React.Component<any, { userId: string, isShowModal: boolean, reqData: any, sid: string, companycode: string, sftp_server_id: string, sftp_ip: string, sftp_port: number, sftp_username: string, sftp_password: string, privatekey_path: string, key: string, editMode: boolean, show_password: boolean, check_sftp: boolean, private_key: string, autoRefresh: any, checkAutoRefresh: boolean, AutoRefreshTime: number }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            userId: '',
            isShowModal: false,
            reqData: {},
            sid: "555",
            companycode: "",
            sftp_server_id: "",
            sftp_ip: "",
            sftp_port: 22,
            sftp_username: "",
            sftp_password: "",
            private_key: "",
            privatekey_path: "",
            key: "",
            editMode: false,
            show_password: false,
            check_sftp: false,
            autoRefresh: '',
            checkAutoRefresh: true,
            AutoRefreshTime: 30000,
        }
        this.callbackReset = this.callbackReset.bind(this)
        this.handleShowPass = this.handleShowPass.bind(this)
        this.handleTestConnect = this.handleTestConnect.bind(this)
    }

    componentDidMount() {
        //this.autoRefresh()

        var appStorage = localStorage.getItem('LG_APPLICATION')
        if (appStorage) {
            var lis = JSON.parse(appStorage)
            console.log(lis)

            this.setState({
                userId: lis.user_id
            })
        }
    }

    componentWillUnmount() {
        //clearInterval(this.state.autoRefresh);
    }

    handleClose = () => {
        console.log(this.state.isShowModal)
        if (this.state.isShowModal == false) {
            this.setState({
                isShowModal: true
            })
        } else {
            this.setState({
                isShowModal: false,
                sftp_server_id: "",
                sftp_ip: "",
                sftp_port: 22,
                sftp_username: "",
                sftp_password: "",
                privatekey_path: "",
                private_key: "",
                editMode: false
            })
        }
    }

    handleChangeInput = (e: any, key: any) => {
        if (key == "sid") {
            this.setState({
                sid: e.target.value
            })
        }
        else if (key == "companycode") {
            this.setState({
                companycode: e.target.value
            })
        }
        else if (key == "sftp_server_id") {
            this.setState({
                sftp_server_id: e.target.value
            })
        } else if (key == "sftp_ip") {
            this.setState({
                sftp_ip: e.target.value
            })
        } else if (key == "sftp_port") {
            this.setState({
                sftp_port: e.target.value
            })
        } else if (key == "sftp_username") {
            this.setState({
                sftp_username: e.target.value
            })
        } else if (key == "sftp_password") {
            this.setState({
                sftp_password: e.target.value
            })
        } else if (key == "private_key") {
            this.setState({
                private_key: e.target.value
            })
        } else {
            this.setState({
                privatekey_path: e.target.value
            })
        }
    }

    handleSave = () => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        const { sftp_server_id, sftp_ip, sftp_port, sftp_username, sftp_password, privatekey_path, editMode, private_key, sid, companycode } = this.state
        var dataSet: any = {
            sid: "555",
            //companycode: companycode,
            sftp_server_id: sftp_server_id,
            sftp_ip: sftp_ip,
            sftp_port: Number(sftp_port),
            sftp_username: sftp_username,
            sftp_password: sftp_password,
            private_key: private_key,
            privatekey_path: privatekey_path,
        }

        console.log(dataSet)
        // save
        if (!editMode) {
            this.props.requestAddSftpConfig(true, "POST", dataSet);
        }
        // edit
        else {
            this.props.requestEditSftpConfig(true, "PUT", dataSet);
        }
    }

    Reset() {
        console.log('Refresh Data!')
        this.props.requestAddSftpConfig(true, "CLEAR", []);
        this.setState({
            key: 'Reset'
        })
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    public handleEdit = (sid: string, sftp_server_id: string) => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //this.props.requestSearchSftpConfig(true, "GET", sid, companycode, sftp_server_id)
        this.props.requestSearchSftpConfig(true, "GET", sid, sftp_server_id)
    }

    handleShowPass(e: any) {
        var x: any = document.getElementById("sftp_password");
        if (x.type === "password") {
            x.type = "text";
            this.setState({
                show_password: true
            })
        } else {
            x.type = "password";
            this.setState({
                show_password: false
            })
        }
    }

    handleTestConnect() {
        if (this.state.sftp_port == undefined || this.state.sftp_username == "" || this.state.sftp_password == "" || this.state.sftp_server_id == "" || this.state.sftp_ip == "") {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'โปรดกรอกข้อมูลให้ครบถ้วนก่อนจะเริ่มทดสอบ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        } else {
            Swal.fire({
                title: 'sFTP Test ?',
                text: 'คุณต้องการทดสอบหรือไม่ ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    var dataReq: any = {}
                    dataReq['host'] = this.state.sftp_ip
                    dataReq['port'] = this.state.sftp_port
                    dataReq['username'] = this.state.sftp_username
                    dataReq['password'] = this.state.sftp_password
                    dataReq['private_key'] = this.state.private_key
                    console.log(dataReq)
                    this.setState({
                        check_sftp: true,
                        //isShowModal: false
                    })
                    this.props.UseLoading(true)
                    this.props.requestGetSftpTest(true, "POST", dataReq);
                }
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { AddSftp, SearchSftp, EditSftp, isLoadTestSftp, statusTestSftp, messageTestSftp } = this.props
        if (AddSftp.isLoadAddSftpConfig && AddSftp.statusAddSftpConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sftp_server_id: "",
                        sftp_ip: "",
                        sftp_port: 22,
                        sftp_username: "",
                        sftp_password: "",
                        private_key: "",
                        editMode: false
                    })
                }
            })
        } else if (AddSftp.isLoadAddSftpConfig && AddSftp.statusAddSftpConfig == 204) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(AddSftp.messageAddSftpConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: true
                    })
                }
            })
        }

        if (SearchSftp.isLoadSearchSftpConfig && SearchSftp.statusSearchSftpConfig == 200 && SearchSftp.responseSearchSftpConfig['result_list'] != null) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var dataArr: SftpConfigModels.SftpConfig = SearchSftp.responseSearchSftpConfig['result_list'][0]
            this.setState({
                isShowModal: true,
                editMode: true,
                sftp_server_id: dataArr.sftp_server_id,
                sftp_ip: dataArr.sftp_ip,
                sftp_port: parseInt(dataArr.sftp_port),
                sftp_username: dataArr.sftp_username,
                sftp_password: dataArr.sftp_password,
                private_key: dataArr.private_key,
                privatekey_path: dataArr.privatekey_path,
            })
            this.props.requestSearchSftpConfig(true, "CLEAR")

        } else if (SearchSftp.isLoadSearchSftpConfig && (SearchSftp.statusSearchSftpConfig == 204 || SearchSftp.statusSearchSftpConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(SearchSftp.messageSearchSftpConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false
                    })
                }
            })
        }

        if (EditSftp.isLoadEditSftpConfig && EditSftp.statusEditSftpConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestEditSftpConfig(true, "CLEAR");
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sftp_server_id: "",
                        sftp_ip: "",
                        sftp_port: 0,
                        sftp_username: "",
                        sftp_password: "",
                        private_key: "",
                        privatekey_path: "",
                        editMode: false
                    })
                }
            })
        } else if (EditSftp.isLoadEditSftpConfig && EditSftp.statusEditSftpConfig != 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(EditSftp.messageEditSftpConfig)
            this.props.requestEditSftpConfig(true, "CLEAR");
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sftp_server_id: "",
                        sftp_ip: "",
                        sftp_port: 0,
                        sftp_username: "",
                        sftp_password: "",
                        private_key: "",
                        privatekey_path: "",
                        editMode: false
                    })
                }
            })
        }

        if (isLoadTestSftp && statusTestSftp == 200 && this.state.check_sftp) {
            this.props.UseLoading(false)
            Swal.fire({
                title: 'สำเร็จ',
                text: 'Test sFTP สำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetSftpTest(true, "CLEAR", "");
                    this.Reset()
                    this.setState({
                        //isShowModal: false,
                        check_sftp: false
                    })
                }
            })
        } else if (isLoadTestSftp && statusTestSftp != 200 && this.state.check_sftp) {
            var msg = JSON.parse(messageTestSftp)
            this.props.UseLoading(false)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetSftpTest(true, "CLEAR", "");
                    this.Reset()
                    this.setState({
                        //isShowModal: false,
                        check_sftp: false
                    })
                }
            })
        }
    }

    onchangeSwitch(checked: boolean) {
        if (!checked) {
            clearInterval(this.state.autoRefresh);
        } else {
            this.autoRefresh()
        }
    }

    autoRefresh() {
        const autoRefresh = setInterval(
            () => this.Reset(),
            this.state.AutoRefreshTime
        );
        this.setState({ autoRefresh: autoRefresh });
    }

    onchangeTimeAutoRefresh = (e: any) => {
        clearInterval(this.state.autoRefresh);
        console.log(e.target.value)
        var sec = Number(e.target.value)
        this.setState({
            AutoRefreshTime: sec
        })
        this.autoRefresh()
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    sFTP Server Config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        sFTP Server Config
                                                    </span>
                                                </div>
                                                <div style={{ display: 'inline-block', float: 'right' }}>
                                                    <Button variant="info" size="sm" onClick={this.handleClose}>
                                                        <FaPlusCircle />
                                                        &nbsp;Add sFTP Server Config
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <div style={{ display: 'inline-block', float: 'right', paddingRight: '10px' }}>*/}
                                {/*            <div className="form-inline">*/}
                                {/*                <Form.Label>Auto Refresh : </Form.Label>*/}
                                {/*                <div className="mx-2">*/}
                                {/*                    <BootstrapSwitchButton*/}
                                {/*                        checked={this.state.checkAutoRefresh}*/}
                                {/*                        onlabel='ON'*/}
                                {/*                        offlabel='OFF'*/}
                                {/*                        onChange={(checked: boolean) => {*/}
                                {/*                            this.onchangeSwitch(checked)*/}
                                {/*                        }}*/}
                                {/*                        size="sm"*/}
                                {/*                        onstyle="primary"*/}
                                {/*                        offstyle="dark"*/}
                                {/*                        width={60}*/}
                                {/*                    />*/}
                                {/*                </div>*/}
                                {/*                <Form.Control as="select" size="sm" className="mx-1" onChange={this.onchangeTimeAutoRefresh} value={this.state.AutoRefreshTime}>*/}
                                {/*                    <option value={10000}>10 s.</option>*/}
                                {/*                    <option value={20000}>20 s.</option>*/}
                                {/*                    <option value={30000}>30 s.</option>*/}
                                {/*                    <option value={60000}>60 s.</option>*/}
                                {/*                </Form.Control>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <SftpServerConfigTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} callbackEdit={this.handleEdit.bind(this)} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={this.state.isShowModal} onHide={this.handleClose} dialogClassName="custom-modal" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Add sFTP Server Config</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/*<Col lg={6}>*/}
                            {/*    <Form.Label className="font-weight-bold">SID :</Form.Label>*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl className="require-field" id="sid" aria-describedby="basic-addon3" value={this.state.sid} onChange={(e) => this.handleChangeInput(e, "sid")} />*/}
                            {/*    </InputGroup>*/}
                            {/*</Col>*/}
                            <Col lg={12}>
                                <Form.Label className="font-weight-bold">sFTP Server ID :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="sftp_server_id" aria-describedby="basic-addon3" value={this.state.sftp_server_id} onChange={(e) => this.handleChangeInput(e, "sftp_server_id")} disabled={this.state.editMode} />
                                </InputGroup>
                            </Col>
                            {/*<Col lg={6}>*/}
                            {/*    <Form.Label className="font-weight-bold">Company Code :</Form.Label>*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl className="require-field" id="companycode" aria-describedby="basic-addon3" value={this.state.companycode} onChange={(e) => this.handleChangeInput(e, "companycode")} />*/}
                            {/*    </InputGroup>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col lg={10}>
                                <Form.Label className="font-weight-bold">sFTP IP :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="sftp_ip" aria-describedby="basic-addon3" value={this.state.sftp_ip} onChange={(e) => this.handleChangeInput(e, "sftp_ip")} />
                                </InputGroup>
                            </Col>
                            <Col lg={2}>
                                <Form.Label className="font-weight-bold">sFTP Port :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="sftp_port" aria-describedby="basic-addon3" value={this.state.sftp_port} onChange={(e) => this.handleChangeInput(e, "sftp_port")} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Label className="font-weight-bold">sFTP Username :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="sftp_username" value={this.state.sftp_username} onChange={(e) => this.handleChangeInput(e, "sftp_username")} autoComplete="new-password" />
                                </InputGroup>
                            </Col>
                            <Col lg={6}>
                                <Form.Label className="font-weight-bold">sFTP Password :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="require-field" id="sftp_password" type="password" value={this.state.sftp_password} onChange={(e) => this.handleChangeInput(e, "sftp_password")} autoComplete="new-password" />
                                    {/*<InputGroup.Checkbox aria-label="Show Password" onChange={this.handleShowPass} />*/}
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary" onClick={this.handleShowPass}>{!this.state.show_password ? <FaEye /> : <FaEyeSlash />}</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            {/*<Col>*/}
                            {/*    <Form.Label className="font-weight-bold">Private Key Path :</Form.Label>*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl id="privatekey_path" aria-describedby="basic-addon3" value={this.state.privatekey_path} onChange={(e) => this.handleChangeInput(e, "privatekey_path")} />*/}
                            {/*    </InputGroup>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">Private Key :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="private_key" as="textarea" rows={8} aria-describedby="basic-addon3" value={this.state.private_key} onChange={(e) => this.handleChangeInput(e, "private_key")} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-start">
                                    <Button variant="info" onClick={this.handleTestConnect}>
                                        <FaPlayCircle /> Test Connection
                                    </Button>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={this.handleClose}>
                                        <FaTimesCircle /> Close
                                    </Button>
                                    <Button variant="primary" className="mx-1" onClick={this.handleSave}>
                                        <FaCheckCircle /> Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.SftpConfig, ...state.Loading, ...state.TestSftp }), ({ ...SftpConfigStore.actionCreators, ...LoadingStore.actionCreators, ...SftpTestStore.actionCreators }))(SftpServerConfig)