import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, ButtonGroup, Table, Spinner, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { FaRedoAlt, FaSearch, FaTimesCircle, FaFile, FaEye, FaDownload } from "react-icons/fa";
import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage, splitFilePath } from '../modules/Common'
import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2'
import * as LegacyTaskModels from '../../models/report/LegacyTask'
import { ApplicationState } from '../../store';
import * as LegacyTasktStore from '../../store/report/LegacyTask'

import * as LoadingStore from '../../store/Loading'

type Store = LegacyTaskModels.GetLegacyTaskState & typeof LegacyTasktStore.actionCreators

class LegacyTaskTable extends React.Component<any, {
    response: Array<LegacyTaskModels.LegacyTask>, isready: boolean, userId: any,
    companyCode: any, checkready: boolean, pageBtn: any, pageSize: any,
    checkPageSize: boolean, curPage: number, isShowTempModal: boolean, checkTypeUser: string,
    isSelectVal: string, isshowListFile: boolean, companyCodeSearch: any,
    legacyNameSearch: string, messageText: string, fileListDes: string,
    isShowAlertConfirmModal: boolean, passwordFromApi: string, dataSftpFile: any,
    fileListKey: string, fileListType: string, isShowOption: boolean
}, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            response: [],
            isready: true,
            userId: '',
            companyCode: '',
            checkready: true,
            pageSize: 25,
            pageBtn: [''],
            checkPageSize: true,
            curPage: 1,
            isShowTempModal: false,
            isSelectVal: "",
            messageText: "",
            isshowListFile: false,
            companyCodeSearch: [],
            legacyNameSearch: '',
            isShowAlertConfirmModal: false,
            passwordFromApi: '',
            checkTypeUser: '',
            isShowOption: false,
            fileListKey: '',
            fileListType: '',
            fileListDes: '',
            dataSftpFile: {
                legacy_id: '',
                company_code: '',
                type_id: '',
                job_instance_no: ''
            }
        }

        this.handleChangePage = this.handleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.runStepHandleClick = this.runStepHandleClick.bind(this);
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("LG_APPLICATION");
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        var jwt = parseJwt(accessToken);
        var extra = JSON.parse(jwt.extra);
        var userIdUse = ""

        if (extra.username) {
            userIdUse = extra.username
        }
        else if (extra.citizen_id) {
            userIdUse = extra.citizen_id
        }

        fetch("/v1/user?id=" + userIdUse,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        if (result.result_list[0].list_company[0].company_code == "*") {
                            console.log('case company_code == "*"')
                            this.getLegacyTask([])
                        } else {
                            console.log('case company_code != "*"')
                            console.log(result.result_list[0].list_company)
                            this.getLegacyTask(result.result_list[0].list_company)
                        }
                    } else {
                        this.props.requestGetLegacyTask(true, "NOUSER", "")
                    }

                    //var data = result.result_list
                    //if (data != null && data.length > 0) {
                    //    if (result.result_list[0].list_company[0].company_code == "*") {
                    //        this.getLegacyTask([])
                    //    } else {
                    //        this.getLegacyTask(result.result_list[0].list_company)
                    //    }
                    //} else {
                    //    this.props.requestGetLegacyTask(true, "NOUSER", "")
                    //}
                },
                (error) => {
                }
            )

        fetch("/v1/user?id=" + userIdUse + '&role_access=admin',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        this.setState({
                            checkTypeUser: data[0].list_company[0].role_access
                        });
                    }
                },
                (error) => {
                }
            )
    }

    private getLegacyTask(companyCodeUse) {
        const { isready, userId, companyCode, pageSize } = this.state
        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to } = this.props

        var dataset: any = []

        companyCodeUse.map((item) => {
            dataset.push(item.company_code)
        })

        this.setState({
            companyCode: dataset
        })
        if (isready) {
            this.props.requestGetLegacyTask(isready, "POST", "", dataset, 1, pageSize, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_from, job_started_on_to, this.state.legacyNameSearch)
            this.setState({
                isready: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { countTab, checkkey, LegacyTaskGetFile, GetTask } = this.props
        if (nextProps.countTab != countTab) {
            console.log(countTab)
        }

        if (checkkey == "search" && checkkey != undefined && !this.state.isready) {
            console.log(checkkey)
            var list_company_code: any = []
            if (this.props.companyCodeSearch != "") {
                list_company_code.push(this.props.companyCodeSearch)
            } else {

            }
            this.setState({
                isready: true,
                companyCodeSearch: list_company_code,
                legacyNameSearch: this.props.legacyNameSearch
            })
            setTimeout(
                function (this: any, err: any) {
                    this.handleSearch()
                }
                    .bind(this),
                1000
            );
        }

        if (GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetTask.responseLegacyTask['total_count'] / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false,
                isready: false
            })
        }

        if (LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && !this.state.isshowListFile) {
            this.props.UseLoading(false)
            this.setState({
                isshowListFile: true
            })
        } else if (LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList != 200) {
            var msg = JSON.parse(LegacyTaskGetFile.messageGetFileList)
            this.props.requestGetFileList(true, "CLEAR", "", "")
            this.props.UseLoading(false)
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        isshowListFile: false
                    })
                }
            })
        }
    }

    handleChangePage = (event: any, size: any) => {
        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, companyCodeSearch, legacyNameSearch } = this.props
        console.log(size);
        this.setState({
            isready: true,
            checkPageSize: true,
            curPage: size
        })
        this.props.requestGetLegacyTask(true, "POST", "", (this.state.companyCodeSearch.length == 0 ? this.state.companyCode : this.state.companyCodeSearch), size, this.state.pageSize, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, legacyNameSearch)
    }

    handlePageSize(e: any) {
        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to } = this.props
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse,
            isready: true,
            checkPageSize: true
        });
        this.props.requestGetLegacyTask(true, "POST", "", (this.state.companyCodeSearch.length == 0 ? this.state.companyCode : this.state.companyCodeSearch), 1, parse, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, this.state.legacyNameSearch)
    }

    beforehandleChangePage(event: any, value: any) {
        console.log(value);
        //this.haddleChangePage(value)
    }

    public handleSearch() {
        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to } = this.props
        console.log(status)
        this.props.requestGetLegacyTask(true, "POST", "", (this.state.companyCodeSearch.length == 0 ? this.state.companyCode : this.state.companyCodeSearch), 1, this.state.pageSize, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, this.state.legacyNameSearch)
        this.props.callbackkey()
        this.setState({
            checkPageSize: true,
            curPage: 1
        })
    }

    handleCloseAlertConfirmModal = () => {
        if (this.state.isShowAlertConfirmModal == false) {
            this.setState({
                isShowAlertConfirmModal: true
            })

        } else {
            this.setState({
                isShowAlertConfirmModal: false
            })
        }
    }

    checkPassword() {
        let password: string = (document.getElementById("passwordconfirm") as HTMLInputElement).value;
        if (this.state.passwordFromApi == password) {
            Swal.fire({
                title: 'สำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                    //this.props.callbackStartAutoRefresh()
                    this.setState({
                        isShowAlertConfirmModal: false
                    })
                    return;
                }
            })
        }
        else {
            Swal.fire({
                title: 'พาสเวิร์ดไม่ถูกต้อง',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                return;
            })
        }
    }

    runStepHandleClick(sid: string, companycode: string, legacyid: string, job_instance_no: string, legacytype: string, step: string, important_job: string) {
        Swal.fire({
            title: 'ต้องการรีรันหรือไม่',
            text: 'ยืนยันคำสั่งรีรันหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            //console.log(result.isConfirmed);
            if (result.isConfirmed) {
                if (important_job && important_job.toLowerCase() == 'true') {
                    let job_password = '1q2w3e4r';
                    Swal.fire({
                        title: 'กรุณาระบุรหัสผ่าน',
                        input: 'password',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        showLoaderOnConfirm: true,
                        preConfirm: (password) => {
                            if (password == null || password == "") {
                                Swal.showValidationMessage(
                                    'กรุณากรอกรหัสผ่าน'
                                )
                            }
                            else if (password == job_password) {
                                this.runStepHandle(sid, companycode, legacyid, job_instance_no, legacytype, step)
                            }
                            else {
                                Swal.showValidationMessage(
                                    'รหัสผ่านไม่ถูกต้อง'
                                )
                            }
                        }
                    })
                }
                else {
                    this.runStepHandle(sid, companycode, legacyid, job_instance_no, legacytype, step)
                }
            }
        })
    }

    runStepHandle(sid: string, companycode: string, legacyid: string, job_instance_no: string, legacytype: string, step: string) {
        //this.setState({
        //    isready: true,
        //    formData: []
        //})
        //this.props.requestGetLegacyReport(true, "CLEAR", "", "")
        //this.props.UseLoading(true)
        //this.props.callbackStopAutoRefresh()
        //Swal.fire({
        //    title: 'ต้องการรีรันหรือไม่',
        //    text: 'คุณต้องการรีรัน Legacy ID นี้หรือไม่ ?',
        //    icon: 'warning',
        //    showCancelButton: true,
        //    confirmButtonColor: '#3085d6',
        //    confirmButtonText: 'ตกลง',
        //    cancelButtonText: 'ไม่ตกลง',
        //    cancelButtonColor: '#FF0000',
        //    allowOutsideClick: false
        //}).then((result) => {
        this.props.UseLoading(true)
        //if (result.value) {
        fetch(`/v1/lis-server/run-step?sid=` + sid + '&company_code=' + companycode + '&legacy_type=' + legacytype + '&legacy_id=' + legacyid + '&job_instance_no=' + job_instance_no + '&step=' + step, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            },
        })
            .then((response) => {
                const { isready, userId, companyCode, pageSize } = this.state
                const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to } = this.props

                this.props.requestGetLegacyTask(true, "POST", "", this.state.companyCode, 1, pageSize, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_from, job_started_on_to, this.state.legacyNameSearch)
                this.props.UseLoading(false)
                console.log(response)
                return response
            })
            .then((data) => {
                console.log(data)
                if (!data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                            //this.props.callbackStartAutoRefresh()
                            return;
                        }
                    })
                }
            })
            .catch(error => {
                console.log('errorrrr')
                this.setState({
                    isready: false
                })
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {

                    }
                })
            });
        //}
        //})
    }

    handleClickTemModal = (id, message: string) => {
        //console.log('id')
        //console.log(id)
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true,
                isSelectVal: id,
                messageText: message
            })

        }
    }

    handleCloseTemModal = () => {
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true
            })

        } else {
            this.setState({
                isShowTempModal: false,
                isSelectVal: "",
                messageText: ""
            })
        }
    }

    handleShowListFile = () => {
        if (this.state.isshowListFile == true) {
            this.setState({
                isshowListFile: false,
                dataSftpFile: {
                    legacy_id: "",
                    company_code: "",
                    type_id: "",
                    job_instance_no: ""
                },
                fileListDes: ""
            })
            this.props.requestGetFileList(true, "CLEAR", "", "", "")
        } else {
            this.setState({
                isshowListFile: true
            })
        }
    }

    handleOpenPath = (id: string, company_code: string, key: string, job_instance_no: string) => {
        //if (this.state.isshowListFile == true) {
        //    this.setState({
        //        isshowListFile: false,
        //    })
        //    /*            this.props.requestViewFile(true, "CLEAR")*/
        //} else {
        //    this.setState({
        //        isshowListFile: true
        //    })
        //}

        console.log(id)
        var showOption: any = true;
        var keys: string = "";

        //check user
        if (this.state.checkTypeUser == undefined || this.state.checkTypeUser == "") {
            showOption = false
        } else {
            showOption = true
        }

        if (key == "sftp_source_server") {
            keys = 'sFTP source server File'
        } else if (key == "sftp_return_server") {
            keys = 'sFTP return Server File'
        } else if (key == "step4_error") {
            keys = 'Step 4 Error Source File'
        } else if (key == "sftp_source_backup_main_page") {
            keys = 'sFTP Source Backup'
        }

        var dataReq: any = this.state.dataSftpFile
        dataReq['legacy_id'] = id;
        dataReq['company_code'] = company_code;
        dataReq['type_id'] = key;
        dataReq['job_instance_no'] = job_instance_no;

        this.setState({
            fileListKey: key,
            fileListType: keys,
            dataSftpFile: dataReq,
            isShowOption: showOption,
            fileListDes: id
        })
        this.props.requestGetFileList(true, "POST", id, key, company_code, job_instance_no)
        this.props.UseLoading(true)
    }

    handleDownloadFile = (path: string, filename: string) => {
        console.log("Download File : " + filename + " Path : " + path)
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile['legacy_id'],
            "company_code": this.state.dataSftpFile['company_code'],
            "sid": this.state.userId,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', filename);
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    handleDownloadAll = () => {
        if (this.state.fileListType == "Step 4 Error Source File") {
            this.props.UseLoading(true)
            var body = {
                "legacy_id": this.state.dataSftpFile.legacy_id,
                "company_code": this.state.dataSftpFile.company_code,
                "job_instance_no": this.state.dataSftpFile.job_instance_no
            }
            fetch(`/v1/lis-server/get-source-files-when-step4-error`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    this.props.UseLoading(false)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            Swal.fire({
                                title: 'ไม่สำเร็จ',
                                text: err.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {

                                }
                            })
                        })
                    } else {
                        console.log(data)
                        data.blob().then(result => {
                            console.log(result)
                            const downloadUrl = window.URL.createObjectURL(result);
                            const link = document.createElement('a');
                            link.setAttribute('href', downloadUrl);
                            link.setAttribute('download', this.state.dataSftpFile.job_instance_no + `_error.zip`);
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(link.href);
                            document.body.removeChild(link);
                        })
                    }
                })
        }
    }

    handleDownloadAllV2 = (path: any) => {
        this.props.UseLoading(true)
        var body = {
            "legacy_id": this.state.dataSftpFile.legacy_id,
            "company_code": this.state.dataSftpFile.company_code,
            "sid": this.state.userId,
            "path": path
        }
        fetch(`/v1/lis-server/get-backup-all-file-with-legacyid`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', this.state.dataSftpFile.job_instance_no + '_' + this.state.fileListKey + '.zip');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    render() {
        const { GetTask, LegacyTaskGetFile } = this.props
        console.log(this.state.companyCode)
        return (
            <React.Fragment>
                <Row className='mt-3'>
                    <Col md={6}>
                        <Container>
                            <Form className="d-flex justify-content-end justify-content-md-start align-items-center text-nowrap" style={{ gap: '0 12px' }}>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    style={{ width: 'fit-content', margin: 0 }}
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </Container>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} onChange={this.handleChangePage} page={this.state.curPage} shape="rounded" defaultPage={1} showFirstButton showLastButton />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Table className="table table-bordered table-hover custom-table-list table-responsive" size="sm">
                            <thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th style={{ width: '2%' }}>Monitor</th>
                                    <th style={{ width: '2%' }}>รายงาน</th>
                                    <th style={{ width: '3%' }}>Company Code</th>
                                    <th style={{ width: '5%' }}>Legacy ID </th>
                                    <th style={{ width: '5%' }}>Legacy Name</th>
                                    <th style={{ width: '8%' }}>Job Instanceno</th>
                                    <th style={{ width: '6%' }}>Status (lastest)</th>
                                    <th style={{ width: '8%' }}>Job Start</th>
                                    <th style={{ width: '8%' }}>Job Finished On</th>
                                    <th style={{ width: '6%' }}>Load Files Status</th>
                                    <th style={{ width: '8%' }}>Load Files started on</th>
                                    <th style={{ width: '5%' }}>Conversion Status</th>
                                    <th style={{ width: '6%' }}>Call Function Status</th>
                                    <th style={{ width: '8%' }}>Call Function started on</th>
                                    <th style={{ width: '6%' }}>Return Files Status</th>
                                    <th style={{ width: '8%' }}>Return Files started on</th>
                                    <th style={{ width: '6%' }}>Function status</th>
                                    <th style={{ width: '15%' }}>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!GetTask.isLoadLegacyTask ? <tr><td colSpan={17} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {GetTask.isLoadLegacyTask && (GetTask.statusLegacyTask != 200 || GetTask.responseLegacyTask['result_list'] == null) ? <tr><td colSpan={17} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && GetTask.responseLegacyTask['result_list'] != null && GetTask.responseLegacyTask['result_list'].map((item: LegacyTaskModels.LegacyTask, idx: any) => {
                                    var srcMonitor = "/legacydetail?company_code=" + item.companycode + "&legacy_id=" + item.legacyid + "&job_no=" + item.job_instanceno
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div className="text-center">
                                                    <Link to={srcMonitor} target="_blank" className="btn btn-primary btn-sm"><FaEye /></Link>
                                                </div>
                                            </td>
                                            <td className="text-center">{idx + 1}</td>
                                            <td className="text-center">{item.companycode}</td>
                                            <td>{item.legacyid}</td>
                                            <td>{item.legacyname}</td>
                                            <td>{item.job_instanceno}</td>
                                            <td className={taskStatusColour(String(item.status)) + ' text-center'}>{item.status}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.job_started_on))}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.job_finished_on))}</td>
                                            {/*<td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status == 'ERROR' ? <span className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white"/></span> : ''}{item.step1_status}</td>*/}
                                            <td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.step1_started_on))}</td>
                                            <td className={taskStatusColour(String(item.step5_status)) + ' text-center'}>{item.step5_status == 'ERROR' ? <div><Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacytype, item.legacyid, item.job_instanceno, '5', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button></div> : ''}{item.step5_status}</td>
                                            <td className={taskStatusColour(String(item.step2_status)) + ' text-center'}>{item.step2_status == 'ERROR' ? <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '2', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button> : ''}{item.step2_status}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.step2_started_on))}</td>
                                            <td className={taskStatusColour(String(item.step3_status)) + ' text-center'}>{item.step3_status == 'ERROR' ? <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '3', item.important_job)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white" /></Button> : ''}{item.step3_status}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.step3_started_on))}</td>
                                            <td className={taskStatusColour(String(item.step4_status)) + ' text-center'}>
                                                <div className="d-inline-block">
                                                    {item.step4_status == 'ERROR' ?
                                                        <div>
                                                            <Button variant="primary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.runStepHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno, item.legacytype, '4', item.important_job)}><FaRedoAlt color="white" style={{ cursor: 'pointer' }} /></Button>
                                                            <Button variant="success" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="View Error File" onClick={(e) => this.handleOpenPath(item.legacyid, item.companycode, 'sftp_source_backup', item.job_instanceno)}><FaFile color="white" style={{ cursor: 'pointer' }} /></Button>
                                                        </div>
                                                        : ''}
                                                    {item.step4_status}
                                                </div>
                                            </td>
                                            <td data-toggle="tooltip" data-placement="bottom" title={item.message}>
                                                {item.message != "" && item.message != null &&
                                                    <div className="text-center">
                                                        <Button variant="info" className="btn-sm text-center" onClick={() => this.handleClickTemModal(item.job_instanceno, item.message)}><FaSearch /></Button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Modal show={this.state.isShowTempModal} onHide={this.handleCloseTemModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>
                            {this.state.isSelectVal}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table-bordered table-hover table-responsive" size="sm" style={{ width: '100%', overflow: 'inherit' }}>
                            {/*<thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th style={{ width: '20%' }}>Name</th>
                                    <th style={{ width: '700px' }}>Data</th>
                                </tr>
                            </thead>*/}
                            <tbody>
                                {this.state.messageText != "" &&
                                    <>
                                        <tr>
                                            <td style={{ width: '800px' }}>{this.state.messageText}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*sFTP List File*/}
                <Modal show={this.state.isshowListFile} onHide={() => this.handleShowListFile()} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>{this.state.fileListType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {/*<div className="blockquote-footer">{this.state.fileListDes}{LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && LegacyTaskGetFile.responseGetFileList.total_count != 0 && LegacyTaskGetFile.responseGetFileList.result_list[0].file_path}</div>*/}
                                <div className="bg-light text-dark">
                                    <table>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>Legacy ID :</td>
                                            <td style={{ width: '80%' }}>{this.state.fileListDes}</td>
                                        </tr>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '20%' }}>File Path :</td>
                                            <td style={{ width: '80%' }}>{LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && LegacyTaskGetFile.responseGetFileList.total_count != 0 && splitFilePath(LegacyTaskGetFile.responseGetFileList.result_list[0].file_path)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "55%" }}>File Name</th>
                                            <th style={{ width: "20%" }}>Date Modified</th>
                                            <th style={{ width: "15%" }}>File Size</th>
                                            {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                <th style={{ width: "10%" }}>Download</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!LegacyTaskGetFile.isLoadGetFileList && this.state.isshowListFile && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr>}
                                        {LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && LegacyTaskGetFile.responseGetFileList.total_count == 0 && <tr style={{ width: '100%' }}><td colSpan={4} style={{ background: "rgba(0, 0, 0, 0.075)", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No such file...</td></tr>}
                                        {LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && LegacyTaskGetFile.responseGetFileList.total_count != 0 && LegacyTaskGetFile.responseGetFileList.result_list.map((item: LegacyTaskModels.LegacyTaskGetFile, idx: any) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.name}</td>
                                                    <td>{formatYMDToDMY(item.last_modify + 'z')}</td>
                                                    <td>{item.size + ' B.'}</td>
                                                    {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') &&
                                                        <td>
                                                            <ButtonGroup>
                                                                <Button variant="info" size="sm" onClick={() => this.handleDownloadFile(item.file_path, item.name)}><FaDownload /></Button>
                                                                {/*<Button variant="danger" size="sm" onClick={() => this.handleDeleteSftpFile(this.state.dataSftpFile['legacy_id'], this.state.dataSftpFile['company_code'], item)}><FaTimesCircle /></Button>*/}
                                                            </ButtonGroup>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Control type="file" ref={this.uploadFileRef} onChange={(e) => this.handleAddSftpFile(e)} hidden />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.checkTypeUser == 'admin' || this.state.checkTypeUser == 'super_admin') && this.state.fileListKey != "api_request_backup" && this.state.fileListKey != "api_response_backup" &&
                            <Button variant="primary" style={{ float: 'left' }} onClick={() => this.handleDownloadAllV2(LegacyTaskGetFile.isLoadGetFileList && LegacyTaskGetFile.statusGetFileList == 200 && LegacyTaskGetFile.responseGetFileList.total_count != 0 && splitFilePath(LegacyTaskGetFile.responseGetFileList.result_list[0].file_path))}>
                                <FaDownload /> Download All
                            </Button>
                        }
                        <Button variant="danger" onClick={() => this.handleShowListFile()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isShowAlertConfirmModal} onHide={this.handleCloseAlertConfirmModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">Password</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="passwordconfirm" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.checkPassword()}>
                            <FaTimesCircle /> ยืนยัน
                        </Button>
                        <Button variant="danger" onClick={this.handleCloseAlertConfirmModal}>
                            <FaTimesCircle /> ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.legacyTask, ...state.Loading }), ({ ...LegacyTasktStore.actionCreators, ...LoadingStore.actionCreators }))(LegacyTaskTable)