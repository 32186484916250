import * as React from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import Select from 'react-select';

export interface optionValue {
    value: string
    label: string
}

export interface searchValue {
    wfNo: string,
    docAmount: string,
    docAmountTo: string,
    requestor: string,
    createBy: string,
    createByTo: string,
}

const customStyles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none', zIndex: 'auto' } : base;
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}

class EmployeeMasterSearchComponent extends React.Component<any, { valueSelectRole: string }> {

    constructor(props: any) {
        super(props)
        this.state = {
            valueSelectRole: ""
        }
    }

    handleValueSelectAdd = (newValue: any, actionMeta: any) => {
        this.props.handleSelectSearch(newValue)
    };

    handleValueroleAccessAdd = (newValue: any, actionMeta: any) => {
        this.setState({ valueSelectRole: newValue.value })
    };

    handleSearchValue = () => {
        var userName = document.getElementById('userName') as HTMLInputElement;
        var roleAccess = document.getElementById('roleAccess') as HTMLInputElement;

        this.props.handleSearchValue(userName.value, this.state.valueSelectRole)
    };

    render() {
        return (
            <Accordion style={{ width: '100%' }} className='h-50 d-inline-block'>
                <Card>
                    <Card.Header className='h-50 d-inline-block' style={{ marginBottom: '-12px', marginTop: '-12px' }}>
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Accordion.Toggle as={Button} variant="light" eventKey="0">
                                        ค้นหาข้อมูล
                                    </Accordion.Toggle>
                                </div>
                            </Col>
                            <Col>
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Accordion.Toggle as={Button} variant="light" eventKey="0">
                                        <FaAngleDown />
                                    </Accordion.Toggle>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ overflow: 'none' }}>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="userName">
                                        <Form.Label>User Name :</Form.Label>
                                        <Form.Control type="text" placeholder="Enter User Name" />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formSelect">
                                        <Form.Label>บริษัท :</Form.Label>
                                        <div >
                                            <Select
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                className="dropdown metadata-group-font"
                                                placeholder="เลือก บริษัท"
                                                options={this.props.valueSelect}
                                                onChange={this.handleValueSelectAdd}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="roleAccess">
                                        <Form.Label>Role Access :</Form.Label>
                                        <div >
                                            <Select
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                className="dropdown metadata-group-font"
                                                placeholder="เลือก Role Access"
                                                options={this.props.valueSelectRole}
                                                onChange={this.handleValueroleAccessAdd}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button style={{ marginBottom: '10px', marginRight: '10px', float: 'right' }} variant="outline-success" size="sm" onClick={this.handleSearchValue} >
                                        <FaSearch />
                                        &nbsp;ค้นหา
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }

}

export default EmployeeMasterSearchComponent