import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/DmsConfig'

// Load sftp
interface LoadDmsConfig {
    type: 'LOAD_DMSCONFIG'
}
interface LoadedDmsConfig {
    type: 'LOADEDDMSCONFIG'
    response: Models.ResponseDmsConfig
    status: number,
    statusMessage: string
}
interface FailLoadedDmsConfig {
    type: 'LOADDMSCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedDmsConfig {
    type: 'CLEARLOADEDDMSCONFIG'
}

// Add sftp
interface AddDmsConfig {
    type: 'ADD_DMSCONFIG'
}
interface AddedDmsConfig {
    type: 'ADDEDDMSCONFIG'
    response: Models.ResponseDmsConfig
    status: number,
    statusMessage: string
}
interface FailAddedDmsConfig {
    type: 'ADDDMSCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedDmsConfig {
    type: 'CLEARADDDMSCONFIG'
}

// Delete sftp
interface DeleteDmsConfig {
    type: 'DELETE_DMSCONFIG'
}
interface DeletedDmsConfig {
    type: 'DELETEDDMSCONFIG'
    status: number,
    statusMessage: string
}
interface FailDeletedDmsConfig {
    type: 'DELETEDMSCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeletedDmsConfig {
    type: 'CLEARDELETEDMSCONFIG'
}

//Search sftp
interface SearchDmsConfig {
    type: 'SEARCH_DMSCONFIG'
}
interface SearchedDmsConfig {
    type: 'SEARCHEDDMSCONFIG'
    response: Models.ResponseDmsConfig
    status: number,
    statusMessage: string
}
interface FailSearchedDmsConfig {
    type: 'SEARCHDMSCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchedDmsConfig {
    type: 'CLEARSEARCHDMSCONFIG'
}

// Edit
interface EditDmsConfig {
    type: 'EDIT_DMSCONFIG'
}
interface EditedDmsConfig {
    type: 'EDITEDDMSCONFIG'
    response: Models.ResponseDmsConfig
    status: number,
    statusMessage: string
}
interface FailEditedDmsConfig {
    type: 'EDITDMSCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditedDmsConfig {
    type: 'CLEAREDITDMSCONFIG'
}

type KnownAction = LoadedDmsConfig | FailLoadedDmsConfig | LoadDmsConfig | AddDmsConfig | AddedDmsConfig | FailAddedDmsConfig | ClearAddedDmsConfig | ClearLoadedDmsConfig | DeleteDmsConfig | DeletedDmsConfig | FailDeletedDmsConfig | ClearDeletedDmsConfig | SearchDmsConfig | SearchedDmsConfig | FailSearchedDmsConfig | ClearSearchedDmsConfig | EditDmsConfig | EditedDmsConfig | FailEditedDmsConfig | ClearEditedDmsConfig

export const actionCreators = {
    // GET
    requestGetDmsConfig: (check: boolean, method: string, sid: string,  page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'GET') {
            console.log('call actionCreators : requestGetDmsConfig')
            fetch(`/v1/config/dms?sid=` + sid  + '&page=' + page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADDMSCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADDMSCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_DMSCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDDMSCONFIG' });
        }
    },

    // ADD
    requestAddDmsConfig: (check: boolean, method: string, Formbody: Models.DmsConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'POST') {
            console.log('call actionCreators : requestAddDmsConfig')
            fetch(`/v1/config/dms/add`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDDMSCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'ADDEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'ADDEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'ADDDMSCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'ADD_DMSCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDDMSCONFIG' });
        }
    },

    // Delete
    requestDeleteDmsConfig: (check: boolean, method: string, sid: string, dms_serverid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteDmsConfig')
            fetch(`/v1/config/dms/delete?sid=` + sid + '&dms_serverid=' + dms_serverid, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETEDMSCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'DELETEDDMSCONFIG', status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'DELETEDDMSCONFIG', status: 200, statusMessage: '' });
            //dispatch({ type: 'DELETEDMSCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'DELETE_DMSCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEDMSCONFIG' });
        }
    },

    // Search
    requestSearchDmsConfig: (check: boolean, method: string, sid: string, dms_serverid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'GET') {
            console.log('call actionCreators : requestGetDmsConfig')
            fetch(`/v1/config/dms?sid=` + sid + '&dms_serverid=' + dms_serverid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'SEARCHDMSCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'SEARCHEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'SEARCHEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'SEARCHDMSCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'SEARCH_DMSCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHDMSCONFIG' });
        }
    },

    // EDIT
    requestEditDmsConfig: (check: boolean, method: string, Formbody: Models.DmsConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        var dataArr = JSON.stringify(Formbody)
        const appState = getState();
        if (appState && appState.DmsConfig && check && method == 'PUT') {
            console.log('call actionCreators : requestEditDmsConfig')
            fetch(`/v1/config/dms/edit`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: dataArr
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'EDITDMSCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'EDITEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'EDITEDDMSCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'EDITDMSCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'EDIT_DMSCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITDMSCONFIG' });
        }
    },
}

const unloadedStateGet: Models.GetDmsConfigState = { isLoadDmsConfig: false };
const unloadedStateAdd: Models.AddDmsConfigState = { isLoadAddDmsConfig: false };
const unloadedStateDelete: Models.DeleteDmsConfigState = { isLoadDeleteDmsConfig: false };
const unloadedStateSearch: Models.SearchDmsConfigState = { isLoadSearchDmsConfig: false };
const unloadedStateEdit: Models.EditDmsConfigState = { isLoadEditDmsConfig: false };

const reducerGet: Reducer<Models.GetDmsConfigState> = (state: Models.GetDmsConfigState | undefined, incomingAction: Action): Models.GetDmsConfigState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_DMSCONFIG':
            return {
                isLoadDmsConfig: false,
            }

        case 'LOADEDDMSCONFIG':
            return {
                isLoadDmsConfig: true,
                responseDmsConfig: action.response as any,
                statusDmsConfig: action.status
            }

        case 'LOADDMSCONFIGFAIL':
            return {
                isLoadDmsConfig: true,
                messageDmsConfig: action.statusMessage,
                statusDmsConfig: action.status
            }

        case 'CLEARLOADEDDMSCONFIG':
            return {
                isLoadDmsConfig: false,
                messageDmsConfig: "",
                statusDmsConfig: 400
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddDmsConfigState> = (state: Models.AddDmsConfigState | undefined, incomingAction: Action): Models.AddDmsConfigState => {
    if (state == undefined) {
        return unloadedStateAdd;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'ADD_DMSCONFIG':
            return {
                isLoadAddDmsConfig: false,
            }

        case 'ADDEDDMSCONFIG':
            return {
                isLoadAddDmsConfig: true,
                responseAddDmsConfig: action.response as any,
                statusAddDmsConfig: action.status
            }

        case 'ADDDMSCONFIGFAIL':
            return {
                isLoadAddDmsConfig: true,
                messageAddDmsConfig: action.statusMessage,
                statusAddDmsConfig: action.status
            }

        case 'CLEARADDDMSCONFIG':
            return {
                isLoadAddDmsConfig: false,
                messageAddDmsConfig: '',
                statusAddDmsConfig: 400
            }
        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteDmsConfigState> = (state: Models.DeleteDmsConfigState | undefined, incomingAction: Action): Models.DeleteDmsConfigState => {
    if (state == undefined) {
        return unloadedStateDelete;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'DELETE_DMSCONFIG':
            return {
                isLoadDeleteDmsConfig: false,
            }

        case 'DELETEDDMSCONFIG':
            return {
                isLoadDeleteDmsConfig: true,
                statusDeleteDmsConfig: action.status
            }

        case 'DELETEDMSCONFIGFAIL':
            return {
                isLoadDeleteDmsConfig: true,
                messageDeleteDmsConfig: action.statusMessage,
                statusDeleteDmsConfig: action.status
            }

        case 'CLEARDELETEDMSCONFIG':
            return {
                isLoadDeleteDmsConfig: false,
                messageDeleteDmsConfig: '',
                statusDeleteDmsConfig: 400
            }
        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchDmsConfigState> = (state: Models.SearchDmsConfigState | undefined, incomingAction: Action): Models.SearchDmsConfigState => {
    if (state == undefined) {
        return unloadedStateSearch;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'SEARCH_DMSCONFIG':
            return {
                isLoadSearchDmsConfig: false,
            }

        case 'SEARCHEDDMSCONFIG':
            return {
                isLoadSearchDmsConfig: true,
                responseSearchDmsConfig: action.response as any,
                statusSearchDmsConfig: action.status
            }

        case 'SEARCHDMSCONFIGFAIL':
            return {
                isLoadSearchDmsConfig: true,
                messageSearchDmsConfig: action.statusMessage,
                statusSearchDmsConfig: action.status
            }

        case 'CLEARSEARCHDMSCONFIG':
            return {
                isLoadSearchDmsConfig: false,
                messageSearchDmsConfig: "",
                statusSearchDmsConfig: 400
            }
        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditDmsConfigState> = (state: Models.EditDmsConfigState | undefined, incomingAction: Action): Models.EditDmsConfigState => {
    if (state == undefined) {
        return unloadedStateEdit;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'EDIT_DMSCONFIG':
            return {
                isLoadEditDmsConfig: false,
            }

        case 'EDITEDDMSCONFIG':
            return {
                isLoadEditDmsConfig: true,
                responseEditDmsConfig: action.response as any,
                statusEditDmsConfig: action.status
            }

        case 'EDITDMSCONFIGFAIL':
            return {
                isLoadEditDmsConfig: true,
                messageEditDmsConfig: action.statusMessage,
                statusEditDmsConfig: action.status
            }

        case 'CLEAREDITDMSCONFIG':
            return {
                isLoadEditDmsConfig: false,
                messageEditDmsConfig: '',
                statusEditDmsConfig: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetDms: reducerGet, AddDms: reducerAdd, DeleteDms: reducerDelete, SearchDms: reducerSearch, EditDms: reducerEdit });