import * as React from 'react';
import { Link, Redirect, useHistory  } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl } from 'react-bootstrap';
import { connect } from "react-redux";
import { ApplicationState } from '../store';
import Swal from 'sweetalert2'

import * as envModel from '../models/common/Env'
import * as envStore from '../store/common/Env'

import * as LoadingStore from '../store/Loading'
import * as LoadingModels from '../models/config/Loading'

type store = envModel.EnvState & typeof envStore.actionCreators;

class Login extends React.Component<any, { Username: string, Password: string, checkLogin: boolean }, store>{
    constructor(props: any) {
        super(props);
        this.state = {
            Username: "",
            Password: "",
            checkLogin : false
        }
        this.handleLogin = this.handleLogin.bind(this)
    }

    componentDidMount() {
        if (localStorage.getItem("LG_APPLICATION") != undefined && !this.state.checkLogin) {
            this.setState({
                checkLogin: true
            })
        }

        this.props.requestFunctionEnv(true , 'GET')
    }

    handleLogin() {
        const { Username, Password } = this.state
        if (Username != "" && Password != "") {
            if (Username == "sonarinno" && Password == "SNI1q2w3e4r") {
                console.log("success")
                localStorage.setItem('LG_APPLICATION', '{"user_id":"555","access_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFwbXVveDhJTjdoZEcwV3pJUFZxTXdiMnhUZSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXhwIjoxOTMxMzcwMDI0LCJpYXQiOjE2MTU4MDA3NjQsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.l2T9zbgSVrmJ9v89KNBhLAcSsKjrWzfyOgyAEtDwKrwytyU8F6acVz72ocZyeEoo0JJ8zyWKtNYVBNLZtVXYlgwfXb7nXlMcnK8fmPzykH4nYBdoSvoEvbR4I5RilYgLBTaWkT3P2rqu2G1_LWFfAfEAEFoDvj8Y5dhS4LUfKkM","company_code" : "2580"}')
                this.setState({
                    checkLogin : true
                })
            } else {
                Swal.fire({
                    title: 'ไม่สำเร็จ',
                    text: 'ตรวจสอบ Username , Password ของท่าน',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ใช่',
                }).then((result) => {
                    if (result.value) {
                        localStorage.removeItem("LG_APPLICATION");
                    }
                })
            }
        } else {
            Swal.fire({
                title: 'ตรวจสอบข้อมูล',
                text: 'โปรดกรอก Username และ Password ของท่าน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    handlechageInput = (e: any, key: any) => {
        if (key == "Username") {
            this.setState({
                Username: e.target.value
            })
        } else {
            this.setState({
                Password: e.target.value
            })
        }
    }

    handleShowPass(e : any) {
        var x : any = document.getElementById("passwordInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    render() {
        if (this.state.checkLogin) {
            return <Redirect to="/" />
        }
        return (
            <Container>
                <Row>
                    <Col>
                        {/*<div style={{ marginTop: '300px' }}>*/}
                        {/*    <div className="card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>*/}
                        {/*        <div className="card-body">*/}
                        {/*            <Form.Text className="text-light" style={{ fontSize: '18px' }}>*/}
                        {/*                Sign In*/}
                        {/*            </Form.Text>*/}
                        {/*            <Form.Group className="mt-3">*/}
                        {/*                <Form.Control type="text" placeholder="Username" onChange={(e) => this.handlechageInput(e, "Username")} />*/}
                        {/*            </Form.Group>*/}
                        {/*            <Form.Group>*/}
                        {/*                <Form.Control type="password" placeholder="Password" id="passwordInput" onChange={(e) => this.handlechageInput(e, "Password")} />*/}
                        {/*            </Form.Group>*/}
                        {/*            <Form.Group>*/}
                        {/*                <Form.Check type="checkbox" label="Show password" className="text-light" onChange={(e) => this.handleShowPass(e)}/>*/}
                        {/*            </Form.Group>*/}
                        {/*            <hr/>*/}
                        {/*            <Button variant="primary" type="button" onClick={this.handleLogin}>*/}
                        {/*                เข้าสู่ระบบ*/}
                        {/*            </Button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {this.props.isLoadingEnv == true &&
                            <iframe style={{ WebkitWritingMode: "vertical-lr", overflow: "none" }} src={this.props.responseEnv.ssO_WEB + btoa(this.props.location.state.from) + "&token=" + this.props.responseEnv.ssO_REDIRECT_TOKEN} name="myIframe" height="100%" width="100%" title="Iframe Example" frameBorder="0" ref="iframe"></iframe>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.Env, ...state.Loading }), ({ ...LoadingStore.actionCreators, ...envStore.actionCreators }))(Login)