import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, InputGroup, FormControl, Table, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSearch, FaRedoAlt, FaTimes, FaTimesCircle, FaFileExcel } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CSVLink, CSVDownload } from "react-csv";

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import Swal from 'sweetalert2'
import Download from './ExportExcel'

import * as ModelsApplicationLog from '../../models/report/ApplicationLog'
import * as ApplicationLogStore from '../../store/report/ApplicationLog'

import * as StoresCompanyMaster from '../../store/companymaster/CompanyMaster';
import * as ModelsCompanyMaster from '../../models/companymaster/CompanyMaster';
import { formatYMDToDMY, parseJwt, setDateTimeToDatePicker } from '../modules/Common';
import { checkExp } from '../../modules/common';

export interface list_company {
    sid: string
    role_access: string
    company_code: string
    created_by: string
    created_on: string
    updated_by: string
    updated_on: string
}

export interface companyCode {
    sid: string
    company_code: string
    company_name: string
}

const headers = [
    { label: "Legacy ID", key: "legacyid" },
    { label: "Company Code", key: "companycode" },
    { label: "Transaction ID", key: "transaction_id" },
    { label: "Transaction Date", key: "transaction_date" },
    { label: "Source ID 1", key: "source_id1" },
    { label: "Source ID 2", key: "source_id2" },
    { label: "Source ID 3", key: "source_id3" },
    { label: "ERP Doc.Type", key: "doc_type" },
    { label: "ERP Doc.Year", key: "doc_year" },
    { label: "ERP Doc.No.", key: "item_no" },
    { label: "Doc.Date", key: "doc_date" },
    { label: "Post Date", key: "post_date" },
    { label: "Status", key: "status" },
    { label: "Message", key: "message" },
    { label: "Create On", key: "created_on" },
    { label: "Meta Data", key: "metadata" }
];

class ApplicationLog extends React.Component<any, { legacy_id: string, transaction_date_from: any, transaction_date_to: any, source_id_1: string, source_id_2: string, source_id_3: string, company_code: string, user: string, company_code_option: Array<companyCode>, checkAllCompany: boolean, company_user: Array<list_company>, checkBindingCompany: boolean, datePickerMemoryFrom: any, datePickerMemoryTo: any, showMetaDataModal: boolean, metaData: string }>{
    constructor(props: any) {
        super(props);
        this.state = {
            legacy_id: '',
            transaction_date_from: '',
            transaction_date_to: '',
            datePickerMemoryFrom: '',
            datePickerMemoryTo: '',
            company_code: '*',
            source_id_1: '',
            source_id_2: '',
            source_id_3: '',
            user: '',
            company_code_option: [],
            checkAllCompany: false,
            company_user: [],
            checkBindingCompany: false,
            showMetaDataModal: false,
            metaData: ''
        }
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("LG_APPLICATION");
        var userId = "";
        var checkUser = "";

        if (ls) {
            var jls: any = JSON.parse(ls)
            var accessToken = jls.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);
            checkExp(jwt)

            if (extra.username) {
                userId = extra.username
                checkUser = extra.username
            }
            else if (extra.first_name) {
                userId = extra.first_name + " " + extra.last_name
                checkUser = extra.citizen_id
            }
            else if (extra.citizen_id) {
                userId = extra.citizen_id
                checkUser = extra.citizen_id
            }

            this.setState({ user: userId })
        }

        //console.log(userId)
        //console.log(checkUser)

        fetch("/v1/user?id=" + checkUser,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    var data_c = result.total_count
                    if (data_c != 0) {
                        var dataRs = result.result_list
                        var company_list: Array<list_company> = dataRs[0].list_company
                        console.log(company_list)
                        var checkAll = company_list.findIndex(item => item.sid == "*")
                        console.log(checkAll)
                        if (checkAll > -1) {
                            this.setState({
                                checkAllCompany: true,
                                checkBindingCompany: true
                            })
                        } else {
                            this.setState({
                                company_user: company_list,
                                checkAllCompany: false,
                                checkBindingCompany: true
                            })
                        }
                    }
                },
                (error) => {
                }
            )

        this.props.requestFunctionCompanyMaster(true, "GET", "", "active");
    }

    handlechageInput = (e, key: string) => {
        if (key != "") {
            if (key == 'legacy_id') {
                this.setState({
                    legacy_id: e.target.value
                })
            } else if (key == 'company_code') {
                console.log(e.target)
                this.setState({
                    company_code: e.target.value
                })
            } else if (key == 'source_id_1') {
                this.setState({
                    source_id_1: e.target.value
                })
            } else if (key == 'source_id_2') {
                this.setState({
                    source_id_2: e.target.value
                })
            } else if (key == 'source_id_3') {
                this.setState({
                    source_id_3: e.target.value
                })
            }
        }
    }

    handleChangeDateFrom = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        //var dataRs = dates.getFullYear() + mnth + day + '000000'
        var dataRs = dates.getFullYear() + mnth + day
        console.log(dataRs)
        this.setState({
            transaction_date_from: dataRs,
            datePickerMemoryFrom: date,
            //job_started_on_from: dataRs
        })
    }

    handleChangeDateTo = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        //var dataRs = dates.getFullYear() + mnth + day + '000000'
        var dataRs = dates.getFullYear() + mnth + day
        console.log(dataRs)
        this.setState({
            transaction_date_to: dataRs,
            datePickerMemoryTo: date,
            //job_started_on_from: dataRs
        })
    }

    handleSearch = () => {
        if (this.state.legacy_id != "" && this.state.transaction_date_from != "" && this.state.transaction_date_to != "") {
            var companyCodes: string[] = []
            console.log(this.state.company_code)
            if (this.state.company_code == '*') {
                console.log(companyCodes)
                if (this.state.company_code_option) {
                    console.log(companyCodes)
                    this.state.company_code_option.forEach(item => {
                        companyCodes.push(item.company_code)
                        console.log(item.company_code)
                    })
                }
            } else {
                companyCodes.push(this.state.company_code)
            }
            console.log(companyCodes)
            this.props.requestGetApplicationLog(true, "GET", "555", companyCodes, "", "", encodeURIComponent(this.state.legacy_id), this.state.transaction_date_from, this.state.transaction_date_to, encodeURIComponent(this.state.source_id_1), encodeURIComponent(this.state.source_id_2), encodeURIComponent(this.state.source_id_3))
        } else {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'โปรดกรอกข้อมูลช่อง legacy Id และ Transaction Date ก่อนค้นหาข้อมูล',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
            })
        }
    }

    handleChangeCompany(e) {
        console.log(e.target.value)

        this.setState({
            company_code: e.target.value
        })
    }

    handleViewMetaData(metadata: string) {
        this.setState({
            showMetaDataModal: true,
            metaData: metadata
        })
    }

    handleCloseModalMetaData = () => {
        if (this.state.showMetaDataModal) {
            this.setState({
                showMetaDataModal: false,
                metaData: ""
            })
        } else {
            this.setState({
                showMetaDataModal: true
            })
        }
    }

    reCheckStatus = (status: string) => {
        if (status == "E") {
            return "Error"
        } else if (status == "S") {
            return "Success"
        }
    }

    handleExportExcel = () => {
        console.log("handleExportExcel")
        return <Download />
    }

    render() {
        console.log(this.state.checkAllCompany)
        const { isLoadingCompanyMaster, statusCompanyMaster, responseCompanyMaster } = this.props
        if (isLoadingCompanyMaster && statusCompanyMaster == 200 && this.state.checkBindingCompany) {
            if (responseCompanyMaster.total_count != 0) {
                var responseCompanyMasterList = responseCompanyMaster.result_list
                console.log(responseCompanyMasterList)
                console.log(this.state.checkAllCompany)
                if (this.state.checkAllCompany) {
                    var company_code_option: Array<companyCode> = []
                    for (var i = 0; i < responseCompanyMasterList.length; i++) {
                        var option: companyCode = {
                            sid: responseCompanyMasterList[i].sid,
                            company_code: responseCompanyMasterList[i].company_code != null ? responseCompanyMasterList[i].company_code : "",
                            company_name: responseCompanyMasterList[i].company_name
                        }
                        company_code_option.push(option)
                    }
                    console.log(company_code_option)
                    this.setState({
                        company_code_option: company_code_option,
                        checkBindingCompany: false
                    })
                } else {
                    var company_code_option: Array<companyCode> = []
                    for (var i = 0; i < this.state.company_user.length; i++) {
                        var dataList: ModelsCompanyMaster.CompanyMaster = responseCompanyMasterList.find(item => item.sid == this.state.company_user[i].sid)
                        if (dataList.sid != undefined) {
                            var option: companyCode = {
                                sid: dataList.sid,
                                company_code: dataList.company_code != null ? dataList.company_code : '',
                                company_name: dataList.company_name
                            }
                            company_code_option.push(option)
                        }
                    }
                    this.setState({
                        company_code_option: company_code_option,
                        checkBindingCompany: false
                    })
                }
            }
        }

        console.log('ngame', this.props.isLoadingApplicationLog)

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Application Log
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                            <span>
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                ค้นหาข้อมูล
                                            </span>
                                        </div>
                                        <div className="mat-box">
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Label className="font-weight-bold"><span style={{ color: 'red' }}>* </span>Legacy ID :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" id="legacy_id" size="sm" onChange={(e) => this.handlechageInput(e, "legacy_id")} />
                                                    </InputGroup>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Label className="font-weight-bold">Source ID 1 :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" id="sourceID1" size="sm" onChange={(e) => this.handlechageInput(e, "source_id_1")} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Label className="font-weight-bold"><span style={{ color: 'red' }}>* </span>Transaction Date From:</Form.Label>
                                                            <InputGroup className="mb-3">
                                                                {/*<FormControl type="date" id="jobStartFrom" size="sm" onChange={(e) => this.handlechageInput(e, "jobStartFrom")} />*/}
                                                                <DatePicker
                                                                    selected={setDateTimeToDatePicker(this.state.datePickerMemoryFrom)}
                                                                    onChange={this.handleChangeDateFrom}
                                                                    customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="dd/mm/yyyy"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col>
                                                            <Form.Label className="font-weight-bold"><span style={{ color: 'red' }}>* </span>Transaction Date To:</Form.Label>
                                                            <InputGroup className="mb-3">
                                                                {/*<FormControl type="date" id="jobStartFrom" size="sm" onChange={(e) => this.handlechageInput(e, "jobStartFrom")} />*/}
                                                                <DatePicker
                                                                    selected={setDateTimeToDatePicker(this.state.datePickerMemoryTo)}
                                                                    onChange={this.handleChangeDateTo}
                                                                    customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="dd/mm/yyyy"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Label className="font-weight-bold">Source ID 2 :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" id="sourceID2" size="sm" onChange={(e) => this.handlechageInput(e, "source_id_2")} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                                    {/*<InputGroup className="mb-3">*/}
                                                    {/*    <FormControl type="text" id="company_code" size="sm" onChange={(e) => this.handlechageInput(e, "company_code")} />*/}
                                                    {/*</InputGroup>*/}
                                                    <Form.Control as="select" onChange={(e) => this.handleChangeCompany(e)}>
                                                        <option value={'*'}>*</option>
                                                        {this.state.company_code_option.length != 0 && this.state.company_code_option.map((item: companyCode, idx: any) => {
                                                            return (
                                                                <option key={idx} value={item.company_code}>{item.company_code + " : " + item.company_name}</option>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Label className="font-weight-bold">Source ID 3 :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" id="sourceID3" size="sm" onChange={(e) => this.handlechageInput(e, "source_id_3")} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col lg={3}>
                                                    <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.handleSearch()}><FaSearch /> Search</Button>
                                                </Col>
                                                <Col lg={6}></Col>
                                                <Col lg={3}>
                                                    {this.props.statusApplicationLog == 200 && this.props.isLoadApplicationLog && this.props.responseApplicationLog['result_list'] != null &&
                                                        //<Button variant="success" size="sm" className="mx-1 btn btn-block" onClick={() => this.handleExportExcel()}><FaFileExcel /> Export Excel</Button>
                                                        //<Download DataSet={this.props.responseApplicationLog['result_list']} key={this.state.legacy_id} />

                                                        <CSVLink
                                                            data={this.props.responseApplicationLog['result_list']}
                                                            filename={this.state.legacy_id}
                                                            className="btn btn-success mx-1 btn btn-block"
                                                            target="_blank"
                                                            headers={headers}
                                                        >
                                                            <FaFileExcel /> Export Excel
                                                        </CSVLink>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    this.props.isLoadingApplicationLog
                                    && (
                                        <div
                                            style={{
                                                background: "rgba(209, 250, 221, 1)",
                                                color: "black",
                                                fontSize: '10pt',
                                                textAlign: "center"
                                            }}
                                        >
                                            <Spinner animation="border" size="sm" />
                                            &nbsp;&nbsp;Loading...
                                        </div>
                                    )
                                }
                                {this.props.isLoadApplicationLog &&
                                    <Row>
                                        <Col>
                                            <div className="tableWrap">
                                                <Table className="table-bordered table-hover custom-table-list table-responsive-lg" size="sm">
                                                    <thead className="">
                                                        <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                                            <th style={{ width: '5%' }}>Legacy ID</th>
                                                            <th style={{ width: '5%' }}>Company Code</th>
                                                            <th style={{ width: '12%' }}>Transaction ID</th>
                                                            <th style={{ width: '5%' }}>Transaction Date</th>
                                                            <th style={{ width: '6%' }}>Source ID 1</th>
                                                            <th style={{ width: '6%' }}>Source ID 2</th>
                                                            <th style={{ width: '6%' }}>Source ID 3</th>
                                                            <th style={{ width: '5%' }}>ERP Doc. Type</th>
                                                            <th style={{ width: '5%' }}>ERP Doc. Year</th>
                                                            <th style={{ width: '7%' }}>ERP Doc. No.</th>
                                                            <th style={{ width: '5%' }}>Doc. Date</th>
                                                            <th style={{ width: '5%' }}>Post Date</th>
                                                            <th style={{ width: '5%' }}>Status</th>
                                                            <th style={{ width: '15%' }}>Message</th>
                                                            <th style={{ width: '8%' }}>Create On</th>
                                                            <th style={{ width: '5%' }}>Meta Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!this.props.isLoadApplicationLog ? <tr><td colSpan={15} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                        {this.props.isLoadApplicationLog && this.props.statusApplicationLog != 200 ? <tr><td colSpan={15} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                        {/*{this.props.isLoadApplicationLog && this.props.responseApplicationLog['result_list'] == null ? <tr><td colSpan={15} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}*/}
                                                        {this.props.statusApplicationLog == 200 && this.props.isLoadApplicationLog && this.props.responseApplicationLog['result_list'] != null && this.props.responseApplicationLog['result_list'].map((item: ModelsApplicationLog.ApplicationLog, idx: any) => {
                                                            console.log(this.props.responseApplicationLog['result_list'])
                                                            return (
                                                                <tr key={idx} style={item.status == "E" ? { color: 'red' } : {}}>
                                                                    <td className="text-center">{item.legacyid}</td>
                                                                    <td className="text-center">{item.companycode}</td>
                                                                    <td className="text-center">{item.transaction_id}</td>
                                                                    <td className="text-center">{formatYMDToDMY(item.transaction_date)}</td>
                                                                    <td className="text-center">{item.source_id1}</td>
                                                                    <td className="text-center">{item.source_id2}</td>
                                                                    <td className="text-center">{item.source_id3}</td>
                                                                    <td className="text-center">{item.doc_type}</td>
                                                                    <td className="text-center">{item.doc_year}</td>
                                                                    <td className="text-center">{item.item_no}</td>
                                                                    <td className="text-center">{formatYMDToDMY(item.doc_date)}</td>
                                                                    <td className="text-center">{item.post_date}</td>
                                                                    <td className="text-center">{this.reCheckStatus(item.status)}</td>
                                                                    <td className="text-center">{item.message}</td>
                                                                    <td className="text-center">{formatYMDToDMY(item.created_on)}</td>
                                                                    <td className="text-center">
                                                                        <Button size='sm' onClick={() => this.handleViewMetaData(item.metadata)}>View</Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                <Modal show={this.state.showMetaDataModal} onHide={this.handleCloseModalMetaData} dialogClassName="custom-modal" size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>View Meta Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {/*{this.state.metaData != "" ? <pre>{JSON.stringify(this.state.metaData, null, 2)}</pre> : ""}*/}
                                {/*<hr/>*/}
                                {this.state.metaData}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseModalMetaData}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.ApplicationLog, ...state.CompanyMaster }), ({ ...ApplicationLogStore.actionCreators, ...StoresCompanyMaster.actionCreators }))(ApplicationLog)