import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import QuartzMonitorTable from './QuartzMonitorTable'
import { FaSearch } from "react-icons/fa";
import { FilterQuartzMonitorForm } from '../../models/config/QuartzMonitor';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { setDateTimeToDatePicker } from '../modules/Common'

interface States {
    filterCompanyCode: string,
    filterLegacyName: string,
    filterLegacyId: string,
    filterLastFire: string,
    filterNextFire: string,
    previewFilterLastFire: Date | string,
    previewFilterNextFire: Date | string,
    search: string,
}

class QuartzMonitor extends React.Component<any, States>{
    public tableRef = React.createRef();

    constructor(props: any) {
        super(props);
        this.state = {
            filterCompanyCode: "",
            filterLegacyName: "",
            filterLegacyId: "",
            filterLastFire: "",
            filterNextFire: "",
            previewFilterLastFire: "",
            previewFilterNextFire: "",
            search: "",
        }
    }

    private newHandleChangeFilter(key: keyof States): React.ChangeEventHandler<HTMLInputElement> {
        return event => {
            event.persist();
            this.setState({
                ...this.state,
                [key]: event.target.value
            });
        }
    }

    private handleChangeDateFilter(key: keyof Pick<States, 'filterLastFire' | 'filterNextFire'>) {
        return (date: Date, _event: unknown) => {
            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            const searchDate = `${day}-${month}-${year}`;

            this.setState({
                ...this.state,
                [key]: searchDate,
                [(key === 'filterLastFire')
                    ? 'previewFilterLastFire'
                    : 'previewFilterNextFire']: date
            });
        }
    }

    handleChangeFilter(e: any, CheckInput: string) {
        //console.log("[handleChangeFilter Fn] CheckInput -> ", CheckInput);

        if (CheckInput === "CompanyCode") {
            this.setState({
                filterCompanyCode: e.target.value
            })
        }
        else if (CheckInput === "LegacyID") {
            this.setState({
                filterLegacyId: e.target.value
            })
        }
        else if (CheckInput === "LegacyName") {
            this.setState({
                filterLegacyName: e.target.value
            })
        }
        else if (CheckInput === "LastFire") {
            this.setState({
                filterLastFire: e.target.value
            })
        }
        else if (CheckInput === "NextFire") {
            this.setState({
                filterNextFire: e.target.value
            })
        }

        //console.log("[handleChangeFilter Fn] Input CompanyCode -> ", this.state.filterCompanyCode);
    }

    handlecheckStatus(e: any, checkbox: string) {
        console.log("[handlecheckStatus Fn] checkbox -> ", checkbox);
    }

    search(key: string) {
        //console.log("[search Fn] key -> ", key);
        // this.setState({ search: key })
        const quartzMonitorTable: any = this.tableRef.current;
        const filterForm: FilterQuartzMonitorForm = {
            legacyName: this.state.filterLegacyName,
            companyCode: this.state.filterCompanyCode,
            legacyId: this.state.filterLegacyId,
            lastFire: this.state.filterLastFire,
            nextFire: this.state.filterNextFire
        };

        quartzMonitorTable.filterQuartzMonitorList(filterForm);
    }

    resetSearch() {
        this.setState({ search: "" })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Quartz Monitor
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                {/*Filtering*/}
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    ค้นหาข้อมูล
                                                </span>
                                            </div>
                                            <div className="mat-box">
                                                <Row>
                                                    <Col lg={3}>
                                                        <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                className="menu-form"
                                                                type="text"
                                                                size="sm"
                                                                value={this.state.filterLegacyName}
                                                                onChange={this.newHandleChangeFilter('filterLegacyName')}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <FormControl
                                                                id="companycode"
                                                                className="menu-form"
                                                                size="sm"
                                                                value={this.state.filterCompanyCode}
                                                                onChange={this.newHandleChangeFilter('filterCompanyCode')}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                className="require-field"
                                                                type="text"
                                                                size="sm"
                                                                value={this.state.filterLegacyId}
                                                                onChange={this.newHandleChangeFilter('filterLegacyId')}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Label className="font-weight-bold">Last Fire :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <DatePicker
                                                                selected={setDateTimeToDatePicker(this.state.previewFilterLastFire as any)}
                                                                onChange={this.handleChangeDateFilter('filterLastFire')}
                                                                customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="dd/mm/yyyy"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Label className="font-weight-bold">Next Fire :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <DatePicker
                                                                selected={setDateTimeToDatePicker(this.state.previewFilterNextFire as any)}
                                                                onChange={this.handleChangeDateFilter('filterNextFire')}
                                                                customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="dd/mm/yyyy"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={{ span: 2, offset: 10 }}>
                                                        <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                            <Button
                                                                variant="info"
                                                                size="sm"
                                                                className="mx-1 btn btn-block"
                                                                onClick={() => this.search("Search")}>
                                                                <FaSearch />Search
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/*Show Table*/}
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <QuartzMonitorTable
                                                ref={this.tableRef}
                                                filterCompanyCode={this.state.filterCompanyCode}
                                                filterLegacyName={this.state.filterLegacyName}
                                                filterLegacyId={this.state.filterLegacyId}
                                                filterLastFire={this.state.filterLastFire}
                                                filterNextFire={this.state.filterNextFire}
                                                search={this.state.search}
                                                resetSearch={this.resetSearch.bind(this)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default QuartzMonitor