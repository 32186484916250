import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/DailyReport'

interface LoadDailyReport {
    type: 'LOAD_DAIRYREPORT'
}

interface LoadedDailyReport {
    type: 'LOADEDDAIRYREPORT'
    response: Models.DailyReport
    status: number,
    statusMessage: string
}
interface FailLoadedDailyReport {
    type: 'LOADDAIRYREPORTFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadedDailyReport {
    type: 'CLEARDAIRYREPORTFAIL'
}

interface NoUserLoadedDailyReport {
    type: 'NOUSERDAIRYREPORTFAIL'
}

type KnownAction = LoadedDailyReport | FailLoadedDailyReport | LoadDailyReport | ClearLoadedDailyReport | NoUserLoadedDailyReport

export const actionCreators = {
    requestGetDailyReport: (check: boolean, method: string, sid: string, company_codes: string[], date_from: any, date_to: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DailyReport && check && method == 'GET') {
            //console.log('call actionCreators : requestGetDailyReport | /v1/legacy/task?sid='+ sid + '&company_code=' + company_code + '&page=' + page + '&page_size=' + page_size + '&job_instance_no=' + job_instance_no + '&legacy_id=' + legacy_id + '&status=' + status + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to )
            var company_code = ""
            if (company_codes.length != 0) {
                company_codes.forEach(element => {
                    company_code += "&company_code=" + element
                });
            }
            fetch(`/v1/legacy/daily-report?sid=` + sid + company_code + '&date_from=' + date_from + '&date_to=' + date_to, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.DailyReport>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDDAIRYREPORT', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADDAIRYREPORTFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_DAIRYREPORT' });

        } else if (appState && appState.legacyTask && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARDAIRYREPORTFAIL' });
        }
        else if (appState && appState.legacyTask && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSERDAIRYREPORTFAIL' });
        }
    }
}

const unloadedState: Models.GetDailyReportState = { isLoadDailyReport: false };

export const reducer: Reducer<Models.GetDailyReportState> = (state: Models.GetDailyReportState | undefined, incomingAction: Action): Models.GetDailyReportState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_DAIRYREPORT':
            return {
                isLoadDailyReport: false,
            }

        case 'LOADEDDAIRYREPORT':
            return {
                isLoadDailyReport: true,
                responseDailyReport: action.response as any,
                statusDailyReport: action.status
            }

        case 'LOADDAIRYREPORTFAIL':
            return {
                isLoadDailyReport: true,
                messageDailyReport: action.statusMessage,
                statusDailyReport: action.status
            }

        case 'CLEARDAIRYREPORTFAIL':
            return {
                isLoadDailyReport: false,
                messageDailyReport: "",
                statusDailyReport: 400,
            }

        case 'NOUSERDAIRYREPORTFAIL':
            return {
                isLoadDailyReport: true,
                statusDailyReport: 400,
            }
        default: return state;
    }
}