import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Row, Col, Button, Table, Spinner } from 'react-bootstrap';
import { FaStopCircle } from "react-icons/fa";

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as QuartzMonitorModels from '../../models/config/QuartzMonitor'
import * as QuartzMonitorStore from '../../store/config/QuartzMonitor'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import { FilterQuartzMonitorForm } from '../../models/config/QuartzMonitor';

type Store = QuartzMonitorModels.QuartzMonitorState & typeof QuartzMonitorStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

interface States {
    formData: QuartzMonitorModels.QuartzMonitor[],
    isready: boolean,
    listQuartzMonitor: any,
    isreadyLoad: boolean,
}

class QuartzMonitorTable extends React.Component<any, States, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            formData: [],
            isready: true,
            listQuartzMonitor: [],
            isreadyLoad: true,
        }

    }

    componentDidMount() {
        const { isready } = this.state
        const { checkkey } = this.props

        var appStorage = localStorage.getItem('LG_APPLICATION')
        if (appStorage) {
            var lis = JSON.parse(appStorage)
            console.log(lis)

            if (isready) {
                this.props.requestGetQuartzMonitor(isready, "GET")
                this.setState({
                    isready: false,
                })
            }
        }
    }

    formatDMY(dmy: string) {
        if (dmy != undefined && dmy != "" && dmy != 'null') {
            var year = dmy.substring(6, 10);
            var day = dmy.substring(3, 5);
            var month = dmy.substring(0, 2);
            var Datevalue = "";
            var datevalue = "";
            var UTC = new Date()
            if (dmy.length < 9) {
                datevalue = day + '-' + month + '-' + year

            } else {
                var times = dmy.substring(11, 19);
                datevalue = day + '-' + month + '-' + year + ' ' + times;
            }
            return datevalue;
        } else {
            Datevalue = "";

            return Datevalue;
        }
    }

    formatCompanyCode(code: string) {
        var companyCode = code.split('|');
        return companyCode[1]
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.props.GetQuartzMonitor.isLoadQuartzMonitor && this.props.GetQuartzMonitor.responseQuartzMonitor != null && this.state.isreadyLoad) {
            var data: any = []
            data = this.props.GetQuartzMonitor.responseQuartzMonitor['result_list'];
            var arr: any = []

            for (var j = 0; j < data.length; j++) {
                var dataFrist: any = null
                var datePotition: any = null
                for (var i = 0; i < data.length; i++) {
                    if (data[i] != "" && data[i] != undefined) {
                        const dataDate = Date.parse(data[i].next_fire);
                        if (dataFrist == null) {
                            dataFrist = dataDate
                            datePotition = i
                        }
                        if (dataFrist < dataDate) {
                            dataFrist = dataDate
                            datePotition = i
                        }
                    }
                }
                arr.push(data[datePotition])
                delete data[datePotition];
            }

            //console.log("555555555")
            //console.log(arr)
            //console.log(JSON.stringify(arr))
            this.setState({
                listQuartzMonitor: arr,
                isreadyLoad: false
            });
        }


        // --------------------------------
        const { filterCompanyCode,
            filterLegacyName,
            filterLegacyId,
            filterLastFire,
            filterNextFire,
            search,
            resetSearch } = this.props;

        console.log("[props] -> ", search);

        if (search === "Search") {
            this.handleSearchDataQuartz();
        }
    }

    async handleSearchDataQuartz() {
        const {
            filterCompanyCode,
            filterLegacyName,
            filterLegacyId,
            filterLastFire,
            filterNextFire,
            search,
            resetSearch
        } = this.props;

        const data: QuartzMonitorModels.QuartzMonitor[] = this.state.listQuartzMonitor;
        let dataArr: QuartzMonitorModels.QuartzMonitor[] = [];
        let filterDataArr: QuartzMonitorModels.QuartzMonitor[] = [];

        console.log("[filterCompanyCode] -> ", filterCompanyCode);
        console.log("[response_data in state] -> ", data);
        console.log('dataArr', dataArr)

        if (filterCompanyCode !== "") {
            if (dataArr !== null && dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    if (this.formatCompanyCode(dataArr[i].jobGroup) === filterCompanyCode) {
                        filterDataArr.push(dataArr[i])
                    }
                }
                dataArr = filterDataArr;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (this.formatCompanyCode(data[i].jobGroup) === filterCompanyCode) {
                        filterDataArr.push(data[i]);
                    }
                }
            }
        }

        if (filterLegacyName !== "") {
            if (dataArr !== null && dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    if (dataArr[i].legacy_name === filterLegacyName) {
                        filterDataArr.push(dataArr[i])
                    }
                }
                dataArr = filterDataArr;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].legacy_name === filterLegacyName) {
                        filterDataArr.push(data[i]);
                    }
                }
            }
        }

        if (filterLegacyId !== "") {
            if (dataArr !== null && dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    if (dataArr[i].jobName === filterLegacyId) {
                        filterDataArr.push(dataArr[i])
                    }
                }
                dataArr = filterDataArr;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].jobName === filterLegacyId) {
                        filterDataArr.push(data[i]);
                    }
                }
            }
        }

        if (filterLastFire !== "") {
            if (dataArr !== null && dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    if (this.formatDMY(String(dataArr[i].previous_fire)) === filterLastFire) {
                        filterDataArr.push(dataArr[i])
                    }
                }
                dataArr = filterDataArr;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (this.formatDMY(String(data[i].previous_fire)) === filterLastFire) {
                        filterDataArr.push(data[i]);
                    }
                }
            }
        }

        if (filterNextFire !== "") {
            if (dataArr !== null && dataArr.length > 0) {
                for (let i = 0; i < dataArr.length; i++) {
                    if (this.formatDMY(String(dataArr[i].next_fire)) === filterNextFire) {
                        filterDataArr.push(dataArr[i])
                    }
                }
                dataArr = filterDataArr;
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (this.formatDMY(String(data[i].next_fire)) === filterNextFire) {
                        filterDataArr.push(data[i]);
                    }
                }
            }
        }

        if (filterDataArr.length > 0) {
            dataArr = filterDataArr;
        }

        if (filterCompanyCode === "" &&
            filterLegacyName === "" &&
            filterLegacyId === "" &&
            filterLastFire === "" &&
            filterNextFire === "") {
            this.setState({
                formData: data,
            })
        } else {
            this.setState({
                formData: filterDataArr,
            })
        }

        resetSearch();
    }

    public filterQuartzMonitorList(filterForm: FilterQuartzMonitorForm) {
        const quartzMonitorList: QuartzMonitorModels.QuartzMonitor[] = [...this.state.listQuartzMonitor];

        const filterdQuartzMonitorList = quartzMonitorList.filter(quartzMonitor => {
            if (quartzMonitor.legacy_name === null) {
                quartzMonitor.legacy_name = '';
            }

            return (
                quartzMonitor.legacy_name.indexOf(filterForm.legacyName) > -1 &&
                quartzMonitor.jobName.indexOf(filterForm.legacyId) > -1 &&
                this.formatCompanyCode(quartzMonitor.jobGroup).indexOf(filterForm.companyCode) > -1 &&
                this.formatDMY(String(quartzMonitor.previous_fire)).split(' ')[0].indexOf(filterForm.lastFire) > -1 &&
                this.formatDMY(String(quartzMonitor.next_fire)).split(' ')[0].indexOf(filterForm.nextFire) > -1
            );
        });

        this.setState({ formData: filterdQuartzMonitorList });
    }

    render() {

        return (
            <div>
                <Row className='mt-2'>
                    <Col>
                        <Table className="table-bordered table-hover rounded custom-table-list table-responsive-lg" size="sm" style={{ width: '100%' }}>
                            <thead className="rounded">
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light require-field">
                                    <th style={{ width: '5%' }} className='text-center'></th>
                                    <th style={{ width: '6%' }}>รายงาน</th>
                                    <th style={{ width: '10%' }}>Legacy Name</th>
                                    <th style={{ width: '15%' }}>Company Code</th>
                                    <th style={{ width: '15%' }}>Legacy ID</th>
                                    <th style={{ width: '15%' }}>Last Fire</th>
                                    <th style={{ width: '15%' }}>Next Fire</th>
                                    <th style={{ width: '10%' }}>Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.props.GetQuartzMonitor.isLoadQuartzMonitor ? <tr><td colSpan={8} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {this.props.GetQuartzMonitor.isLoadQuartzMonitor && this.props.GetQuartzMonitor.statusQuartzMonitor != 200 ? <tr><td colSpan={8} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {//this.props.GetQuartzMonitor.statusQuartzMonitor == 200 &&
                                    //this.props.GetQuartzMonitor.isLoadQuartzMonitor &&
                                    //this.props.GetQuartzMonitor.responseQuartzMonitor != null &&
                                    //this.state.formData != null &&
                                    this.state.formData.length !== 0 &&
                                    this.state.formData.map((item: QuartzMonitorModels.QuartzMonitor, idx: any) => {
                                        return (
                                            <tr key={idx}>
                                                <td className='text-center'>
                                                    <Button variant="secondary" size="sm" className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Stop" ><FaStopCircle /></Button>
                                                </td>
                                                <td className='text-center'>{idx + 1}</td>
                                                <td>{item.legacy_name}</td>
                                                <td>{this.formatCompanyCode(item.jobGroup)}</td>
                                                <td>{item.jobName}</td>
                                                <td>{this.formatDMY(String(item.previous_fire))}</td>
                                                <td>{this.formatDMY(String(item.next_fire))}</td>
                                                <td className='text-overflow'>{item.is_completed}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.QuartzMonitor, ...state.Loading }), ({ ...QuartzMonitorStore.actionCreators, ...LoadingStore.actionCreators }), null, { forwardRef: true })(QuartzMonitorTable)