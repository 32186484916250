import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Layout from './Layout';
import SubLayout from './SubLayout';
import Home from './Home';
import LegacyReport from './report/LegacyReport'
import LegacyTask from './report/LegacyTask'
import LegacyLog from './report/LegacyLog'
import CompanyConfig from './config/CompanyConfig'
import MasterConfig from './config/MasterConfig'
import SftpServerConfig from './config/SftpServerConfig'
import LogIn from './LogIn'
import SignIn from './auth/SignIn'
import Logout from './auth/Logout'
import UploadfileConfig from './config/UploadFileConfig'
import DmsConfig from './config/DmsConfig'
import EmployeeMasterComponent from '../components/Employee/EmployeeMasterComponent';
import Ingress from './modules/ingress'
import QuartzMonitor from './config/QuartzMonitor'
import PullJobConfig from './config/PullJobConfig'
import EmailContent from './config/EmailContent'
import ReportDetail from './report/ReportDetail'
import DownloadReport from './report/DownloadReport'
import ApplicationLog from './report/ApplicationLog'
import DailyReport from './report/DailyReport'
import ExpectedRunJob from './report/ExpectedRunJob'

import { Route, Switch } from 'react-router';
import { PrivateRoute } from './PrivateRoute';

import { connect } from "react-redux";
import { ApplicationState } from '../store';
import UserRegistration from './auth/UserRegistration';
import LegacyReportManual from './report/manual/LegacyReportManual';
import PullJobConfigLog from './config/PullJobConfigLog';

export default (props: { children?: React.ReactNode }, store) => (
    <React.Fragment>
        <Switch>
            <Route path='/Login' component={LogIn} />
            <Route path='/auth/signin' component={SignIn} />
            <Route path='/ingress' component={Ingress} />
            <Route path='/monitor' component={ReportDetail} />
            <Route path='/report' component={ApplicationLog} />
            <SubLayout>
                <PrivateRoute exact path='/' component={Home} />

                <PrivateRoute path='/legacytask' component={LegacyTask} />
                <PrivateRoute path='/user-registration' component={UserRegistration} />
                <PrivateRoute path='/legacyreport' component={LegacyReport} />
                <PrivateRoute path='/legacyreport-manual' component={LegacyReportManual} />
                <PrivateRoute path='/legacylog' component={LegacyLog} />
                <PrivateRoute path='/companyconfig' component={CompanyConfig} />
                <PrivateRoute path='/masterconfig' component={MasterConfig} />
                <PrivateRoute path='/sftpconfig' component={SftpServerConfig} />
                <PrivateRoute path='/uploadfileconfig' component={UploadfileConfig} />
                <PrivateRoute path='/dmsconfig' component={DmsConfig} />
                <PrivateRoute path='/employee' component={EmployeeMasterComponent} />
                <PrivateRoute path='/quartzmonitor' component={QuartzMonitor} />
                <PrivateRoute path='/pulljobconfig' component={PullJobConfig} />
                <PrivateRoute path='/pulljobconfig-log' component={PullJobConfigLog} />
                <PrivateRoute path='/emailcontent' component={EmailContent} />
                <PrivateRoute path='/logout' component={Logout} />
                <PrivateRoute path='/legacydetail' component={ReportDetail} />
                <PrivateRoute path='/downloadsourceandresponsefile' component={DownloadReport} />
                <PrivateRoute path='/applicationlog' component={ApplicationLog} />
                <PrivateRoute path='/daily-report' component={DailyReport} />
                <PrivateRoute path='/expected-running-job-report' component={ExpectedRunJob} />
            </SubLayout>
        </Switch>
    </React.Fragment>
);
