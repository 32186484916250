import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps { }

interface State { }

class UserRegistration extends React.Component<Props, State> {
    public render(): React.ReactNode {
        return <p>User Registration is under-implemented</p>;
    }
}

export default UserRegistration;