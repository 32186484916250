import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />
        <Container fluid={true}>
            <Row>
                {/* <Col md="2" style={{ paddingLeft: "0", zIndex: 1 }}>
                    <SideBarMenu />
                </Col> */}
                <Col md="12" style={{ marginLeft: 0 }}>
                    <main role="main" style={{ minHeight: "92.5vh" }}>
                        {props.children}
                    </main>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);
