import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';

// models
import * as Models from '../../models/testConnect/SftpConnection'

interface LoadSftpSourceTest {
    type: 'LOAD_SFTPSOURCETEST'
}

interface LoadedSftpSourceTest {
    type: 'LOADEDSFTPSOURCETEST'
    response: Models.ResponseTestSftp
    status: number,
    statusMessage: string
}
interface FailLoadedSftpSourceTest {
    type: 'LOADSFTPTESTSOURCEFAIL'
    status: number,
    statusMessage: any
}
interface ClearSftpSourceTest {
    type: 'CLEARSFTPSOURCETEST'
}


type KnownAction = LoadSftpSourceTest | LoadedSftpSourceTest | FailLoadedSftpSourceTest | ClearSftpSourceTest

export const actionCreators = {
    requestGetSftpSourceTest: (check: boolean, method: string, formBody: Models.ReqTestSftp): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.TestSftp && check && method == 'POST') {
            console.log('call actionCreators : requestGetSftpTest')
            fetch(`/v1/test/sftp/connection`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formBody),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSFTPTESTSOURCEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDSFTPSOURCETEST', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDSFTPSOURCETEST', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADSFTPTESTSOURCEFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_SFTPSOURCETEST' });

        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSFTPSOURCETEST' });
        }

    }
}

const unloadedState: Models.TestSftpSourceState = { isLoadTestSftpSource: false };

export const reducer: Reducer<Models.TestSftpSourceState> = (state: Models.TestSftpSourceState | undefined, incomingAction: Action): Models.TestSftpSourceState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SFTPSOURCETEST':
            return {
                isLoadTestSftpSource: false,
            }

        case 'LOADEDSFTPSOURCETEST':
            return {
                isLoadTestSftpSource: true,
                responseTestSftpSource: action.response as any,
                statusTestSftpSource: action.status
            }

        case 'LOADSFTPTESTSOURCEFAIL':
            return {
                isLoadTestSftpSource: true,
                messageTestSftpSource: action.statusMessage,
                statusTestSftpSource: action.status
            }

        case 'CLEARSFTPSOURCETEST':
            return {
                isLoadTestSftpSource: false,
                messageTestSftpSource: '',
                statusTestSftpSource: 400
            }
        default: return state;
    }
}