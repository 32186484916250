import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";
import Swal from 'sweetalert2'
import * as LegacyReportModels from '../../models/report/LegacyReport'
import * as LegacyReportStore from '../../store/report/LegacyReport'

type conditionProps = LegacyReportModels.GetLegacyReportState & typeof LegacyReportStore.actionCreators

export interface inititem {
    value: "",
    label: "",
    display: ""
};

class AutocompleteLegacyID extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: Array<inititem>, checkSearch: boolean, checkValue: boolean, companycodereq: string, sidreq: string, selectedValue: Array<string> }, conditionProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: [],
            checkSearch: true,
            checkValue: true,
            companycodereq: '',
            sidreq: '',
            selectedValue: []
        }
    }

    componentDidMount() {
        const { sid, companycode } = this.props
        if (companycode != "") {
            this.props.requestGetLegacyReportByID(true, "POST", "", companycode);
            this.setState({
                companycodereq: this.props.companycode,
                checkValue: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { sid, companycode, GetReportByID, valueSearch } = this.props
        if (companycode != this.state.companycodereq) {
            this.props.requestGetLegacyReportByID(true, "POST", "", companycode);
            this.setState({
                companycodereq: this.props.companycode,
                checkValue: false
            })
        }

        if (GetReportByID.statusLegacyReportByID == 200 && GetReportByID.isLoadLegacyReportByID && GetReportByID.responseLegacyReportByID['result_list'] != undefined && !this.state.checkValue) {
            console.log("GetReportByID.statusLegacyReportByID == 200 && GetReportByID.isLoadLegacyReportByID && GetReportByID.responseLegacyReportByID['result_list'] != undefined && !this.state.checkValue")
            var dataArr: any = []
            var dataFindArr = ""
            for (let i = 0; i < GetReportByID.responseLegacyReportByID['result_list'].length; i++) {
                var dataReq = {}
                dataReq['value'] = GetReportByID.responseLegacyReportByID['result_list'][i].legacyid
                dataReq['display'] = GetReportByID.responseLegacyReportByID['result_list'][i].legacyid + ' : ' + GetReportByID.responseLegacyReportByID['result_list'][i].legacyname
                dataReq['label'] = GetReportByID.responseLegacyReportByID['result_list'][i].legacyid + ' : ' + GetReportByID.responseLegacyReportByID['result_list'][i].legacyname
                dataArr.push(dataReq)

                //if (GetReportByID.responseLegacyReportByID['result_list'][i].legacyid == valueSearch && valueSearch != "") {
                //    dataFindArr = GetReportByID.responseDmsConfig['result_list'][i].dms_serverid + ' , ' + GetReportByID.responseDmsConfig['result_list'][i].dms_url
                //}
            }
            //var dataSearchArr: any = []
            //var dataSearch = {}
            //dataSearch['value'] = valueSearch
            //dataSearch['display'] = dataFindArr
            //dataSearch['label'] = dataFindArr
            //dataSearchArr.push(dataSearch)

            if (this.props.selectedValue.length != 0) {
                this.setState({
                    selectedValue: this.props.selectedValue
                })
                var Arr = this.props.selectedValue;
                console.log(Arr)
                for (let i = 0; i < Arr.length; i++) {
                    var uni = dataArr.findIndex(item => item.value === this.props.selectedValue[i])
                    console.log(uni)
                    dataArr.splice(uni, 1)
                }
                console.log(dataArr)
                //this.setState({
                //    dataResult: option
                //})
            }

            this.setState({
                dataResult: dataArr,
                checkValue: true,
                //dataSearch: dataSearchArr
            })
            //console.log(dataSearch)
        }

        if (this.props.selectedValue.length != nextProps.selectedValue.length && this.state.dataResult.length != 0) {
            console.log("this.props.selectedValue != this.state.selectedValue")
            var dataArr: any = this.state.dataResult
            this.setState({
                selectedValue: this.props.selectedValue,
                dataResult: []
            })
            var Arr = this.props.selectedValue;
            console.log(Arr)
            for (let i = 0; i < Arr.length; i++) {
                var uni = dataArr.findIndex(item => item.value === this.props.selectedValue[i])
                console.log(uni)
                dataArr.splice(uni, 1)
            }
            console.log(dataArr)
            this.setState({
                dataResult: dataArr
            })
        }

        if (this.props.ResetSelected && this.state.dataResult.length != 0 && this.state.checkValue) {
            this.props.callBackResetSelected()
            this.setState({
                dataResult: [],
                checkValue : false
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.dataResult.length != 0 &&
                    <Select
                        options={this.state.dataResult.length != 0 ? this.state.dataResult : []}
                        searchBy="display"
                        labelField="display"
                        valueField="value"
                        values={this.state.dataSearch}
                        dropdownGap={5}
                        required={false}
                        onChange={(value) => this.props.handlechageLegacyID(value[0].value)}
                        loading={this.state.checkValue ? false : true}
                        onDropdownOpen={() => this.forceUpdate()}
                        //styles={{ menuPortal: base => ({ ...base, zIndex: 9999, overflow: 'hidden' }) }}
                    />
                }
            </div>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.legacyReport }), ({ ...LegacyReportStore.actionCreators }))(AutocompleteLegacyID)
