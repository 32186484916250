import * as React from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';

import { ApplicationState } from '../../store';

import * as Stores from '../../store/config/EmployeeMaster';
import * as Models from '../../models/config/Employee';
//import * as StoresRoleAccess from '../../store/RoleAccess';
//import * as ModelsRoleAccess from '../../models/RoleAccess';
import * as StoresCompanyMaster from '../../store/companymaster/CompanyMaster';
import * as ModelsCompanyMaster from '../../models/companymaster/CompanyMaster';

import Pagination from '@material-ui/lab/Pagination';
import { Table } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Row, Col, Form, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import { Spinner } from 'reactstrap';
import { FaPlusCircle, FaEdit, FaTimesCircle, FaUpload } from 'react-icons/fa';
import './../lis.css';

import EmployeeMasterSearchComponent from '../Employee/EmployeeMasterSearchComponent'

type Store = Models.EmployeeState & typeof Stores.actionCreators | ModelsCompanyMaster.CompanyMasterState & typeof StoresCompanyMaster.actionCreators;
//type Store = Models.EmployeeState & typeof Stores.actionCreators | ModelsRoleAccess.RoleAccessState & typeof StoresRoleAccess.actionCreators | ModelsCompanyMaster.CompanyMasterState & typeof StoresCompanyMaster.actionCreators;

class EmployeeMasterComponent extends React.PureComponent<any, { roleAccess: string, userName: string, valueSelectSearch: string, page: number, pageSize: number, pageBtn: any, valueSelectEdit: any, valueRole: any, valueSelectRole: any, valueSelect: any, multiValue: any, version: number, modelEmployee: any, listModelEmployee: any, isOpenModal: boolean, manageMode: string, checkCompanyMaster: boolean }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            version: 0,
            modelEmployee: [],
            listModelEmployee: [],
            isOpenModal: false,
            manageMode: "Select",
            multiValue: [],
            valueSelectEdit: [],
            valueSelect: [],
            valueSelectRole: [
                { value: 'user', label: 'user' },
                { value: 'admin', label: 'admin' }
            ],
            valueRole: [],
            pageBtn: [],
            pageSize: 25,
            page: 1,
            valueSelectSearch: "",
            userName: "",
            roleAccess: "",
            checkCompanyMaster: false
        }

    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.setState({ manageMode: "Select" })
        this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, "", "", "", 1, 25);
        //this.props.requestFunctionRole(true, this.state.version, "GET", { role_access_code: "" });
        this.props.requestFunctionCompanyMaster(true, "GET", "", "");
    }

    toggleModal = () => {
        this.setState({ isOpenModal: !this.state.isOpenModal })
    }

    addClickAndEdit = () => {
        const { valueRole, manageMode } = this.state
        const { valueSelectSearch, userName, roleAccess } = this.state

        var node = ""
        var nameId = ""
        var checkCompany = ""

        if (manageMode == "Add") {

            node = "checkCompany"
            nameId = "userId"

        } else if (manageMode == "Edit") {

            node = "checkCompanyEdit"
            nameId = "userIdEdit"

        }
        var userId = document.getElementById(nameId) as HTMLInputElement;

        Array.from(document.querySelectorAll("input[name=" + node + "]"))
            .forEach((checkbox) => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb.checked) {
                    checkCompany = "value"
                }
            })

        if (userId.value == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง User ID',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            })
        } else if (checkCompany == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณาเลือก Company อย่างน้อย 1 Company',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            })
        } else {

            var employeeList: any = []

            Array.from(document.querySelectorAll("input[name=" + node + "]"))
                .forEach((checkbox) => {
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb.checked) {

                        var key: any = cb.dataset.key
                        var keyInt = parseInt(key)
                        console.log('valueRole')
                        console.log(valueRole)

                        var valueRoleStr: string = ""
                        if (valueRole.length > 0) {
                            for (let i = 0; i < valueRole[keyInt].length; i++) {
                                if (valueRole[keyInt][i] != undefined) {
                                    if (valueRole[keyInt][i] != undefined) {
                                        if (i == 0) {
                                            valueRoleStr += valueRole[keyInt][i].value
                                        } else {
                                            valueRoleStr += "|" + valueRole[keyInt][i].value
                                        }
                                    }
                                }
                            }
                        }

                        let dataset = {
                            sid: cb.dataset.valuecheck,
                            role_access: valueRoleStr,
                            company_code: cb.dataset.company
                        }

                        employeeList.push(dataset)
                    }
                })

            console.log('employeeList')
            console.log(employeeList)

            var data = [{
                id: userId.value,
                list_company: employeeList
            }]

            this.setState({
                page: 1
            })

            if (manageMode == "Add") {
                this.props.requestFunction(this.state.version, "POST", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
            } else if (manageMode == "Edit") {
                this.props.requestFunction(this.state.version, "PUT", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
            }
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    deleteClick = () => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        this.setState({
            page: 1
        })

        this.props.requestFunction(this.state.version, "DELETE", this.state.valueSelect, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    openModalAdd = () => {
        this.setState({
            manageMode: "Add",
            isOpenModal: true
        });
    }

    openModalEdit = (data: Models.EmployeeMain) => {
        var dataset = this.state.valueSelect

        var datasetDetailList: any = []
        var datasetRoleList: any = []

        dataset.map((item, ids) => {

            var status = false
            var role_access = ""

            data.list_company.map((items, idx) => {

                if (items.sid == item.value) {
                    status = true
                    role_access = items.role_access
                }
            })

            var datasetList: any = []
            if (role_access != "") {
                var role_accessCout = role_access.split('|')
                role_accessCout.map((item, id) => {
                    dataset = {
                        value: item,
                        label: item
                    }
                    datasetList.push(dataset)
                })
            }

            let datasetDetail = {
                id: data.id,
                sid: item.value,
                label: item.label,
                role_access: role_access,
                company_code: item.company_code,
                status: status
            }

            datasetDetailList.push(datasetDetail)
            datasetRoleList.push(datasetList)
        })
        this.setState({
            valueSelectEdit: datasetDetailList,
            valueRole: datasetRoleList,
            manageMode: "Edit",
            isOpenModal: true
        });
    }

    openModalDelete = (data: Models.EmployeeMain) => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        Swal.fire({
            title: 'ลบข้อมูล ' + data.id + ' ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.setState({
                    page: 1
                })
                this.props.requestFunction(this.state.version, "DELETE", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
                //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            }
        })
    }

    handleChange = (newValue: any, actionMeta: any, check: string, key: any) => {
        if (check == "Role") {
            var data = this.state.valueRole
            data[key] = new Array(newValue)
            this.setState({ valueRole: data })

        }
    };

    handleCheckBox(key: any, e: any) {
        var checkVisible = document.getElementById('Select' + key) as HTMLInputElement;

        if (e.target.checked == true) {
            checkVisible.style.visibility = ""
        } else {
            checkVisible.style.visibility = "hidden"
        }
    }

    formatSelectCreate(value) {
        var dataset: any
        var datasetList: any = []

        if (value.role_access != "") {
            var role_accessCout = value.role_access.split('|')
            role_accessCout.map((item, id) => {
                dataset = {
                    value: item,
                    label: item
                }
                datasetList.push(dataset)
            })
        }

        return datasetList
    }

    findDataCheckBox(value) {
        var model = this.state.valueSelect.filter(function (item) { return item.value == value });
        if (model[0] != undefined) {
            return model[0]['label'].toString();
        } else {
            return value.toString();
        }
    }

    setBodyModal = () => {
        const { manageMode, valueSelect } = this.state
        if (manageMode == "Add") {
            return <div>
                <Row>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>User Name</span></Col>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>บริษัท</span></Col>
                    <Col xs="6"><span style={{ fontSize: 16, fontWeight: "bold" }}>Role Access</span></Col>
                </Row>
                {valueSelect.length > 0 && valueSelect.map((item, id) => {
                    return (
                        <Row style={{ marginTop: "15px", height: "30px" }} key={id}>
                            <Col xs="3" hidden={id > 0 ? true : false}>
                                <Form>
                                    <Input bsSize="sm" type="text" id="userId" />
                                </Form>
                            </Col>
                            <Col xs={id > 0 ? { size: 3, offset: 3 } : { size: 3 }}>
                                <Form>
                                    <FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="checkCompany" id={id} value={item.value} data-key={id} data-valuecheck={item.value} data-company={item.company_code} onChange={(e) => this.handleCheckBox(id, e)} />{' '}
                                                {item.label}
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs="6">
                                <Form >
                                    <FormGroup id={"Select" + id} style={{ visibility: 'hidden' }} >
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(value, action) => this.handleChange(value, action, "Role", id)}
                                            options={this.state.valueSelectRole}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    )
                }
                )}
            </div>;
        } else if (manageMode == "Edit") {
            return <div>
                <Row >
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>User Name</span></Col>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>บริษัท</span></Col>
                    <Col xs="6"><span style={{ fontSize: 16, fontWeight: "bold" }}>Role Access</span></Col>
                </Row>
                {this.state.valueSelectEdit.length > 0 && this.state.valueSelectEdit.map((item, id) => {
                    return (
                        <Row style={{ marginTop: "15px", height: "30px" }} key={id}>
                            <Col xs="3" hidden={id > 0 ? true : false}>
                                <Form>
                                    <Input bsSize="sm" type="text" id="userIdEdit" value={item.id} defaultValue={manageMode == "Edit" ? valueSelect.id : ""} disabled={manageMode == "Edit" ? true : false} />
                                </Form>
                            </Col>
                            <Col xs={id > 0 ? { size: 3, offset: 3 } : { size: 3 }}>
                                <Form>
                                    <FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="checkCompanyEdit" id={id} data-key={id} defaultChecked={item.status} data-valuecheck={item.sid} data-company={item.company_code} onChange={(e) => this.handleCheckBox(id, e)} />{' '}
                                                {item.label}
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs="6">
                                <Form >
                                    <FormGroup id={"Select" + id} style={{ visibility: item.status == false ? 'hidden' : 'initial' }} >
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            defaultValue={this.formatSelectCreate(item)}
                                            onChange={(value, action) => this.handleChange(value, action, "Role", id)}
                                            options={this.state.valueSelectRole}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    )
                }
                )}
            </div>;
        }
    }

    setButtonModal = () => {
        if (this.state.manageMode == "Add") {
            return <Button color="primary" className="employee-font" onClick={this.addClickAndEdit}>Add</Button>;
        } else if (this.state.manageMode == "Edit") {
            return <Button color="warning" className="employee-font" onClick={this.addClickAndEdit}>Edit</Button>;
        } else if (this.state.manageMode == "Delete") {
            return <Button color="danger" className="employee-font" onClick={this.deleteClick}>Delete</Button>;
        }
    }

    onChangeFileHandler = (event: any) => {

        if (event.target.files.length > 0) {

            if (event.target.files[0].type.indexOf('.sheet') == -1) {
                Swal.fire({
                    title: 'Please select file excel.',
                    icon: 'warning'
                })

            } else {
                var file = event.target.files;

                Swal.fire({
                    title: 'Are you sure to Upload?',
                    text: "File : " + event.target.files[0].name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {

                        this.props.requestFunction(this.state.version, "POST", this.state.modelEmployee, true, file);
                        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                    } else {
                        this.inputFileElement.value = null;
                    }
                })
            }
        }
    }

    private inputFileElement: any;

    afterCallApiSuccess = () => {
        if (this.props.responseEmployee != null && this.props.statusEmployee == 200) {

            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.responseEmployee['total_count'] / this.state.pageSize)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr
            })

            this.setState({
                listModelEmployee: this.props.responseEmployee.result_list != null ? this.props.responseEmployee.result_list as Array<Models.EmployeeMain> : [],
                isOpenModal: false
            });
        }
        else {
            this.setState({
                isOpenModal: false
            });

            if (this.state.manageMode != "Select") {
                this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null);
                this.props.requestFunctionRole(true, this.state.version, "GET", { role_access_code: "" });
                this.props.requestFunctionCompanyMaster(true, "GET", "", "active");
            }
        }

        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    }

    componentDidUpdate() {

        const { response, versionGroup, isLoading, statusEmployee, isLoadingEmployee, responseEmployee, isLoadingCompanyMaster, responseCompanyMaster, statusCompanyMaster } = this.props
        const { valueSelectSearch, userName, roleAccess } = this.state

        if (this.props.isLoadingRole) {
            if (response != undefined) {
                this.state.valueSelectRole.splice(0)
                var DataSelectArray = this.state.valueSelectRole
                for (var i = 0; i < response.total_count; i++) {
                    let data = {}
                    data['value'] = response.result_list[i].role_access_code
                    data['label'] = response.result_list[i].role_access_name
                    DataSelectArray.push(data)
                }
            }
            this.setState({
                valueSelectRole: DataSelectArray
            })
        }

        if (!isLoadingEmployee && statusEmployee == 204) {
            this.setState({
                isOpenModal: false
            });
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: "User Name ซ้ำกับข้อมูลที่มีอยู่",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestFunction(this.state.version, "GET", "", false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
                }
            })
        }

        if (isLoadingCompanyMaster && statusCompanyMaster == 200) {
            if (responseCompanyMaster != undefined) {
                this.state.valueSelect.splice(0)
                var DataSelect = this.state.valueSelect
                for (var i = 0; i < responseCompanyMaster.total_count; i++) {
                    let data = {}
                    data['value'] = responseCompanyMaster.result_list[i].sid
                    data['label'] = responseCompanyMaster.result_list[i].company_name
                    data['company_code'] = responseCompanyMaster.result_list[i].company_code
                    data['status'] = false
                    DataSelect.push(data)
                }
            }
            this.setState({
                valueSelect: DataSelect,
                checkCompanyMaster: true
            })
        }

    }

    haddleChangePage = (pageNo: any, pagesize: any) => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        var pagesizeUse: any
        if (pagesize != "") {
            pagesizeUse = pagesize
        } else {
            pagesizeUse = this.state.pageSize
        }

        this.setState({
            page: pageNo
        })

        this.props.requestFunction(this.state.version, "GET", "", false, null, valueSelectSearch, userName, roleAccess, pageNo, pagesizeUse);
    }

    BeforehandleChangePage = (event, value) => {
        this.haddleChangePage(value, '')
    }

    handlePageSize = (e) => {
        this.setState({ pageSize: e.target.value })
        this.haddleChangePage(this.state.page, e.target.value)
    }

    handleSelectSearch = (value) => {
        this.setState({
            valueSelectSearch: value != null && value != undefined && value != "" ? value['value'] : ""
        })
    }

    handleSearchValue = (userName, roleAccess) => {
        var valueSelectSearch = this.state.valueSelectSearch

        this.setState({
            userName: userName,
            roleAccess: roleAccess
        })

        this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
    }

    render() {
        const { listModelEmployee, valueSelectRole } = this.state
        if (this.props.versionEmployee > this.state.version) {
            if (this.props.versionEmployee - this.state.version == 2) {//get
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version == 3) {//post
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version == 4) {//post upload
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version == 5) {//put
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version == 6) {//delete
                this.afterCallApiSuccess();

            }

            this.setState({ version: this.props.versionEmployee });
        }

        return (
            <div>
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="employee-font">
                            Employee Master
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="btn-add employee-font" style={{ float: 'right', marginLeft: '5px' }} onClick={this.openModalAdd}><FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>
                        {/*&nbsp;
                        <label htmlFor="inputUploadFilEmployee" className="btn-upload-file employee-font" style={{ float: 'right' }}  ><FaUpload style={{ marginBottom: '4px' }} /> Upload Excel</label>
                        <input type="file" name="file" id="inputUploadFilEmployee" onChange={this.onChangeFileHandler} className="inp-upload-file" ref={input => this.inputFileElement = input} />*/}
                    </div>
                </div>
                <div className="mat-box">
                    <Row>
                        <Col>
                            <EmployeeMasterSearchComponent valueSelect={this.state.valueSelect} valueSelectRole={this.state.valueSelectRole} handleSelectSearch={this.handleSelectSearch.bind(this)} handleSearchValue={this.handleSearchValue.bind(this)} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={6}>
                            <div className='d-flex justify-content-start my-2 my-lg-0'>
                                <Form inline>
                                    <Label style={{ fontSize: '14px' }}>
                                        Show&nbsp;&nbsp;
                                    </Label>
                                    <Input
                                        type="select"
                                        name="select"
                                        bsSize="sm"
                                        value={this.state.pageSize}
                                        onChange={this.handlePageSize}
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Input >
                                    <Label style={{ fontSize: '14px' }}>
                                        &nbsp;&nbsp;entries
                                    </Label>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='d-flex justify-content-end my-2 my-lg-0'>
                                <Pagination count={this.state.pageBtn.length != 0 && this.state.pageBtn.length} shape="rounded" defaultPage={1} showFirstButton showLastButton onChange={this.BeforehandleChangePage} page={this.state.page} />
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <Table className="table-bordered table-hover custom-table-list table-responsive-lg" size="sm">
                            <thead >
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light require-field">
                                    <th className="td-menu" style={{ width: '10%', top: -1, position: 'sticky', zIndex: 1 }} >Menu</th>
                                    <th style={{ width: '20%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id" >User Name</th>
                                    <th style={{ width: '30%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id" >บริษัท</th>
                                    <th style={{ width: '20%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id" >Company Code</th>
                                    <th className="td-role-access" style={{ width: '20%', top: -1, position: 'sticky', zIndex: 1 }} >Role Access</th>
                                </tr>
                            </thead>
                            {!this.props.isLoadingEmployee ? <tr><td colSpan={6} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner size="sm" color="dark" />{' '}Loading...</td></tr> : <tr></tr>}
                            {this.props.isLoadingEmployee && this.props.statusEmployee != 200 ? <tr><td colSpan={6} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>No Data.</td></tr> : <tr></tr>}
                            {this.props.isLoadingEmployee && this.props.statusEmployee == 200 && this.state.checkCompanyMaster && this.state.listModelEmployee.map((item: Models.EmployeeMain, id) => {
                                if (item.list_company[0].role_access != "super_admin") {
                                    console.log(this.state.listModelEmployee)
                                    return (
                                        <tbody>
                                            <tr key={item.id}>
                                                <td className="td-menu" style={{ width: '150px' }} rowSpan={item.list_company.length}>
                                                    <Button className="btn-edit-file" size="sm" onClick={() => this.openModalEdit(item)} ><FaEdit style={{ marginBottom: '4px' }} /> Edit</Button>{' '}
                                                    <Button className="btn-delete-file" size="sm" style={{ padding: '3px 5px' }} onClick={() => this.openModalDelete(item)}><FaTimesCircle style={{ marginBottom: '2px' }} /> Delete</Button>
                                                </td>
                                                <td style={{ width: '100px' }} className="td-id" rowSpan={item.list_company.length}>{item.id}</td>
                                                <td style={{ width: '100px' }} className="td-id" >
                                                    {item.list_company.map((items: Models.EmployeeMainList, id_sid) => {
                                                        if (id_sid == 0) {
                                                            return (
                                                                <span >{this.findDataCheckBox(items.sid)}</span>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </td>
                                                <td style={{ width: '100px' }} className="td-id" >
                                                    {item.list_company.map((items: Models.EmployeeMainList, id_sid) => {
                                                        if (id_sid == 0) {
                                                            return (
                                                                <span >{items.company_code}</span>
                                                                //<span >{company_list.company_name}</span>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </td>
                                                <td className="td-role-access" style={{ width: '200px' }}>
                                                    {item.list_company.map((items: Models.EmployeeMainList, id_role) => {
                                                        if (id_role == 0) {
                                                            return (
                                                                <span >{items.role_access}</span>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                item.list_company.map((subItem: Models.EmployeeMainList, subId) => {
                                                    if (subId > 0) {
                                                        return (
                                                            <tr key={"subId" + subId + id}>
                                                                <td>
                                                                    <span >{this.findDataCheckBox(subItem.sid)}</span>
                                                                </td>
                                                                <td>
                                                                    <span >{this.findDataCheckBox(subItem.company_code)}</span>
                                                                </td>
                                                                <td>
                                                                    <span >{this.findDataCheckBox(subItem.role_access)}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    )
                                }
                            })}
                        </Table>
                    </div>
                </div>
                <Modal isOpen={this.state.isOpenModal} className="modal-upload-file" style={{ minWidth: "1500px" }}>
                    <ModalHeader className="employee-font" toggle={this.toggleModal}>{this.state.manageMode + ' Employee'}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModal()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button color="secondary" className="employee-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.employee, ...state.CompanyMaster }), ({ ...Stores.actionCreators, ...StoresCompanyMaster.actionCreators }))(EmployeeMasterComponent);
//export default connect((state: ApplicationState) => ({ ...state.employee, ...state.roleAccess, ...state.companyMaster }), ({ ...Stores.actionCreators, ...StoresRoleAccess.actionCreators, ...StoresCompanyMaster.actionCreators }))(EmployeeMasterComponent);