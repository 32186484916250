import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import PullJobConfigTable from './PullJobConfigTable'
import { FaSearch, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaFolderOpen, FaAngleDown, FaPlusCircle } from "react-icons/fa";

//import 'react-bootstrap-tagsinput/dist/index.css'

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import AutocompleteDmsServerID from '../autocomplete/AutocompleteDmsServerID'
import AutocompleteSftpServer from '../autocomplete/AutocompleteSftpServer'
import AutocompleteApiServerID from '../autocomplete/AutocompleteApiServerID'
import SftpPathFolder from './SftpPathFolder'

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as JobConfigModels from '../../models/config/JobConfig'
import * as JobConfigStore from '../../store/config/JobConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import * as SftpConfigModels from '../../models/config/SftpConfig'
import * as SftpConfigStore from '../../store/config/SftpConfig'
import * as ApiConfigModels from '../../models/config/ApiConfig'
import * as ApiConfigStore from '../../store/config/ApiConfig'
import * as ReSchedulerModels from '../../models/config/ReScheduler'
import * as ReSchedulerStore from '../../store/config/ReScheduler'

import * as TestFappStore from '../../store/testConnect/FappConnection'
import * as TestFappModels from '../../models/testConnect/FappConnection'

import * as SftpTestModels from '../../models/testConnect/SftpConnection'
import * as SftpTestStore from '../../store/testConnect/SftpConnection'
import * as SftpTestSourceStore from '../../store/testConnect/SftpSourceConnection'

import * as JobTestModels from '../../models/testConnect/JobConnection'
import * as JobTestStore from '../../store/testConnect/JobConnection'
import { useAccordionButton } from 'react-bootstrap/cjs/AccordionButton';

import * as lisCommandModels from '../../models/liscommand/liscommand'
import * as lisCommandStore from '../../store/liscommand/liscommand'

import { Script } from 'vm';
import '../NavMenu.css'

import { ReCron } from '@sbzen/re-cron';
import cronstrue from 'cronstrue';
import * as cronValidator from 'cron-expression-validator';


type Store = lisCommandModels.TestSftpSourceState & typeof lisCommandStore.actionCreators | JobTestModels.TestJobState & typeof JobTestStore.actionCreators | SftpTestModels.TestSftpSourceState & typeof SftpTestSourceStore.actionCreators | SftpTestModels.TestSftpState & typeof SftpTestStore.actionCreators | TestFappModels.TestFappState & typeof TestFappStore.actionCreators | JobConfigModels.GetJobConfigState & typeof JobConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators | SftpConfigModels.GetSftpConfigState & typeof SftpConfigStore.actionCreators | ApiConfigModels.GetApiConfigState & typeof ApiConfigStore.actionCreators | ReSchedulerModels.ReSchedulerConfigState & typeof ReSchedulerStore.actionCreators

class PullJobConfig extends React.Component<any, {
    status_sFTPDest: number, status_sFTPSource: number, dataApiEditUserPass: any,
    passAPI: string, usernameAPI: string, status_sFTP_Source: number, dataSftpEditSource: any,
    sftpValueSource: any, dataSftpEdit: any, dataApiEdit: any, status_Job: number, status_sFTP: number,
    status_Fapp: number, check_job: boolean, check_sftp: boolean, sftpValue: any, apiUrl: string,
    check_fapp: boolean, isShowModal: boolean, formData: any, editMode: boolean, key: any, isShowTempModal: boolean,
    dataTemplate: Array<JobConfigModels.JobTemplate>, checkTemplate: boolean, selectedTemplate: any, checkSftp: boolean,
    dataSftp: Array<SftpConfigModels.SftpSelected>, dataApi: Array<ApiConfigModels.ApiSelected>, CheckApi: boolean,
    isShowTestStatusModal: boolean, isShowSftpPathModal: boolean, keyPath: string, searchJobReq: JobConfigModels.SearchJobReq,
    listFolder: any, checkQueryFolder: boolean, valuePath: any, JobConfigPath: any, isShowContriger: boolean, contriger_list: Array<string>, contriger_string: string,
    isShowCronModal: boolean, contrigerCheck: string
}, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            check_fapp: false,
            check_sftp: false,
            check_job: false,
            isShowModal: false,
            isShowTempModal: false,
            isShowTestStatusModal: false,
            status_Fapp: 0,
            status_sFTP: 0,
            status_sFTP_Source: 0,
            status_Job: 0,
            status_sFTPSource: 0,
            status_sFTPDest: 0,
            formData: {
                sid: "555",
                companycode: "",
                legacyid: "",
                legacyname: "",
                legacytype: "PULL_WAIT",
                sftp_source_server_id: "",
                sftp_source_path: "",
                sftp_source_formatfilename: "",
                sftp_source_filetype: "",
                sftp_source_dest_localpath: "",
                sftp_source_isdeletesource: "N",
                api_serverid: "",
                api_classname: "",
                api_function_name: "",
                api_success_path: "",
                api_error_path: "",
                api_result_path: "",
                sftp_return_server_id: "",
                sftp_return_path: "",
                cron_trigger: "",
                isactive: "N",
                sftp_return_formatfilename: "",
                sftp_return_success_path: "",
                sftp_return_error_path: "",
                dms_serverid: "",
                dms_bucket: "",
                dms_boxid: "",
                dms_roleaccess: "",
                dms_group: "",
                dms_groupid: ""
            },
            JobConfigPath: {
                sftp_source_path: "",
                sftp_return_path: ""
            },
            apiUrl: "",
            sftpValue: undefined,
            sftpValueSource: undefined,
            editMode: false,
            key: "",
            dataTemplate: [],
            checkTemplate: true,
            selectedTemplate: {
                label: "",
                sid: "",
                companycode: "",
                legacyid: ""
            },
            dataSftp: [],
            dataSftpEdit: [],
            dataSftpEditSource: [],
            checkSftp: true,
            dataApi: [],
            dataApiEdit: [],
            dataApiEditUserPass: undefined,
            CheckApi: true,
            isShowSftpPathModal: false,
            keyPath: '',
            searchJobReq: {
                sid: '',
                company_code: '',
                legacy_id: '',
                legacy_name: '',
                api_class_name: '',
                api_function_name: ''
            },
            passAPI: '',
            usernameAPI: '',
            listFolder: undefined,
            checkQueryFolder: false,
            valuePath: '',
            isShowContriger: false,
            contriger_list: [],
            contriger_string: '',
            isShowCronModal: false,
            contrigerCheck: ""
        }
        this.callbackReset = this.callbackReset.bind(this)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { GetSftpDest, GetSftpSource, AddJob, SearchJob, EditJob, GetJobTem, GetSftp, GetAPI, isLoadTestJob, statusTestJob, isLoadTestSftpSource, statusTestSftpSource, messageTestSftpSource, isLoadTestSftp, statusTestSftp, messageTestSftp, isLoadTestFapp, statusTestFapp, messageTestFapp, GetSftpPath } = this.props
        if (AddJob.isLoadAddJobConfig && AddJob.statusAddJobConfig == 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true,'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestAddJobConfig(true, "CLEAR", this.state.formData);
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    this.setState({
                        isShowModal: false
                    })
                }
            })
        } else if (AddJob.isLoadAddJobConfig && AddJob.statusAddJobConfig != 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(AddJob.messageAddJobConfig)
            this.props.requestAddJobConfig(true, "CLEAR", this.state.formData);
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    //this.setState({
                    //    isShowModal: false
                    //})
                }
            })
        }

        if (SearchJob.isLoadSearchJobConfig && SearchJob.statusSearchJobConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var dataarr: JobConfigModels.JobConfig = SearchJob.responseSearchJobConfig

            //this.state.dataSftp.map((item, id) => {
            //    if (item.value == dataarr.sftp_return_server_id) {
            //        const data = this.state.dataSftpEdit[id].value.split('|')
            //        var dataSet: any = {
            //            host: data[1],
            //            port: Number(data[2]),
            //            username: data[3],
            //            password: data[4],
            //            private_key: data[5],
            //        }
            //        this.setState({
            //            sftpValue: dataSet
            //        })
            //    }
            //    if (item.value == dataarr.sftp_source_server_id) {
            //        const data = this.state.dataSftpEditSource[id].value.split('|')
            //        var dataSet: any = {
            //            host: data[1],
            //            port: Number(data[2]),
            //            username: data[3],
            //            password: data[4],
            //            private_key: data[5],
            //        }
            //        this.setState({
            //            sftpValueSource: dataSet
            //        })
            //    }
            //})

            //this.state.dataApi.map((item, id) => {
            //    if (item.value == dataarr.api_serverid) {
            //        const data = this.state.dataApiEdit[id].value.split('|')
            //        this.setState({
            //            apiUrl: data[1]
            //        })
            //    }
            //})

            if (dataarr.sid != "" && dataarr.companycode != "") {
                this.props.requestGetSftpConfig(true, "GET", dataarr.sid, dataarr.companycode, "", "")
                this.props.requestGetApiConfig(true, "POST", dataarr.sid, dataarr.companycode, "", "")
            }

            dataarr.legacytype = 'PULL_WAIT'

            var cron_List: Array<string> = []
            if (dataarr.cron_trigger.indexOf("|") > -1) {
                var cronList: any = dataarr.cron_trigger.split("|")
                for (let i = 0; i < cronList.length; i++) {
                    cron_List.push(cronList[i])
                }
            } else {
                cron_List.push(dataarr.cron_trigger)
            }

            this.setState({
                isShowModal: true,
                formData: dataarr,
                contriger_list: cron_List
            })
            this.props.requestSearchJobConfig(true, "CLEAR")

        } else if (SearchJob.isLoadSearchJobConfig && (SearchJob.statusSearchJobConfig == 204 || SearchJob.statusSearchJobConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(SearchJob.messageSearchJobConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        CheckApi: true,
                        checkSftp: true
                    })
                }
            })
        }


        if (EditJob.isLoadEditJobConfig && EditJob.statusEditJobConfig == 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestEditJobConfig(true, "CLEAR");
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        formData: {},
                        editMode: false
                    })
                }
            })
        } else if (EditJob.isLoadEditJobConfig && EditJob.statusEditJobConfig != 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(EditJob.messageEditJobConfig)
            this.props.requestEditJobConfig(true, "CLEAR");
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        formData: {},
                        editMode: false
                    })
                }
            })
        }

        if (GetJobTem.isLoadJobTemConfig && GetJobTem.statusJobTemConfig == 200 && this.state.checkTemplate) {
            this.setState({
                checkTemplate: false
            })
            this.props.UseLoading(false)
            var res: any = GetJobTem.responseJobTemConfig['result_list'];
            var dataArr: any = []
            for (let i = 0; i < res.length; i++) {
                var data: any = {}
                if (res[i].legacytype == 'PULL_WAIT') {
                    data['label'] = res[i].sid + ' , ' + res[i].companycode + ' , ' + res[i].legacyid
                    data['sid'] = res[i].sid
                    data['companycode'] = res[i].companycode
                    data['legacyid'] = res[i].legacyid
                    dataArr.push(data)
                }
            }
            this.props.requestSearchJobConfig(true, "CLEAR", '', '', '')
            this.setState({
                dataTemplate: dataArr
            })
        }

        if (GetSftp.statusSftpConfig == 200 && GetSftp.isLoadSftpConfig && this.state.checkSftp) {
            console.log('Update option SFTP')

            if (GetSftp.responseSftpConfig['result_list'] != null) {
                var resSftp = GetSftp.responseSftpConfig['result_list'];
                var dataArrSftp: any = []
                var dataArrSftpEdit: any = []
                var dataArrSftpEditSource: any = []
                for (let x = 0; x < resSftp.length; x++) {
                    var dataSftp: any = {}
                    dataSftp['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftp['value'] = resSftp[x].sftp_server_id
                    dataArrSftp.push(dataSftp)

                    var dataSftpEdit: any = {}
                    dataSftpEdit['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftpEdit['value'] = resSftp[x].sftp_server_id + '|' + resSftp[x].sftp_ip + '|' + resSftp[x].sftp_port + '|' + resSftp[x].sftp_username + '|' + resSftp[x].sftp_password + '|' + resSftp[x].private_key
                    dataArrSftpEdit.push(dataSftpEdit)

                    var dataSftpEditDest: any = {}
                    dataSftpEditDest['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftpEditDest['value'] = resSftp[x].sftp_server_id + '|' + resSftp[x].sftp_ip + '|' + resSftp[x].sftp_port + '|' + resSftp[x].sftp_username + '|' + resSftp[x].sftp_password + '|' + resSftp[x].private_key
                    dataArrSftpEditSource.push(dataSftpEditDest)
                }
                this.setState({
                    dataSftp: dataArrSftp,
                    dataSftpEdit: dataArrSftpEdit,
                    dataSftpEditSource: dataArrSftpEditSource,
                    checkSftp: false
                })
                var dataarr: JobConfigModels.JobConfig = this.state.formData
                dataArrSftp.map((item, id) => {
                    if (item.value == dataarr.sftp_return_server_id) {
                        const data = dataArrSftpEdit[id].value.split('|')
                        console.log(data)
                        var dataSet: any = {
                            host: data[1],
                            port: Number(data[2]),
                            username: data[3],
                            password: data[4],
                            private_key: data[5] == "null" ? "" : data[5],
                        }
                        console.log(dataSet)
                        this.setState({
                            sftpValue: dataSet
                        })
                    }
                    if (item.value == dataarr.sftp_source_server_id) {
                        const data = dataArrSftpEditSource[id].value.split('|')
                        var dataSet: any = {
                            host: data[1],
                            port: Number(data[2]),
                            username: data[3],
                            password: data[4],
                            private_key: data[5] == "null" ? "" : data[5],
                        }
                        console.log(dataSet)
                        this.setState({
                            sftpValueSource: dataSet
                        })
                    }
                })
            } else {
                this.setState({
                    dataSftp: [{ value: '', label: 'ไม่มีข้อมูล' }]
                })
                this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
            }
            this.forceUpdate()
        }

        if (GetAPI.statusApiConfig == 200 && GetAPI.isLoadApiConfig && this.state.CheckApi) {
            console.log('Update option API')

            if (GetAPI.responseApiConfig['result_list'] != null) {
                var resApi = GetAPI.responseApiConfig['result_list'];
                var dataArrApi: any = []
                var dataArrApiEdit: any = []
                var dataArrApiEditUserPass: any = []
                for (let x = 0; x < resApi.length; x++) {
                    var dataApi: any = {}
                    dataApi['label'] = resApi[x].sid + ' , ' + resApi[x].companycode + ' , ' + resApi[x].api_url_callfunction + ' , ' + resApi[x].api_serverid
                    dataApi['value'] = resApi[x].api_serverid
                    dataArrApi.push(dataApi)

                    var dataApiEdit: any = {}
                    dataApiEdit['label'] = resApi[x].sid + ' , ' + resApi[x].companycode + ' , ' + resApi[x].api_url_callfunction + ' , ' + resApi[x].api_serverid
                    dataApiEdit['value'] = resApi[x].api_serverid + '|' + resApi[x].api_url_callfunction
                    dataArrApiEdit.push(dataApiEdit)

                    var dataApiEditUserPass: any = {}
                    dataApiEditUserPass['label'] = resApi[x].api_username + ',' + resApi[x].api_password
                    dataApiEditUserPass['value'] = resApi[x].api_serverid
                    dataArrApiEditUserPass.push(dataApiEditUserPass)
                }
                this.setState({
                    dataApi: dataArrApi,
                    dataApiEdit: dataArrApiEdit,
                    dataApiEditUserPass: dataArrApiEditUserPass,
                    CheckApi: false
                })
                var dataarr: JobConfigModels.JobConfig = this.state.formData
                dataArrApi.map((item, id) => {
                    if (item.value == dataarr.api_serverid) {
                        const data = dataArrApiEdit[id].value.split('|')
                        this.setState({
                            apiUrl: data[1]
                        })
                        const dataUserPass = dataArrApiEditUserPass[id].label.split(',')
                        this.setState({
                            usernameAPI: dataUserPass[0],
                            passAPI: dataUserPass[1]
                        })
                    }
                })
            } else {
                this.setState({
                    dataApi: [{ value: '', label: 'ไม่มีข้อมูล' }],
                })
                this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
            }
            this.forceUpdate()
        }

        if (isLoadTestFapp && (statusTestFapp == 200 || statusTestFapp != 200) && isLoadTestJob && (statusTestJob == 200 || statusTestJob != 200) && this.state.check_job) {
            this.props.UseLoading(false)
            this.setState({
                check_job: false,
                isShowTestStatusModal: true,
                status_Fapp: statusTestFapp,
                status_sFTP: statusTestSftp,
                status_sFTP_Source: statusTestSftpSource,
                status_Job: statusTestJob,
                status_sFTPSource: GetSftpSource.statusSftpSource,
                status_sFTPDest: GetSftpDest.statusSftpDest
            })
            this.clearValue();
            this.props.requestGetFappTest(true, "CLEAR", "")
            this.props.requestGetSftpTest(true, "CLEAR", "")
            this.props.requestGetSftpSourceTest(true, "CLEAR", "")
            this.props.requestGetJobTest(true, "CLEAR", "")
            this.props.requestGetSftpSource(true, "CLEAR", "")
            this.props.requestGetSftpDest(true, "CLEAR", "")
        }

        if (GetSftpPath.isLoadGetSftpPath && GetSftpPath.statusGetSftpPath == 200 && !this.state.isShowSftpPathModal) {

            var listObjFolder: any = []
            if (this.state.listFolder == undefined) {
                GetSftpPath.responseGetSftpPath.map((item, id) => {
                    var dataset = {
                        name: item.name,
                        node_level: item.node_level,
                        parent: item.parent,
                        path: item.path,
                        listNode: []
                    }
                    listObjFolder.push(dataset)
                })
            }

            this.setState({
                listFolder: listObjFolder,
                isShowSftpPathModal: true
            })
        }

        if (GetSftpPath.isLoadGetSftpPath && GetSftpPath.statusGetSftpPath == 200 && this.state.listFolder != undefined && this.state.checkQueryFolder) {

            var listObjSubFolder: any = []
            GetSftpPath.responseGetSftpPath.map((item, id) => {
                var dataset = {
                    name: item.name,
                    node_level: item.node_level,
                    parent: item.parent,
                    path: item.path,
                    listNode: []
                }
                listObjSubFolder.push(dataset)
            })

            this.filterPathOneTime(this.state.listFolder, this.state.valuePath, "", listObjSubFolder)
        }
    }

    filterPathOneTime(data, key, checkloop, valueInListNode) {

        var checkloopUse = checkloop

        if (checkloopUse == "") {
            for (let j = 0; j < data.length; j++) {
                if (data[j].path == key) {
                    data[j].listNode = valueInListNode
                    break
                }
            }
            checkloopUse = "Loop"
        }

        if (checkloopUse == "Loop") {
            for (let i = 0; i < data.length; i++) {
                this.filterPathOneTime(data[i].listNode, key, "Loop", valueInListNode);
                if (data[i].path == key) {
                    data[i].listNode = valueInListNode
                    break
                }
            }
        }
        this.setState({
            listFolder: data,
            checkQueryFolder: false
        })
        this.props.UseLoading(false)
    }

    clearValue = () => {
        this.setState({
            //isShowModal: false,
            //formData: {
            //    sid: "",
            //    companycode: "",
            //    legacyid: "",
            //    legacyname: "",
            //    legacytype: "IN",
            //    sftp_source_server_id: "",
            //    sftp_source_path: "",
            //    sftp_source_formatfilename: "",
            //    sftp_source_filetype: "",
            //    sftp_source_dest_localpath: "",
            //    sftp_source_isdeletesource: "N",
            //    api_serverid: "",
            //    api_classname: "",
            //    api_function_name: "",
            //    api_success_path: "",
            //    api_error_path: "",
            //    api_result_path: "",
            //    sftp_return_server_id: "",
            //    sftp_return_path: "",
            //    cron_trigger: "",
            //    isactive: "N",
            //    sftp_return_formatfilename: "",
            //    sftp_return_success_path: "",
            //    sftp_return_error_path: "",
            //},
            dataSftp: [],
            dataSftpEdit: [],
            dataSftpEditSource: [],
            apiUrl: "",
            sftpValue: undefined,
            sftpValueSource: undefined,
            checkSftp: true,
            dataApi: [],
            CheckApi: true,
            contriger_list: [],
            contriger_string: ""
        })
    }

    handleClose = () => {
        if (this.state.isShowModal == false) {
            //this.props.requestGetSftpConfig(true, "GET", "", "", "", "")
            //this.props.requestGetApiConfig(true, "POST", "", "", "", "")
            this.setState({
                isShowModal: true,
                CheckApi: true,
                checkSftp: true,
                editMode: false
            })
        } else {
            this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
            this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
            this.setState({
                isShowModal: false,
                formData: {
                    sid: "",
                    companycode: "",
                    legacyid: "",
                    legacyname: "",
                    legacytype: "PULL_WAIT",
                    sftp_source_server_id: "",
                    sftp_source_path: "",
                    sftp_source_formatfilename: "",
                    sftp_source_filetype: "",
                    sftp_source_dest_localpath: "",
                    sftp_source_isdeletesource: "N",
                    api_serverid: "",
                    api_classname: "",
                    api_function_name: "",
                    api_success_path: "",
                    api_error_path: "",
                    api_result_path: "",
                    sftp_return_server_id: "",
                    sftp_return_path: "",
                    cron_trigger: "",
                    isactive: "N",
                    sftp_return_formatfilename: "",
                    sftp_return_success_path: "",
                    sftp_return_error_path: "",
                    dms_serverid: "",
                    dms_bucket: "",
                    dms_boxid: "",
                    dms_roleaccess: "",
                    dms_group: "",
                    dms_groupid: ""
                },
                dataSftp: [],
                dataSftpEdit: [],
                dataSftpEditSource: [],
                dataApiEditUserPass: undefined,
                apiUrl: "",
                sftpValue: undefined,
                sftpValueSource: undefined,
                checkSftp: true,
                dataApi: [],
                CheckApi: true,
                contriger_list: [],
                contriger_string: ""
            })
        }
    }

    handleChangeInput = (e: any, key: any, checkConnection: any) => {
        var data: JobConfigModels.JobConfig = this.state.formData
        data[key] = e.target.value
        this.setState({
            formData: data
        })
        //if (checkConnection == "API") {
        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataApiEdit[keyUse]
        //    let data = dataUse.value.split('|')
        //    this.setState({
        //        apiUrl: data[1],
        //    })

        //} else if (checkConnection == "sFTP_Dest") {

        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataSftpEdit[keyUse]

        //    let data = dataUse.value.split('|')
        //    var dataSet: any = {
        //        host: data[1],
        //        port: Number(data[2]),
        //        username: data[3],
        //        password: data[4],
        //        private_key: data[5],
        //    }
        //    this.setState({
        //        sftpValue: dataSet
        //    })
        //} else if (checkConnection == "sFTP_Source") {

        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataSftpEditSource[keyUse]

        //    let data = dataUse.value.split('|')
        //    var dataSet: any = {
        //        host: data[1],
        //        port: Number(data[2]),
        //        username: data[3],
        //        password: data[4],
        //        private_key: data[5],
        //    }
        //    this.setState({
        //        sftpValueSource: dataSet
        //    })
        //}
        if (key == "sid" || key == "companycode") {
            this.setState({
                CheckApi: true,
                checkSftp: true
            })
        }
    }

    handleSave = () => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        // save
        var conTrigger: string = ""
        for (let i = 0; i < this.state.contriger_list.length; i++) {
            if (conTrigger == "") {
                conTrigger = this.state.contriger_list[i]
            } else {
                conTrigger += "|"
                conTrigger += this.state.contriger_list[i]
            }
        }
        console.log(conTrigger)
        this.state.formData.cron_trigger = conTrigger
        console.log(this.state.formData)
        if (!this.state.editMode) {
            this.props.requestAddJobConfig(true, "POST", this.state.formData);
        }
        else {
            this.props.requestEditJobConfig(true, "PUT", this.state.formData);
        }
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    public handleEdit = (sid: string, companycode: string, legacyid: string) => {
        this.props.UseLoading(true)
        //this.props.requestGetSftpConfig(true, "GET", "", "", "", "")
        //this.props.requestGetApiConfig(true, "POST", "", "", "", "")
        this.setState({
            editMode: true,
            CheckApi: true,
            checkSftp: true
        })

        setTimeout(() => {
            this.props.requestSearchJobConfig(true, "GET", sid, companycode, legacyid)
        }, 100);
    }

    Reset = () => {
        console.log('Reset function')
        this.props.requestAddJobConfig(true, "CLEAR", []);
        this.setState({
            key: 'Reset'
        })
    }

    onchangeSwitch = (value: any, key: any) => {
        var data: JobConfigModels.JobConfig = this.state.formData
        if (value == true) {
            data[key] = "Y"
        } else {
            data[key] = "N"
        }
        this.setState({
            formData: data
        })
    }

    handleCloseTemModal = () => {
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true
            })
            this.props.requestGetJobTemConfig(true, "POST")
        } else {
            this.props.requestSearchJobConfig(true, "CLEAR", '', '', '')
            this.setState({
                isShowTempModal: false,
                dataTemplate: [],
                checkTemplate: true,
                selectedTemplate: {
                    label: "",
                    sid: "",
                    companycode: "",
                    legacyid: ""
                }
            })
        }
    }

    handleCloseTestModal = () => {
        if (this.state.isShowTestStatusModal == false) {
            this.setState({
                isShowTestStatusModal: true
            })
        } else {
            this.setState({
                isShowTestStatusModal: false,
            })

        }
    }

    handleClickTemplate = () => {
        const { selectedTemplate } = this.state
        this.setState({
            isShowTempModal: false,
            editMode: false,
        })
        this.props.UseLoading(true)
        this.props.requestSearchJobConfig(true, "GET", selectedTemplate['sid'], selectedTemplate['companycode'], selectedTemplate['legacyid'])
    }

    handleSelectTemplate = (e: any) => {
        var value = e.target.value.split(' , ')
        var dataArr: any = {}
        dataArr['label'] = e.target.value
        dataArr['sid'] = value[0]
        dataArr['companycode'] = value[1]
        dataArr['legacyid'] = value[2]
        this.setState({
            selectedTemplate: dataArr
        })
    }

    handleTestConnect = () => {
        if (this.state.apiUrl == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง API Server ID',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        //else if (this.state.sftpValue == undefined) {
        //    Swal.fire({
        //        title: 'ไม่สำเร็จ',
        //        text: 'กรุณากรองข้อมูลในช่อง sFTP Return Server ',
        //        icon: 'error',
        //        showCancelButton: false,
        //        confirmButtonColor: '#3085d6',
        //        confirmButtonText: 'ตกลง',
        //    })
        //} else if (this.state.sftpValueSource == undefined) {
        //    Swal.fire({
        //        title: 'ไม่สำเร็จ',
        //        text: 'กรุณากรองข้อมูลในช่อง sFTP Source  Server ',
        //        icon: 'error',
        //        showCancelButton: false,
        //        confirmButtonColor: '#3085d6',
        //        confirmButtonText: 'ตกลง',
        //    })
        //}
        //else if (this.state.formData.sftp_source_path == "") {
        //    Swal.fire({
        //        title: 'ไม่สำเร็จ',
        //        text: 'กรุณากรองข้อมูลในช่อง sFTP Source Path ',
        //        icon: 'error',
        //        showCancelButton: false,
        //        confirmButtonColor: '#3085d6',
        //        confirmButtonText: 'ตกลง',
        //    })
        //}
        //else if (this.state.formData.sftp_return_path == "") {
        //    Swal.fire({
        //        title: 'ไม่สำเร็จ',
        //        text: 'กรุณากรองข้อมูลในช่อง sFTP Return Path ',
        //        icon: 'error',
        //        showCancelButton: false,
        //        confirmButtonColor: '#3085d6',
        //        confirmButtonText: 'ตกลง',
        //    })
        //}
        else {
            Swal.fire({
                title: 'Test Connection ?',
                text: 'คุณต้องการทดสอบหรือไม่ ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่'
            }).then((result) => {
                if (result.value) {
                    this.props.UseLoading(true)
                    this.setState({
                        check_job: true,
                        //isShowModal: false
                    })

                    var dataset = {
                        api_url: this.state.apiUrl,
                        class_name: this.state.formData.api_classname,
                        function_name: this.state.formData.api_function_name,
                        sid: this.state.formData.sid,
                        username: this.state.usernameAPI,
                        password: this.state.passAPI
                    }

                    //var dataReturn = JSON.parse(JSON.stringify(this.state.sftpValue))
                    //var dataSource = JSON.parse(JSON.stringify(this.state.sftpValueSource))

                    //console.log(dataReturn)
                    //console.log(dataSource)

                    //var datasetSftpSource = {
                    //    path: this.state.formData.sftp_source_path,
                    //    host: dataSource['host'],
                    //    port: dataSource['port'] != "" ? parseInt(dataSource['port']) : 0,
                    //    username: dataSource['username'],
                    //    password: dataSource['password'],
                    //    private_key: dataSource['private_key'] === "null" ? '' : dataSource['private_key'],
                    //}

                    //var datasetSftpDest = {
                    //    path: this.state.formData.sftp_return_path,
                    //    host: dataReturn['host'],
                    //    port: dataReturn['port'] != "" ? parseInt(dataReturn['port']) : 0,
                    //    username: dataReturn['username'],
                    //    password: dataReturn['password'],
                    //    private_key: dataReturn['private_key'] === "null" ? '' : dataReturn['private_key']
                    //}

                    this.props.requestGetFappTest(true, "GET", this.state.apiUrl);
                    //this.props.requestGetSftpTest(true, "POST", this.state.sftpValue);
                    this.props.requestGetJobTest(true, "POST", dataset);
                    //this.props.requestGetSftpSourceTest(true, "POST", this.state.sftpValueSource);
                    //this.props.requestGetSftpSource(true, "POST", datasetSftpSource);
                    //this.props.requestGetSftpDest(true, "POST", datasetSftpDest);
                }
            })
        }
    }

    handleClickSelected = async () => {
        this.setState({
            CheckApi: true,
            checkSftp: true
        })
        //this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
        //this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
        //this.setState(prevState => ({ dataSftp: [], dataApi: [] }))
        //setTimeout(function () {

        if (this.state.formData['sid'] != "" && this.state.formData['companycode']) {
            this.props.requestGetSftpConfig(true, "GET", this.state.formData['sid'], this.state.formData['companycode'], "", "")
            this.props.requestGetApiConfig(true, "POST", this.state.formData['sid'], this.state.formData['companycode'], "", "")
        } else {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'กรุณากรอกข้อมูล SID และ Company Code',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        //}, 3000);
    }

    clearData = () => {
        this.setState(prevState => ({ dataSftp: [], dataApi: [] }))
    }

    handlechageAutoComplete = (value: string, key: string, label: string) => {
        var data: JobConfigModels.JobConfig = this.state.formData
        data[key] = value
        this.setState({
            formData: data
        })

        if (key != "") {
            if (key == "sftp_source_server_id") {
                let data = label.split('|')
                var dataSet: any = {
                    host: data[1],
                    port: Number(data[2]),
                    username: data[3],
                    password: data[4],
                    private_key: data[5],
                }
                this.setState({
                    sftpValueSource: dataSet
                })
            } else if (key == "sftp_return_server_id") {
                let data = label.split('|')
                var dataSet: any = {
                    host: data[1],
                    port: Number(data[2]),
                    username: data[3],
                    password: data[4],
                    private_key: data[5],
                }
                this.setState({
                    sftpValue: dataSet
                })
            } else if (key == "api_serverid") {
                let data = label.split('|')
                this.setState({
                    apiUrl: data[1],
                })

                if (this.state.dataApiEditUserPass != undefined) {

                    var listUserAndPass = this.state.dataApiEditUserPass.filter(function (item) {
                        return item.value == value;
                    });

                    if (listUserAndPass.length > 0) {
                        let userAndPass = listUserAndPass[0]['label'].split(',')

                        this.setState({
                            usernameAPI: userAndPass[0],
                            passAPI: userAndPass[1]
                        })
                    }

                }
            }
        }
    }

    handleGetSftpReturnPath = (key: string) => {
        if (this.state.sftpValue != undefined) {
            var dataReq = this.state.sftpValue

            var formData = {
                path: "/",
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }
            this.setState({
                keyPath: key
            })

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Return Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleGetSftpPath = (key: string) => {
        if (this.state.sftpValueSource != undefined) {
            var dataReq = this.state.sftpValueSource

            var formData = {
                path: "/",
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }
            this.setState({
                keyPath: key
            })

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Source Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    selectFolder = (path: string) => {
        if (this.state.sftpValueSource != undefined) {
            var dataReq = this.state.sftpValueSource
            this.props.UseLoading(true)
            this.setState({
                valuePath: path,
                checkQueryFolder: true
            })

            var formData = {
                path: path,
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Source Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleClosePathModal = () => {
        this.setState({ isShowSftpPathModal: false, listFolder: undefined, checkQueryFolder: false })
        this.props.requestGetSftpPath(true, 'CLEAR', '')
    }


    handleCloseCronModal = () => {
        this.setState({ isShowCronModal: false })
    }

    handleShowCronModal = () => {
        this.setState({ isShowCronModal: true })
    }

    handleSelectedPath = (key: string, value: string) => {

        var data: JobConfigModels.JobConfigPath = this.state.JobConfigPath
        data[key] = value
        this.setState({
            JobConfigPath: data
        })
    }

    handleSaveFolder = () => {
        var data: JobConfigModels.JobConfig = this.state.formData
        if (this.state.keyPath != "") {
            data[this.state.keyPath] = this.state.JobConfigPath[this.state.keyPath]
            this.setState({
                formData: data
            })
        }
        this.setState({ isShowSftpPathModal: false, listFolder: undefined, checkQueryFolder: false })
        this.props.requestGetSftpPath(true, 'CLEAR', '')
    }

    handleChangeSearchInput = (e: any, key: string) => {
        if (key != "") {
            var data = this.state.searchJobReq
            data[key] = e.target.value
            this.setState({
                searchJobReq: data
            })
        }
    }

    handleOpenContriger = () => {
        if (this.state.isShowContriger) {
            this.setState({
                isShowContriger: false
            })
        } else {
            this.setState({
                isShowContriger: true
            })
        }
    }

    handleChangeContrigger = (e: any) => {
        console.log(e.target.value)
        this.setState({
            contriger_string: e.target.value
        })
    }

    handleAddContrigger = () => {
        if (this.state.contriger_string != "") {
            var data: any = this.state.contriger_list
            data.push(this.state.contriger_string)
            this.setState({
                contriger_list: data,
                contriger_string: "",
                isShowCronModal: false
            })
            console.log(this.state.contriger_list)
        }
    }

    handleDeleteContrig = (id) => {
        console.log(id)
        var data: any = this.state.contriger_list
        data.splice(id, 1);
        this.setState({
            contriger_list: data
        })
        console.log(this.state.contriger_list)
    }

    private handleChangeCron = (cronValue: string) => {
        this.setState({ contriger_string: cronValue });
    }

    handleChangeContriggerCheck = (e: any) => {
        console.log(e.target.value)
        this.setState({
            contrigerCheck: e.target.value
        })
    }

    handleValidatorContrigger = () => {
        const { contrigerCheck } = this.state
        //console.log(contrigerCheck)
        if (cronValidator.isValidCronExpression(contrigerCheck)) {
            //console.log('true')
            this.setState({ contriger_string: contrigerCheck })
        }
    }

    render() {
        const { listFolder, formData } = this.state
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Pull Job Config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                {/* <Accordion defaultActiveKey="1">
                                                    <Accordion.Toggle className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }} eventKey="0" as='a'>*/}
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    Search menu
                                                    {/* <div style={{ display: 'inline-block', float: 'right' }}>
                                                                <FaAngleDown />
                                                            </div>*/}
                                                </span>
                                                {/*  </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">*/}
                                            </div>
                                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                                <Row>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>SID :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'sid')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Company Code :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'company_code')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Legacy ID :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'legacy_id')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Legacy Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'legacy_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Api Class Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'api_class_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Api Function Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'api_function_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={{ span: 2, offset: 10 }}>
                                                        <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                            <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={this.Reset}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ display: 'inline-block', float: 'right' }}>
                                                <Button variant="primary" size="sm" onClick={this.handleCloseTemModal}>
                                                    <FaPlusCircle />
                                                    &nbsp; Create From Template
                                                </Button>
                                                <Button variant="info" size="sm" className="mx-1" onClick={this.handleClose}>
                                                    <FaPlusCircle />
                                                    &nbsp;Add Pull Job Config
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row className="mt-3">
                                    <Col>
                                        <PullJobConfigTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} callbackEdit={this.handleEdit.bind(this)} searchJobReq={this.state.searchJobReq} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/*ADD / Edit Job config*/}
                <Modal show={this.state.isShowModal} onHide={this.handleClose} dialogClassName="custom-modal" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Add Pull Job Config </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                Legacy
                            </span>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                {/*<Col>
                                    <Form.Label className="font-weight-bold">SID :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="sid" aria-describedby="basic-addon3" value={this.state.formData['sid']} onChange={(e) => this.handleChangeInput(e, "sid", "")} />
                                    </InputGroup>
                                </Col>*/}
                                <Col>
                                    <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="companycode" aria-describedby="basic-addon3" value={this.state.formData['companycode']} onChange={(e) => this.handleChangeInput(e, "companycode", "")} disabled={this.state.editMode} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="legacyid" aria-describedby="basic-addon3" value={this.state.formData['legacyid']} onChange={(e) => this.handleChangeInput(e, "legacyid", "")} disabled={this.state.editMode} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="legacyname" aria-describedby="basic-addon3" value={this.state.formData['legacyname']} onChange={(e) => this.handleChangeInput(e, "legacyname", "")} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">Legacy Type :</Form.Label>
                                    <div key='inline-radio' className="mt-2">
                                        <Form.Check inline label="PULL JOB" name="LegacyType" type='radio' id='LegacyTypeIn' value="PULL_WAIT" defaultChecked={this.state.formData['legacytype'] == "PULL_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2}>
                                    {/*<Form.Label className="font-weight-bold">Is Active :</Form.Label>
                                    <InputGroup className="mb-3">
                                    <FormControl id="isactive" aria-describedby="basic-addon3" value={this.state.formData['isactive']} onChange={(e) => this.handleChangeInput(e, "isactive")} />
                                </InputGroup>*/}
                                    <InputGroup className="mb-3 mt-1">
                                        <BootstrapSwitchButton
                                            checked={this.state.formData['isactive'] == "Y"
                                                ? true
                                                : false
                                            }
                                            onlabel='Y'
                                            offlabel='N'
                                            onChange={(checked: boolean) => {
                                                this.onchangeSwitch(checked, 'isactive')
                                            }}
                                            size="sm"
                                            onstyle="info"
                                            offstyle="dark"
                                            width={80}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col lg={4}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Cron trigger :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                {/*<FormControl id="cron_trigger" value={this.state.contriger_string} onChange={(e) => this.handleChangeContrigger(e)} disabled={true} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddContrigger()}>*/}
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleShowCronModal()}>
                                                    <FaPlusCircle />
                                                    &nbsp;Add Crontriger
                                                </Button>
                                                {/*<ReCron />*/}
                                                {/*<div className='input-group'>*/}
                                                {/*    <InputTags values={this.state.formData['cron_trigger']} onTags={(value) => this.handleChangeContrigger(value, 'cron_trigger')} />*/}
                                                {/*</div>*/}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Contriger List :
                                            </Form.Label>
                                            <Card className={this.state.contriger_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.contriger_list.length != 0 && this.state.contriger_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={3}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '1em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteContrig(idx)} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>

                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                API Server
                            </span>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row className="mt-2">
                                <Col>
                                    <Form.Label className="font-weight-bold">API Server ID :</Form.Label>
                                    <AutocompleteApiServerID keyReq='api_serverid' sid={this.state.formData['sid']} companycode={this.state.formData['companycode']} handlechageApiServerID={this.handlechageAutoComplete.bind(this)} valueSearch={this.state.formData['api_serverid']} />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <div className="d-flex justify-content-start">
                                <Button variant="info" onClick={this.handleTestConnect}>
                                    <FaPlayCircle /> Test Connection
                                </Button>
                            </div>
                        </Col>
                        <Button variant="danger" onClick={this.handleClose}>
                            <FaTimesCircle /> Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSave}>
                            <FaCheckCircle /> Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Create From Template*/}
                <Modal show={this.state.isShowTempModal} onHide={this.handleCloseTemModal} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Create From Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Select Pull Job Config  :</Form.Label>
                                    <Form.Control as="select" onChange={(e) => this.handleSelectTemplate(e)}>
                                        <option></option>
                                        {this.state.dataTemplate.length != 0 && this.state.dataTemplate.map((item: JobConfigModels.JobTemplate, idx: any) => {
                                            return (
                                                <option key={idx} value={item.label}>{item.label}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                        <Button variant="primary" onClick={() => this.handleClickTemplate()}>
                            <FaCheckCircle /> Create
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Test Status*/}
                <Modal show={this.state.isShowTestStatusModal} onHide={this.handleCloseTestModal} size="sm" dialogClassName="modal-fitscreen" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal' >
                    <Modal.Header translate closeButton >
                        <Modal.Title>Test Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Row className="text-center">
                            {/*<Col>*/}
                            {/*    <Card>*/}
                            {/*        <Card.Body >*/}
                            {/*            <Form.Group>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <Form.Label>{this.state.status_sFTPSource == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Col className="mt-3">*/}
                            {/*                        <Form.Label>Has sFTP Source Path</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            </Form.Group>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*    <Card>*/}
                            {/*        <Card.Body >*/}
                            {/*            <Form.Group>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <Form.Label>{this.state.status_sFTPDest == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Col className="mt-3">*/}
                            {/*                        <Form.Label>Has sFTP Return Path</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            </Form.Group>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col>
                                <Card>
                                    <Card.Body >
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_Fapp == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>API Server Connection</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/*<Col>*/}
                            {/*    <Card>*/}
                            {/*        <Card.Body>*/}
                            {/*            <Form.Group>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <Form.Label>{this.state.status_sFTP_Source == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Col className="mt-3">*/}
                            {/*                        <Form.Label >sFTP Source Server Connection</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            </Form.Group>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*    <Card>*/}
                            {/*        <Card.Body>*/}
                            {/*            <Form.Group>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <Form.Label>{this.state.status_sFTP == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Col className="mt-3">*/}
                            {/*                        <Form.Label >sFTP Return Server Connection</Form.Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            </Form.Group>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col>
                                <Card>
                                    <Card.Body style={{ height: '175px' }}>
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_Job == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>Has Function</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTestModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isShowSftpPathModal} onHide={this.handleClosePathModal} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Select Path </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SftpPathFolder selectFolder={this.selectFolder.bind(this)} JobConfigPath={this.state.JobConfigPath} listFolder={listFolder} handleSelectedPath={this.handleSelectedPath.bind(this)} keyPath={this.state.keyPath} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.handleSaveFolder}>
                            <FaCheckCircle /> Save
                        </Button>
                        <Button variant="danger" onClick={this.handleClosePathModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isShowCronModal} onHide={this.handleCloseCronModal} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Select Cron trigger </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{
                            backgroundColor: "#ddf4fb", borderColor: "#c6edf9", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px",
                            paddingTop: "10px"
                        }}>
                            <Form.Label >{this.state.contriger_string != "" ? cronstrue.toString(this.state.contriger_string) : ''}</Form.Label>
                        </div>
                        <br />
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">
                                    Cron trigger :
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="cron_trigger" value={this.state.contrigerCheck} onChange={(e) => this.handleChangeContriggerCheck(e)} />
                                    {/*<Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddContrigger()}>*/}
                                    <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleValidatorContrigger()}>
                                        <FaPlusCircle />
                                        &nbsp;Validator
                                    </Button>
                                    {/*<ReCron />*/}
                                    {/*<div className='input-group'>*/}
                                    {/*    <InputTags values={this.state.formData['cron_trigger']} onTags={(value) => this.handleChangeContrigger(value, 'cron_trigger')} />*/}
                                    {/*</div>*/}
                                </InputGroup>
                            </Col>
                        </Row>
                        <ReCron value={this.state.contriger_string}
                            onChange={(e) => this.handleChangeCron(e)}></ReCron>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex flex-row" style={{ width: "75%" }}>
                            <FormControl id="cron_triggerShow" value={this.state.contriger_string} style={{ width: "70%" }} disabled={true} />
                        </div>

                        <Button variant="info" onClick={() => this.handleAddContrigger()}>
                            <FaCheckCircle /> OK
                        </Button>
                        <Button variant="danger" onClick={() => this.handleCloseCronModal()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.JobConfig, ...state.Loading, ...state.SftpConfig, ...state.ApiConfig, ...state.ReSchedule, ...state.TestSftp, ...state.TestSftp, ...state.TestFapp, ...state.TestJob, ...state.TestSftpSource, ...state.lisCommand }), ({ ...JobConfigStore.actionCreators, ...LoadingStore.actionCreators, ...SftpConfigStore.actionCreators, ...ApiConfigStore.actionCreators, ...ReSchedulerStore.actionCreators, ...SftpTestStore.actionCreators, ...TestFappStore.actionCreators, ...JobTestStore.actionCreators, ...SftpTestSourceStore.actionCreators, ...lisCommandStore.actionCreators }))(PullJobConfig)