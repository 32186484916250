import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, ButtonGroup, Table, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaTimesCircle } from "react-icons/fa";
import Swal from 'sweetalert2'
import Pagination from '@material-ui/lab/Pagination';

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as JobConfigModels from '../../models/config/JobConfig'
import * as JobConfigStore from '../../store/config/JobConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import { parseJwt } from '../modules/Common';
type Store = JobConfigModels.GetJobConfigState & typeof JobConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class MasterConfigTable extends React.Component<any, { isready: boolean, userId: any, companyCode: any, checkready: boolean, pageSize: any, pageBtn: any, checkPageSize: boolean, curPage: number, searchJobReq: JobConfigModels.SearchJobReq }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            userId: '',
            companyCode: [],
            checkready: true,
            pageSize: 25,
            pageBtn: ['', '', ''],
            checkPageSize: true,
            curPage: 1,
            searchJobReq: {
                sid: '',
                company_code: '',
                legacy_id: '',
                legacy_name: '',
                api_class_name: '',
                api_function_name: ''
            }
        }
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        const { isready, pageSize } = this.state
        const { checkkey, DeleteJob } = this.props

        var appStorage = localStorage.getItem('LG_APPLICATION')
        if (appStorage) {
            var lis = JSON.parse(appStorage)
            var accessToken = lis.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);
            var userIdUse = ""

            if (extra.username) {
                userIdUse = extra.username
            }
            else if (extra.citizen_id) {
                userIdUse = extra.citizen_id
            }

            fetch("/v1/user?id=" + userIdUse + '&role_access=admin',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        var data = result.result_list
                        if (data != null && data.length > 0) {
                            if (result.result_list[0].list_company[0].company_code == "*") {
                                this.getJobConfig([])
                            } else {
                                this.getJobConfig(result.result_list[0].list_company)
                            }
                        } else {
                            this.props.requestGetJobConfig(true, "NOUSER");
                        }
                    },
                    (error) => {
                    }
                )
        }
    }

    getJobConfig(companyCodeUse) {
        const { isready, pageSize } = this.state
        const { checkkey, DeleteJob } = this.props

        var dataset: any = []

        companyCodeUse.map((item) => {
            dataset.push(item.company_code)
        })

        this.setState({
            companyCode: dataset,
            isready: false
        })
        console.log(dataset)
        if (isready) {
            this.props.requestGetJobConfig(isready, "POST", "", dataset, 1, pageSize, this.state.searchJobReq, "ALL")

        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { checkkey, DeleteJob, GetJob } = this.props
        if (checkkey == "Reset" && checkkey != undefined) {
            console.log(checkkey)
            this.handleSearch()
        }

        if (DeleteJob.isLoadDeleteJobConfig && DeleteJob.statusDeleteJobConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log("statusDeleteJobConfig 200")
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteJobConfig(true, "CLEAR");
                    this.handleSearch()
                }
            })
        } else if (DeleteJob.isLoadDeleteJobConfig && (DeleteJob.statusDeleteJobConfig == 204 || DeleteJob.statusDeleteJobConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(DeleteJob.messageDeleteJobConfig)
            console.log("statusDeleteJobConfig 204")
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteJobConfig(true, "CLEAR");
                    this.handleSearch()
                }
            })
        }

        if (GetJob.statusJobConfig == 200 && GetJob.isLoadJobConfig && GetJob.responseJobConfig['result_list'] != undefined && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetJob.responseJobConfig['total_count'] / this.state.pageSize)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false
            })
        }
    }

    handleDelete = (sid: string, companycode: string, legacyid: string) => {
        this.props.UseLoading(false)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.props.UseLoading(true)
                this.props.requestDeleteJobConfig(true, "DELETE", sid, companycode, legacyid);
            }
        })
    }

    handleSearch() {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        console.log("handleSearch 204")
        const { isready, pageSize } = this.state
        const { checkkey, job_started_on_from, job_started_on_to } = this.props
        if (this.props.searchJobReq.company_code != "") {

            var dataset: any = []

            dataset.push(this.props.searchJobReq.company_code)

            this.props.requestGetJobConfig(true, "POST", "", dataset, 1, pageSize, this.props.searchJobReq, "ALL")
        } else {
            this.props.requestGetJobConfig(true, "POST", "", this.state.companyCode, 1, pageSize, this.props.searchJobReq, "ALL")
        }
        this.props.callbackkey()
        this.setState({
            checkPageSize: true,
            curPage: 1,
            searchJobReq: this.props.searchJobReq
        })
    }

    handleUpdate = (sid: string, companycode: string, legacyid: string) => {
        console.log(sid + '|' + companycode + '|' + legacyid)
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.callbackEdit(sid, companycode, legacyid)
        //this.props.requestSearchSftpConfig(true, "GET", sid, companycode, sftp_server_id)
    }

    handlePageSize(e: any) {
        const { isready, pageSize } = this.state
        var parse = parseInt(e.target.value)
        console.log(parse)
        this.setState({
            checkPageSize: true,
            pageSize: parse,
            isready: true,
            curPage: 1
        });
        if (this.props.searchJobReq.company_code != "") {

            var dataset: any = []

            dataset.push(this.props.searchJobReq.company_code)
            this.props.requestGetJobConfig(true, "POST", "", dataset, 1, parse, this.state.searchJobReq, "ALL")
        } else {
            this.props.requestGetJobConfig(true, "POST", "", this.state.companyCode, 1, parse, this.state.searchJobReq, "ALL")
        }
    }

    handleChangePage = (event: any, page: any) => {
        const { isready, pageSize } = this.state
        console.log(page);
        this.setState({
            checkPageSize: true,
            isready: true,
            curPage: page
        })
        if (this.props.searchJobReq.company_code != "") {

            var dataset: any = []

            dataset.push(this.props.searchJobReq.company_code)
            this.props.requestGetJobConfig(true, "POST", "", dataset, page, pageSize, this.state.searchJobReq, "ALL")
        } else {
            this.props.requestGetJobConfig(true, "POST", "", this.state.companyCode, page, pageSize, this.state.searchJobReq, "ALL")
        }
    }

    render() {
        return (
            <div>
                {/*Pagination*/}
                <Row className='mt-2'>
                    <Col md={6}>
                        <Container>
                            <Form className="d-flex justify-content-end justify-content-md-start align-items-center text-nowrap" style={{ gap: '0 12px' }}>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    style={{ width: 'fit-content', margin: 0 }}
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </Container>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination
                                count={this.state.pageBtn.length > 0
                                    ? this.state.pageBtn.length
                                    : undefined
                                }
                                onChange={this.handleChangePage}
                                page={this.state.curPage}
                                shape="rounded"
                                defaultPage={1}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                    </Col>
                </Row>

                {/*Table*/}
                <Row>
                    <Col>
                        <Table className="table-bordered table-hover rounded custom-table-list table-responsive-lg" size="sm">
                            <thead className="rounded">
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light require-field">
                                    <th style={{ width: '5%' }} className='text-center'></th>
                                    {/*<th style={{ width: '5%' }}>SID</th>*/}
                                    <th style={{ width: '5%' }}>Company Code</th>
                                    <th style={{ width: '10%' }}>Legacy ID</th>
                                    <th style={{ width: '10%' }}>Legacy Name</th>
                                    <th style={{ width: '5%' }}>Legacy Type</th>
                                    <th style={{ width: '10%' }}>sFTP Source Server</th>
                                    <th style={{ width: '10%' }}>API Server ID</th>
                                    <th style={{ width: '25%' }}>API ClassName</th>
                                    <th style={{ width: '10%' }}>API Function Name</th>
                                    <th style={{ width: '5%' }}>Is Active</th>
                                    <th style={{ width: '5%' }}>Cron Trigger</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.props.GetJob.isLoadJobConfig ? <tr><td colSpan={12} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {this.props.GetJob.isLoadJobConfig && this.props.GetJob.statusJobConfig != 200 ? <tr><td colSpan={12} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {this.props.GetJob.statusJobConfig == 200 && this.props.GetJob.isLoadJobConfig && this.props.GetJob.responseJobConfig['result_list'] != null && this.props.GetJob.responseJobConfig['result_list'].map((item: JobConfigModels.JobConfig, idx: any) => {
                                    return (
                                        <tr key={idx}>
                                            <td className='text-center'>
                                                <ButtonGroup>
                                                    <Button variant="info" size="sm" onClick={() => this.handleUpdate("555", item.companycode, item.legacyid)}><FaRegEdit /></Button>
                                                    <Button variant="danger" size="sm" onClick={() => this.handleDelete("555", item.companycode, item.legacyid)}><FaTimesCircle /></Button>
                                                </ButtonGroup>
                                            </td>
                                            {/*<td className='text-center'>{item.sid}</td>*/}
                                            <td className='text-center'>{item.companycode}</td>
                                            <td className='text-overflow'>{item.legacyid}</td>
                                            <td className='text-overflow'>{item.legacyname}</td>
                                            <td className='text-center text-overflow'>{item.legacytype}</td>
                                            <td className='text-overflow'>{item.sftp_source_server_id}</td>
                                            <td className='text-overflow'>{item.api_serverid}</td>
                                            <td className='text-overflow'>{item.api_classname}</td>
                                            <td className='text-overflow'>{item.api_function_name}</td>
                                            <td className="text-center text-overflow">{item.isactive}</td>
                                            <td className="text-center text-overflow">{item.cron_trigger}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.JobConfig, ...state.Loading }), ({ ...JobConfigStore.actionCreators, ...LoadingStore.actionCreators }))(MasterConfigTable)