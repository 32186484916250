import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card } from 'react-bootstrap';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import Checkbox from '@material-ui/core/Checkbox';

import * as SftpConfigModels from '../../models/config/SftpConfig'
import * as SftpConfigStore from '../../store/config/SftpConfig'

type Store = SftpConfigModels.GetSftpConfigState & typeof SftpConfigStore.actionCreators

declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    labelPath: string,
    keyPath: string,
    formData: string
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.primary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                //backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.primary,
            //borderTopRightRadius: theme.spacing(2),
            //borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            //fontWeight: theme.typography.fontWeightMedium,
            fontWeight: 'bold',
            '$expanded > &': {
                //fontWeight: theme.typography.fontWeightRegular,
                fontWeight: 'normal',
            },
            fontFamily: 'sans-serif'
        },
        group: {
            marginLeft: 0,
            borderLeft: "1px dashed #bebebe"
        },
        expanded: {
        },
        selected: {
            "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
                background: theme.palette.primary.main,
                border: "1px solid transparent"
            }
        },
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            fontFamily: 'sans-serif'
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
            height: '35px'
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            fontFamily: 'sans-serif'
        },
    }),
);

class SftpPathFolder extends React.Component<any, {expandFolder :any, detailFolder:any, response: any, isCheckPath: boolean }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            response: [],
            isCheckPath: true,
            detailFolder: undefined,
            expandFolder: []
        }
    }

    filterPathOneTime(data, key, checkloop) {

        var checkloopUse = checkloop

        if (checkloopUse == "") {
            for (let j = 0; j < data.length; j++) {
                if (data[j].listNode.length == 0 && data[j].path == key) {
                    this.props.selectFolder(key)
                    break
                }
            }
            checkloopUse = "Loop"
        }

        if (checkloopUse == "Loop"){
            for (let i = 0; i < data.length; i++) {
                this.filterPathOneTime(data[i].listNode, key, "Loop");
                if (data[i].path == key && data[i].listNode.length == 0) {
                    this.props.selectFolder(key)
                    break
                }
            }
        }
    }

    onSelectFolder = (e: any) => {
        const { listFolder } = this.props

        this.state.expandFolder.push(e.target.dataset.path)

        this.props.handleSelectedPath(this.props.keyPath, e.target.dataset.path)

        this.filterPathOneTime(listFolder, e.target.dataset.path, "")

    }

    render() {
        const { expandFolder } = this.state
        const { GetSftpPath, listFolder } = this.props
        return (
            <div>
                <TreeView
                    className=""
                    defaultExpanded={listFolder != undefined ? expandFolder : []}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    onFocus={this.onSelectFolder}
                >
                    {listFolder != undefined && this.renderNode(listFolder, 0)}
                </TreeView>
            </div>

        )
    }
    renderNode(item: Array<SftpConfigModels.ListFolder>, indexMain: number) {
        return (
            <div >
                {item.map((item: SftpConfigModels.ListFolder, index: number) =>
                    <StyledTreeItem
                        key={index}
                        nodeId={item.path}
                        labelText={item.name}
                        labelIcon={FolderIcon}
                        labelPath={item.path}
                        keyPath={this.props.keyPath}
                        formData={this.props.JobConfigPath[this.props.keyPath]}
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                        style={{ paddingLeft: "10px" }}
                        data-path={item.path}
                        data-name={item.name}
                        data-parent={item.parent}
                    >
                        {item.listNode.length > 0 && this.renderNode(item.listNode, 0)}
                    </StyledTreeItem>
                )}
            </div>
        );
    }
}

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, labelPath, keyPath, formData, ...other } = props;

    return (
        <TreeItem
            onLabelClick={event => {
                event.stopPropagation();
                event.preventDefault();
            }}
            label={
                <div className={classes.labelRoot}>
                    <Checkbox
                        size= 'small'
                        checked={formData == labelPath ? true : false}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <FolderIcon style={{ color: '#267fcc' }} />&nbsp;
                    <Typography variant="body2" className={classes.labelText} style={{ fontSize: "14px" }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit"  >
                        
                    </Typography>
                </div>
            }
            //style={{
            //    '--tree-view-color': color,
            //    '--tree-view-bg-color': bgColor,
            //}}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

export default connect((state: ApplicationState) => ({ ...state.SftpConfig }), ({ ...SftpConfigStore.actionCreators }))(SftpPathFolder)



