import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPlusCircle, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import DmsConfigTable from './DmsConfigTable'
import * as DmsConfigModels from '../../models/config/DmsConfig'
import * as DmsConfigStore from '../../store/config/DmsConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
type Store = DmsConfigModels.GetDmsConfigState & typeof DmsConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class DmsConfig extends React.Component<any, { isShowModal: boolean, reqData: any, sid: string, companycode: string, dms_serverid: string, dms_url: string, dms_token: string, key: string, editMode: boolean, autoRefresh: any, checkAutoRefresh: boolean, AutoRefreshTime: number, checkRequiredInput: boolean }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isShowModal: false,
            reqData: {},
            sid: "555",
            companycode: "",
            dms_serverid: "",
            dms_url: "",
            dms_token: "",
            key: "",
            editMode: false,
            autoRefresh: '',
            checkAutoRefresh: true,
            AutoRefreshTime: 30000,
            checkRequiredInput: false
        }
        this.callbackReset = this.callbackReset.bind(this)
    }

    componentDidMount() {
        //this.autoRefresh()
    }

    componentWillUnmount() {
        //clearInterval(this.state.autoRefresh);
    }

    handleClose = () => {
        console.log(this.state.isShowModal)
        if (this.state.isShowModal == false) {
            this.setState({
                isShowModal: true
            })
        } else {
            this.setState({
                isShowModal: false,
                sid: "555",
                companycode: "",
                dms_serverid: "",
                dms_url: "",
                dms_token: "",
                editMode: false,
                checkRequiredInput: false
            })
        }
    }

    handleChangeInput = (e: any, key: any) => {
        if (key == "sid") {
            this.setState({
                sid: e.target.value
            })
        } else if (key == "companycode") {
            this.setState({
                companycode: e.target.value
            })
        } else if (key == "dms_serverid") {
            this.setState({
                dms_serverid: e.target.value
            })
        } else if (key == "dms_url") {
            this.setState({
                dms_url: e.target.value
            })
        } else {
            this.setState({
                dms_token: e.target.value
            })
        }
    }

    handleSave = () => {
        const { sid, companycode, dms_serverid, dms_url, editMode, dms_token } = this.state
        if (sid != "" && dms_serverid != "") {
            this.props.UseLoading(true)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            var dataSet: any = {
                sid: '555',
                companycode: '2580',
                dms_serverid: dms_serverid,
                dms_url: dms_url,
                dms_token: dms_token
            }

            console.log(dataSet)
            // save
            if (!editMode) {
                this.props.requestAddDmsConfig(true, "POST", dataSet);
            }
            // edit
            else {
                this.props.requestEditDmsConfig(true, "PUT", dataSet);
            }
        } else {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'โปรดกรอก SID , Company Code และ Dms Server ID',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
            })
        }
    }

    Reset() {
        console.log('Refresh Data!')
        this.props.requestAddDmsConfig(true, "CLEAR", []);
        this.setState({
            key: 'Reset'
        })
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    public handleEdit = (sid: string, dms_serverid: string) => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //this.props.requestSearchDmsConfig(true, "GET", sid, companycode, sftp_server_id)
        this.props.requestSearchDmsConfig(true, "GET", sid, dms_serverid)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { AddDms, SearchDms, EditDms, isLoadTestDms, statusTestDms, messageTestDms } = this.props
        if (AddDms.isLoadAddDmsConfig && AddDms.statusAddDmsConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sid: "555",
                        companycode: "",
                        dms_serverid: "",
                        dms_url: "",
                        dms_token: "",
                        editMode: false
                    })
                }
            })
        } else if (AddDms.isLoadAddDmsConfig && AddDms.statusAddDmsConfig == 204) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(AddDms.messageAddDmsConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: true
                    })
                }
            })
        }

        if (SearchDms.isLoadSearchDmsConfig && SearchDms.statusSearchDmsConfig == 200 && SearchDms.responseSearchDmsConfig['result_list'] != null) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var dataArr: DmsConfigModels.DmsConfig = SearchDms.responseSearchDmsConfig['result_list'][0]
            this.setState({
                isShowModal: true,
                editMode: true,
                sid: dataArr.sid,
                //companycode: dataArr.companycode,
                dms_serverid: dataArr.dms_serverid,
                dms_url: dataArr.dms_url,
                dms_token: dataArr.dms_token,
                checkRequiredInput: true
            })
            this.props.requestSearchDmsConfig(true, "CLEAR")

        } else if (SearchDms.isLoadSearchDmsConfig && (SearchDms.statusSearchDmsConfig == 204 || SearchDms.statusSearchDmsConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(SearchDms.messageSearchDmsConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false
                    })
                }
            })
        }

        if (EditDms.isLoadEditDmsConfig && EditDms.statusEditDmsConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestEditDmsConfig(true, "CLEAR");
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sid: "555",
                        companycode: "",
                        dms_serverid: "",
                        dms_url: "",
                        dms_token: "",
                        editMode: false,
                        checkRequiredInput: false
                    })
                }
            })
        } else if (EditDms.isLoadEditDmsConfig && EditDms.statusEditDmsConfig != 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(EditDms.messageEditDmsConfig)
            this.props.requestEditDmsConfig(true, "CLEAR");
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        sid: "555",
                        companycode: "",
                        dms_serverid: "",
                        dms_url: "",
                        dms_token: "",
                        editMode: false
                    })
                }
            })
        }
    }

    onchangeSwitch(checked: boolean) {
        if (!checked) {
            clearInterval(this.state.autoRefresh);
        } else {
            this.autoRefresh()
        }
    }

    autoRefresh() {
        const autoRefresh = setInterval(
            () => this.Reset(),
            this.state.AutoRefreshTime
        );
        this.setState({ autoRefresh: autoRefresh });
    }

    onchangeTimeAutoRefresh = (e: any) => {
        clearInterval(this.state.autoRefresh);
        console.log(e.target.value)
        var sec = Number(e.target.value)
        this.setState({
            AutoRefreshTime: sec
        })
        this.autoRefresh()
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Dms Config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='trapezoid trapezoid-purple' style={{ width: '150px', display: 'inline-block' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        Dms Config
                                                    </span>
                                                </div>
                                                <div style={{ display: 'inline-block', float: 'right' }}>
                                                    <Button variant="info" size="sm" onClick={this.handleClose}>
                                                        <FaPlusCircle />
                                                        &nbsp;Add Dms Config
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <div style={{ display: 'inline-block', float: 'right', paddingRight: '10px' }}>*/}
                                {/*            <div className="form-inline">*/}
                                {/*                <Form.Label>Auto Refresh : </Form.Label>*/}
                                {/*                <div className="mx-2">*/}
                                {/*                    <BootstrapSwitchButton*/}
                                {/*                        checked={this.state.checkAutoRefresh}*/}
                                {/*                        onlabel='ON'*/}
                                {/*                        offlabel='OFF'*/}
                                {/*                        onChange={(checked: boolean) => {*/}
                                {/*                            this.onchangeSwitch(checked)*/}
                                {/*                        }}*/}
                                {/*                        size="sm"*/}
                                {/*                        onstyle="primary"*/}
                                {/*                        offstyle="dark"*/}
                                {/*                        width={60}*/}
                                {/*                    />*/}
                                {/*                </div>*/}
                                {/*                <Form.Control as="select" size="sm" className="mx-1" onChange={this.onchangeTimeAutoRefresh} value={this.state.AutoRefreshTime}>*/}
                                {/*                    <option value={10000}>10 s.</option>*/}
                                {/*                    <option value={20000}>20 s.</option>*/}
                                {/*                    <option value={30000}>30 s.</option>*/}
                                {/*                    <option value={60000}>60 s.</option>*/}
                                {/*                </Form.Control>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <DmsConfigTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} callbackEdit={this.handleEdit.bind(this)} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={this.state.isShowModal} onHide={this.handleClose} dialogClassName="custom-modal" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Add Dms Config</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/*<Col lg={6}>
                                <Form.Label className="font-weight-bold">SID :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl disabled={this.state.checkRequiredInput} className="require-field" id="sid" value={this.state.sid} onChange={(e) => this.handleChangeInput(e, "sid")} />
                                </InputGroup>
                            </Col>*/}
                            <Col lg={12}>
                                <Form.Label className="font-weight-bold">Dms Server ID :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="dms_serverid" value={this.state.dms_serverid} onChange={(e) => this.handleChangeInput(e, "dms_serverid")} autoComplete="new-password" disabled={this.state.editMode} />
                                </InputGroup>
                            </Col>
                            {/*<Col lg={6}>*/}
                            {/*    <Form.Label className="font-weight-bold">Company Code :</Form.Label>*/}
                            {/*    <InputGroup className="mb-3">*/}
                            {/*        <FormControl disabled={this.state.checkRequiredInput} className="require-field" id="companycode" value={this.state.companycode} onChange={(e) => this.handleChangeInput(e, "companycode")} />*/}
                            {/*    </InputGroup>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Label className="font-weight-bold">Dms URL :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="dms_url" type="text" value={this.state.dms_url} onChange={(e) => this.handleChangeInput(e, "dms_url")} autoComplete="new-password" />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">Dms Token :</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl as="textarea" rows={6} id="dms_token" value={this.state.dms_token} onChange={(e) => this.handleChangeInput(e, "dms_token")} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={this.handleClose}>
                                        <FaTimesCircle /> Close
                                    </Button>
                                    <Button variant="primary" className="mx-1" onClick={this.handleSave}>
                                        <FaCheckCircle /> Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.DmsConfig, ...state.Loading }), ({ ...DmsConfigStore.actionCreators, ...LoadingStore.actionCreators }))(DmsConfig)