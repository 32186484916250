import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";
import Swal from 'sweetalert2'
import * as DmsConfigModels from '../../models/config/DmsConfig'
import * as DmsConfigStore from '../../store/config/DmsConfig'

type conditionProps = DmsConfigModels.GetDmsConfigState & typeof DmsConfigStore.actionCreators

export interface inititem {
    value: "",
    label: "",
    display: ""
};

class AutocompleteDmsServerID extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: Array<inititem>, checkSearch: boolean, checkValue: boolean, companycodereq: string, sidreq: string }, conditionProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: [],
            checkSearch: true,
            checkValue: true,
            companycodereq: '',
            sidreq: ''
        }
    }

    componentDidMount() {
        const { sid, companycode } = this.props
        if (sid != "") {
            this.props.requestGetDmsConfig(true, "GET", sid, "", "");
            this.setState({
                sidreq: this.props.sid,
                companycodereq: this.props.companycode,
                checkValue : false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { sid, companycode, GetDms, valueSearch } = this.props
        if (sid != this.state.sidreq) {
            this.props.requestGetDmsConfig(true, "GET", sid, "", "");
            this.setState({
                sidreq: this.props.sid,
                companycodereq: this.props.companycode,
                checkValue: false
            })
        }

        if (GetDms.statusDmsConfig == 200 && GetDms.isLoadDmsConfig && GetDms.responseDmsConfig['result_list'] != undefined && !this.state.checkValue) {
            var dataArr: any = []
            var dataFindArr = ""
            for (let i = 0; i < GetDms.responseDmsConfig['result_list'].length; i++) {
                var dataReq = {}
                dataReq['value'] = GetDms.responseDmsConfig['result_list'][i].dms_serverid
                dataReq['display'] = GetDms.responseDmsConfig['result_list'][i].dms_serverid + ' , ' + GetDms.responseDmsConfig['result_list'][i].dms_url
                dataReq['label'] = GetDms.responseDmsConfig['result_list'][i].dms_serverid + ' , ' + GetDms.responseDmsConfig['result_list'][i].dms_url
                dataArr.push(dataReq)

                if (GetDms.responseDmsConfig['result_list'][i].dms_serverid == valueSearch && valueSearch != "") {
                    dataFindArr = GetDms.responseDmsConfig['result_list'][i].dms_serverid + ' , ' + GetDms.responseDmsConfig['result_list'][i].dms_url
                }
            }
            var dataSearchArr : any = []
            var dataSearch = {}
            dataSearch['value'] = valueSearch
            dataSearch['display'] = dataFindArr
            dataSearch['label'] = dataFindArr
            dataSearchArr.push(dataSearch)

            this.setState({
                dataResult: dataArr,
                checkValue: true,
                dataSearch: dataSearchArr
            })
            console.log(dataSearch)
        }
    }

    render() {
        return (
            <Select
                options={this.state.dataResult}
                searchBy="display"
                labelField="display"
                valueField="value"
                values={this.state.dataSearch}
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageDmsServerID(value[0].value, this.props.keyReq , value[0].label)}
                loading={this.state.checkValue ? false : true}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.DmsConfig }), ({ ...DmsConfigStore.actionCreators }))(AutocompleteDmsServerID)
