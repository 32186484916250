import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import MasterConfigTable from './MasterConfigTable'
import { FaSearch, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaFolderOpen, FaPlusCircle, FaPen, FaRandom, FaFileExport } from "react-icons/fa";
//import { GrDocumentExcel } from 'react-icons/gr';
//import { ReCron } from '@sbzen/re-cron';
//import { InputTags } from 'react-bootstrap-tagsinput'
//import 'react-bootstrap-tagsinput/dist/index.css'

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import AutocompleteDmsServerID from '../autocomplete/AutocompleteDmsServerID'
import AutocompleteSftpServer from '../autocomplete/AutocompleteSftpServer'
import AutocompleteApiServerID from '../autocomplete/AutocompleteApiServerID'
import AutocompleteLegacyID from '../autocomplete/AutocompleteLegacyID'
import SftpPathFolder from './SftpPathFolder'

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as JobConfigModels from '../../models/config/JobConfig'
import * as JobConfigStore from '../../store/config/JobConfig'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
import * as SftpConfigModels from '../../models/config/SftpConfig'
import * as SftpConfigStore from '../../store/config/SftpConfig'
import * as ApiConfigModels from '../../models/config/ApiConfig'
import * as ApiConfigStore from '../../store/config/ApiConfig'
import * as ReSchedulerModels from '../../models/config/ReScheduler'
import * as ReSchedulerStore from '../../store/config/ReScheduler'

import * as TestFappStore from '../../store/testConnect/FappConnection'
import * as TestFappModels from '../../models/testConnect/FappConnection'

import * as SftpTestModels from '../../models/testConnect/SftpConnection'
import * as SftpTestStore from '../../store/testConnect/SftpConnection'
import * as SftpTestSourceStore from '../../store/testConnect/SftpSourceConnection'

import * as JobTestModels from '../../models/testConnect/JobConnection'
import * as JobTestStore from '../../store/testConnect/JobConnection'
import { useAccordionButton } from 'react-bootstrap/cjs/AccordionButton';

import * as lisCommandModels from '../../models/liscommand/liscommand'
import * as lisCommandStore from '../../store/liscommand/liscommand'

import { Script } from 'vm';
import '../NavMenu.css'

import { ReCron } from '@sbzen/re-cron';
import cronstrue from 'cronstrue';
import * as cronValidator from 'cron-expression-validator';
import LoadingScreen from '../../common/components/LoadingScreen';
import * as XLSX from 'xlsx';

type Store = lisCommandModels.TestSftpSourceState & typeof lisCommandStore.actionCreators | JobTestModels.TestJobState & typeof JobTestStore.actionCreators | SftpTestModels.TestSftpSourceState & typeof SftpTestSourceStore.actionCreators | SftpTestModels.TestSftpState & typeof SftpTestStore.actionCreators | TestFappModels.TestFappState & typeof TestFappStore.actionCreators | JobConfigModels.GetJobConfigState & typeof JobConfigStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators | SftpConfigModels.GetSftpConfigState & typeof SftpConfigStore.actionCreators | ApiConfigModels.GetApiConfigState & typeof ApiConfigStore.actionCreators | ReSchedulerModels.ReSchedulerConfigState & typeof ReSchedulerStore.actionCreators

interface State {
    status_sFTPDest: number,
    status_sFTPSource: number,
    dataApiEditUserPass: any,
    passAPI: string,
    usernameAPI: string,
    status_sFTP_Source: number,
    dataSftpEditSource: any,
    sftpValueSource: any,
    dataSftpEdit: any,
    dataApiEdit: any,
    status_Job: number,
    status_sFTP: number,
    status_Fapp: number,
    check_job: boolean,
    check_sftp: boolean,
    sftpValue: any,
    apiUrl: string,
    check_fapp: boolean,
    isShowModal: boolean,
    formData: any,
    editMode: boolean,
    key: any,
    isShowTempModal: boolean,
    dataTemplate: Array<JobConfigModels.JobTemplate>,
    checkTemplate: boolean,
    selectedTemplate: any,
    checkSftp: boolean,
    dataSftp: Array<SftpConfigModels.SftpSelected>,
    dataApi: Array<ApiConfigModels.ApiSelected>,
    CheckApi: boolean,
    isShowTestStatusModal: boolean,
    isShowSftpPathModal: boolean,
    keyPath: string,
    searchJobReq: JobConfigModels.SearchJobReq,
    listFolder: any,
    checkQueryFolder: boolean,
    valuePath: any,
    JobConfigPath: any,
    isShowContriger: boolean,
    contriger_list: Array<string>,
    contriger_string: string
    email_error_to_string: string,
    email_error_to_list: Array<string>,
    email_error_cc_string: string,
    email_error_cc_list: Array<string>,
    email_error_bcc_string: string,
    email_error_bcc_list: Array<string>,
    email_warning_to_string: string,
    email_warning_to_list: Array<string>,
    email_warning_cc_string: string,
    email_warning_cc_list: Array<string>,
    email_warning_bcc_string: string,
    email_warning_bcc_list: Array<string>,
    email_finished_to_string: string,
    email_finished_to_list: Array<string>,
    email_finished_cc_string: string,
    email_finished_cc_list: Array<string>,
    email_finished_bcc_string: string,
    email_finished_bcc_list: Array<string>,
    legacyIDSelect: string,
    legacyID_List: Array<string>,
    noti_when: Array<string>,
    checkLegacySelected: boolean,
    isShowCronModal: boolean,
    contrigerCheck: string,
    showModalValidate: boolean,
    SftpFileNameDraft: string,
    SftpFileNameList: Array<string>,
    SftpFileNameValidate: Array<string>,
    token: string,
    SftpRegexDraft: string,
    email_f1_err_to_string: string,
    email_f1_err_cc_string: string,
    email_f1_err_bcc_string: string,
    email_f1_err_to_list: Array<string>,
    email_f1_err_cc_list: Array<string>,
    email_f1_err_bcc_list: Array<string>,
    email_finished_is_active: SwitchType,
    email_warning_is_active: SwitchType,
    email_error_is_active: SwitchType,
    email_function_error_is_active: SwitchType,
    //openModalExportExcel: boolean,
    isLoadingComponent: boolean,
}

type SwitchType = 'Y' | 'N';

class MasterConfig extends React.Component<any, State, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            token: "",
            check_fapp: false,
            check_sftp: false,
            check_job: false,
            isShowModal: false,
            isShowTempModal: false,
            isShowTestStatusModal: false,
            status_Fapp: 0,
            status_sFTP: 0,
            status_sFTP_Source: 0,
            status_Job: 0,
            status_sFTPSource: 0,
            status_sFTPDest: 0,
            formData: {
                sid: "555",
                companycode: "",
                legacyid: "",
                legacyname: "",
                legacytype: "IN",
                sftp_source_server_id: "",
                sftp_source_path: "",
                sftp_source_formatfilename: "",
                sftp_source_filetype: "",
                sftp_source_dest_localpath: "path_getsftp",
                sftp_source_isdeletesource: "N",
                api_serverid: "",
                api_classname: "",
                api_function_name: "",
                api_success_path: "api_source_success",
                api_error_path: "api_source_error",
                api_result_path: "api_result",
                sftp_return_server_id: "",
                sftp_return_path: "",
                cron_trigger: "",
                isactive: "N",
                sftp_return_formatfilename: "",
                sftp_return_success_path: "return_success",
                sftp_return_error_path: "return_error",
                dms_serverid: "",
                dms_bucket: "",
                dms_boxid: "",
                dms_roleaccess: "",
                dms_group: "",
                dms_groupid: "",
                stopif_step4_error: "N",
                email_error_to: "",
                email_error_cc: "",
                email_error_bcc: "",
                email_warning_to: "",
                email_warning_cc: "",
                email_warning_bcc: "",
                email_finished_to: "",
                email_finished_cc: "",
                email_finished_bcc: "",
                //email_noti_when: "",
                criteria_stopif_mainstatus_warning: "Y",
                legacy_criteria: "",
                f1_function_error_email_to: "",
                f1_function_error_email_cc: "",
                f1_function_error_email_bcc: "",
                important_job: "False",
            },
            JobConfigPath: {
                sftp_source_path: "",
                sftp_return_path: ""
            },
            apiUrl: "",
            sftpValue: undefined,
            sftpValueSource: undefined,
            editMode: false,
            key: "",
            dataTemplate: [],
            checkTemplate: true,
            selectedTemplate: {
                label: "",
                sid: "555",
                companycode: "",
                legacyid: ""
            },
            dataSftp: [],
            dataSftpEdit: [],
            dataSftpEditSource: [],
            checkSftp: true,
            dataApi: [],
            dataApiEdit: [],
            dataApiEditUserPass: undefined,
            CheckApi: true,
            isShowSftpPathModal: false,
            keyPath: '',
            searchJobReq: {
                sid: '555',
                company_code: '',
                legacy_id: '',
                legacy_name: '',
                api_class_name: '',
                api_function_name: ''
            },
            passAPI: '',
            usernameAPI: '',
            listFolder: undefined,
            checkQueryFolder: false,
            valuePath: '',
            isShowContriger: false,
            contriger_list: [],
            contriger_string: "",
            email_error_to_string: "",
            email_error_to_list: [],
            email_error_cc_string: "",
            email_error_cc_list: [],
            email_error_bcc_string: "",
            email_error_bcc_list: [],
            email_warning_to_string: "",
            email_warning_to_list: [],
            email_warning_cc_string: "",
            email_warning_cc_list: [],
            email_warning_bcc_string: "",
            email_warning_bcc_list: [],
            email_finished_to_string: "",
            email_finished_to_list: [],
            email_finished_cc_string: "",
            email_finished_cc_list: [],
            email_finished_bcc_string: "",
            email_finished_bcc_list: [],
            legacyIDSelect: "",
            legacyID_List: [],
            noti_when: [],
            checkLegacySelected: false,
            isShowCronModal: false,
            contrigerCheck: "",
            showModalValidate: false,
            SftpFileNameDraft: "",
            SftpRegexDraft: "",
            SftpFileNameList: [],
            SftpFileNameValidate: [],
            email_f1_err_to_string: "",
            email_f1_err_cc_string: "",
            email_f1_err_bcc_string: "",
            email_f1_err_to_list: [],
            email_f1_err_cc_list: [],
            email_f1_err_bcc_list: [],
            email_finished_is_active: 'N',
            email_warning_is_active: 'N',
            email_error_is_active: 'N',
            email_function_error_is_active: 'N',
            //openModalExportExcel: false,
            isLoadingComponent: false,
        }
        this.callbackReset = this.callbackReset.bind(this)
        this.convertNotiWhen = this.convertNotiWhen.bind(this)
        this.toggleLoadingScreen = this.toggleLoadingScreen.bind(this);
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("LG_APPLICATION");
        this.setState({
            token: ls
        })
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { GetSftpDest, GetSftpSource, AddJob, SearchJob, EditJob, GetJobTem, GetSftp, GetAPI, isLoadTestJob, statusTestJob, isLoadTestSftpSource, statusTestSftpSource, messageTestSftpSource, isLoadTestSftp, statusTestSftp, messageTestSftp, isLoadTestFapp, statusTestFapp, messageTestFapp, GetSftpPath } = this.props
        if (AddJob.isLoadAddJobConfig && AddJob.statusAddJobConfig == 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true,'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    var dataSet: any = {
                        sid: this.state.formData.sid,
                        company_code: this.state.formData.companycode,
                        legacy_id: this.state.formData.legacyid
                    }
                    this.props.requestGetReScheduler(true, 'POST', dataSet)
                    this.Reset()
                    this.setState({
                        isShowModal: false
                    })
                }
            })

            this.props.requestAddJobConfig(true, "CLEAR", this.state.formData);
        } else if (AddJob.isLoadAddJobConfig && AddJob.statusAddJobConfig != 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            //console.log("[Sakaz Debug] ", AddJob.messageAddJobConfig);
            const msg = JSON.parse(AddJob.messageAddJobConfig);

            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    //this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    //this.setState({
                    //    isShowModal: false
                    //})
                }
            })

            this.props.requestAddJobConfig(true, "CLEAR", this.state.formData);
        }

        if (SearchJob.isLoadSearchJobConfig && SearchJob.statusSearchJobConfig == 200) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var dataarr: JobConfigModels.JobConfig = SearchJob.responseSearchJobConfig

            //console.log("[Sakaz Debug] response job config =", dataarr);

            //this.state.dataSftp.map((item, id) => {
            //    if (item.value == dataarr.sftp_return_server_id) {
            //        const data = this.state.dataSftpEdit[id].value.split('|')
            //        var dataSet: any = {
            //            host: data[1],
            //            port: Number(data[2]),
            //            username: data[3],
            //            password: data[4],
            //            private_key: data[5],
            //        }
            //        this.setState({
            //            sftpValue: dataSet
            //        })
            //    }
            //    if (item.value == dataarr.sftp_source_server_id) {
            //        const data = this.state.dataSftpEditSource[id].value.split('|')
            //        var dataSet: any = {
            //            host: data[1],
            //            port: Number(data[2]),
            //            username: data[3],
            //            password: data[4],
            //            private_key: data[5],
            //        }
            //        this.setState({
            //            sftpValueSource: dataSet
            //        })
            //    }
            //})

            //this.state.dataApi.map((item, id) => {
            //    if (item.value == dataarr.api_serverid) {
            //        const data = this.state.dataApiEdit[id].value.split('|')
            //        this.setState({
            //            apiUrl: data[1]
            //        })
            //    }
            //})

            if (dataarr.sid != "" && dataarr.companycode != "") {
                this.props.requestGetSftpConfig(true, "GET", dataarr.sid, dataarr.companycode, "", "")
                this.props.requestGetApiConfig(true, "POST", dataarr.sid, dataarr.companycode, "", "")
            }

            var cron_List: Array<string> = []
            if (dataarr.cron_trigger != null && dataarr.cron_trigger != "") {
                if (dataarr.cron_trigger.indexOf("|") > -1) {
                    var cronList: any = dataarr.cron_trigger.split("|")
                    for (let i = 0; i < cronList.length; i++) {
                        cron_List.push(cronList[i])
                    }
                } else {
                    cron_List.push(dataarr.cron_trigger)
                }
            }

            var emailFinishTo_List: Array<string> = []
            if (dataarr.email_finished_to != null && dataarr.email_finished_to != "") {
                console.log(dataarr.email_finished_to.indexOf("|"))
                if (dataarr.email_finished_to.indexOf("|") > -1) {
                    var emailToList: any = dataarr.email_finished_to.split("|")
                    for (let i = 0; i < emailToList.length; i++) {
                        emailFinishTo_List.push(emailToList[i])
                    }
                } else {
                    emailFinishTo_List.push(dataarr.email_finished_to)
                }
            }

            var emailFinishCc_List: Array<string> = []
            if (dataarr.email_finished_cc != null && dataarr.email_finished_cc != "") {
                if (dataarr.email_finished_cc.indexOf("|") > -1) {
                    var emailCcList: any = dataarr.email_finished_cc.split("|")
                    for (let i = 0; i < emailCcList.length; i++) {
                        emailFinishCc_List.push(emailCcList[i])
                    }
                } else {
                    emailFinishCc_List.push(dataarr.email_finished_cc)
                }
            }

            var emailFinishBcc_List: Array<string> = []
            if (dataarr.email_finished_bcc != null && dataarr.email_finished_bcc != "") {
                if (dataarr.email_finished_bcc.indexOf("|") > -1) {
                    var emailBccList: any = dataarr.email_finished_bcc.split("|")
                    for (let i = 0; i < emailBccList.length; i++) {
                        emailFinishBcc_List.push(emailBccList[i])
                    }
                } else {
                    emailFinishBcc_List.push(dataarr.email_finished_bcc)
                }
            }

            var emailWarningTo_List: Array<string> = []
            if (dataarr.email_warning_to != null && dataarr.email_warning_to != "") {
                console.log(dataarr.email_warning_to.indexOf("|"))
                if (dataarr.email_warning_to.indexOf("|") > -1) {
                    var emailToList: any = dataarr.email_warning_to.split("|")
                    for (let i = 0; i < emailToList.length; i++) {
                        emailWarningTo_List.push(emailToList[i])
                    }
                } else {
                    emailWarningTo_List.push(dataarr.email_warning_to)
                }
            }

            var emailWarningCc_List: Array<string> = []
            if (dataarr.email_warning_cc != null && dataarr.email_warning_cc != "") {
                if (dataarr.email_warning_cc.indexOf("|") > -1) {
                    var emailCcList: any = dataarr.email_warning_cc.split("|")
                    for (let i = 0; i < emailCcList.length; i++) {
                        emailWarningCc_List.push(emailCcList[i])
                    }
                } else {
                    emailWarningCc_List.push(dataarr.email_warning_cc)
                }
            }

            var emailWarningBcc_List: Array<string> = []
            if (dataarr.email_warning_bcc != null && dataarr.email_warning_bcc != "") {
                if (dataarr.email_warning_bcc.indexOf("|") > -1) {
                    var emailBccList: any = dataarr.email_warning_bcc.split("|")
                    for (let i = 0; i < emailBccList.length; i++) {
                        emailWarningBcc_List.push(emailBccList[i])
                    }
                } else {
                    emailWarningBcc_List.push(dataarr.email_warning_bcc)
                }
            }

            var emailErrorTo_List: Array<string> = []
            if (dataarr.email_error_to != null && dataarr.email_error_to != "") {
                console.log(dataarr.email_error_to.indexOf("|"))
                if (dataarr.email_error_to.indexOf("|") > -1) {
                    var emailToList: any = dataarr.email_error_to.split("|")
                    for (let i = 0; i < emailToList.length; i++) {
                        emailErrorTo_List.push(emailToList[i])
                    }
                } else {
                    emailErrorTo_List.push(dataarr.email_error_to)
                }
            }

            var emailErrorCc_List: Array<string> = []
            if (dataarr.email_error_cc != null && dataarr.email_error_cc != "") {
                if (dataarr.email_error_cc.indexOf("|") > -1) {
                    var emailCcList: any = dataarr.email_error_cc.split("|")
                    for (let i = 0; i < emailCcList.length; i++) {
                        emailErrorCc_List.push(emailCcList[i])
                    }
                } else {
                    emailErrorCc_List.push(dataarr.email_error_cc)
                }
            }

            var emailErrorBcc_List: Array<string> = []
            if (dataarr.email_error_bcc != null && dataarr.email_error_bcc != "") {
                if (dataarr.email_error_bcc.indexOf("|") > -1) {
                    var emailBccList: any = dataarr.email_error_bcc.split("|")
                    for (let i = 0; i < emailBccList.length; i++) {
                        emailErrorBcc_List.push(emailBccList[i])
                    }
                } else {
                    emailErrorBcc_List.push(dataarr.email_error_bcc)
                }
            }

            var emailF1ErrTo_List: Array<string> = []
            if (dataarr.f1_function_error_email_to != null && dataarr.f1_function_error_email_to != "") {
                console.log(dataarr.f1_function_error_email_to.indexOf("|"))
                if (dataarr.f1_function_error_email_to.indexOf("|") > -1) {
                    var emailErrToList: any = dataarr.f1_function_error_email_to.split("|")
                    for (let i = 0; i < emailErrToList.length; i++) {
                        emailF1ErrTo_List.push(emailErrToList[i])
                    }
                } else {
                    emailF1ErrTo_List.push(dataarr.f1_function_error_email_to)
                }
            }

            var emailF1ErrCc_List: Array<string> = []
            if (dataarr.f1_function_error_email_cc != null && dataarr.f1_function_error_email_cc != "") {
                console.log(dataarr.f1_function_error_email_cc.indexOf("|"))
                if (dataarr.f1_function_error_email_cc.indexOf("|") > -1) {
                    var emailErrCcList: any = dataarr.f1_function_error_email_cc.split("|")
                    for (let i = 0; i < emailErrCcList.length; i++) {
                        emailF1ErrCc_List.push(emailErrCcList[i])
                    }
                } else {
                    emailF1ErrCc_List.push(dataarr.f1_function_error_email_cc)
                }
            }

            var emailF1ErrBcc_List: Array<string> = []
            if (dataarr.f1_function_error_email_bcc != null && dataarr.f1_function_error_email_bcc != "") {
                console.log(dataarr.f1_function_error_email_bcc.indexOf("|"))
                if (dataarr.f1_function_error_email_bcc.indexOf("|") > -1) {
                    var emailErrBccList: any = dataarr.f1_function_error_email_bcc.split("|")
                    for (let i = 0; i < emailErrBccList.length; i++) {
                        emailF1ErrBcc_List.push(emailErrBccList[i])
                    }
                } else {
                    emailF1ErrBcc_List.push(dataarr.f1_function_error_email_bcc)
                }
            }

            var legacyIDCriteria_List: Array<string> = []
            if (dataarr.legacy_criteria != null && dataarr.legacy_criteria != "") {
                if (dataarr.legacy_criteria.indexOf("|") > -1) {
                    var legacyIDCriteria_List_: any = dataarr.legacy_criteria.split("|")
                    for (let i = 0; i < legacyIDCriteria_List_.length; i++) {
                        legacyIDCriteria_List.push(legacyIDCriteria_List_[i])
                    }
                } else {
                    legacyIDCriteria_List.push(dataarr.legacy_criteria)
                }
            }

            this.setState({
                isShowModal: true,
                formData: dataarr,
            })

            if (cron_List.length != 0) {
                this.setState({
                    contriger_list: cron_List,
                })
            }

            if (dataarr.email_finished_is_active !== "") {
                this.setState({
                    email_finished_is_active: dataarr.email_finished_is_active as SwitchType,
                })
            }

            if (emailFinishTo_List.length != 0) {
                this.setState({
                    email_finished_to_list: emailFinishTo_List,
                })
            }

            if (emailFinishCc_List.length != 0) {
                this.setState({
                    email_finished_cc_list: emailFinishCc_List,
                })
            }

            if (emailFinishBcc_List.length != 0) {
                this.setState({
                    email_finished_bcc_list: emailFinishBcc_List,
                })
            }

            if (dataarr.email_warning_is_active !== "") {
                this.setState({
                    email_warning_is_active: dataarr.email_warning_is_active as SwitchType,
                })
            }

            if (emailWarningTo_List.length != 0) {
                this.setState({
                    email_warning_to_list: emailWarningTo_List,
                })
            }

            if (emailWarningCc_List.length != 0) {
                this.setState({
                    email_warning_cc_list: emailWarningCc_List,
                })
            }

            if (emailWarningBcc_List.length != 0) {
                this.setState({
                    email_warning_bcc_list: emailWarningBcc_List,
                })
            }

            if (dataarr.email_error_is_active !== "") {
                this.setState({
                    email_error_is_active: dataarr.email_error_is_active as SwitchType,
                })
            }

            if (emailErrorTo_List.length != 0) {
                this.setState({
                    email_error_to_list: emailErrorTo_List,
                })
            }

            if (emailErrorCc_List.length != 0) {
                this.setState({
                    email_error_cc_list: emailErrorCc_List,
                })
            }

            if (emailErrorBcc_List.length != 0) {
                this.setState({
                    email_error_bcc_list: emailErrorBcc_List,
                })
            }

            if (dataarr.f1_function_error_email_is_active !== "") {
                this.setState({
                    email_function_error_is_active: dataarr.f1_function_error_email_is_active as SwitchType,
                })
            }

            if (emailF1ErrTo_List.length != 0) {
                this.setState({
                    email_f1_err_to_list: emailF1ErrTo_List,
                })
            }

            if (emailF1ErrCc_List.length != 0) {
                this.setState({
                    email_f1_err_cc_list: emailF1ErrCc_List,
                })
            }

            if (emailF1ErrBcc_List.length != 0) {
                this.setState({
                    email_f1_err_bcc_list: emailF1ErrBcc_List,
                })
            }

            if (legacyIDCriteria_List.length != 0) {
                this.setState({
                    legacyID_List: legacyIDCriteria_List,
                })
            }

            this.props.requestSearchJobConfig(true, "CLEAR")

        } else if (SearchJob.isLoadSearchJobConfig && (SearchJob.statusSearchJobConfig == 204 || SearchJob.statusSearchJobConfig == 400)) {
            this.props.UseLoading(false)
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(SearchJob.messageSearchJobConfig)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        CheckApi: true,
                        checkSftp: true
                    })
                }
            })
        }


        if (EditJob.isLoadEditJobConfig && EditJob.statusEditJobConfig == 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestEditJobConfig(true, "CLEAR");
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    var dataSet: any = {
                        sid: this.state.formData.sid,
                        company_code: this.state.formData.companycode,
                        legacy_id: this.state.formData.legacyid
                    }
                    console.log(dataSet)
                    this.props.requestGetReScheduler(true, 'POST', dataSet)
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        formData: {},
                        editMode: false
                    })
                }
            })
        } else if (EditJob.isLoadEditJobConfig && EditJob.statusEditJobConfig != 200) {
            this.props.UseLoading(false)
            //this.props.requestGetReScheduler(true, 'POST')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(EditJob.messageEditJobConfig)
            this.props.requestEditJobConfig(true, "CLEAR");
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestGetReScheduler(true, 'POST')
                    this.Reset()
                    this.setState({
                        isShowModal: false,
                        formData: {},
                        editMode: false
                    })
                }
            })
        }

        if (GetJobTem.isLoadJobTemConfig && GetJobTem.statusJobTemConfig == 200 && this.state.checkTemplate) {
            this.setState({
                checkTemplate: false
            })
            this.props.UseLoading(false)
            var res: any = GetJobTem.responseJobTemConfig['result_list'];
            var dataArr: any = []
            for (let i = 0; i < res.length; i++) {
                var data: any = {}
                if (res[i].legacytype != 'PULL_WAIT') {
                    data['label'] = res[i].sid + ' , ' + res[i].companycode + ' , ' + res[i].legacyid
                    data['sid'] = res[i].sid
                    data['companycode'] = res[i].companycode
                    data['legacyid'] = res[i].legacyid
                    dataArr.push(data)
                }
            }
            this.props.requestSearchJobConfig(true, "CLEAR", '', '', '')
            this.setState({
                dataTemplate: dataArr
            })
        }

        if (GetSftp.statusSftpConfig == 200 && GetSftp.isLoadSftpConfig && this.state.checkSftp) {
            console.log('Update option SFTP')

            if (GetSftp.responseSftpConfig['result_list'] != null) {
                var resSftp = GetSftp.responseSftpConfig['result_list'];
                var dataArrSftp: any = []
                var dataArrSftpEdit: any = []
                var dataArrSftpEditSource: any = []
                for (let x = 0; x < resSftp.length; x++) {
                    var dataSftp: any = {}
                    dataSftp['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftp['value'] = resSftp[x].sftp_server_id
                    dataArrSftp.push(dataSftp)

                    var dataSftpEdit: any = {}
                    dataSftpEdit['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftpEdit['value'] = resSftp[x].sftp_server_id + '|' + resSftp[x].sftp_ip + '|' + resSftp[x].sftp_port + '|' + resSftp[x].sftp_username + '|' + resSftp[x].sftp_password + '|' + resSftp[x].private_key
                    dataArrSftpEdit.push(dataSftpEdit)

                    var dataSftpEditDest: any = {}
                    dataSftpEditDest['label'] = resSftp[x].sid + ' , ' + resSftp[x].companycode + ' , ' + resSftp[x].sftp_server_id + ' , ' + resSftp[x].sftp_ip
                    dataSftpEditDest['value'] = resSftp[x].sftp_server_id + '|' + resSftp[x].sftp_ip + '|' + resSftp[x].sftp_port + '|' + resSftp[x].sftp_username + '|' + resSftp[x].sftp_password + '|' + resSftp[x].private_key
                    dataArrSftpEditSource.push(dataSftpEditDest)
                }
                this.setState({
                    dataSftp: dataArrSftp,
                    dataSftpEdit: dataArrSftpEdit,
                    dataSftpEditSource: dataArrSftpEditSource,
                    checkSftp: false
                })
                var dataarr: JobConfigModels.JobConfig = this.state.formData
                dataArrSftp.map((item, id) => {
                    if (item.value == dataarr.sftp_return_server_id) {
                        const data = dataArrSftpEdit[id].value.split('|')
                        var dataSet: any = {
                            host: data[1],
                            port: Number(data[2]),
                            username: data[3],
                            password: data[4],
                            private_key: data[5],
                        }
                        this.setState({
                            sftpValue: dataSet
                        })
                    }
                    if (item.value == dataarr.sftp_source_server_id) {
                        const data = dataArrSftpEditSource[id].value.split('|')
                        var dataSet: any = {
                            host: data[1],
                            port: Number(data[2]),
                            username: data[3],
                            password: data[4],
                            private_key: data[5],
                        }
                        this.setState({
                            sftpValueSource: dataSet
                        })
                    }
                })
            } else {
                this.setState({
                    dataSftp: [{ value: '', label: 'ไม่มีข้อมูล' }]
                })
                this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
            }
            this.forceUpdate()
        }

        if (GetAPI.statusApiConfig == 200 && GetAPI.isLoadApiConfig && this.state.CheckApi) {
            console.log('Update option API')

            if (GetAPI.responseApiConfig['result_list'] != null) {
                var resApi = GetAPI.responseApiConfig['result_list'];
                var dataArrApi: any = []
                var dataArrApiEdit: any = []
                var dataArrApiEditUserPass: any = []
                for (let x = 0; x < resApi.length; x++) {
                    var dataApi: any = {}
                    dataApi['label'] = resApi[x].sid + ' , ' + resApi[x].companycode + ' , ' + resApi[x].api_url_callfunction + ' , ' + resApi[x].api_serverid
                    dataApi['value'] = resApi[x].api_serverid
                    dataArrApi.push(dataApi)

                    var dataApiEdit: any = {}
                    dataApiEdit['label'] = resApi[x].sid + ' , ' + resApi[x].companycode + ' , ' + resApi[x].api_url_callfunction + ' , ' + resApi[x].api_serverid
                    dataApiEdit['value'] = resApi[x].api_serverid + '|' + resApi[x].api_url_callfunction
                    dataArrApiEdit.push(dataApiEdit)

                    var dataApiEditUserPass: any = {}
                    dataApiEditUserPass['label'] = resApi[x].api_username + ',' + resApi[x].api_password
                    dataApiEditUserPass['value'] = resApi[x].api_serverid
                    dataArrApiEditUserPass.push(dataApiEditUserPass)
                }
                this.setState({
                    dataApi: dataArrApi,
                    dataApiEdit: dataArrApiEdit,
                    dataApiEditUserPass: dataArrApiEditUserPass,
                    CheckApi: false
                })
                var dataarr: JobConfigModels.JobConfig = this.state.formData
                dataArrApi.map((item, id) => {
                    if (item.value == dataarr.api_serverid) {
                        const data = dataArrApiEdit[id].value.split('|')
                        this.setState({
                            apiUrl: data[1]
                        })
                        const dataUserPass = dataArrApiEditUserPass[id].label.split(',')
                        this.setState({
                            usernameAPI: dataUserPass[0],
                            passAPI: dataUserPass[1]
                        })
                    }
                })
            } else {
                this.setState({
                    dataApi: [{ value: '', label: 'ไม่มีข้อมูล' }],
                })
                this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
            }
            this.forceUpdate()
        }

        if (GetSftpDest.isLoadSftpDest && (GetSftpDest.statusSftpDest == 200 || GetSftpDest.statusSftpDest != 200) && GetSftpSource.isLoadSftpSource && (GetSftpSource.statusSftpSource == 200 || GetSftpSource.statusSftpSource != 200) && isLoadTestSftpSource && (statusTestSftpSource == 200 || statusTestSftpSource != 200) && isLoadTestSftp && (statusTestSftp == 200 || statusTestSftp != 200) && isLoadTestFapp && (statusTestFapp == 200 || statusTestFapp != 200) && isLoadTestJob && (statusTestJob == 200 || statusTestJob != 200) && this.state.check_job) {
            this.props.UseLoading(false)
            this.setState({
                check_job: false,
                isShowTestStatusModal: true,
                status_Fapp: statusTestFapp,
                status_sFTP: statusTestSftp,
                status_sFTP_Source: statusTestSftpSource,
                status_Job: statusTestJob,
                status_sFTPSource: GetSftpSource.statusSftpSource,
                status_sFTPDest: GetSftpDest.statusSftpDest
            })
            this.clearValue();
            this.props.requestGetFappTest(true, "CLEAR", "")
            this.props.requestGetSftpTest(true, "CLEAR", "")
            this.props.requestGetSftpSourceTest(true, "CLEAR", "")
            this.props.requestGetJobTest(true, "CLEAR", "")
            this.props.requestGetSftpSource(true, "CLEAR", "")
            this.props.requestGetSftpDest(true, "CLEAR", "")
        }

        if (GetSftpPath.isLoadGetSftpPath && GetSftpPath.statusGetSftpPath == 200 && !this.state.isShowSftpPathModal) {

            var listObjFolder: any = []
            if (this.state.listFolder == undefined) {
                GetSftpPath.responseGetSftpPath.map((item, id) => {
                    var dataset = {
                        name: item.name,
                        node_level: item.node_level,
                        parent: item.parent,
                        path: item.path,
                        listNode: []
                    }
                    listObjFolder.push(dataset)
                })
            }

            this.setState({
                listFolder: listObjFolder,
                isShowSftpPathModal: true
            })
        }

        if (GetSftpPath.isLoadGetSftpPath && GetSftpPath.statusGetSftpPath == 200 && this.state.listFolder != undefined && this.state.checkQueryFolder) {

            var listObjSubFolder: any = []
            GetSftpPath.responseGetSftpPath.map((item, id) => {
                var dataset = {
                    name: item.name,
                    node_level: item.node_level,
                    parent: item.parent,
                    path: item.path,
                    listNode: []
                }
                listObjSubFolder.push(dataset)
            })

            this.filterPathOneTime(this.state.listFolder, this.state.valuePath, "", listObjSubFolder)
        }
    }

    filterPathOneTime(data, key, checkloop, valueInListNode) {

        var checkloopUse = checkloop

        if (checkloopUse == "") {
            for (let j = 0; j < data.length; j++) {
                if (data[j].path == key) {
                    data[j].listNode = valueInListNode
                    break
                }
            }
            checkloopUse = "Loop"
        }

        if (checkloopUse == "Loop") {
            for (let i = 0; i < data.length; i++) {
                this.filterPathOneTime(data[i].listNode, key, "Loop", valueInListNode);
                if (data[i].path == key) {
                    data[i].listNode = valueInListNode
                    break
                }
            }
        }
        this.setState({
            listFolder: data,
            checkQueryFolder: false
        })
        this.props.UseLoading(false)
    }

    clearValue = () => {
        this.setState({
            //isShowModal: false,
            //formData: {
            //    sid: "",
            //    companycode: "",
            //    legacyid: "",
            //    legacyname: "",
            //    legacytype: "IN",
            //    sftp_source_server_id: "",
            //    sftp_source_path: "",
            //    sftp_source_formatfilename: "",
            //    sftp_source_filetype: "",
            //    sftp_source_dest_localpath: "",
            //    sftp_source_isdeletesource: "N",
            //    api_serverid: "",
            //    api_classname: "",
            //    api_function_name: "",
            //    api_success_path: "",
            //    api_error_path: "",
            //    api_result_path: "",
            //    sftp_return_server_id: "",
            //    sftp_return_path: "",
            //    cron_trigger: "",
            //    isactive: "N",
            //    sftp_return_formatfilename: "",
            //    sftp_return_success_path: "",
            //    sftp_return_error_path: "",
            //},
            dataSftp: [],
            dataSftpEdit: [],
            dataSftpEditSource: [],
            apiUrl: "",
            sftpValue: undefined,
            sftpValueSource: undefined,
            checkSftp: true,
            dataApi: [],
            CheckApi: true,
            contriger_list: [],
            contriger_string: "",
            email_finished_to_string: "",
            email_finished_to_list: [],
            email_finished_cc_string: "",
            email_finished_cc_list: [],
            email_finished_bcc_string: "",
            email_finished_bcc_list: [],
            email_warning_to_string: "",
            email_warning_to_list: [],
            email_warning_cc_string: "",
            email_warning_cc_list: [],
            email_warning_bcc_string: "",
            email_warning_bcc_list: [],
            email_error_to_string: "",
            email_error_to_list: [],
            email_error_cc_string: "",
            email_error_cc_list: [],
            email_error_bcc_string: "",
            email_error_bcc_list: [],
            email_f1_err_to_string: "",
            email_f1_err_to_list: [],
            email_f1_err_cc_string: "",
            email_f1_err_cc_list: [],
            email_f1_err_bcc_string: "",
            email_f1_err_bcc_list: [],
        })
    }

    handleClose = () => {
        if (!this.state.isShowModal) {
            //this.props.requestGetSftpConfig(true, "GET", "", "", "", "")
            //this.props.requestGetApiConfig(true, "POST", "", "", "", "")
            this.setState({
                isShowModal: true,
                CheckApi: true,
                checkSftp: true,
                editMode: false
            });

            return;
        }

        this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
        this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
        this.setState({
            isShowModal: false,
            formData: {
                sid: "555",
                companycode: "",
                legacyid: "",
                legacyname: "",
                legacytype: "IN",
                sftp_source_server_id: "",
                sftp_source_path: "",
                sftp_source_formatfilename: "",
                sftp_source_filetype: "",
                sftp_source_dest_localpath: "path_getsftp",
                sftp_source_isdeletesource: "N",
                api_serverid: "",
                api_classname: "",
                api_function_name: "",
                api_success_path: "api_source_success",
                api_error_path: "api_source_error",
                api_result_path: "api_result",
                sftp_return_server_id: "",
                sftp_return_path: "",
                cron_trigger: "",
                isactive: "N",
                sftp_return_formatfilename: "",
                sftp_return_success_path: "return_success",
                sftp_return_error_path: "return_error",
                dms_serverid: "",
                dms_bucket: "",
                dms_boxid: "",
                dms_roleaccess: "",
                dms_group: "",
                dms_groupid: "",
                stopif_step4_error: "N",
                email_finished_to: "",
                email_finished_cc: "",
                email_finished_bcc: "",
                email_warning_to: "",
                email_warning_cc: "",
                email_warning_bcc: "",
                email_error_to: "",
                email_error_cc: "",
                email_error_bcc: "",
                //email_noti_when: "",
                criteria_stopif_mainstatus_warning: "Y",
                legacy_criteria: "",
                f1_function_error_email_to: "",
                f1_function_error_email_cc: "",
                f1_function_error_email_bcc: "",
                important_job: "False",

            },
            dataSftp: [],
            dataSftpEdit: [],
            dataSftpEditSource: [],
            dataApiEditUserPass: undefined,
            apiUrl: "",
            sftpValue: undefined,
            sftpValueSource: undefined,
            checkSftp: true,
            dataApi: [],
            CheckApi: true,
            contriger_list: [],
            contriger_string: "",
            email_finished_to_string: "",
            email_finished_to_list: [],
            email_finished_cc_string: "",
            email_finished_cc_list: [],
            email_finished_bcc_string: "",
            email_finished_bcc_list: [],

            email_warning_to_string: "",
            email_warning_to_list: [],
            email_warning_cc_string: "",
            email_warning_cc_list: [],
            email_warning_bcc_string: "",
            email_warning_bcc_list: [],

            email_error_to_string: "",
            email_error_to_list: [],
            email_error_cc_string: "",
            email_error_cc_list: [],
            email_error_bcc_string: "",
            email_error_bcc_list: [],

            email_f1_err_to_string: "",
            email_f1_err_to_list: [],
            email_f1_err_cc_string: "",
            email_f1_err_cc_list: [],
            email_f1_err_bcc_string: "",
            email_f1_err_bcc_list: [],
        });
    }

    handleChangeInput = (e: any, key: any, checkConnection: any) => {
        console.log(this.state.formData.companycode + ' | ' + this.state.formData.legacyid)
        var data: JobConfigModels.JobConfig = JSON.parse(JSON.stringify(this.state.formData))
        data[key] = e.target.value
        this.setState({
            formData: data
        })

        //if (key == 'companycode' || key == 'legacyid') {
        //    var sftp_source_dest_localpath: string = this.state.formData.sftp_source_dest_localpath
        //    var api_success_path: string = this.state.formData.api_success_path
        //    var api_error_path: string = this.state.formData.api_error_path
        //    var api_result_path: string = this.state.formData.api_result_path
        //    var sftp_return_success_path: string = this.state.formData.sftp_return_success_path
        //    var sftp_return_error_path: string = this.state.formData.sftp_return_error_path

        //    console.log(sftp_source_dest_localpath + ' | ' + (sftp_source_dest_localpath.split("/").length - 1))
        //    var value_sftp_source_dest_localpath = ''
        //    if ((sftp_source_dest_localpath.split("/").length - 1) == 2) {
        //        var split_sftp_source_dest_localpath = sftp_source_dest_localpath.split('/')
        //        if ((this.state.formData.companycode != "" || this.state.formData.companycode != undefined) && (this.state.formData.legacyid == "" || this.state.formData.legacyid == undefined)) {
        //            value_sftp_source_dest_localpath = this.state.formData.companycode + '/' + '.' + '/' + split_sftp_source_dest_localpath[2]

        //        } else if ((this.state.formData.legacyid != "" || this.state.formData.legacyid != undefined) && (this.state.formData.companycode == "" || this.state.formData.companycode == undefined)) {
        //            value_sftp_source_dest_localpath = './' + this.state.formData.legacyid + '/' + split_sftp_source_dest_localpath[2]

        //        } else if ((this.state.formData.companycode != "" || this.state.formData.companycode != undefined) && (this.state.formData.legacyid != "" || this.state.formData.legacyid != undefined)) {
        //            value_sftp_source_dest_localpath = this.state.formData.companycode + '/' + this.state.formData.legacyid + '/' + split_sftp_source_dest_localpath[2]

        //        }

        //        this.setState({
        //            formData: {
        //                sftp_source_dest_localpath: value_sftp_source_dest_localpath
        //            }
        //        })
        //    }

        //var sftp_source_dest_localpath_v = ""
        //console.log(sftp_source_dest_localpath + ' | ' +(sftp_source_dest_localpath.match('/') || []).length)
        //if ((sftp_source_dest_localpath.match('/') || []).length == 1) {
        //    var sftp_source_dest_localpath_s = sftp_source_dest_localpath.split('/')
        //    console.log(sftp_source_dest_localpath_s)
        //    sftp_source_dest_localpath_v = this.state.formData.companycode + '/' + this.state.formData.legacyid + '/' + sftp_source_dest_localpath_s[1]
        //    if (this.state.formData.companycode == "") {
        //        sftp_source_dest_localpath_v = this.state.formData.legacyid + '/' + sftp_source_dest_localpath_s[1]
        //    } else if (this.state.formData.legacyid == "") {
        //        sftp_source_dest_localpath_v = this.state.formData.companycode + '/' + sftp_source_dest_localpath_s[1]
        //    }

        //} else if ((sftp_source_dest_localpath.match('/') || []).length == 2) {
        //    var sftp_source_dest_localpath_s = sftp_source_dest_localpath.split('/')
        //    console.log(sftp_source_dest_localpath_s)
        //    sftp_source_dest_localpath_v = this.state.formData.companycode + '/' + this.state.formData.legacyid + '/' + sftp_source_dest_localpath_s[2]

        //} else {
        //    console.log(sftp_source_dest_localpath_s)
        //    sftp_source_dest_localpath_v = this.state.formData.companycode + '/' + this.state.formData.legacyid + '/' + sftp_source_dest_localpath
        //}
        //}
        //if (checkConnection == "API") {
        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataApiEdit[keyUse]
        //    let data = dataUse.value.split('|')
        //    this.setState({
        //        apiUrl: data[1],
        //    })

        //} else if (checkConnection == "sFTP_Dest") {

        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataSftpEdit[keyUse]

        //    let data = dataUse.value.split('|')
        //    var dataSet: any = {
        //        host: data[1],
        //        port: Number(data[2]),
        //        username: data[3],
        //        password: data[4],
        //        private_key: data[5],
        //    }
        //    this.setState({
        //        sftpValue: dataSet
        //    })
        //} else if (checkConnection == "sFTP_Source") {

        //    const selectedIndex = e.target.options.selectedIndex;
        //    const keyUse = e.target.options[selectedIndex].getAttribute('data-key')

        //    const dataUse = this.state.dataSftpEditSource[keyUse]

        //    let data = dataUse.value.split('|')
        //    var dataSet: any = {
        //        host: data[1],
        //        port: Number(data[2]),
        //        username: data[3],
        //        password: data[4],
        //        private_key: data[5],
        //    }
        //    this.setState({
        //        sftpValueSource: dataSet
        //    })
        //}
        if (key == "sid" || key == "companycode") {
            this.setState({
                CheckApi: true,
                checkSftp: true
            })
        }
    }

    onchangeCheckBoxImportant = (e: any, key: any) => {
        console.log(key + ' | ' + e.target.checked)
        var data: JobConfigModels.JobConfig = this.state.formData
        if (e.target.checked == true) {
            data[key] = "True"
        } else {
            data[key] = "False"
        }
        this.setState({
            formData: data
        })
    }

    handleSave = () => {
        this.props.UseLoading(true)
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        // save
        var conTrigger: string = ""
        for (let i = 0; i < this.state.contriger_list.length; i++) {
            if (conTrigger == "") {
                conTrigger = this.state.contriger_list[i]
            } else {
                conTrigger += "|"
                conTrigger += this.state.contriger_list[i]
            }
        }
        this.state.formData.cron_trigger = conTrigger

        this.state.formData.email_finished_is_active = this.state.email_finished_is_active
        var emailFinishedTo: string = ""
        for (let i = 0; i < this.state.email_finished_to_list.length; i++) {
            if (emailFinishedTo == "") {
                emailFinishedTo = this.state.email_finished_to_list[i].split(' ').join('');
            } else {
                emailFinishedTo += "|"
                emailFinishedTo += this.state.email_finished_to_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_finished_to = emailFinishedTo

        var emailFinishedCc: string = ""
        for (let i = 0; i < this.state.email_finished_cc_list.length; i++) {
            if (emailFinishedCc == "") {
                emailFinishedCc = this.state.email_finished_cc_list[i].split(' ').join('');
            } else {
                emailFinishedCc += "|"
                emailFinishedCc += this.state.email_finished_cc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_finished_cc = emailFinishedCc

        var emailFinishedBcc: string = ""
        for (let i = 0; i < this.state.email_finished_bcc_list.length; i++) {
            if (emailFinishedBcc == "") {
                emailFinishedBcc = this.state.email_finished_bcc_list[i].split(' ').join('');
            } else {
                emailFinishedBcc += "|"
                emailFinishedBcc += this.state.email_finished_bcc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_finished_bcc = emailFinishedBcc

        this.state.formData.email_warning_is_active = this.state.email_warning_is_active
        var emailWarningTo: string = ""
        for (let i = 0; i < this.state.email_warning_to_list.length; i++) {
            if (emailWarningTo == "") {
                emailWarningTo = this.state.email_warning_to_list[i].split(' ').join('');
            } else {
                emailWarningTo += "|"
                emailWarningTo += this.state.email_warning_to_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_warning_to = emailWarningTo

        var emailWarningCc: string = ""
        for (let i = 0; i < this.state.email_warning_cc_list.length; i++) {
            if (emailWarningCc == "") {
                emailWarningCc = this.state.email_warning_cc_list[i].split(' ').join('');
            } else {
                emailWarningCc += "|"
                emailWarningCc += this.state.email_warning_cc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_warning_cc = emailWarningCc

        var emailWarningBcc: string = ""
        for (let i = 0; i < this.state.email_warning_bcc_list.length; i++) {
            if (emailWarningBcc == "") {
                emailWarningBcc = this.state.email_warning_bcc_list[i].split(' ').join('');
            } else {
                emailWarningBcc += "|"
                emailWarningBcc += this.state.email_warning_bcc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_warning_bcc = emailWarningBcc

        this.state.formData.email_error_is_active = this.state.email_error_is_active
        var emailErrorTo: string = ""
        for (let i = 0; i < this.state.email_error_to_list.length; i++) {
            if (emailErrorTo == "") {
                emailErrorTo = this.state.email_error_to_list[i].split(' ').join('');
            } else {
                emailErrorTo += "|"
                emailErrorTo += this.state.email_error_to_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_error_to = emailErrorTo

        var emailErrorCc: string = ""
        for (let i = 0; i < this.state.email_error_cc_list.length; i++) {
            if (emailErrorCc == "") {
                emailErrorCc = this.state.email_error_cc_list[i].split(' ').join('');
            } else {
                emailErrorCc += "|"
                emailErrorCc += this.state.email_error_cc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_error_cc = emailErrorCc

        var emailErrorBcc: string = ""
        for (let i = 0; i < this.state.email_error_bcc_list.length; i++) {
            if (emailErrorBcc == "") {
                emailErrorBcc = this.state.email_error_bcc_list[i].split(' ').join('');
            } else {
                emailErrorBcc += "|"
                emailErrorBcc += this.state.email_error_bcc_list[i].split(' ').join('');
            }
        }
        this.state.formData.email_error_bcc = emailErrorBcc

        this.state.formData.f1_function_error_email_is_active = this.state.email_function_error_is_active
        var emailF1ErrTo: string = ""
        for (let i = 0; i < this.state.email_f1_err_to_list.length; i++) {
            if (emailF1ErrTo == "") {
                emailF1ErrTo = this.state.email_f1_err_to_list[i].split(' ').join('');
            } else {
                emailF1ErrTo += "|"
                emailF1ErrTo += this.state.email_f1_err_to_list[i].split(' ').join('');
            }
        }
        this.state.formData.f1_function_error_email_to = emailF1ErrTo

        var emailF1ErrCc: string = ""
        for (let i = 0; i < this.state.email_f1_err_cc_list.length; i++) {
            if (emailF1ErrCc == "") {
                emailF1ErrCc = this.state.email_f1_err_cc_list[i].split(' ').join('');
            } else {
                emailF1ErrCc += "|"
                emailF1ErrCc += this.state.email_f1_err_cc_list[i].split(' ').join('');
            }
        }
        this.state.formData.f1_function_error_email_cc = emailF1ErrCc

        var emailF1ErrBcc: string = ""
        for (let i = 0; i < this.state.email_f1_err_bcc_list.length; i++) {
            if (emailF1ErrBcc == "") {
                emailF1ErrBcc = this.state.email_f1_err_bcc_list[i].split(' ').join('');
            } else {
                emailF1ErrBcc += "|"
                emailF1ErrBcc += this.state.email_f1_err_bcc_list[i].split(' ').join('');
            }
        }
        this.state.formData.f1_function_error_email_bcc = emailF1ErrBcc

        var legacy_criteria: string = ""
        for (let i = 0; i < this.state.legacyID_List.length; i++) {
            if (legacy_criteria == "") {
                legacy_criteria = this.state.legacyID_List[i].split(' ').join('');
            } else {
                legacy_criteria += "|"
                legacy_criteria += this.state.legacyID_List[i].split(' ').join('');
            }
        }
        this.state.formData.legacy_criteria = legacy_criteria

        if (!this.state.editMode) {
            this.props.requestAddJobConfig(true, "POST", this.state.formData);
        }
        else {
            this.props.requestEditJobConfig(true, "PUT", this.state.formData);
        }

        this.clearEmailState();
    }

    public clearEmailState() {
        this.setState({
            email_finished_to_string: "",
            email_finished_to_list: [],
            email_finished_cc_string: "",
            email_finished_cc_list: [],
            email_finished_bcc_string: "",
            email_finished_bcc_list: [],

            email_warning_to_string: "",
            email_warning_to_list: [],
            email_warning_cc_string: "",
            email_warning_cc_list: [],
            email_warning_bcc_string: "",
            email_warning_bcc_list: [],

            email_error_to_string: "",
            email_error_to_list: [],
            email_error_cc_string: "",
            email_error_cc_list: [],
            email_error_bcc_string: "",
            email_error_bcc_list: [],

            email_f1_err_to_string: "",
            email_f1_err_to_list: [],
            email_f1_err_cc_string: "",
            email_f1_err_cc_list: [],
            email_f1_err_bcc_string: "",
            email_f1_err_bcc_list: [],

            // Other
            contriger_list: [],
        });
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    public handleEdit = (sid: string, companycode: string, legacyid: string) => {
        this.props.UseLoading(true)
        //this.props.requestGetSftpConfig(true, "GET", "", "", "", "")
        //this.props.requestGetApiConfig(true, "POST", "", "", "", "")
        this.setState({
            editMode: true,
            CheckApi: true,
            checkSftp: true
        })

        setTimeout(() => {
            this.props.requestSearchJobConfig(true, "GET", sid, companycode, legacyid)
        }, 100);
    }

    Reset = () => {
        console.log('Reset function')
        this.props.requestAddJobConfig(true, "CLEAR", []);
        this.setState({
            key: 'Reset'
        })
    }

    onchangeSwitch = (value: any, key: any) => {
        var data: JobConfigModels.JobConfig = this.state.formData
        if (value == true) {
            data[key] = "Y"
        } else {
            data[key] = "N"
        }
        this.setState({
            formData: data
        })
    }

    onChangeEmailStatus = <K extends keyof Pick<State, 'email_finished_is_active' | 'email_warning_is_active' | 'email_error_is_active' | 'email_function_error_is_active'>>(key: K, isChecked: State[K]) => {
        this.setState(prevState => ({
            ...prevState,
            [key]: isChecked
        }));
    }

    handleCloseTemModal = () => {
        if (!this.state.isShowTempModal) {
            this.setState({
                isShowTempModal: true
            })

            this.props.requestGetJobTemConfig(true, "POST")

            return;
        }

        this.props.requestSearchJobConfig(true, "CLEAR", '', '', '')
        this.setState({
            isShowTempModal: false,
            dataTemplate: [],
            checkTemplate: true,
            selectedTemplate: {
                label: "",
                sid: "555",
                companycode: "",
                legacyid: ""
            }
        });
    }

    handleCloseTestModal = () => {
        if (this.state.isShowTestStatusModal == false) {
            this.setState({
                isShowTestStatusModal: true
            })
        } else {
            this.setState({
                isShowTestStatusModal: false,
            })

        }
    }

    handleClickTemplate = () => {
        const { selectedTemplate } = this.state
        this.setState({
            isShowTempModal: false,
            editMode: false,
        })
        this.props.UseLoading(true)
        this.props.requestSearchJobConfig(true, "GET", selectedTemplate['sid'], selectedTemplate['companycode'], selectedTemplate['legacyid'])
    }

    handleSelectTemplate = (e: any) => {
        var value = e.target.value.split(' , ')
        var dataArr: any = {}
        dataArr['label'] = e.target.value
        dataArr['sid'] = value[0]
        dataArr['companycode'] = value[1]
        dataArr['legacyid'] = value[2]
        this.setState({
            selectedTemplate: dataArr
        })
    }

    handleTestConnect = () => {
        if (this.state.apiUrl == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง API Server ID',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        } else if (this.state.sftpValue == undefined) {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง sFTP Return Server ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        } else if (this.state.sftpValueSource == undefined) {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง sFTP Source  Server ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        else if (this.state.formData.sftp_source_path == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง sFTP Source Path ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        else if (this.state.formData.sftp_return_path == "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง sFTP Return Path ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        else {
            Swal.fire({
                title: 'Test Connection ?',
                text: 'คุณต้องการทดสอบหรือไม่ ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่'
            }).then((result) => {
                if (result.value) {
                    this.props.UseLoading(true)
                    this.setState({
                        check_job: true,
                        //isShowModal: false
                    })

                    var dataset = {
                        api_url: this.state.apiUrl,
                        class_name: this.state.formData.api_classname,
                        function_name: this.state.formData.api_function_name,
                        sid: this.state.formData.sid,
                        username: this.state.usernameAPI,
                        password: this.state.passAPI
                    }

                    var dataReturn = this.state.sftpValue
                    var dataSource = this.state.sftpValueSource

                    var datasetSftpSource = {
                        path: this.state.formData.sftp_source_path,
                        host: dataSource['host'],
                        port: dataSource['port'] != "" ? parseInt(dataSource['port']) : 0,
                        username: dataSource['username'],
                        password: dataSource['password'],
                        private_key: dataSource['private_key'],
                    }

                    var datasetSftpDest = {
                        path: this.state.formData.sftp_return_path,
                        host: dataReturn['host'],
                        port: dataReturn['port'] != "" ? parseInt(dataReturn['port']) : 0,
                        username: dataReturn['username'],
                        password: dataReturn['password'],
                        private_key: dataReturn['private_key'],
                    }

                    this.props.requestGetFappTest(true, "GET", this.state.apiUrl);
                    this.props.requestGetSftpTest(true, "POST", this.state.sftpValue);
                    this.props.requestGetJobTest(true, "POST", dataset);
                    this.props.requestGetSftpSourceTest(true, "POST", this.state.sftpValueSource);
                    this.props.requestGetSftpSource(true, "POST", datasetSftpSource);
                    this.props.requestGetSftpDest(true, "POST", datasetSftpDest);
                }
            })
        }
    }

    handleClickSelected = async () => {
        this.setState({
            CheckApi: true,
            checkSftp: true
        })
        //this.props.requestGetSftpConfig(true, "CLEAR", "", "", "", "")
        //this.props.requestGetApiConfig(true, "CLEAR", "", "", "", "")
        //this.setState(prevState => ({ dataSftp: [], dataApi: [] }))
        //setTimeout(function () {

        if (this.state.formData['sid'] != "" && this.state.formData['companycode']) {
            this.props.requestGetSftpConfig(true, "GET", this.state.formData['sid'], this.state.formData['companycode'], "", "")
            this.props.requestGetApiConfig(true, "POST", this.state.formData['sid'], this.state.formData['companycode'], "", "")
        } else {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'กรุณากรอกข้อมูล SID และ Company Code',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
            })
        }
        //}, 3000);
    }

    clearData = () => {
        this.setState(prevState => ({ dataSftp: [], dataApi: [] }))
    }

    handlechageAutoComplete = (value: string, key: string, label: string) => {
        var data: JobConfigModels.JobConfig = this.state.formData
        data[key] = value
        this.setState({
            formData: data
        })

        if (key != "") {
            if (key == "sftp_source_server_id") {
                let data = label.split('|')
                var dataSet: any = {
                    host: data[1],
                    port: Number(data[2]),
                    username: data[3],
                    password: data[4],
                    private_key: data[5],
                }
                this.setState({
                    sftpValueSource: dataSet
                })
            } else if (key == "sftp_return_server_id") {
                let data = label.split('|')
                var dataSet: any = {
                    host: data[1],
                    port: Number(data[2]),
                    username: data[3],
                    password: data[4],
                    private_key: data[5],
                }
                this.setState({
                    sftpValue: dataSet
                })
            } else if (key == "api_serverid") {
                let data = label.split('|')
                this.setState({
                    apiUrl: data[1],
                })

                if (this.state.dataApiEditUserPass != undefined) {

                    var listUserAndPass = this.state.dataApiEditUserPass.filter(function (item) {
                        return item.value == value;
                    });

                    if (listUserAndPass.length > 0) {
                        let userAndPass = listUserAndPass[0]['label'].split(',')

                        this.setState({
                            usernameAPI: userAndPass[0],
                            passAPI: userAndPass[1]
                        })
                    }

                }
            }
        }
    }

    handleGetSftpReturnPath = (key: string) => {
        if (this.state.sftpValue != undefined) {
            var dataReq = this.state.sftpValue

            var formData = {
                path: "/",
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }
            this.setState({
                keyPath: key
            })

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Return Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleGetSftpPath = (key: string) => {
        if (this.state.sftpValueSource != undefined) {
            var dataReq = this.state.sftpValueSource

            var formData = {
                path: "/",
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }
            this.setState({
                keyPath: key
            })

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Source Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    selectFolder = (path: string) => {
        if (this.state.sftpValueSource != undefined) {
            var dataReq = this.state.sftpValueSource
            this.props.UseLoading(true)
            this.setState({
                valuePath: path,
                checkQueryFolder: true
            })

            var formData = {
                path: path,
                host: dataReq['host'],
                port: dataReq['port'],
                username: dataReq['username'],
                password: dataReq['password'],
                private_key: dataReq['private_key'],
            }

            this.props.requestGetSftpPath(true, 'POST', formData)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดเลือก sFTP Source Server ก่อน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleClosePathModal = () => {
        this.setState({ isShowSftpPathModal: false, listFolder: undefined, checkQueryFolder: false })
        this.props.requestGetSftpPath(true, 'CLEAR', '')
    }

    handleSelectedPath = (key: string, value: string) => {

        var data: JobConfigModels.JobConfigPath = this.state.JobConfigPath
        data[key] = value
        this.setState({
            JobConfigPath: data
        })
    }

    handleSaveFolder = () => {
        var data: JobConfigModels.JobConfig = this.state.formData
        if (this.state.keyPath != "") {
            data[this.state.keyPath] = this.state.JobConfigPath[this.state.keyPath]
            this.setState({
                formData: data
            })
        }
        this.setState({ isShowSftpPathModal: false, listFolder: undefined, checkQueryFolder: false })
        this.props.requestGetSftpPath(true, 'CLEAR', '')
    }

    handleChangeSearchInput = (e: any, key: string) => {
        if (key != "") {
            var data = this.state.searchJobReq
            data[key] = e.target.value
            this.setState({
                searchJobReq: data
            })
        }
    }

    handleOpenContriger = () => {
        if (this.state.isShowContriger) {
            this.setState({
                isShowContriger: false
            })
        } else {
            this.setState({
                isShowContriger: true
            })
        }
    }

    handleChangeContrigger = (e: any) => {
        console.log(e.target.value)
        this.setState({
            contriger_string: e.target.value
        })
    }

    handleAddContrigger = () => {
        if (this.state.contriger_string != "") {
            var data: any = this.state.contriger_list
            data.push(this.state.contriger_string)
            this.setState({
                contriger_list: data,
                contriger_string: "",
                isShowCronModal: false
            })
            console.log(this.state.contriger_list)
        }
    }

    handleChangeEmail = (e: any, key: string) => {
        console.log(e.target.value)
        if (key == "finished_to") {
            this.setState({
                email_finished_to_string: e.target.value
            })
        } else if (key == "finished_cc") {
            this.setState({
                email_finished_cc_string: e.target.value
            })
        } else if (key == "finished_bcc") {
            this.setState({
                email_finished_bcc_string: e.target.value
            })
        } else if (key == "warning_to") {
            this.setState({
                email_warning_to_string: e.target.value
            })
        } else if (key == "warning_cc") {
            this.setState({
                email_warning_cc_string: e.target.value
            })
        } else if (key == "warning_bcc") {
            this.setState({
                email_warning_bcc_string: e.target.value
            })
        } else if (key == "error_to") {
            this.setState({
                email_error_to_string: e.target.value
            })
        } else if (key == "error_cc") {
            this.setState({
                email_error_cc_string: e.target.value
            })
        } else if (key == "error_bcc") {
            this.setState({
                email_error_bcc_string: e.target.value
            })
        } else if (key == "f1_error_to") {
            this.setState({
                email_f1_err_to_string: e.target.value
            })
        } else if (key == "f1_error_cc") {
            this.setState({
                email_f1_err_cc_string: e.target.value
            })
        } else if (key == "f1_error_bcc") {
            this.setState({
                email_f1_err_bcc_string: e.target.value
            })
        }

    }

    handleAddEmail = (key: string) => {
        if (key == "finished_to") {
            if (this.state.email_finished_to_string != "") {
                var data: any = this.state.email_finished_to_list
                data.push(this.state.email_finished_to_string)
                this.setState({
                    email_finished_to_list: data,
                    email_finished_to_string: ""
                })
                console.log(this.state.email_finished_to_list)
            }
        } else if (key == "finished_cc") {
            if (this.state.email_finished_cc_string != "") {
                var data: any = this.state.email_finished_cc_list
                data.push(this.state.email_finished_cc_string)
                this.setState({
                    email_finished_cc_list: data,
                    email_finished_cc_string: ""
                })
                console.log(this.state.email_finished_cc_list)
            }
        } else if (key == "finished_bcc") {
            if (this.state.email_finished_bcc_string != "") {
                var data: any = this.state.email_finished_bcc_list
                data.push(this.state.email_finished_bcc_string)
                this.setState({
                    email_finished_bcc_list: data,
                    email_finished_bcc_string: ""
                })
                console.log(this.state.email_finished_bcc_list)
            }
        } else if (key == "warning_to") {
            if (this.state.email_warning_to_string != "") {
                var data: any = this.state.email_warning_to_list
                data.push(this.state.email_warning_to_string)
                this.setState({
                    email_warning_to_list: data,
                    email_warning_to_string: ""
                })
                console.log(this.state.email_warning_to_list)
            }
        } else if (key == "warning_cc") {
            if (this.state.email_warning_cc_string != "") {
                var data: any = this.state.email_warning_cc_list
                data.push(this.state.email_warning_cc_string)
                this.setState({
                    email_warning_cc_list: data,
                    email_warning_cc_string: ""
                })
                console.log(this.state.email_warning_cc_list)
            }
        } else if (key == "warning_bcc") {
            if (this.state.email_warning_bcc_string != "") {
                var data: any = this.state.email_warning_bcc_list
                data.push(this.state.email_warning_bcc_string)
                this.setState({
                    email_warning_bcc_list: data,
                    email_warning_bcc_string: ""
                })
                console.log(this.state.email_warning_bcc_list)
            }
        } else if (key == "error_to") {
            if (this.state.email_error_to_string != "") {
                var data: any = this.state.email_error_to_list
                data.push(this.state.email_error_to_string)
                this.setState({
                    email_error_to_list: data,
                    email_error_to_string: ""
                })
                console.log(this.state.email_error_to_list)
            }
        } else if (key == "error_cc") {
            if (this.state.email_error_cc_string != "") {
                var data: any = this.state.email_error_cc_list
                data.push(this.state.email_error_cc_string)
                this.setState({
                    email_error_cc_list: data,
                    email_error_cc_string: ""
                })
                console.log(this.state.email_error_cc_list)
            }
        } else if (key == "error_bcc") {
            if (this.state.email_error_bcc_string != "") {
                var data: any = this.state.email_error_bcc_list
                data.push(this.state.email_error_bcc_string)
                this.setState({
                    email_error_bcc_list: data,
                    email_error_bcc_string: ""
                })
                console.log(this.state.email_error_bcc_list)
            }
        } else if (key == "f1_error_to") {
            if (this.state.email_f1_err_to_string != "") {
                var data: any = this.state.email_f1_err_to_list
                data.push(this.state.email_f1_err_to_string)
                this.setState({
                    email_f1_err_to_list: data,
                    email_f1_err_to_string: ""
                })
                console.log(this.state.email_f1_err_to_list)
            }
        } else if (key == "f1_error_cc") {
            if (this.state.email_f1_err_cc_string != "") {
                var data: any = this.state.email_f1_err_cc_list
                data.push(this.state.email_f1_err_cc_string)
                this.setState({
                    email_f1_err_cc_list: data,
                    email_f1_err_cc_string: ""
                })
                console.log(this.state.email_f1_err_cc_list)
            }
        } else if (key == "f1_error_bcc") {
            if (this.state.email_f1_err_bcc_string != "") {
                var data: any = this.state.email_f1_err_bcc_list
                data.push(this.state.email_f1_err_bcc_string)
                this.setState({
                    email_f1_err_bcc_list: data,
                    email_f1_err_bcc_string: ""
                })
                console.log(this.state.email_f1_err_bcc_list)
            }
        }

    }

    handleDeleteEmail = (id: any, key: string) => {
        console.log(id)
        if (key == "finished_to") {
            var data: any = this.state.email_finished_to_list
            data.splice(id, 1);
            this.setState({
                email_finished_to_list: data
            })
            console.log(this.state.email_finished_to_list)
        } else if (key == "finished_cc") {
            var data: any = this.state.email_finished_cc_list
            data.splice(id, 1);
            this.setState({
                email_finished_cc_list: data
            })
            console.log(this.state.email_finished_cc_list)
        } else if (key == "finished_bcc") {
            var data: any = this.state.email_finished_bcc_list
            data.splice(id, 1);
            this.setState({
                email_finished_bcc_list: data
            })
            console.log(this.state.email_finished_bcc_list)
        } else if (key == "warning_to") {
            var data: any = this.state.email_warning_to_list
            data.splice(id, 1);
            this.setState({
                email_warning_to_list: data
            })
            console.log(this.state.email_warning_to_list)
        } else if (key == "warning_cc") {
            var data: any = this.state.email_warning_cc_list
            data.splice(id, 1);
            this.setState({
                email_warning_cc_list: data
            })
            console.log(this.state.email_warning_cc_list)
        } else if (key == "warning_bcc") {
            var data: any = this.state.email_warning_bcc_list
            data.splice(id, 1);
            this.setState({
                email_warning_bcc_list: data
            })
            console.log(this.state.email_warning_bcc_list)
        } else if (key == "error_to") {
            var data: any = this.state.email_error_to_list
            data.splice(id, 1);
            this.setState({
                email_error_to_list: data
            })
            console.log(this.state.email_error_to_list)
        } else if (key == "error_cc") {
            var data: any = this.state.email_error_cc_list
            data.splice(id, 1);
            this.setState({
                email_error_cc_list: data
            })
            console.log(this.state.email_error_cc_list)
        } else if (key == "error_bcc") {
            var data: any = this.state.email_error_bcc_list
            data.splice(id, 1);
            this.setState({
                email_error_bcc_list: data
            })
            console.log(this.state.email_error_bcc_list)
        } else if (key == "f1_error_to") {
            var data: any = this.state.email_f1_err_to_list
            data.splice(id, 1);
            this.setState({
                email_f1_err_to_list: data
            })
            console.log(this.state.email_f1_err_to_list)
        } else if (key == "f1_error_cc") {
            var data: any = this.state.email_f1_err_cc_list
            data.splice(id, 1);
            this.setState({
                email_f1_err_cc_list: data
            })
            console.log(this.state.email_f1_err_cc_list)
        } else if (key == "f1_error_bcc") {
            var data: any = this.state.email_f1_err_bcc_list
            data.splice(id, 1);
            this.setState({
                email_f1_err_bcc_list: data
            })
            console.log(this.state.email_f1_err_bcc_list)
        }

    }

    handleDeleteContrig = (id) => {
        console.log(id)
        var data: any = this.state.contriger_list
        data.splice(id, 1);
        this.setState({
            contriger_list: data
        })
        console.log(this.state.contriger_list)
    }

    handleDisabledInput = (key: string) => {
        var input: any = document.getElementById(key)
        if (input.disabled) {
            input.disabled = false
        } else {
            input.disabled = true
        }
    }

    handlechageLegacyID = (value: string) => {
        console.log(value)
        this.setState({
            legacyIDSelect: value
        })
    }

    handleAddLegacyIDList = () => {
        var legacyid: string = this.state.legacyIDSelect
        var legacyid_List: Array<string> = this.state.legacyID_List
        legacyid_List.push(legacyid)
        this.setState({
            legacyID_List: legacyid_List,
            legacyIDSelect: "",
            checkLegacySelected: true
        })
    }

    handleDeleteLegacyID = (id: number) => {
        console.log(id)
        var data: any = this.state.legacyID_List
        data.splice(id, 1);
        this.setState({
            legacyID_List: data
        })
        console.log(this.state.legacyID_List)
    }

    handleNotificationWhen(e: any, id: any) {
        var form_data: JobConfigModels.JobConfig = this.state.formData
        var noti_success: any = document.getElementById("NotiWhenSuccess");
        var noti_warning: any = document.getElementById("NotiWhenWarning");
        var noti_error: any = document.getElementById("NotiWhenError");
        var data: any = []

        if (noti_success.checked === true) {
            data.push("FINISHED")
        }
        if (noti_warning.checked === true) {
            data.push("WARNING")
        }
        if (noti_error.checked === true) {
            data.push("ERROR")
        }
        var noti_when: any = ""
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                noti_when += data[i]
                if (i < data.length - 1) {
                    noti_when += "|"
                }
            }
        }

        form_data[id] = noti_when
        this.setState({
            formData: form_data
        })
    }

    convertNotiWhen = (value: string, poti: string) => {
        if (value != "" && value != null && value != undefined) {
            var data: any = value.split("|");
            //console.log(data)
            for (var i = 0; i < data.length; i++) {
                if (data[i] == poti) {
                    return true;
                }
            }
        }
        else {
            return false;
        }

    }

    handleShowCronModal = () => {
        this.setState({ isShowCronModal: true })
    }

    handleChangeContriggerCheck = (e: any) => {
        console.log(e.target.value)
        this.setState({
            contrigerCheck: e.target.value
        })
    }

    handleValidatorContrigger = () => {
        const { contrigerCheck } = this.state
        //console.log(contrigerCheck)
        if (cronValidator.isValidCronExpression(contrigerCheck)) {
            //console.log('true')
            this.setState({ contriger_string: contrigerCheck })
        }
    }

    handleCloseCronModal = () => {
        this.setState({ isShowCronModal: false })
    }

    private handleChangeCron = (cronValue: string) => {
        this.setState({ contriger_string: cronValue });
    }

    handleCloseModalValidate = (isOk: boolean) => {
        if (this.state.showModalValidate) {
            console.log("handleCloseModalValidate : true")
            var data: JobConfigModels.JobConfig = JSON.parse(JSON.stringify(this.state.formData))
            data['sftp_source_formatfilename'] = this.state.SftpRegexDraft;
            this.setState({
                showModalValidate: false,
                formData: data
            })

            //if (this.state.SftpFileNameValidate.length != 0) {
            //    var data: JobConfigModels.JobConfig = JSON.parse(JSON.stringify(this.state.formData))
            //    var fileName: string = ""
            //    for (var i = 0; i < this.state.SftpFileNameValidate.length; i++) {
            //        if (i == 0) {
            //            fileName += this.state.SftpFileNameValidate[i]
            //        } else {
            //            fileName += ' ' + this.state.SftpFileNameValidate[i]
            //        }
            //    }
            //    data['sftp_source_formatfilename'] = fileName;
            //    this.setState({
            //        showModalValidate: false,
            //        formData: data
            //    })
            //} else {
            //    this.setState({
            //        showModalValidate: false,
            //    })
            //}
            console.log(this.state.formData['sftp_source_formatfilename'])
        } else {
            console.log("handleCloseModalValidate : false")
            this.setState({
                showModalValidate: true,
                //SftpFileNameDraft: this.state.formData['sftp_source_formatfilename'],
                SftpRegexDraft: this.state.formData['sftp_source_formatfilename']
            })
            //if (this.state.formData.sftp_source_formatfilename != "") {
            //    if (this.state.formData.sftp_source_formatfilename.indexOf(" ") > -1) {
            //        var data_s = this.state.formData.sftp_source_formatfilename.split(" ")
            //        var data_list: Array<string> = []
            //        for (var i = 0; i < data_s.length; i++) {
            //            data_list.push(data_s[i])
            //        }
            //        this.setState({
            //            SftpFileNameList: data_list,
            //            //SftpFileNameValidate: data_list
            //        })
            //    } else {
            //        var dataList: Array<string> = []
            //        dataList.push(this.state.formData.sftp_source_formatfilename)
            //        this.setState({
            //            SftpFileNameList: dataList,
            //            //SftpFileNameValidate: dataList
            //        })
            //    }
            //}
        }
    }

    handleAddSftpSourceFileName = () => {
        if (this.state.SftpFileNameDraft != "") {
            var List = this.state.SftpFileNameList
            if (List.includes(this.state.SftpFileNameDraft)) {
                return;
            }
            List.push(this.state.SftpFileNameDraft)
            this.setState({
                SftpFileNameList: List,
                SftpFileNameDraft: ""
            })
            console.log(this.state.SftpFileNameList)
            console.log(this.state.SftpFileNameValidate)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'โปรดกรอกข้อมูลในช่อง sFTP Source Format Filename ก่อนกดเพื่ม',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleDeleteSftpSourceFileName = (id) => {
        console.log(id)
        var data: any = this.state.SftpFileNameList
        data.splice(id, 1);
        this.setState({
            SftpFileNameList: data
        })
        console.log(this.state.SftpFileNameList)
    }

    handleValidateSftpSourceFileName = (regex) => {
        if (this.state.SftpFileNameList.length != 0) {
            this.props.UseLoading(true)
            fetch("v1/config/validate-sftp-filename?regex=" + regex,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + this.state.token
                    },
                    body: JSON.stringify(this.state.SftpFileNameList)
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        this.props.UseLoading(false)
                        //if (result.length != 0) {
                        this.setState({
                            SftpFileNameValidate: result
                        })
                        //}
                    },
                    (error) => {
                        this.props.UseLoading(false)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: error,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'คกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                            }
                        })
                    }
                )


        } else {
            Swal.fire({
                text: 'โปรดเพิ่ม sFTP Source Format Filename List ก่อนเพื่อตรวจสอบชื่อไฟล์ก่อนบันทึก',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'คกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    toggleLoadingScreen = (isLoading: boolean) => {
        this.setState({ isLoadingComponent: isLoading });
    }

    //toggleModalExportExcel = (value = false) => {
    //    this.setState(prevState => ({ openModalExportExcel: value ?? !prevState.openModalExportExcel }));
    //}

    exportExcel = async () => {
        this.toggleLoadingScreen(true);

        try {
            const exportJobConfigList = await this.fetchExportJobConfig();

            if (!exportJobConfigList) {
                this.toggleLoadingScreen(false);

                Swal.fire({
                    title: 'No Content',
                    text: 'ไม่พบข้อมูล',
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน',
                });

                return;
            }

            const date = new Date();
            const fileName = "MasterJobConfig_" + date.getTime().toString() + ".xlsx";
            const worksheet = XLSX.utils.json_to_sheet(exportJobConfigList);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, "MasterJobConfig");
            XLSX.writeFile(workbook, fileName);

            this.toggleLoadingScreen(false);
        } catch {
            this.toggleLoadingScreen(false);

            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถ export เอกสารได้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน',
            });
        }
    }

    private async fetchExportJobConfig() {
        const { company_code, legacy_id, sid, legacy_name, api_class_name, api_function_name } = this.state.searchJobReq;

        const queryString = `?sid=${sid}&company_code=${company_code}&legacy_id=${legacy_id}&legacy_name=${legacy_name}&api_class_name=${api_class_name}&api_function_name=${api_function_name}`;

        const response = await fetch('v1/config/export' + queryString, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
            }
        });

        if (!response.ok) {
            return;
        }

        const data: JobConfigModels.ExportJobConfigResponse = await response.json();

        if (data.total_count === 0) {
            return;
        }

        return data.result_list;
    }

    render() {
        const { listFolder, formData } = this.state
        return (
            <Container>
                <LoadingScreen loading={this.state.isLoadingComponent} />
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px', zIndex: 1 }}>
                                <span>
                                    Job config
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    Search menu
                                                </span>
                                            </div>
                                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                                <Row>
                                                    {/*<Col>*/}
                                                    {/*    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                                                    {/*        <Form.Label>SID :</Form.Label>*/}
                                                    {/*        <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'sid')} />*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Company Code :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'company_code')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Legacy ID :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'legacy_id')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Legacy Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'legacy_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Api Class Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'api_class_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Api Function Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'api_function_name')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={{ span: 2, offset: 10 }}>
                                                        <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                            <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={this.Reset}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ display: 'inline-block', float: 'right' }}>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    className="mr-4"
                                                    onClick={() => this.exportExcel()}
                                                >
                                                    <FaFileExport />
                                                    &nbsp; Export Excel
                                                </Button>
                                                <Button variant="primary" size="sm" onClick={this.handleCloseTemModal}>
                                                    <FaPlusCircle />
                                                    &nbsp; Create From Template
                                                </Button>
                                                <Button variant="info" size="sm" className="mx-1" onClick={this.handleClose}>
                                                    <FaPlusCircle />
                                                    &nbsp;Add Job Config
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row className="mt-3">
                                    <Col>
                                        <MasterConfigTable
                                            checkkey={this.state.key}
                                            callbackkey={this.callbackReset.bind(this)}
                                            callbackEdit={this.handleEdit.bind(this)}
                                            searchJobReq={this.state.searchJobReq}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row >

                {/*ADD / Edit Job config*/}
                <Modal show={this.state.isShowModal} onHide={this.handleClose} dialogClassName="custom-modal" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Add Job Config</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <div className='trapezoid-head' style={{ width: '200px' }}>
                                    <span>
                                        Legacy
                                    </span>
                                </div>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" style={{ padding: "10px 5px 5px 30px" }} id="checkImportantJob" label="ImportantJob" checked={this.state.formData['important_job'] == "True" ? true : false} onChange={(e) => this.onchangeCheckBoxImportant(e, "important_job")} />
                            </Col>
                        </Row>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                {/*<Col>
                                    <Form.Label className="font-weight-bold">SID :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="sid" aria-describedby="basic-addon3" value={this.state.formData['sid']} onChange={(e) => this.handleChangeInput(e, "sid", "")} />
                                    </InputGroup>
                                </Col>*/}
                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="companycode" aria-describedby="basic-addon3" value={this.state.formData['companycode']} onChange={(e) => this.handleChangeInput(e, "companycode", "")} disabled={this.state.editMode} />
                                    </InputGroup>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="legacyid" aria-describedby="basic-addon3" value={this.state.formData['legacyid']} onChange={(e) => this.handleChangeInput(e, "legacyid", "")} disabled={this.state.editMode} />
                                    </InputGroup>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="legacyname" aria-describedby="basic-addon3" value={this.state.formData['legacyname']} onChange={(e) => this.handleChangeInput(e, "legacyname", "")} />
                                    </InputGroup>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Legacy Type :</Form.Label>
                                    <Row>
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check inline label="IN" name="LegacyType" type='radio' id='LegacyTypeIn' value="IN_WAIT" defaultChecked={this.state.formData['legacytype'] == "IN_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check inline label="OUT" name="LegacyType" type='radio' id='LegacyTypeOut' value="OUT_WAIT" defaultChecked={this.state.formData['legacytype'] == "OUT_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check inline label="DMS" name="LegacyType" type='radio' id='LegacyTypeDms' value="DMS_WAIT" defaultChecked={this.state.formData['legacytype'] == "DMS_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check inline label="DMS_DYN" name="LegacyType" type='radio' id='LegacyTypeDMS_DYN' value="DMS_DYN_WAIT" defaultChecked={this.state.formData['legacytype'] == "DMS_DYN_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check inline label="DMS_ETAX" name="LegacyType" type='radio' id='LegacyTypeDMS_ETAX' value="DMS_ETAX_WAIT" defaultChecked={this.state.formData['legacytype'] == "DMS_ETAX_WAIT" ? true : false} onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={4} md={3} lg={2}>
                                            <Form.Check
                                                inline
                                                label="EDI_CON"
                                                name="LegacyType"
                                                type='radio'
                                                id='EdiCon'
                                                value="EDI_CON"
                                                defaultChecked={this.state.formData['legacytype'] == "EDI_CON"}
                                                onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />
                                        </Col>
                                        {/*<Col xs={4} md={3} lg={2}>*/}
                                        {/*    <Form.Check*/}
                                        {/*        inline*/}
                                        {/*        label="EDI_EXP_CON"*/}
                                        {/*        name="LegacyType"*/}
                                        {/*        type='radio'*/}
                                        {/*        id='EdiExpCon'*/}
                                        {/*        value="EDI_EXP_CON"*/}
                                        {/*        defaultChecked={this.state.formData['legacytype'] == "EDI_EXP_CON"}*/}
                                        {/*        onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />*/}
                                        {/*</Col>*/}
                                        {/*<Col xs={4} md={3} lg={2}>*/}
                                        {/*    <Form.Check*/}
                                        {/*        inline*/}
                                        {/*        label="EDI_EXP_FUN_CON"*/}
                                        {/*        name="LegacyType"*/}
                                        {/*        type='radio'*/}
                                        {/*        id='EdiExpFunCon'*/}
                                        {/*        value="EDI_EXP_FUN_CON"*/}
                                        {/*        defaultChecked={this.state.formData['legacytype'] == "EDI_EXP_FUN_CON"}*/}
                                        {/*        onChange={(e) => this.handleChangeInput(e, "legacytype", "")} />*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={6} md={3}>
                                    <Form.Label className="font-weight-bold">Is Active :</Form.Label>
                                    {/* <InputGroup className="mb-3">
                                    <FormControl id="isactive" aria-describedby="basic-addon3" value={this.state.formData['isactive']} onChange={(e) => this.handleChangeInput(e, "isactive")} />
                                    </InputGroup> */}
                                    <InputGroup className="mb-3 mt-1">
                                        <BootstrapSwitchButton
                                            checked={this.state.formData['isactive'] == "Y"
                                                ? true
                                                : false
                                            }
                                            onlabel='Y'
                                            offlabel='N'
                                            onChange={(checked: boolean) => {
                                                this.onchangeSwitch(checked, 'isactive')
                                            }}
                                            size="sm"
                                            onstyle="info"
                                            offstyle="dark"
                                            width={80}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Label className="font-weight-bold">Suspend When Error :</Form.Label>
                                    <InputGroup className="mb-3 mt-1">
                                        <BootstrapSwitchButton
                                            checked={this.state.formData['stopif_step4_error'] == "Y"
                                                ? true
                                                : false
                                            }
                                            onlabel='Y'
                                            offlabel='N'
                                            onChange={(checked: boolean) => {
                                                this.onchangeSwitch(checked, 'stopif_step4_error')
                                            }}
                                            size="sm"
                                            onstyle="info"
                                            offstyle="dark"
                                            width={80}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Cron trigger :
                                            </Form.Label>
                                            <InputGroup className="mb-3"> */}
                                {/* <FormControl id="cron_trigger" value={this.state.contriger_string} onChange={(e) => this.handleChangeContrigger(e)} /> */}
                                {/* <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddContrigger()}> */}
                                {/* <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleShowCronModal()}>
                                                    <FaPlusCircle />
                                                    &nbsp;Add Crontriger
                                                </Button> */}
                                {/*<ReCron />*/}
                                {/*<div className='input-group'>*/}
                                {/*    <InputTags values={this.state.formData['cron_trigger']} onTags={(value) => this.handleChangeContrigger(value, 'cron_trigger')} />*/}
                                {/*</div>*/}
                                {/* </InputGroup>
                                        </Col>
                                    </Row>
                                </Col> */}
                                <Col md={6}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Cron trigger List :
                                            </Form.Label>
                                            <div className="d-flex mb-3">
                                                <Button className="text-nowrap" variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleShowCronModal()}>
                                                    <FaPlusCircle />
                                                    &nbsp;Add Crontriger
                                                </Button>
                                                <Card className="w-100">
                                                    <Card.Body className="list_card">
                                                        <Row>
                                                            {this.state.contriger_list.length != 0 && this.state.contriger_list.map((item: string, idx: any) => {
                                                                return (
                                                                    <Col lg={3}>
                                                                        <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                            {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteContrig(idx)} />
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Label className="font-weight-bold">Suspend When Warning :</Form.Label>
                                    <InputGroup className="mb-3 mt-1">
                                        <BootstrapSwitchButton
                                            checked={this.state.formData['criteria_stopif_mainstatus_warning'] == "Y"
                                                ? true
                                                : false
                                            }
                                            onlabel='Y'
                                            offlabel='N'
                                            onChange={(checked: boolean) => {
                                                this.onchangeSwitch(checked, 'criteria_stopif_mainstatus_warning')
                                            }}
                                            size="sm"
                                            onstyle="info"
                                            offstyle="dark"
                                            width={80}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Label className="font-weight-bold">
                                        Legacy ID :
                                    </Form.Label>
                                    {/*<div className="form-inline">*/}
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-start">
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddLegacyIDList()}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={{ zIndex: 2000, position: 'relative' }}>
                                                <AutocompleteLegacyID keyReq='sftp_source_server_id' companycode={this.state.formData['companycode']} handlechageLegacyID={this.handlechageLegacyID.bind(this)} selectedValue={this.state.legacyID_List} ResetSelected={this.state.checkLegacySelected} callBackResetSelected={() => this.setState({ checkLegacySelected: false })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/*</div>*/}
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">
                                        Start After Legacy ID List Success :
                                    </Form.Label>
                                    <Card className="w-100">
                                        <Card.Body className="list_card">
                                            <Row>
                                                {this.state.legacyID_List.length != 0 && this.state.legacyID_List.map((item: string, idx: any) => {
                                                    return (
                                                        <Col lg={3}>
                                                            <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteLegacyID(idx)} />
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        {this.state.formData['legacytype'] != "OUT_WAIT" &&
                            <>
                                <div className='trapezoid-head' style={{ width: '200px' }}>
                                    <span>
                                        Source sFTP Server
                                    </span>
                                </div>
                                <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                                    <Row className="mt-2">
                                        <Col>
                                            <Form.Label className="font-weight-bold">sFTP Source Server :</Form.Label>
                                            <AutocompleteSftpServer keyReq='sftp_source_server_id' sid={this.state.formData['sid']} companycode={this.state.formData['companycode']} handlechageSftpServerID={this.handlechageAutoComplete.bind(this)} valueSearch={this.state.formData['sftp_source_server_id']} />
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">sFTP Source Path :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="sftp_source_path" aria-describedby="basic-addon3" value={this.state.formData['sftp_source_path']} onChange={(e) => this.handleChangeInput(e, "sftp_source_path", "")} />
                                                <InputGroup.Append>
                                                    <Button variant="outline-secondary" size="sm" onClick={() => this.handleGetSftpPath('sftp_source_path')}><FaFolderOpen /></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">sFTP Source Format Filename :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="sftp_source_formatfilename" aria-describedby="basic-addon3" value={this.state.formData['sftp_source_formatfilename']} onChange={(e) => this.handleChangeInput(e, "sftp_source_formatfilename", "")} />
                                                <InputGroup.Append>
                                                    <Button variant="outline-secondary" size="sm" onClick={() => this.handleCloseModalValidate(false)}><FaCheckCircle /></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">sFTP Source File Type :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="sftp_source_filetype" aria-describedby="basic-addon3" value={this.state.formData['sftp_source_filetype']} onChange={(e) => this.handleChangeInput(e, "sftp_source_filetype", "")} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Backup sFTP Local Path :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="sftp_source_dest_localpath" aria-describedby="basic-addon3" value={this.state.formData['sftp_source_dest_localpath']} onChange={(e) => this.handleChangeInput(e, "sftp_source_dest_localpath", "")} disabled />
                                                <InputGroup.Append>
                                                    <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('sftp_source_dest_localpath')}><FaPen /></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">sFTP Source Is Delete Source :</Form.Label>
                                            <InputGroup className="mb-3 mt-1">
                                                <BootstrapSwitchButton
                                                    checked={this.state.formData['sftp_source_isdeletesource'] == "Y"
                                                        ? true
                                                        : false
                                                    }
                                                    onlabel='Y'
                                                    offlabel='N'
                                                    onChange={(checked: boolean) => {
                                                        this.onchangeSwitch(checked, 'sftp_source_isdeletesource')
                                                    }}
                                                    size="sm"
                                                    onstyle="info"
                                                    offstyle="dark"
                                                    width={80}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                Destination sFTP Server
                            </span>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row className="mt-2">
                                <Col>
                                    <Form.Label className="font-weight-bold">sFTP Return Server :</Form.Label>
                                    <AutocompleteSftpServer keyReq='sftp_return_server_id' sid={this.state.formData['sid']} companycode={this.state.formData['companycode']} handlechageSftpServerID={this.handlechageAutoComplete.bind(this)} valueSearch={this.state.formData['sftp_return_server_id']} />
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">sFTP Return Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="sftp_return_path" aria-describedby="basic-addon3" value={this.state.formData['sftp_return_path']} onChange={(e) => this.handleChangeInput(e, "sftp_return_path", "")} />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleGetSftpReturnPath('sftp_return_path')}><FaFolderOpen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                {/*<Col>*/}
                                {/*    <Form.Label className="font-weight-bold">sFTP Return Format File Name :</Form.Label>*/}
                                {/*    <InputGroup className="mb-3">*/}
                                {/*        <FormControl id="sftp_return_formatfilename" aria-describedby="basic-addon3" value={this.state.formData['sftp_return_formatfilename']} onChange={(e) => this.handleChangeInput(e, "sftp_return_formatfilename", "")} />*/}
                                {/*    </InputGroup>*/}
                                {/*</Col>*/}
                                <Col>
                                    <Form.Label className="font-weight-bold">Backup sFTP Return Success Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="sftp_return_success_path" aria-describedby="basic-addon3" value={this.state.formData['sftp_return_success_path']} onChange={(e) => this.handleChangeInput(e, "sftp_return_success_path", "")} disabled />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('sftp_return_success_path')}><FaPen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">Backup sFTP Return Error Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="sftp_return_error_path" aria-describedby="basic-addon3" value={this.state.formData['sftp_return_error_path']} onChange={(e) => this.handleChangeInput(e, "sftp_return_error_path", "")} disabled />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('sftp_return_error_path')}><FaPen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                EDI Config
                            </span>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row className="mt-2">
                                <Col>
                                    <Form.Label className="font-weight-bold">EDI Source Job Version :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            id="edi_source_job_version"
                                            aria-describedby="basic-addon3"
                                            value={this.state.formData['edi_source_job_version']}
                                            onChange={(e) => this.handleChangeInput(e, "edi_source_job_version", "")}
                                        />
                                        {/*<InputGroup.Append>*/}
                                        {/*    <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('edi_source_job_version')}><FaPen /></Button>*/}
                                        {/*</InputGroup.Append>*/}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">EDI Source result Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            id="edi_source_result_path"
                                            aria-describedby="basic-addon3"
                                            value={this.state.formData['edi_source_result_path']}
                                            onChange={(e) => this.handleChangeInput(e, "edi_source_result_path", "")}
                                            disabled
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => this.handleDisabledInput('edi_source_result_path')}
                                            >
                                                <FaPen />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="font-weight-bold">EDI Source Success Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            id="edi_source_success_path"
                                            aria-describedby="basic-addon3"
                                            value={this.state.formData['edi_source_success_path']}
                                            onChange={(e) => this.handleChangeInput(e, "edi_source_success_path", "")}
                                            disabled
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => this.handleDisabledInput('edi_source_success_path')}
                                            >
                                                <FaPen />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col lg={6}>
                                    <Form.Label className="font-weight-bold">EDI Source Error Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            id="edi_source_error_path"
                                            aria-describedby="basic-addon3"
                                            value={this.state.formData['edi_source_error_path']}
                                            onChange={(e) => this.handleChangeInput(e, "edi_source_error_path", "")}
                                            disabled
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => this.handleDisabledInput('edi_source_error_path')}
                                            >
                                                <FaPen />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                API Server
                            </span>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row className="mt-2">
                                <Col>
                                    <Form.Label className="font-weight-bold">API Server ID :</Form.Label>
                                    <AutocompleteApiServerID keyReq='api_serverid' sid={this.state.formData['sid']} companycode={this.state.formData['companycode']} handlechageApiServerID={this.handlechageAutoComplete.bind(this)} valueSearch={this.state.formData['api_serverid']} />
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">API Class Name :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="api_classname" aria-describedby="basic-addon3" value={this.state.formData['api_classname']} onChange={(e) => this.handleChangeInput(e, "api_classname", "")} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">API Function Name :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="api_function_name" aria-describedby="basic-addon3" value={this.state.formData['api_function_name']} onChange={(e) => this.handleChangeInput(e, "api_function_name", "")} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="font-weight-bold">Backup Source Files when Call API Success Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="api_success_path" aria-describedby="basic-addon3" value={this.state.formData['api_success_path']} onChange={(e) => this.handleChangeInput(e, "api_success_path", "")} disabled />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('api_success_path')}><FaPen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="font-weight-bold">Backup Source Files when Call API Error Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="api_error_path" aria-describedby="basic-addon3" value={this.state.formData['api_error_path']} onChange={(e) => this.handleChangeInput(e, "api_error_path", "")} disabled />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('api_error_path')}><FaPen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Label className="font-weight-bold">Backup Return Files Path :</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="api_result_path" aria-describedby="basic-addon3" value={this.state.formData['api_result_path']} onChange={(e) => this.handleChangeInput(e, "api_result_path", "")} disabled />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" size="sm" onClick={() => this.handleDisabledInput('api_result_path')}><FaPen /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    {/*<Form.Label className="font-weight-bold">Suspend When API Function Error :</Form.Label>*/}
                                    {/*<InputGroup className="mb-3 mt-1">*/}
                                    {/*    <BootstrapSwitchButton*/}
                                    {/*        checked={this.state.formData['stopif_step4_error'] == "Y"*/}
                                    {/*            ? true*/}
                                    {/*            : false*/}
                                    {/*        }*/}
                                    {/*        onlabel='Y'*/}
                                    {/*        offlabel='N'*/}
                                    {/*        onChange={(checked: boolean) => {*/}
                                    {/*            this.onchangeSwitch(checked, 'stopif_step4_error')*/}
                                    {/*        }}*/}
                                    {/*        size="sm"*/}
                                    {/*        onstyle="info"*/}
                                    {/*        offstyle="dark"*/}
                                    {/*        width={80}*/}
                                    {/*    />*/}
                                    {/*</InputGroup>*/}
                                </Col>
                            </Row>
                        </div>
                        {(this.state.formData['legacytype'] == "DMS_WAIT" || this.state.formData['legacytype'] == "DMS_DYN_WAIT" || this.state.formData['legacytype'] == "DMS_ETAX_WAIT") &&
                            <>
                                <div className='trapezoid-head' style={{ width: '200px' }}>
                                    <span>
                                        DMS Server
                                    </span>
                                </div>
                                <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Server ID :</Form.Label>
                                            <AutocompleteDmsServerID keyReq='dms_serverid' sid={this.state.formData['sid']} companycode={this.state.formData['companycode']} handlechageDmsServerID={this.handlechageAutoComplete.bind(this)} valueSearch={this.state.formData['dms_serverid']} />
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Bucket :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="dms_bucket" value={this.state.formData['dms_bucket']} onChange={(e) => this.handleChangeInput(e, "dms_bucket", "")} disabled={this.state.formData['legacytype'] == "DMS_DYN_WAIT" ? true : false} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Box ID :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="dms_boxid" value={this.state.formData['dms_boxid']} onChange={(e) => this.handleChangeInput(e, "dms_boxid", "")} disabled={this.state.formData['legacytype'] == "DMS_DYN_WAIT" ? true : false} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Role Access :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="dms_roleaccess" value={this.state.formData['dms_roleaccess']} onChange={(e) => this.handleChangeInput(e, "dms_roleaccess", "")} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Group :</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="dms_group" value={this.state.formData['dms_group']} onChange={(e) => this.handleChangeInput(e, "dms_group", "")} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Form.Label className="font-weight-bold">Dms Group ID:</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="dms_groupid" value={this.state.formData['dms_groupid']} onChange={(e) => this.handleChangeInput(e, "dms_groupid", "")} disabled={this.state.formData['legacytype'] == "DMS_DYN_WAIT" ? true : false} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                        <div className='d-flex'>
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span> Job Finished Email Notification</span>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <label className="font-weight-bold w-100 pr-4">Is Active :</label>
                                <InputGroup className="mb-3 mt-1">
                                    <BootstrapSwitchButton
                                        checked={this.state.email_finished_is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onChangeEmailStatus('email_finished_is_active', checked ? 'Y' : 'N')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_to" value={this.state.email_finished_to_string} onChange={(e) => this.handleChangeEmail(e, "finished_to")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("finished_to")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To List :
                                            </Form.Label>
                                            <Card className={this.state.email_finished_to_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_finished_to_list.length != 0 && this.state.email_finished_to_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "finished_to")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_cc" value={this.state.email_finished_cc_string} onChange={(e) => this.handleChangeEmail(e, "finished_cc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("finished_cc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc List :
                                            </Form.Label>
                                            <Card className={this.state.email_finished_cc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_finished_cc_list.length != 0 && this.state.email_finished_cc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "finished_cc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc :
                                            </Form.Label>
                                            <InputGroup className="mb-3" >
                                                <FormControl id="email_bcc" value={this.state.email_finished_bcc_string} onChange={(e) => this.handleChangeEmail(e, "finished_bcc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("finished_bcc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc List :
                                            </Form.Label>
                                            <Card className={this.state.email_finished_bcc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_finished_bcc_list.length != 0 && this.state.email_finished_bcc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "finished_bcc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-flex'>
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>Job Warning Email Notification</span>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <label className="font-weight-bold w-100 pr-4">Is Active :</label>
                                <InputGroup className="mb-3 mt-1">
                                    <BootstrapSwitchButton
                                        checked={this.state.email_warning_is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onChangeEmailStatus('email_warning_is_active', checked ? 'Y' : 'N')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_to" value={this.state.email_warning_to_string} onChange={(e) => this.handleChangeEmail(e, "warning_to")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("warning_to")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To List :
                                            </Form.Label>
                                            <Card className={this.state.email_warning_to_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_warning_to_list.length != 0 && this.state.email_warning_to_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "warning_to")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_cc" value={this.state.email_warning_cc_string} onChange={(e) => this.handleChangeEmail(e, "warning_cc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("warning_cc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc List :
                                            </Form.Label>
                                            <Card className={this.state.email_warning_cc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_warning_cc_list.length != 0 && this.state.email_warning_cc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "warning_cc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc :
                                            </Form.Label>
                                            <InputGroup className="mb-3" >
                                                <FormControl id="email_bcc" value={this.state.email_warning_bcc_string} onChange={(e) => this.handleChangeEmail(e, "warning_bcc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("warning_bcc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc List :
                                            </Form.Label>
                                            <Card className={this.state.email_warning_bcc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_warning_bcc_list.length != 0 && this.state.email_warning_bcc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "warning_bcc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-flex'>
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>Job Error Email Notification</span>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <label className="font-weight-bold w-100 pr-4">Is Active :</label>
                                <InputGroup className="mb-3 mt-1">
                                    <BootstrapSwitchButton
                                        checked={this.state.email_error_is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onChangeEmailStatus('email_error_is_active', checked ? 'Y' : 'N')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_to" value={this.state.email_error_to_string} onChange={(e) => this.handleChangeEmail(e, "error_to")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("error_to")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email To List :
                                            </Form.Label>
                                            <Card className={this.state.email_error_to_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_error_to_list.length != 0 && this.state.email_error_to_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "error_to")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_cc" value={this.state.email_error_cc_string} onChange={(e) => this.handleChangeEmail(e, "error_cc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("error_cc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Cc List :
                                            </Form.Label>
                                            <Card className={this.state.email_error_cc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_error_cc_list.length != 0 && this.state.email_error_cc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "error_cc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc :
                                            </Form.Label>
                                            <InputGroup className="mb-3" >
                                                <FormControl id="email_bcc" value={this.state.email_error_bcc_string} onChange={(e) => this.handleChangeEmail(e, "error_bcc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("error_bcc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Bcc List :
                                            </Form.Label>
                                            <Card className={this.state.email_error_bcc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_error_bcc_list.length != 0 && this.state.email_error_bcc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "error_bcc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-flex'>
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>Function Error Email Notification</span>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <label className="font-weight-bold w-100 pr-4">Is Active :</label>
                                <InputGroup className="mb-3 mt-1">
                                    <BootstrapSwitchButton
                                        checked={this.state.email_function_error_is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onChangeEmailStatus('email_function_error_is_active', checked ? 'Y' : 'N')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="mat-box shadow-sm p-3 mb-5 bg-white rounded">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error To :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_err_to" value={this.state.email_f1_err_to_string} onChange={(e) => this.handleChangeEmail(e, "f1_error_to")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("f1_error_to")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error To List :
                                            </Form.Label>
                                            <Card className={this.state.email_f1_err_to_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_f1_err_to_list.length != 0 && this.state.email_f1_err_to_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "f1_error_to")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error Cc :
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl id="email_err_cc" value={this.state.email_f1_err_cc_string} onChange={(e) => this.handleChangeEmail(e, "f1_error_cc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("f1_error_cc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error Cc List :
                                            </Form.Label>
                                            <Card className={this.state.email_f1_err_cc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_f1_err_cc_list.length != 0 && this.state.email_f1_err_cc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "f1_error_cc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error Bcc :
                                            </Form.Label>
                                            <InputGroup className="mb-3" >
                                                <FormControl id="email_err_bcc" value={this.state.email_f1_err_bcc_string} onChange={(e) => this.handleChangeEmail(e, "f1_error_bcc")} />
                                                <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddEmail("f1_error_bcc")}>
                                                    <FaPlusCircle />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label className="font-weight-bold">
                                                Email Error Bcc List :
                                            </Form.Label>
                                            <Card className={this.state.email_f1_err_bcc_list.length != 0 ? "h-50" : "h-75"}>
                                                <Card.Body className="list_card">
                                                    <Row>
                                                        {this.state.email_f1_err_bcc_list.length != 0 && this.state.email_f1_err_bcc_list.map((item: string, idx: any) => {
                                                            return (
                                                                <Col lg={4}>
                                                                    <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                        {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteEmail(idx, "f1_error_bcc")} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <div className="d-flex justify-content-start">
                                <Button variant="info" onClick={this.handleTestConnect}>
                                    <FaPlayCircle /> Test Connection
                                </Button>
                            </div>
                        </Col>
                        <Button variant="danger" onClick={this.handleClose}>
                            <FaTimesCircle /> Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSave}>
                            <FaCheckCircle /> Save
                        </Button>
                    </Modal.Footer>
                </Modal >

                {/*Create From Template*/}
                < Modal show={this.state.isShowTempModal} dialogClassName="custom-modal" onHide={this.handleCloseTemModal} size="lg" backdrop="static" keyboard={false} >
                    <Modal.Header translate closeButton>
                        <Modal.Title>Create From Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Select Job Config :</Form.Label>
                                    <Form.Control as="select" onChange={(e) => this.handleSelectTemplate(e)}>
                                        <option></option>
                                        {this.state.dataTemplate.length != 0 && this.state.dataTemplate.map((item: JobConfigModels.JobTemplate, idx: any) => {
                                            return (
                                                <option key={idx} value={item.label}>{item.label}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                        <Button variant="primary" onClick={() => this.handleClickTemplate()}>
                            <FaCheckCircle /> Create
                        </Button>
                    </Modal.Footer>
                </Modal >

                {/*Test Status*/}
                < Modal show={this.state.isShowTestStatusModal} onHide={this.handleCloseTestModal} size="lg" dialogClassName="modal-fitscreen" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal' >
                    <Modal.Header translate closeButton >
                        <Modal.Title>Test Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Row className="text-center">
                            <Col>
                                <Card>
                                    <Card.Body >
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_sFTPSource == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>Has sFTP Source Path</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body >
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_sFTPDest == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>Has sFTP Return Path</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body >
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_Fapp == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>API Server Connection</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_sFTP_Source == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label >sFTP Source Server Connection</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_sFTP == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label >sFTP Return Server Connection</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body style={{ height: '175px' }}>
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>{this.state.status_Job == 200 ? <FaCheckCircle size={48} color="green" /> : <FaTimesCircle size={48} color="red" />}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label>Has Function</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleCloseTestModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal >

                <Modal show={this.state.isShowSftpPathModal} onHide={this.handleClosePathModal} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Select Path </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SftpPathFolder selectFolder={this.selectFolder.bind(this)} JobConfigPath={this.state.JobConfigPath} listFolder={listFolder} handleSelectedPath={this.handleSelectedPath.bind(this)} keyPath={this.state.keyPath} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.handleSaveFolder}>
                            <FaCheckCircle /> Save
                        </Button>
                        <Button variant="danger" onClick={this.handleClosePathModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isShowCronModal} dialogClassName="custom-modal" onHide={this.handleCloseCronModal} size="lg" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>Select Cron trigger </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{
                            backgroundColor: "#ddf4fb", borderColor: "#c6edf9", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px",
                            paddingTop: "10px"
                        }}>
                            <Form.Label >{this.state.contriger_string != "" ? cronstrue.toString(this.state.contriger_string) : ''}</Form.Label>
                        </div>
                        <br />
                        <Row>
                            <Col>
                                <Form.Label className="font-weight-bold">
                                    Cron trigger :
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="cron_trigger" value={this.state.contrigerCheck} onChange={(e) => this.handleChangeContriggerCheck(e)} />
                                    {/*<Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleAddContrigger()}>*/}
                                    <Button variant="outline-secondary" id="button-addon2" size="sm" onClick={() => this.handleValidatorContrigger()}>
                                        <FaPlusCircle />
                                        &nbsp;Validator
                                    </Button>
                                    {/*<ReCron />*/}
                                    {/*<div className='input-group'>*/}
                                    {/*    <InputTags values={this.state.formData['cron_trigger']} onTags={(value) => this.handleChangeContrigger(value, 'cron_trigger')} />*/}
                                    {/*</div>*/}
                                </InputGroup>
                            </Col>
                        </Row>
                        <ReCron value={this.state.contriger_string}
                            onChange={(e) => this.handleChangeCron(e)}></ReCron>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex flex-row" style={{ width: "75%" }}>
                            <FormControl id="cron_triggerShow" value={this.state.contriger_string} style={{ width: "70%" }} disabled={true} />
                        </div>

                        <Button variant="info" onClick={() => this.handleAddContrigger()}>
                            <FaCheckCircle /> OK
                        </Button>
                        <Button variant="danger" onClick={() => this.handleCloseCronModal()}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModalValidate} onHide={() => this.handleCloseModalValidate(false)} size="xl" backdrop="static" keyboard={false} backdropClassName='front-backdrop-modal'>
                    <Modal.Header translate closeButton>
                        <Modal.Title>sFTP Source Format Filename Validate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <div className="bg-light text-dark">
                                    <table>
                                        <tr style={{ width: '100%' }}>
                                            <td className="font-weight-bold" style={{ width: '40%' }}>Regular :</td>
                                            {/*<td style={{ width: '60%' }}>{this.state.formData.sftp_source_formatfilename}</td>*/}
                                            <td style={{ width: '60%' }}>
                                                <FormControl id="sFTP_Source_Filename" value={this.state.SftpRegexDraft} onChange={(e) => this.setState({ SftpRegexDraft: e.target.value })} />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label className="font-weight-bold">
                                            sFTP Source Format Filename :
                                        </Form.Label>
                                        <InputGroup className="mb-3" >
                                            <FormControl id="sFTP_Source_Filename" value={this.state.SftpFileNameDraft} onChange={(e) => this.setState({ SftpFileNameDraft: e.target.value })} />
                                            <Button variant="outline-secondary" id="button-handleAddSftpSourceFileName" size="sm" onClick={() => this.handleAddSftpSourceFileName()}>
                                                <FaPlusCircle />
                                            </Button>
                                            <Button variant="outline-secondary" id="button-handleValidateSftpSourceFileName" size="sm" onClick={() => this.handleValidateSftpSourceFileName(this.state.SftpRegexDraft)}>
                                                <FaRandom />
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="font-weight-bold">
                                            sFTP Source Format Filename List :
                                        </Form.Label>
                                        <Card className={this.state.SftpFileNameList.length != 0 ? "" : "h-75"}>
                                            <Card.Body className="list_card">
                                                <Row>
                                                    {this.state.SftpFileNameList.length != 0 && this.state.SftpFileNameList.map((item: string, idx: any) => {
                                                        return (
                                                            <Col>
                                                                <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                    {item} <FaTimesCircle style={{ color: 'red', float: 'right' }} onClick={() => this.handleDeleteSftpSourceFileName(idx)} />
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label className="font-weight-bold">
                                            sFTP Source Format Filename Validate Success :
                                        </Form.Label>
                                        <Card className={this.state.SftpFileNameValidate.length != 0 ? "h-50" : "h-75"}>
                                            <Card.Body className="list_card">
                                                <Row>
                                                    {this.state.SftpFileNameValidate.length != 0 && this.state.SftpFileNameValidate.map((item: string, idx: any) => {
                                                        return (
                                                            <Col>
                                                                <div key={idx} style={{ backgroundColor: '#dee2e6', borderRadius: '3em', textAlign: 'center', fontSize: '1rem' }}>
                                                                    {item}
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={() => this.handleCloseModalValidate(true)}>
                            <FaCheckCircle /> OK
                        </Button>
                        <Button variant="danger" onClick={() => this.handleCloseModalValidate(false)}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Export Excel*/}
                {/*<JobConfigExportExcelComponent*/}
                {/*    openModalExportExcel={this.state.openModalExportExcel}*/}
                {/*    toggleModalExportExcel={this.toggleModalExportExcel}*/}
                {/*    toggleLoadingScreen={this.toggleLoadingScreen}*/}
                {/*    filter={this.state.searchJobReq}*/}
                {/*/>*/}
                
            </Container >
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.JobConfig, ...state.Loading, ...state.SftpConfig, ...state.ApiConfig, ...state.ReSchedule, ...state.TestSftp, ...state.TestSftp, ...state.TestFapp, ...state.TestJob, ...state.TestSftpSource, ...state.lisCommand }), ({ ...JobConfigStore.actionCreators, ...LoadingStore.actionCreators, ...SftpConfigStore.actionCreators, ...ApiConfigStore.actionCreators, ...ReSchedulerStore.actionCreators, ...SftpTestStore.actionCreators, ...TestFappStore.actionCreators, ...JobTestStore.actionCreators, ...SftpTestSourceStore.actionCreators, ...lisCommandStore.actionCreators }))(MasterConfig)
