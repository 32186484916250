import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';

import { Spinner } from "react-bootstrap";
import Loader from 'react-loader-advanced';
import './custom.css'

import { connect } from "react-redux";
import { ApplicationState } from './store';

import * as LoadingStore from './store/Loading'
import * as LoadingModels from './models/config/Loading'
type store = LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class App extends React.Component<any, {}, store>{
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Loader show={this.props.statusLoading} message={<Spinner animation="border" />} backgroundStyle={{ zIndex: 2000 }}>
                <Layout />
            </Loader>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.Loading }), ({ ...LoadingStore.actionCreators }))(App)