import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/testConnect/JobConnection'

interface LoadJobTest {
    type: 'LOAD_JOBTEST'
}

interface LoadedJobTest {
    type: 'LOADEDJOBTEST'
    response: Models.ResponseTestJob
    status: number,
    statusMessage: string
}
interface FailLoadedJobTest {
    type: 'LOADJOBTESTFAIL'
    status: number,
    statusMessage: any
}
interface ClearJobTest {
    type: 'CLEARJOBTEST'
}

type KnownAction = LoadJobTest | FailLoadedJobTest | LoadedJobTest | ClearJobTest

export const actionCreators = {
    requestGetJobTest: (check: boolean, method: string, formBody: Models.JobModelTestConnect): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.TestJob && check && method == 'POST') {
            console.log('call actionCreators : requestGetJobTest')
            fetch(`/v1/test/job/connection` , {
               method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formBody),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADJOBTESTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDJOBTEST', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
                    //dispatch({ type: 'LOADEDJOBTEST', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'LOADJOBTESTFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'LOAD_JOBTEST' });

        } else if (appState && appState.TestJob && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARJOBTEST' });
        }

    }
}

const unloadedState: Models.TestJobState = { isLoadTestJob: false };

export const reducer: Reducer<Models.TestJobState> = (state: Models.TestJobState | undefined, incomingAction: Action): Models.TestJobState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_JOBTEST':
            return {
                isLoadTestJob: false,
            }

        case 'LOADEDJOBTEST':
            return {
                isLoadTestJob: true,
                responseTestJob: action.response as any,
                statusTestJob: action.status
            }

        case 'LOADJOBTESTFAIL':
            return {
                isLoadTestJob: true,
                messageTestJob: action.statusMessage,
                statusTestJob: action.status
            }

        case 'CLEARJOBTEST':
            return {
                isLoadTestJob: false,
                messageTestJob: '',
                statusTestJob: 400
            }
        default: return state;
    }
}
