import * as React from "react";
import ReactExport from "react-data-export";
import { Button } from 'react-bootstrap';
import { FaFileExcel } from "react-icons/fa";
import { formatYMDToDMY } from "../modules/Common";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class Download extends React.Component<any, {} > {
    constructor(props: any) {
        super(props);
    }

    render() {
        console.log(this.props.DataSet)
        return (
            <ExcelFile filename="test" element={<Button className="mx-1 btn btn-block" variant="success" size="sm"><FaFileExcel /> Export Excel</Button>}>
                <ExcelSheet data={this.props.DataSet} name={this.props.key}>
                    <ExcelColumn label="Legacy ID" value="sid" />
                    <ExcelColumn label="Company Code" value="companycode" />
                    <ExcelColumn label="Transaction ID" value="transaction_id" />
                    <ExcelColumn label="Source ID 1" value="source_id1" />
                    <ExcelColumn label="Source ID 2" value="source_id2" />
                    <ExcelColumn label="Source ID 3" value="source_id3" />
                    <ExcelColumn label="ERP Doc. Type" value="doc_type" />
                    <ExcelColumn label="ERP Doc. Year" value="doc_year" />
                    <ExcelColumn label="ERP Doc. No." value="item_no" />
                    <ExcelColumn label="Doc. Date"
                        value={(col) => formatYMDToDMY(col.doc_date)}/>
                    <ExcelColumn label="Post Date" value="post_date" />
                    <ExcelColumn label="Status"
                        value={(col) => col.status == "E" ? "Error" : "Success"} />
                    <ExcelColumn label="Message" value="message" />
                    <ExcelColumn label="Create On"
                        value={(col) => formatYMDToDMY(col.created_on)} />
                    <ExcelColumn label="Meta Data" value="metadata" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download;