import * as LegacyReportStore from './report/LegacyReport'
import * as LegacyReportModels from '../models/report/LegacyReport'

import * as LegacyTaskStore from './report/LegacyTask'
import * as LegacyTaskModels from '../models/report/LegacyTask'

import * as SystemLogStore from './report/SystemLog'
import * as SystemLogModels from '../models/report/SystemLog'

import * as ApiConfigStore from './config/ApiConfig'
import * as ApiConfigModels from '../models/config/ApiConfig'

import * as JobConfigStore from './config/JobConfig'
import * as JobConfigModels from '../models/config/JobConfig'

import * as SftpConfigStore from './config/SftpConfig'
import * as SftpConfigModels from '../models/config/SftpConfig'

import * as LoadingStore from './Loading'
import * as LoadingModels from '../models/config/Loading'

import * as TestFappStore from './testConnect/FappConnection'
import * as TestFappModels from '../models/testConnect/FappConnection'

import * as ReScheduleStore from './config/ReScheduler'
import * as ReScheduleModels from '../models/config/ReScheduler'

import * as TestSftpSourceStore from './testConnect/SftpSourceConnection'

import * as TestSftpStore from './testConnect/SftpConnection'
import * as TestSftpModels from '../models/testConnect/SftpConnection'

import * as TestJobStore from './testConnect/JobConnection'
import * as TestJobModels from '../models/testConnect/JobConnection'

import * as DmsConfigStore from './config/DmsConfig'
import * as DmsConfigModels from '../models/config/DmsConfig'

import * as QuartzMonitorStore from './config/QuartzMonitor'
import * as QuartzMonitorModels from '../models/config/QuartzMonitor'

import * as lisCommandStore from './liscommand/liscommand'
import * as lisCommandModels from '../models/liscommand/liscommand'

import * as EmployeeModel from '../models/config/Employee';
import * as Employee from './config/EmployeeMaster';

import * as CompanyMasterModel from '../models/companymaster/CompanyMaster';
import * as CompanyMaster from './companymaster/CompanyMaster';

import * as EmailContentModel from '../models/config/EmailContent'
import * as EmailContentStore from './config/EmailContent'

import * as EnvModel from '../models/common/Env'
import * as EnvStore from './common/Env'

import * as ApplicationLogStore from './report/ApplicationLog'
import * as ApplicationLogModels from '../models/report/ApplicationLog'

import * as DailyReportStore from './report/DailyReport'
import * as DailyReportModels from '../models/report/DailyReport'

import * as ExpectedRunJobStore from './report/ExpectedRunJob'
import * as ExpectedRunJobModels from '../models/report/ExpectedRunJob'

// App State
import * as AppState from './AppState'
import * as ModelsAppState from '../models/AppState'

// The top-level state object
export interface ApplicationState {
    legacyReport: LegacyReportModels.GetLegacyReportState | undefined;
    legacyTask: LegacyTaskModels.GetLegacyTaskState | undefined;
    systemLog: SystemLogModels.GetSystemLogState | undefined;
    ApiConfig: ApiConfigModels.GetApiConfigState | undefined;
    JobConfig: JobConfigModels.GetJobConfigState | undefined;
    SftpConfig: SftpConfigModels.GetSftpConfigState | undefined;
    Loading: LoadingModels.LoadingState | undefined;
    TestFapp: TestFappModels.TestFappState | undefined;
    ReSchedule: ReScheduleModels.ReSchedulerConfigState | undefined;
    TestSftp: TestSftpModels.TestSftpState | undefined;
    TestSftpSource: TestSftpModels.TestSftpSourceState | undefined;
    TestJob: TestJobModels.TestJobState | undefined;
    DmsConfig: DmsConfigModels.GetDmsConfigState | undefined;
    lisCommand: lisCommandModels.TestSftpSourceState | undefined;
    employee: EmployeeModel.EmployeeState | undefined;
    CompanyMaster: CompanyMasterModel.CompanyMasterState | undefined;
    QuartzMonitor: QuartzMonitorModels.QuartzMonitorState | undefined;
    EmailContent: EmailContentModel.GetEmailContetState | undefined;
    Env: EnvModel.EnvState | undefined;
    appState: ModelsAppState.AppState | undefined;
    ApplicationLog: ApplicationLogModels.GetApplicationLogState | undefined;
    DailyReport: DailyReportModels.GetDailyReportState | undefined;
    ExpectedRunJob: ExpectedRunJobModels.GetExpectedRunJobState | undefined
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    legacyReport: LegacyReportStore.rootReducer,
    legacyTask: LegacyTaskStore.rootReducer,
    systemLog: SystemLogStore.reducer,
    ApiConfig: ApiConfigStore.rootReducer,
    JobConfig: JobConfigStore.rootReducer,
    SftpConfig: SftpConfigStore.rootReducer,
    Loading: LoadingStore.reducer,
    TestFapp: TestFappStore.reducer,
    ReSchedule: ReScheduleStore.rootReducer,
    TestSftp: TestSftpStore.reducer,
    TestSftpSource: TestSftpSourceStore.reducer,
    TestJob: TestJobStore.reducer,
    DmsConfig: DmsConfigStore.rootReducer,
    lisCommand: lisCommandStore.rootReducer,
    employee: Employee.reducer,
    CompanyMaster: CompanyMaster.reducer,
    QuartzMonitor: QuartzMonitorStore.rootReducer,
    EmailContent: EmailContentStore.rootReducer,
    Env: EnvStore.reducer,
    appState: AppState.reducer,
    ApplicationLog: ApplicationLogStore.reducer,
    DailyReport: DailyReportStore.reducer,
    ExpectedRunJob: ExpectedRunJobStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
