import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';

import * as Models from '../../models/config/EmailContent'

interface LoadEmailContent {
    type: 'LOAD_EMAILCONTENT'
}
interface LoadedEmailContent {
    type: 'LOADEDEMAILCONTENT'
    response: Models.ResEmailContent
    status: number,
    statusMessage: string
}
interface FailLoadedEmailContent {
    type: 'LOADEMAILCONTENTFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedEmailContent {
    type: 'CLEARLOADEDEMAILCONTENT'
}

interface AddEmailContent {
    type: 'ADD_EMAILCONTENT'
}
interface AddedEmailContent {
    type: 'ADDEDEMAILCONTENT'
    response: string
    status: number,
    statusMessage: string
}
interface FailAddedEmailContent {
    type: 'ADDEMAILCONTENTFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedEmailContent {
    type: 'CLEARADDEMAILCONTENT'
}

type KnownAction = LoadEmailContent | FailLoadedEmailContent | ClearLoadedEmailContent | AddEmailContent | AddedEmailContent | FailAddedEmailContent | ClearAddedEmailContent | LoadedEmailContent

export const actionCreators = {
    requestLoadEmailContent: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.EmailContent && check && method == 'GET') {
            console.log('call actionCreators : requestLoadEmailContent')
            fetch(`/v1/config/email-content`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADEMAILCONTENTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDEMAILCONTENT', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_EMAILCONTENT' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDEMAILCONTENT' });
        }
    },

    requestSaveEmailContent: (check: boolean, method: string, req: Array<Models.EmailContent>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.EmailContent && check && method == 'POST') {
            console.log('call actionCreators : requestSaveEmailContent')
            fetch(`/v1/config/email-content`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDEMAILCONTENTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.text().then(data => {
                            console.log(data)
                            dispatch({ type: 'ADDEDEMAILCONTENT', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'ADD_EMAILCONTENT' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDEMAILCONTENT' });
        }
    }
}

const unloadedStateGet: Models.GetEmailContetState = { isLoadGetEmailContent: false };
const unloadedStateAdd: Models.SaveEmailContetState = { isLoadSaveEmailContent: false };

const reducerGet: Reducer<Models.GetEmailContetState> = (state: Models.GetEmailContetState | undefined, incomingAction: Action): Models.GetEmailContetState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_EMAILCONTENT':
            return {
                isLoadGetEmailContent: false,
            }

        case 'LOADEDEMAILCONTENT':
            return {
                isLoadGetEmailContent: true,
                responseGetEmailContent: action.response,
                statusGetEmailContent: action.status
            }

        case 'LOADEMAILCONTENTFAIL':
            return {
                isLoadGetEmailContent: true,
                messageGetEmailContent: action.statusMessage,
                statusGetEmailContent: action.status
            }

        case 'CLEARLOADEDEMAILCONTENT':
            return {
                isLoadGetEmailContent: false,
                messageGetEmailContent: "",
                statusGetEmailContent: 400
            }
        default: return state;
    }
}

const reducerSave: Reducer<Models.SaveEmailContetState> = (state: Models.SaveEmailContetState | undefined, incomingAction: Action): Models.SaveEmailContetState => {
    if (state == undefined) {
        return unloadedStateAdd;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'ADD_EMAILCONTENT':
            return {
                isLoadSaveEmailContent: false,
            }

        case 'ADDEDEMAILCONTENT':
            return {
                isLoadSaveEmailContent: true,
                responseSaveEmailContent: action.response,
                statusSaveEmailContent: action.status
            }

        case 'ADDEMAILCONTENTFAIL':
            return {
                isLoadSaveEmailContent: true,
                messageSaveEmailContent: action.statusMessage,
                statusSaveEmailContent: action.status
            }

        case 'CLEARADDEMAILCONTENT':
            return {
                isLoadSaveEmailContent: false,
                messageSaveEmailContent: "",
                statusSaveEmailContent: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetEmailContent: reducerGet, SaveEmailContent: reducerSave });