import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Models from '../models/AppState';

interface AppStateAction {
    type: 'UPDATE_COMPANY';
    company: string;
}
interface AppStatePathAction {
    type: 'UPDATE_PATH';
    pathUrl: string;
}

interface LoadAppStateEnvGet {
    type: 'LOAD_ENV';
}

interface AppStateEnvGet {
    type: 'GET_ENV';
    status: number,
    response: Models.EnvList,
    message: any
}

interface FailAppStateEnvGet {
    type: 'FAIL_GET_ENV'
    status: number,
    statusMessage: any
}

interface ClearAppStateEnvGet{
    type: 'CLEAR_GET_ENV'
}

type KnownAction = AppStateAction | AppStatePathAction | AppStateEnvGet | ClearAppStateEnvGet | FailAppStateEnvGet | LoadAppStateEnvGet

export const actionCreators = {
    updateCompany: (company: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            dispatch({ type: 'UPDATE_COMPANY', company: company });
        }
    },
    updateUrl: (url: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            dispatch({ type: 'UPDATE_PATH', pathUrl: url });
        }
    },

    updateEnv: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log("updateEnv")
        const appState = getState();
        if (appState) {
            if (method == 'GET' && check) {
                fetch('/v1/sso-env/get', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    },
                })
                    .then((response) => {
                        //console.log("requestBatchDetailInbox")
                        //console.log(response)
                        return response
                    })
                    .then((data) => {
                        //console.log(data)
                        if (!data.ok) {
                            data.json().then(err => {
                                //console.log(err.message_res)
                                console.log(err)
                                //dispatch({ type: 'FAIL_GET_ENV', status: 204, statusMessage: err.message });
                            })
                        } else {
                            data.json().then(data => {
                                //console.log(data)
                                dispatch({ type: 'GET_ENV', response: data as Models.EnvList, status: 200, message : ''});
                            })
                        }
                    })

                //dispatch({ type: 'LOAD_ENV' });
            }
            //else if (method == 'CLEAR' && check) {
            //    dispatch({ type: 'CLEAR_GET_ENV' });
            //}
        }
    },
};

const unloadedState: Models.AppState = { company: undefined, pathUrl: undefined, ssoEnv: undefined };

export const reducer: Reducer<Models.AppState> = (state: Models.AppState | undefined, incomingAction: Action): Models.AppState => {
    if (state == undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'UPDATE_COMPANY':
            return {
                company: action.company,
                pathUrl: state.pathUrl,
                ssoEnv: state.ssoEnv
            };

        case 'UPDATE_PATH':
            return {
                pathUrl: action.pathUrl,
                company: state.company,
                ssoEnv: state.ssoEnv
            };

        case 'GET_ENV':
            return {
                pathUrl: state.pathUrl,
                company: state.company,
                ssoEnv: action.response
            };

        default: return state;
    }
};