import * as React from 'react';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FaPlusCircle, FaEye } from "react-icons/fa";

import { connect } from "react-redux";
import { ApplicationState } from '../../store';

import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'

import * as EmailContentModel from '../../models/config/EmailContent'
import * as EmailContentStore from '../../store/config/EmailContent'
import { forEach } from 'react-bootstrap/esm/ElementChildren';
type store = EmailContentModel.GetEmailContetState & typeof EmailContentStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class EmailContent extends React.Component<any, { isReady: boolean, formData: Array<EmailContentModel.EmailContent>, isShowPreviewModal: boolean, contentDesc: string, condition: string }, store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isReady: true,
            formData: [],
            isShowPreviewModal: false,
            contentDesc: "",
            condition: ""
        }
    }

    componentDidMount() {
        this.props.requestLoadEmailContent(this.state.isReady, "GET");
        this.props.UseLoading(true)
    } SaveEmailContent

    componentDidUpdate() {
        const { GetEmailContent, SaveEmailContent } = this.props
        if (GetEmailContent.isLoadGetEmailContent && GetEmailContent.statusGetEmailContent == 200 && this.state.formData.length == 0) {
            this.props.UseLoading(false)
            var dataSet: any = []
            for (var i = 0; i < GetEmailContent.responseGetEmailContent.result_list.length; i++) {
                var data: EmailContentModel.EmailContent = {
                    sid: GetEmailContent.responseGetEmailContent.result_list[i].sid,
                    condition: GetEmailContent.responseGetEmailContent.result_list[i].condition,
                    //content_subject: atob(GetEmailContent.responseGetEmailContent.result_list[i].content_subject),
                    content_subject: decodeURIComponent(escape(window.atob(GetEmailContent.responseGetEmailContent.result_list[i].content_subject))),
                    content_body: decodeURIComponent(escape(window.atob(GetEmailContent.responseGetEmailContent.result_list[i].content_body)))
                    //content_body: atob(GetEmailContent.responseGetEmailContent.result_list[i].content_body)
                }
                dataSet.push(data)
            }

            this.setState({
                formData: dataSet
            })
            this.props.requestLoadEmailContent(true, "CLEAR")
        }

        if (SaveEmailContent.isLoadSaveEmailContent && SaveEmailContent.statusSaveEmailContent == 200) {
            this.props.UseLoading(false)
            this.props.requestSaveEmailContent(true, "CLEAR", this.state.formData)
            Swal.fire({
                title: 'สำเร็จ',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                }
            })

        } else if (SaveEmailContent.isLoadSaveEmailContent && SaveEmailContent.statusSaveEmailContent == 204) {
            this.props.UseLoading(false)
            var msg = SaveEmailContent.messageSaveEmailContent
            this.props.requestSaveEmailContent(true, "CLEAR", this.state.formData)
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: msg,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.Reset()
                }
            })
        }
    }

    handleChangeSearchInput = (e, key: string, index: number) => {
        var dataArr = JSON.parse(JSON.stringify(this.state.formData))
        dataArr[index][key] = e.target.value
        this.setState({
            formData: dataArr
        })
    }

    handleSave = () => {
        console.log(this.state.formData)
        //var encodedString = btoa(this.state.formData[0].content_subject)
        //console.log(encodedString)
        //console.log(atob(encodedString))

        var dataSet: any = []
        for (var i = 0; i < this.state.formData.length; i++) {
            console.log()
            var data: EmailContentModel.EmailContent = {
                sid: this.state.formData[i].sid,
                condition: this.state.formData[i].condition,
                //content_subject: btoa(this.state.formData[i].content_subject),
                content_subject: btoa(unescape(encodeURIComponent(this.state.formData[i].content_subject))),
                content_body: btoa(unescape(encodeURIComponent(this.state.formData[i].content_body)))
                //content_body: btoa(this.state.formData[i].content_body)
            }
            dataSet.push(data)
        }
        console.log(dataSet)
        this.props.UseLoading(true)
        this.props.requestSaveEmailContent(true, "POST", dataSet)
    }

    handleOpenClosePreviewModal = (text: string, condition: string) => {
        if (this.state.isShowPreviewModal) {
            this.setState({
                isShowPreviewModal: false,
                contentDesc: "",
                condition: ""
            })
        } else {
            this.setState({
                isShowPreviewModal: true,
                contentDesc: text,
                condition: condition
            })
        }
    }

    Reset() {
        this.setState({
            formData: []
        })
        this.props.requestLoadEmailContent(true, "CLEAR")
        this.props.requestLoadEmailContent(this.state.isReady, "GET");
        this.props.UseLoading(true)
    }

    b64_to_utf8(str) {
        str = str.replace(/\s/g, '');
        return decodeURIComponent(escape(window.atob(str)));
    }

    render() {
        const { GetEmailContent } = this.props
        return (
            <Container>
                <div className="master-data-content">
                    <div className='trapezoid trapezoid-purple' style={{ width: '200px' }}>
                        <span>
                            Email Content
                        </span>
                    </div>
                    <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                        <Row>
                            <Col>
                                <p className="text-muted">
                                    %COMPANY_CODE%, %LEGACY_ID%, %JOB_INSTANCE_NO%, %MESSAGE% is Variable
                                </p>
                            </Col>
                            <Col>
                                <div style={{ display: 'inline-block', float: 'right' }}>
                                    <Button variant="info" size="sm" className="mx-1" onClick={this.handleSave}>
                                        <FaPlusCircle />
                                        &nbsp;Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        {this.state.formData.length != 0 && this.state.formData.map((item: EmailContentModel.EmailContent, idx: any) => {
                            return (
                                <Row key={idx}>
                                    <Col>
                                        <div className='trapezoid-head' style={{ width: '200px' }}>
                                            <span>
                                                {item.condition} Condition
                                            </span>
                                        </div>
                                        <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                            <Row>
                                                <Col>
                                                    <Form.Label>Content Subject :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" as="textarea" rows={8} size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'content_subject', idx)} value={item.content_subject} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleOpenClosePreviewModal(item.content_subject, item.condition)}>
                                                            <FaEye />
                                                        </Button>
                                                    </InputGroup >
                                                </Col>
                                                <Col>
                                                    <Form.Label>Content Body :</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl type="text" as="textarea" rows={8} size='sm' onChange={(e) => this.handleChangeSearchInput(e, 'content_body', idx)} value={item.content_body} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleOpenClosePreviewModal(item.content_body, item.condition)}>
                                                            <FaEye />
                                                        </Button>
                                                    </InputGroup >
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                </div>

                <Modal show={this.state.isShowPreviewModal} onHide={() => this.handleOpenClosePreviewModal('', '')} size="lg">
                    <Modal.Header translate closeButton>
                        <Modal.Title>{'Preview ' + this.state.condition + ' Condition'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <div className='shadow-lg p-3 mb-5 bg-white rounded'>
                                    {/*{this.state.contentDesc != "" && renderHTML(this.state.contentDesc)}*/}
                                    <div dangerouslySetInnerHTML={{ __html: this.state.contentDesc }} />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleOpenClosePreviewModal('', '')}>
                            Close
                        </Button>
                        {/*<Button variant="primary" onClick={() => this.handleOpenClosePreviewModal('', '')}>*/}
                        {/*    Save Changes*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.EmailContent, ...state.Loading }), ({ ...EmailContentStore.actionCreators, ...LoadingStore.actionCreators }))(EmailContent)