import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSearch, FaRedoAlt, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LegacyLogTable from './LegacyLogTable'

import { setDateTimeToDatePicker } from '../modules/Common'

class LegacyLog extends React.Component<any, { job_instanceno: any, job_started_on_from: any, job_started_on_to: any, key: any, autoRefresh: any, jobStartedOnFrom: any, jobStartedOnTo: any }>{

    constructor(props: any) {
        super(props);
        this.state = {
            job_started_on_from: '',
            job_started_on_to: '',
            job_instanceno: '',
            key: '',
            autoRefresh: '',
            jobStartedOnFrom: '',
            jobStartedOnTo: ''
        }
    }

    componentDidMount() {
        //const autoRefresh = setInterval(
        //    () => this.search('Reset'),
        //    30000
        //);
        //this.setState({ autoRefresh: autoRefresh });
    }

    handlechageInput = (e: any, key: any) => {
        console.log(e.target.value)
        console.log(key)
        if (key != undefined && key != "") {
            if (key == 'job_instanceno') {
                this.setState({
                    job_instanceno: e.target.value
                })
            }
        }
    }

    search(key: any) {
        if (key == 'Reset') {
            var list: any = document.getElementsByClassName('require-field');
            for (let n = 0; n < list.length; ++n) {
                list[n].value = '';
            }
            this.setState({
                job_started_on_from: '',
                job_started_on_to: '',
                job_instanceno: '',
                jobStartedOnFrom: '',
                jobStartedOnTo: ''
            })
        }
        this.setState({
            key: 'search',
        })
    }

    callbackSearch() {
        this.setState({
            key: ''
        })
    }

    handleChangeDateStart = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'
        console.log(dataRs)
        this.setState({
            jobStartedOnFrom: date,
            job_started_on_from: dataRs
        })
    }

    handleChangeDateTo = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'
        console.log(dataRs)
        this.setState({
            jobStartedOnTo: date,
            job_started_on_to: dataRs
        })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Legacy Log
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    ค้นหาข้อมูล
                                                </span>
                                            </div>
                                            <div className="mat-box">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Label className="font-weight-bold">Job Instanceno :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <FormControl type="text" id="job_instanceno" size="sm" onChange={(e) => this.handlechageInput(e, "job_instanceno")} />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label className="font-weight-bold">Job start from :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            {/*<FormControl type="date" id="jobStartFrom" size="sm" onChange={(e) => this.handlechageInput(e, "jobStartFrom")} />*/}
                                                            <DatePicker
                                                                className="w-100"
                                                                selected={setDateTimeToDatePicker(this.state.jobStartedOnFrom)}
                                                                onChange={this.handleChangeDateStart}
                                                                customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="dd/mm/yyyy"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label className="font-weight-bold">Job start to :</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            {/*<FormControl type="date" id="jobTo" size="sm" onChange={(e) => this.handlechageInput(e, "jobTo")} />*/}
                                                            <DatePicker
                                                                selected={setDateTimeToDatePicker(this.state.jobStartedOnTo)}
                                                                onChange={this.handleChangeDateTo}
                                                                customInput={<FormControl className="menu-form custom-datepicker w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="dd/mm/yyyy"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col lg={{ span: 2, offset: 10 }}>
                                                        <div className="d-flex justify-content-center mt-4">
                                                            {/*<Button variant="primary" size="sm" onClick={() => this.search('Reset')}><FaTimes /> Reset</Button>
                                                            <Button variant="primary" size="sm" className="mx-1" onClick={() => this.search('Refresh')}><FaRedoAlt /> Refresh</Button>*/}
                                                            <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.search('Search')}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <LegacyLogTable job_instanceno={this.state.job_instanceno} job_started_on_from={this.state.job_started_on_from} job_started_on_to={this.state.job_started_on_to} checkkey={this.state.key} callbackkey={this.callbackSearch.bind(this)} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default LegacyLog