import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';

import * as Models from '../../models/common/Env';

interface RequestFunctionAction {
    type: 'REQUEST_ENV';
    status: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_ENV';
    model: Models.ResponseEnv;
    status: number;
}

interface ClearFunctionAction {
    type: 'CLEAR_ENV';
    status: number;
}

interface WhaitFunctionAction {
    type: 'WHAIT_ENV';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction | WhaitFunctionAction

export const actionCreators = {
    requestFunctionEnv: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.Env && method == "GET" && check) {
            fetch(`/v1/env/get`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Models.ResponseEnv>
                })
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_ENV', model: data, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_ENV', status: 204 });
                });
        }

        dispatch({ type: 'WHAIT_ENV' });

        if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_ENV', status: 400 });
        }

    }
};

const unloadedState: Models.EnvState = { isLoadingEnv: false, responseEnv: undefined, statusEnv: 0 };

export const reducer: Reducer<Models.EnvState> = (state: Models.EnvState | undefined, incomingAction: Action): Models.EnvState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'WHAIT_ENV':
            return {
                isLoadingEnv: false,
                statusEnv: 400,
            };
        case 'REQUEST_ENV':
            return {
                isLoadingEnv: false,
                statusEnv: action.status,
            };
        case 'RECEIVE_ENV':
            return {
                isLoadingEnv: true,
                responseEnv: action.model,
                statusEnv: action.status
            };
        case 'CLEAR_ENV':
            return {
                isLoadingEnv: false,
                statusEnv: action.status
            };

        default: return state;
    }
};