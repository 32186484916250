import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Table, Spinner, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { formatDMY, formatYMDToDMY, parseJwt } from '../modules/Common'
import Pagination from '@material-ui/lab/Pagination';

import { ApplicationState } from '../../store';
import * as LegacyLogModels from '../../models/report/SystemLog'
import * as LegacyLogStore from '../../store/report/SystemLog'
type Store = LegacyLogModels.GetSystemLogState & typeof LegacyLogStore.actionCreators

class LegacyLogTable extends React.Component<any, { response: Array<LegacyLogModels.SystemLog>, isready: boolean, userId: any, companyCode: any, checkready: boolean, pageSize: any, pageBtn: any, checkPageSize: boolean }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            response: [],
            isready: true,
            userId: '',
            companyCode: '',
            checkready: true,
            pageSize: 25,
            pageBtn: ['', '', ''],
            checkPageSize: true
        }

        this.handleChangePage = this.handleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("LG_APPLICATION");
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        var jwt = parseJwt(accessToken);
        var extra = JSON.parse(jwt.extra);
        var userIdUse = ""

        if (extra.username) {
            userIdUse = extra.username
        }
        else if (extra.citizen_id) {
            userIdUse = extra.citizen_id
        }

        fetch("/v1/user?id=" + userIdUse,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    var data = result.result_list
                    if (data != null && data.length > 0) {
                        if (result.result_list[0].list_company[0].company_code == "*") {
                            this.getLegacyLog([])
                        } else {
                            this.getLegacyLog(result.result_list[0].list_company)
                        }
                    } else {
                        this.props.requestGetSystemLog(true, "NOUSER", "")
                    }
                },
                (error) => {
                }
            )
    }

    private getLegacyLog(companyCodeUse) {
        const { isready, userId, companyCode } = this.state
        const { checkkey, job_started_on_from, job_started_on_to, job_instanceno } = this.props

        var dataset: any = []

        companyCodeUse.map((item) => {
            dataset.push(item.company_code)
        })

        this.setState({
            companyCode: dataset
        })

        if (isready) {
            this.props.requestGetSystemLog(isready, "POST", "", dataset, 1, this.state.pageSize, job_started_on_from, job_started_on_to, job_instanceno)
            this.setState({
                isready: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { checkkey, job_started_on_from, job_started_on_to } = this.props
        if (checkkey == "search" && checkkey != undefined && !this.state.isready) {
            console.log(checkkey)
            this.setState({
                isready: true
            })
            this.handleSearch()
        }

        if (this.props.statusSystemLog == 200 && this.props.isLoadSystemLog && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.responseSystemLog['total_count'] / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false,
                isready: false
            })
        }
    }

    handleChangePage = (event: any, size: any) => {
        const { checkkey, job_started_on_from, job_started_on_to, job_instanceno } = this.props
        console.log(size);
        this.setState({
            isready: true,
            checkPageSize: true,
        })
        this.props.requestGetSystemLog(true, "POST", "", this.state.companyCode, size, this.state.pageSize, job_started_on_from, job_started_on_to, job_instanceno)
    }

    handlePageSize(e: any) {
        const { checkkey, job_started_on_from, job_started_on_to, job_instanceno } = this.props
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse,
            isready: true,
            checkPageSize: true,
        });
        this.props.requestGetSystemLog(true, "POST", "", this.state.companyCode, 1, parse, job_started_on_from, job_started_on_to, job_instanceno)
    }

    public handleSearch() {
        const { checkkey, job_started_on_from, job_started_on_to, job_instanceno } = this.props
        this.props.requestGetSystemLog(true, "POST", "", this.state.companyCode, 1, 25, job_started_on_from, job_started_on_to, job_instanceno)
        this.props.callbackkey()
        this.setState({
            checkPageSize: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <Row className='mt-3'>
                    <Col md={6}>
                        <Container>
                            <Form className="d-flex justify-content-end justify-content-md-start align-items-center text-nowrap" style={{ gap: '0 12px' }}>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    style={{ width: 'fit-content', margin: 0 }}
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label style={{ margin: 0 }} htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </Container>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} onChange={this.handleChangePage} shape="rounded" defaultPage={1} showFirstButton showLastButton />
                        </div>
                    </Col>
                </Row >
                <Row>
                    <Col>
                        <Table className="table-bordered table-hover custom-table-list table-responsive-lg" size="sm">
                            <thead className="">
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th style={{ width: '5%' }}>sid</th>
                                    <th style={{ width: '8%' }}>Company Code</th>
                                    <th style={{ width: '14%' }}>Job Instanceno</th>
                                    <th style={{ width: '8%' }}>Log Type</th>
                                    <th style={{ width: '40%' }}>Message</th>
                                    <th style={{ width: '10%' }}>Created On</th>
                                    <th style={{ width: '15%' }}>Msg Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.props.isLoadSystemLog ? <tr><td colSpan={7} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {this.props.isLoadSystemLog && this.props.statusSystemLog != 200 ? <tr><td colSpan={7} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {this.props.statusSystemLog == 200 && this.props.isLoadSystemLog && this.props.responseSystemLog['result_list'] != null && this.props.responseSystemLog['result_list'].map((item: LegacyLogModels.SystemLog, idx: any) => {
                                    return (
                                        <tr key={idx}>
                                            <td className="text-center">{item.sid}</td>
                                            <td className="text-center">{item.companycode}</td>
                                            <td>{item.job_instanceno}</td>
                                            <td className="text-center">{item.log_type}</td>
                                            <td>{item.message}</td>
                                            <td className="text-center">{formatYMDToDMY(String(item.created_on))}</td>
                                            <td className="td-legacylog" style={{ overflowX: 'scroll' }}>{item.msg_source}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment >
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.systemLog }), ({ ...LegacyLogStore.actionCreators }))(LegacyLogTable)