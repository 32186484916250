import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, InputGroup, FormControl, Table, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaRedoAlt, FaSearch, FaEye, FaFile, FaFileExcel } from "react-icons/fa";
import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage } from '../modules/Common'
import Swal from 'sweetalert2'
import * as QueryString from "query-string"
import { CSVLink, CSVDownload } from "react-csv";

import * as ExpectedRunJobModels from '../../models/report/ExpectedRunJob'
import { ApplicationState } from '../../store';
import * as ExpectedRunJobStore from '../../store/report/ExpectedRunJob'
import { connect } from 'react-redux';

const headers = [
    { label: "Legacy ID", key: "legacyid" },
    { label: "Company Code", key: "companycode" },
    { label: "Job Instance No.", key: "job_instanceno" },
    { label: "Legacy Name", key: "legacyname" },
    { label: "Legacy Type", key: "legacytype" },
    { label: "Status", key: "status" },
    { label: "Status", key: "status" },
    { label: "job Started On", key: "job_started_on" },
    { label: "job Finished On", key: "job_finished_on" },
    { label: "job Finished On", key: "job_finished_on" },
    { label: "Message", key: "message" },
    { label: "Step1 Started On", key: "step1_started_on" },
    { label: "Step1 Status", key: "step1_status" },
    { label: "Step2 Started On", key: "step2_started_on" },
    { label: "Step2 Status", key: "step2_status" },
    { label: "Step3 Started On", key: "step3_started_on" },
    { label: "step3 Status", key: "step3_status" },
    { label: "step4 Status", key: "step4_status" },
];

class ExpectedRunJob extends React.Component<any, { isready: boolean, isShowErrorFile: boolean, isShowTempModal: boolean, isSelectVal: boolean, messageText: string, userId: string, companyCode: Array<string>, date_from: string, date_to: string }>{
    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            isShowErrorFile: false,
            isShowTempModal: false,
            isSelectVal: false,
            messageText: "",
            userId: "",
            companyCode: [],
            date_from: "",
            date_to: ""
        }
    }

    componentDidMount() {

        var ls: any = localStorage.getItem("LG_APPLICATION");
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        var jwt = parseJwt(accessToken);
        var extra = JSON.parse(jwt.extra);
        var userIdUse = ""

        if (extra.username) {
            userIdUse = extra.username
        }
        else if (extra.citizen_id) {
            userIdUse = extra.citizen_id
        }
        console.log(userIdUse)
        this.setState({
            userId: userIdUse,
        })

        var { companycode, date_to, date_from, sid } = QueryString.parse(this.props.location.search);
        var companycode_list: any = []

        if (companycode != undefined) {
            console.log(typeof (companycode))
            if (typeof (companycode) == 'string') {
                companycode_list.push(companycode)
            } else if (typeof (companycode) == 'object') {
                companycode.forEach(element => {
                    companycode_list.push(element)
                })
            }
            this.setState({
                companyCode: companycode_list,
            })
        }

        if (companycode == undefined) {
            fetch("/v1/user?id=" + userIdUse,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        var data = result.result_list
                        if (data != null && data.length > 0) {
                            if (result.result_list[0].list_company[0].company_code == "*") {
                                this.getExpectedRunJob([], 'fromApi', userIdUse)
                            } else {
                                this.getExpectedRunJob(result.result_list[0].list_company, 'fromApi', userIdUse)
                            }
                        } else {
                            this.props.requestGetExpectedRunJob(true, "NOUSER", "")
                        }
                    },
                    (error) => {
                    }
                )
        } else {
            this.getExpectedRunJob(companycode_list, 'fromQueryString', userIdUse)
        }
    }

    private getExpectedRunJob(companyCodeUse: any, mode: string, user_id: string) {
        console.log(companyCodeUse)
        const { isready, userId, companyCode } = this.state

        var dataset: any = []
        if (mode == 'fromApi') {
            companyCodeUse.map((item) => {
                dataset.push(item.company_code)
            })

            this.setState({
                companyCode: dataset
            })
        } else {
            if (companyCodeUse == "*") {
                this.setState({
                    companyCode: dataset
                })
            } else {
                companyCodeUse.map((item) => {
                    dataset.push(item)
                })
                this.setState({
                    companyCode: dataset
                })
            }
        }
        if (isready) {
            console.log(companyCode)
            console.log(userId)
            this.props.requestGetExpectedRunJob(isready, "GET", user_id, dataset)
            this.setState({
                isready: false
            })
        }
    }

    handleshowErrorFile = () => {
        if (this.state.isShowErrorFile == true) {
            this.setState({
                isShowErrorFile: false,
            })
        } else {
            this.setState({
                isShowErrorFile: true
            })
        }
    }

    handleClickTemModal = (id, message: string) => {
        //console.log('id')
        //console.log(id)
        if (this.state.isShowTempModal == false) {
            this.setState({
                isShowTempModal: true,
                isSelectVal: id,
                messageText: message
            })

        }
    }

    ResetHandleClick(sid: string, companycode: string, legacyid: string, job_instance_no: string, legacytype: string, step: string) {
        //this.setState({
        //    isready: true,
        //    formData: []
        //})
        //this.props.requestGetLegacyReport(true, "CLEAR", "", "")
        //this.props.UseLoading(true)
        //this.props.callbackStopAutoRefresh()
        Swal.fire({
            title: 'ต้องการรีรันหรือไม่',
            text: 'คุณต้องการรีรัน Legacy ID นี้หรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ไม่ตกลง',
            cancelButtonColor: '#FF0000',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                fetch(`/v1/lis-server/run-step?sid=` + sid + '&company_code=' + companycode + '&legacy_type=' + legacytype + '&legacy_id=' + legacyid + '&job_instance_no=' + job_instance_no + '&step=' + step, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    },
                })
                    .then((response) => {
                        const { isready, userId, companyCode } = this.state
                        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to } = this.props

                        //this.props.requestGetLegacyTask(true, "POST", "", this.state.companyCode, 1, pageSize, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_from, job_started_on_to, this.state.legacyNameSearch)
                        this.props.UseLoading(false)
                        console.log(response)
                        return response
                    })
                    .then((data) => {
                        console.log(data)
                        if (!data.ok) {
                            Swal.fire({
                                title: 'Error!',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {
                                    //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                                    //this.props.callbackStartAutoRefresh()
                                    return;
                                }
                            })
                        } else {
                            Swal.fire({
                                title: 'Success!',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {
                                    //this.props.requestGetLegacyReport(true, "POST", "", this.state.companyCode)
                                    //this.props.callbackStartAutoRefresh()
                                    return;
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('errorrrr')
                        this.setState({
                            isready: false
                        })
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: error,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    });
            }
        })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Expected Run Job
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                {/*<Row>*/}
                                {/*    <Col></Col>*/}
                                {/*    <Col lg={2}>*/}
                                {/*        {this.props.statusExpectedRunJob == 200 && this.props.isLoadExpectedRunJob && this.props.responseExpectedRunJob['result_list'] != null &&*/}
                                {/*            <CSVLink*/}
                                {/*                data={this.props.responseExpectedRunJob['result_list']}*/}
                                {/*                filename={"TEST"}*/}
                                {/*                className="btn btn-success mx-1 btn btn-block"*/}
                                {/*                target="_blank"*/}
                                {/*                headers={headers}*/}
                                {/*            >*/}
                                {/*                <FaFileExcel /> Export Excel*/}
                                {/*            </CSVLink>*/}
                                {/*        }*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row className="mt-2">
                                    <Col>
                                        <Table className="table-bordered table-hover custom-table-list" size="sm" style={{ width: '100%', overflow: 'inherit' }}>
                                            <thead>
                                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                                    <th style={{ width: '5%' }}>#</th>
                                                    <th style={{ width: '10%' }}>Company Code</th>
                                                    <th style={{ width: '15%' }}>Legacy ID</th>
                                                    <th style={{ width: '20%' }}>Legacy Name</th>
                                                    <th style={{ width: '15%' }}>Expected Time</th>
                                                    <th style={{ width: '10%' }}>Expected Count</th>
                                                    <th style={{ width: '15%' }}>Actual Time</th>
                                                    <th style={{ width: '10%' }}>Actual Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.props.isLoadExpectedRunJob ? <tr><td colSpan={17} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                {this.props.isLoadExpectedRunJob && (this.props.statusExpectedRunJob != 200 || this.props.responseExpectedRunJob['result_list'] == null) ? <tr><td colSpan={17} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                {this.props.statusExpectedRunJob == 200 && this.props.isLoadExpectedRunJob && this.props.responseExpectedRunJob['result_list'] != null && this.props.responseExpectedRunJob['result_list'].map((item: ExpectedRunJobModels.ExpectedRunJob, idx: any) => {
                                                    //var srcMonitor = "/legacydetail?company_code=" + item.companycode + "&legacy_id=" + item.legacyid + "&job_no=" + item.job_instanceno
                                                    return (
                                                        <tr key={idx} className={item.expected_count != item.actual_count ? 'Actual-error' : ''}>
                                                            {/*<td>*/}
                                                            {/*    <div className="text-center">*/}
                                                            {/*        <Link to={srcMonitor} className="btn btn-primary btn-sm"><FaEye /></Link>*/}
                                                            {/*    </div>*/}
                                                            {/*</td>*/}
                                                            <td className="text-center">{idx + 1}</td>
                                                            <td className="text-center">{item.companycode}</td>
                                                            <td>{item.legacyid}</td>
                                                            <td>{item.legacyname}</td>
                                                            {/*<td className="text-center">{formatYMDToDMY(String(item.expected_time))}</td>*/}
                                                            <td className="text-center">{item.expected_time}</td>
                                                            <td className="text-right">{item.expected_count}</td>
                                                            {/*<td className="text-center">{formatYMDToDMY(String(item.actual_time))}</td>*/}
                                                            <td className="text-center">{item.actual_time}</td>
                                                            <td className="text-right">{item.actual_count}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.ExpectedRunJob }), ({ ...ExpectedRunJobStore.actionCreators }))(ExpectedRunJob)