import React from 'react';
import { Table, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import { parseJwt } from '../modules/Common';
import { ApplicationState } from '../../store';

import * as JobConfigModels from '../../models/config/JobConfig'
import * as JobConfigStore from '../../store/config/JobConfig'

import * as LegacyReportStore from '../../store/report/LegacyReport'
import * as LegacyReportModels from '../../models/report/LegacyReport'

import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'

interface States {
    companyCode: string[],
    searchJobReq: JobConfigModels.SearchJobReq,
    formData: LegacyReportModels.LegacyReport[],
}

interface ComponentProps {
    jobConfig: JobConfigModels.JobConfigResponeState,
    legacyReport: LegacyReportModels.LegacyReportResponeState,
    loading: LoadingModels.LoadingState,
}

type Props =
    & ComponentProps
    & typeof JobConfigStore.actionCreators
    & typeof LegacyReportStore.actionCreators
    & typeof LoadingStore.actionCreators;

class PullJobConfigLog extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formData: [],
            companyCode: [],
            searchJobReq: {
                sid: '',
                company_code: '',
                legacy_id: '',
                legacy_name: '',
                api_class_name: '',
                api_function_name: ''
            },
        }
    }

    public componentDidMount() {
        const appStorage = localStorage.getItem('LG_APPLICATION');

        if (appStorage) {
            const lis = JSON.parse(appStorage);
            const accessToken = lis.access_token;
            const jwt = parseJwt(accessToken);
            const extra = JSON.parse(jwt.extra);
            let userIdUse = "";

            if (extra.username) 
                userIdUse = extra.username;
            else if (extra.citizen_id) 
                userIdUse = extra.citizen_id;

            fetch("/v1/user?id=" + userIdUse + '&role_access=admin',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        const data = result.result_list;

                        if (data !== null && data.length > 0) {
                            if (result.result_list[0].list_company[0].company_code === "*") {
                                this.getJobConfig([]);
                                //this.getLegacyReport([]);
                            } else {
                                this.getJobConfig(result.result_list[0].list_company);
                                //this.getLegacyReport(result.result_list[0].list_company);
                            }
                        } else {
                            this.props.requestGetJobConfig(true, "NOUSER", "", [], "", "", this.state.searchJobReq, "PULL_WAIT");
                            //this.props.requestGetLegacyReport(true, "NOUSER", "", []);
                        }
                    },
                    (error) => { }
                )
        }
    }

    public componentDidUpdate(nextProps: any, nextState: any) {
        //const { GetReport } = this.props.legacyReport;
    }

    private getJobConfig(companyCodeUse) {
        const dataset: string[] = [];

        companyCodeUse.map((item) => dataset.push(item.company_code));

        this.setState({
            companyCode: dataset,
        });

        this.props.requestGetJobConfig(true, "POST", "", dataset, "", "", this.state.searchJobReq, "PULL_WAIT");
    }

    private getLegacyReport(companyCodeUse) {
        const dataset: string[] = [];

        companyCodeUse.map((item) => dataset.push(item.company_code));

        this.setState({
            companyCode: dataset
        });

        console.log(this.state.companyCode)

        this.props.requestGetLegacyReport(true, "POST", "", dataset);
    }

    render() {
        const { GetJob } = this.props.jobConfig;
        //const { GetReport } = this.props.legacyReport;

        //console.log("[Debug] Legacy Report", GetReport)

        return (
            <div style={{ overflowX: 'auto' }}>
                <Table
                    className="table-bordered table-hover rounded custom-table-list table-responsive-lg"
                    size="sm"
                >
                    <thead className="rounded">
                        <tr style={{ width: '100%' }}
                            className="text-center table-active thead-light font-weight-bold require-field"
                        >
                            <th style={{ width: '5%' }}>รายงาน</th>
                            <th style={{ width: '30%' }}>Company Code</th>
                            <th style={{ width: '30%' }}>Legacy ID </th>
                            <th style={{ width: '35%' }}>Last Pull</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!GetJob.isLoadJobConfig
                            ? <tr>
                                <td colSpan={12} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                    <Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...
                                </td>
                            </tr>
                            : <tr></tr>
                        }
                        {GetJob.isLoadJobConfig &&
                            GetJob.statusJobConfig !== 200
                            ? <tr>
                                <td colSpan={12} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                    &nbsp;&nbsp;No Data...
                                </td>
                            </tr>
                            : <tr></tr>
                        }
                        {GetJob.statusJobConfig === 200 &&
                            GetJob.isLoadJobConfig &&
                            GetJob.responseJobConfig &&
                            GetJob.responseJobConfig.result_list.length > 0 &&
                            GetJob.responseJobConfig.result_list.map((item: JobConfigModels.JobConfig, index: number) => {
                                
                                return (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td className='text-center'>{item.companycode}</td>
                                        <td className='text-overflow'>{item.legacyid}</td>
                                        <td className='text-center'>{}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default connect((state: ApplicationState, componentProps) => ({
    ...componentProps,
    jobConfig: state.JobConfig,
    legacyReport: state.legacyReport,
    loading: state.Loading,
}), ({
    ...JobConfigStore.actionCreators,
    ...LegacyReportStore.actionCreators,
    ...LoadingStore.actionCreators
}))(PullJobConfigLog as any);