import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/SystemLog'

interface LoadSystemLog {
    type: 'LOAD_SYSTEMLOG'
}

interface LoadedSystemLog {
    type: 'LOADEDSYSTEMLOG'
    response: Models.SystemLog
    status: number,
    statusMessage: string
}
interface FailLoadedSystemLog {
    type: 'LOADSYSTEMLOGFAIL'
    status: number,
    statusMessage: any
}
interface ClearSystemLog {
    type: 'CLEARSYSTEMLOG'
}
interface NoUserSystemLog {
    type: 'NOUSERSYSTEMLOG'
}

type KnownAction = LoadedSystemLog | FailLoadedSystemLog | LoadSystemLog | ClearSystemLog | NoUserSystemLog

export const actionCreators = {
    requestGetSystemLog: (check: boolean, method: string, sid: string, company_codes: string[], page: any, page_size: any, job_started_on_from: any, job_started_on_to: any, job_instanceno : any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.legacyReport && check && method == 'POST') {
            //console.log('call actionCreators : requestGetSystemLog | /v1/system/log?sid=' + sid + '&company_code=' + company_code + '&page=' + page + '&page_size=' + page_size + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to + '&job_instanceno=' + job_instanceno)
            var company_code = ""
            company_codes.forEach(element => {
                company_code += "&company_code=" + element
            });
            fetch(`/v1/system/log?sid=` + sid + company_code + '&page=' + page + '&page_size=' + page_size + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to + '&job_instanceno=' + job_instanceno, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.SystemLog>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDSYSTEMLOG', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADSYSTEMLOGFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_SYSTEMLOG' });

        } else if (appState && appState.legacyTask && check && method == 'CLEAR') {
            dispatch({ type: 'CLEARSYSTEMLOG' });
        }
        else if (appState && appState.legacyTask && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSERSYSTEMLOG' });
        }

    }
}

const unloadedState: Models.GetSystemLogState = { isLoadSystemLog: false };

export const reducer: Reducer<Models.GetSystemLogState> = (state: Models.GetSystemLogState | undefined, incomingAction: Action): Models.GetSystemLogState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SYSTEMLOG':
            return {
                isLoadSystemLog: false,
            }

        case 'LOADEDSYSTEMLOG':
            return {
                isLoadSystemLog: true,
                responseSystemLog: action.response as any,
                statusSystemLog: action.status
            }

        case 'LOADSYSTEMLOGFAIL':
            return {
                isLoadSystemLog: true,
                messageSystemLog: action.statusMessage,
                statusSystemLog: action.status
            }

        case 'CLEARSYSTEMLOG':
            return {
                isLoadSystemLog: false,
                messageSystemLog: '',
                statusSystemLog: 400
            }

        case 'NOUSERSYSTEMLOG':
            return {
                isLoadSystemLog: true,
                statusSystemLog: 400
            }
        default: return state;
    }
}