import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/SftpConfig'

// Load sftp
interface LoadSftpConfig {
    type: 'LOAD_SFTPCONFIG'
}
interface LoadedSftpConfig {
    type: 'LOADEDSFTPCONFIG'
    response: Models.ResponseSftpConfig
    status: number,
    statusMessage: string
}
interface FailLoadedSftpConfig {
    type: 'LOADSFTPCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedSftpConfig {
    type: 'CLEARLOADEDSFTPCONFIG'
}

// Add sftp
interface AddSftpConfig {
    type: 'ADD_SFTPCONFIG'
}
interface AddedSftpConfig {
    type: 'ADDEDSFTPCONFIG'
    response: Models.ResponseSftpConfig
    status: number,
    statusMessage: string
}
interface FailAddedSftpConfig {
    type: 'ADDSFTPCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedSftpConfig {
    type: 'CLEARADDSFTPCONFIG'
}

// Delete sftp
interface DeleteSftpConfig {
    type: 'DELETE_SFTPCONFIG'
}
interface DeletedSftpConfig {
    type: 'DELETEDSFTPCONFIG'
    status: number,
    statusMessage: string
}
interface FailDeletedSftpConfig {
    type: 'DELETESFTPCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeletedSftpConfig {
    type: 'CLEARDELETESFTPCONFIG'
}

//Search sftp
interface SearchSftpConfig {
    type: 'SEARCH_SFTPCONFIG'
}
interface SearchedSftpConfig {
    type: 'SEARCHEDSFTPCONFIG'
    response: Models.ResponseSftpConfig
    status: number,
    statusMessage: string
}
interface FailSearchedSftpConfig {
    type: 'SEARCHSFTPCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchedSftpConfig {
    type: 'CLEARSEARCHSFTPCONFIG'
}

// Edit
interface EditSftpConfig {
    type: 'EDIT_SFTPCONFIG'
}
interface EditedSftpConfig {
    type: 'EDITEDSFTPCONFIG'
    response: Models.ResponseSftpConfig
    status: number,
    statusMessage: string
}
interface FailEditedSftpConfig {
    type: 'EDITSFTPCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditedSftpConfig {
    type: 'CLEAREDITSFTPCONFIG'
}

interface GetSftpPath {
    type: 'GET_SFTP_PATH'
}
interface GetedSftpPath {
    type: 'GETEDSFTPPATH'
    response: Array<string>
    status: number,
    statusMessage: string
}
interface GetedSftpPathFail {
    type: 'GETEDSFTPPATHFAIL'
    status: number,
    statusMessage: any
}
interface ClearGetSftpPath{
    type: 'CLEARGETEDSFTPPATH'
}

type KnownAction = LoadedSftpConfig | FailLoadedSftpConfig | LoadSftpConfig | AddSftpConfig | AddedSftpConfig | FailAddedSftpConfig | ClearAddedSftpConfig | ClearLoadedSftpConfig | DeleteSftpConfig | DeletedSftpConfig | FailDeletedSftpConfig | ClearDeletedSftpConfig | SearchSftpConfig | SearchedSftpConfig | FailSearchedSftpConfig | ClearSearchedSftpConfig | EditSftpConfig | EditedSftpConfig | FailEditedSftpConfig | ClearEditedSftpConfig | GetSftpPath | GetedSftpPath | GetedSftpPathFail | ClearGetSftpPath

export const actionCreators = {
    // GET
    requestGetSftpConfig: (check: boolean, method: string, sid: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'GET') {
            console.log('call actionCreators : requestGetSftpConfig')
            fetch(`/v1/config/sftp-server?sid=` + sid + '&page=' + page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSFTPCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_SFTPCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDSFTPCONFIG' });
        }
    },

    // ADD
    requestAddSftpConfig: (check: boolean, method: string, Formbody: Models.SftpConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'POST') {
            console.log('call actionCreators : requestAddSftpConfig')
            fetch(`/v1/config/sftp-server/add`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDSFTPCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'ADDEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'ADDEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'ADDSFTPCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'ADD_SFTPCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDSFTPCONFIG' });
        }
    },

    // Delete
    requestDeleteSftpConfig: (check: boolean, method: string, sid: string, sftp_server_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteSftpConfig')
            fetch(`/v1/config/sftp-server/delete?sid=` + sid + '&sftp_server_id=' + sftp_server_id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETESFTPCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'DELETEDSFTPCONFIG', status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'DELETEDSFTPCONFIG', status: 200, statusMessage: '' });
            //dispatch({ type: 'DELETESFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'DELETE_SFTPCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETESFTPCONFIG' });
        }
    },

    // Search
    requestSearchSftpConfig: (check: boolean, method: string, sid: string, sftp_server_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'GET') {
            console.log('call actionCreators : requestGetSftpConfig')
            fetch(`/v1/config/sftp-server?sid=` + sid + '&sftp_server_id=' + sftp_server_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'SEARCHSFTPCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'SEARCHEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'SEARCHEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'SEARCHSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'SEARCH_SFTPCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHSFTPCONFIG' });
        }
    },

    // EDIT
    requestEditSftpConfig: (check: boolean, method: string, Formbody: Models.SftpConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        var dataArr = JSON.stringify(Formbody)
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'PUT') {
            console.log('call actionCreators : requestEditSftpConfig')
            fetch(`/v1/config/sftp-server/edit`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: dataArr
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'EDITSFTPCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'EDITEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'EDITEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'EDITSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'EDIT_SFTPCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITSFTPCONFIG' });
        }
    },

    // Get Sftp Path
    requestGetSftpPath: (check: boolean, method: string, formData: Models.SftpPathReq): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.SftpConfig && check && method == 'POST') {
            console.log('call actionCreators : requestGetSftpPath')
            fetch(`/v1/lis-server/expand/sftp/path`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(formData)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'GETEDSFTPPATHFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'GETEDSFTPPATH', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDSFTPCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADSFTPCONFIGFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'GET_SFTP_PATH' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARGETEDSFTPPATH' });
        }
    },
}

const unloadedStateGet: Models.GetSftpConfigState = { isLoadSftpConfig: false };
const unloadedStateAdd: Models.AddSftpConfigState = { isLoadAddSftpConfig: false };
const unloadedStateDelete: Models.DeleteSftpConfigState = { isLoadDeleteSftpConfig: false };
const unloadedStateSearch: Models.SearchSftpConfigState = { isLoadSearchSftpConfig: false };
const unloadedStateEdit: Models.EditSftpConfigState = { isLoadEditSftpConfig: false };
const unloadedStateGetSftpPath: Models.GetSftpPathState = { isLoadGetSftpPath: false };

const reducerGet: Reducer<Models.GetSftpConfigState> = (state: Models.GetSftpConfigState | undefined, incomingAction: Action): Models.GetSftpConfigState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_SFTPCONFIG':
            return {
                isLoadSftpConfig: false,
            }

        case 'LOADEDSFTPCONFIG':
            return {
                isLoadSftpConfig: true,
                responseSftpConfig: action.response as any,
                statusSftpConfig: action.status
            }

        case 'LOADSFTPCONFIGFAIL':
            return {
                isLoadSftpConfig: true,
                messageSftpConfig: action.statusMessage,
                statusSftpConfig: action.status
            }

        case 'CLEARLOADEDSFTPCONFIG':
            return {
                isLoadSftpConfig: false,
                messageSftpConfig: "",
                statusSftpConfig: 400
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddSftpConfigState> = (state: Models.AddSftpConfigState | undefined, incomingAction: Action): Models.AddSftpConfigState => {
    if (state == undefined) {
        return unloadedStateAdd;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'ADD_SFTPCONFIG':
            return {
                isLoadAddSftpConfig: false,
            }

        case 'ADDEDSFTPCONFIG':
            return {
                isLoadAddSftpConfig: true,
                responseAddSftpConfig: action.response as any,
                statusAddSftpConfig: action.status
            }

        case 'ADDSFTPCONFIGFAIL':
            return {
                isLoadAddSftpConfig: true,
                messageAddSftpConfig: action.statusMessage,
                statusAddSftpConfig: action.status
            }

        case 'CLEARADDSFTPCONFIG':
            return {
                isLoadAddSftpConfig: false,
                messageAddSftpConfig: '',
                statusAddSftpConfig: 400
            }
        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteSftpConfigState> = (state: Models.DeleteSftpConfigState | undefined, incomingAction: Action): Models.DeleteSftpConfigState => {
    if (state == undefined) {
        return unloadedStateDelete;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'DELETE_SFTPCONFIG':
            return {
                isLoadDeleteSftpConfig: false,
            }

        case 'DELETEDSFTPCONFIG':
            return {
                isLoadDeleteSftpConfig: true,
                statusDeleteSftpConfig: action.status
            }

        case 'DELETESFTPCONFIGFAIL':
            return {
                isLoadDeleteSftpConfig: true,
                messageDeleteSftpConfig: action.statusMessage,
                statusDeleteSftpConfig: action.status
            }

        case 'CLEARDELETESFTPCONFIG':
            return {
                isLoadDeleteSftpConfig: false,
                messageDeleteSftpConfig: '',
                statusDeleteSftpConfig: 400
            }
        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchSftpConfigState> = (state: Models.SearchSftpConfigState | undefined, incomingAction: Action): Models.SearchSftpConfigState => {
    if (state == undefined) {
        return unloadedStateSearch;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'SEARCH_SFTPCONFIG':
            return {
                isLoadSearchSftpConfig: false,
            }

        case 'SEARCHEDSFTPCONFIG':
            return {
                isLoadSearchSftpConfig: true,
                responseSearchSftpConfig: action.response as any,
                statusSearchSftpConfig: action.status
            }

        case 'SEARCHSFTPCONFIGFAIL':
            return {
                isLoadSearchSftpConfig: true,
                messageSearchSftpConfig: action.statusMessage,
                statusSearchSftpConfig: action.status
            }

        case 'CLEARSEARCHSFTPCONFIG':
            return {
                isLoadSearchSftpConfig: false,
                messageSearchSftpConfig: "",
                statusSearchSftpConfig: 400
            }
        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditSftpConfigState> = (state: Models.EditSftpConfigState | undefined, incomingAction: Action): Models.EditSftpConfigState => {
    if (state == undefined) {
        return unloadedStateEdit;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'EDIT_SFTPCONFIG':
            return {
                isLoadEditSftpConfig: false,
            }

        case 'EDITEDSFTPCONFIG':
            return {
                isLoadEditSftpConfig: true,
                responseEditSftpConfig: action.response as any,
                statusEditSftpConfig: action.status
            }

        case 'EDITSFTPCONFIGFAIL':
            return {
                isLoadEditSftpConfig: true,
                messageEditSftpConfig: action.statusMessage,
                statusEditSftpConfig: action.status
            }

        case 'CLEAREDITSFTPCONFIG':
            return {
                isLoadEditSftpConfig: false,
                messageEditSftpConfig: '',
                statusEditSftpConfig: 400
            }
        default: return state;
    }
}

const reducerGetSftpPath: Reducer<Models.GetSftpPathState> = (state: Models.GetSftpPathState | undefined, incomingAction: Action): Models.GetSftpPathState => {
    if (state == undefined) {
        return unloadedStateGetSftpPath;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'GET_SFTP_PATH':
            return {
                isLoadGetSftpPath: false,
            }

        case 'GETEDSFTPPATH':
            return {
                isLoadGetSftpPath: true,
                responseGetSftpPath: action.response as any,
                statusGetSftpPath: action.status
            }

        case 'GETEDSFTPPATHFAIL':
            return {
                isLoadGetSftpPath: true,
                messageGetSftpPath: action.statusMessage,
                statusGetSftpPath: action.status
            }

        case 'CLEARGETEDSFTPPATH':
            return {
                isLoadGetSftpPath: false,
                messageGetSftpPath: '',
                statusGetSftpPath: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetSftp: reducerGet, AddSftp: reducerAdd, DeleteSftp: reducerDelete, SearchSftp: reducerSearch, EditSftp: reducerEdit, GetSftpPath: reducerGetSftpPath });