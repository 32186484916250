import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/testConnect/SftpConnection'

interface LoadSftpTest {
    type: 'LOAD_SFTPTEST'
}

interface LoadedSftpTest {
    type: 'LOADEDSFTPTEST'
    response: Models.ResponseTestSftp
    status: number,
    statusMessage: string
}
interface FailLoadedSftpTest {
    type: 'LOADSFTPTESTFAIL'
    status: number,
    statusMessage: any
}
interface ClearSftpTest {
    type: 'CLEARSFTPTEST'
}


type KnownAction = LoadedSftpTest | FailLoadedSftpTest | LoadSftpTest | ClearSftpTest

export const actionCreators = {
    requestGetSftpTest: (check: boolean, method: string, formBody: Models.ReqTestSftp): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.TestSftp && check && method == 'POST') {
            console.log('call actionCreators : requestGetSftpTest')
            fetch(`/v1/test/sftp/connection`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formBody),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADSFTPTESTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDSFTPTEST', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
                    //dispatch({ type: 'LOADEDSFTPTEST', response: data, status: 200, statusMessage: '' });
                    //dispatch({ type: 'LOADSFTPTESTFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_SFTPTEST' });

        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSFTPTEST' });
        }

    }
}

const unloadedState: Models.TestSftpState = { isLoadTestSftp: false };

export const reducer: Reducer<Models.TestSftpState> = (state: Models.TestSftpState | undefined, incomingAction: Action): Models.TestSftpState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SFTPTEST':
            return {
                isLoadTestSftp: false,
            }

        case 'LOADEDSFTPTEST':
            return {
                isLoadTestSftp: true,
                responseTestSftp: action.response as any,
                statusTestSftp: action.status
            }

        case 'LOADSFTPTESTFAIL':
            return {
                isLoadTestSftp: true,
                messageTestSftp: action.statusMessage,
                statusTestSftp: action.status
            }

        case 'CLEARSFTPTEST':
            return {
                isLoadTestSftp: false,
                messageTestSftp: '',
                statusTestSftp: 400
            }
        default: return state;
    }
}