import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/report/ExpectedRunJob'

interface LoadExpectedRunJob {
    type: 'LOAD_EXPECTEDRUNJOB'
}

interface LoadedExpectedRunJob {
    type: 'LOADEDEXPECTEDRUNJOB'
    response: Models.ExpectedRunJob
    status: number,
    statusMessage: string
}
interface FailLoadedExpectedRunJob {
    type: 'LOADEXPECTEDRUNJOBFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadedExpectedRunJob {
    type: 'CLEAREXPECTEDRUNJOBFAIL'
}

interface NoUserLoadedExpectedRunJob {
    type: 'NOUSEREXPECTEDRUNJOBFAIL'
}

type KnownAction = LoadedExpectedRunJob | FailLoadedExpectedRunJob | LoadExpectedRunJob | ClearLoadedExpectedRunJob | NoUserLoadedExpectedRunJob

export const actionCreators = {
    requestGetExpectedRunJob: (check: boolean, method: string, sid: string, company_codes: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ExpectedRunJob && check && method == 'GET') {
            //console.log('call actionCreators : requestGetExpectedRunJob | /v1/legacy/task?sid='+ sid + '&company_code=' + company_code + '&page=' + page + '&page_size=' + page_size + '&job_instance_no=' + job_instance_no + '&legacy_id=' + legacy_id + '&status=' + status + '&job_started_on_from=' + job_started_on_from + '&job_started_on_to=' + job_started_on_to )
            var company_code = ""
            if (company_codes.length != 0) {
                company_codes.forEach(element => {
                    company_code += "&company_code=" + element
                });
            }
            fetch(`/v1/legacy/expected-running-job-report?sid=` + sid + company_code, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.ExpectedRunJob>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADEDEXPECTEDRUNJOB', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('errorrrr')
                    dispatch({ type: 'LOADEXPECTEDRUNJOBFAIL', status: 204, statusMessage: error.message });
                });
            dispatch({ type: 'LOAD_EXPECTEDRUNJOB' });

        } else if (appState && appState.legacyTask && check && method == 'CLEAR') {
            dispatch({ type: 'CLEAREXPECTEDRUNJOBFAIL' });
        }
        else if (appState && appState.legacyTask && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSEREXPECTEDRUNJOBFAIL' });
        }
    }
}

const unloadedState: Models.GetExpectedRunJobState = { isLoadExpectedRunJob : false };

export const reducer: Reducer<Models.GetExpectedRunJobState> = (state: Models.GetExpectedRunJobState | undefined, incomingAction: Action): Models.GetExpectedRunJobState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_EXPECTEDRUNJOB':
            return {
                isLoadExpectedRunJob: false,
            }

        case 'LOADEDEXPECTEDRUNJOB':
            return {
                isLoadExpectedRunJob: true,
                responseExpectedRunJob: action.response as any,
                statusExpectedRunJob: action.status
            }

        case 'LOADEXPECTEDRUNJOBFAIL':
            return {
                isLoadExpectedRunJob: true,
                messageExpectedRunJob: action.statusMessage,
                statusExpectedRunJob: action.status
            }

        case 'CLEAREXPECTEDRUNJOBFAIL':
            return {
                isLoadExpectedRunJob: false,
                messageExpectedRunJob: "",
                statusExpectedRunJob: 400,
            }

        case 'NOUSEREXPECTEDRUNJOBFAIL':
            return {
                isLoadExpectedRunJob: true,
                statusExpectedRunJob: 400,
            }
        default: return state;
    }
}