import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, FormControl, InputGroup, Modal, Table, Spinner, ButtonGroup } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
//import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2';
//import DatePicker from "react-bootstrap-date-picker";
import { FaSearch, FaRedoAlt, FaEye, FaFile, FaDownload } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage, setDateTimeToDatePicker } from '../modules/Common'

import { ApplicationState } from '../../store';
import * as LegacyTaskModels from '../../models/report/LegacyTask'
import * as LegacyTasktStore from '../../store/report/LegacyTask'
import * as LoadingStore from '../../store/Loading'
import * as LoadingModels from '../../models/config/Loading'
type Store = LegacyTaskModels.GetLegacyTaskState & typeof LegacyTasktStore.actionCreators | LoadingModels.LoadingState & typeof LoadingStore.actionCreators

class DownloadReport extends React.Component<any, { pageSize: any, pageBtn: any, countTab: any, job_instance_no: any, legacy_id: any, company_code: any, status: string[], job_started_on_from: any, job_started_on_to: any, key: any, autoRefresh: any, jobStartedOnTo: any, jobStartedOnFrom: any, checkAutoRefresh: boolean, AutoRefreshTime: number, checkFinish: boolean, checkRunning: boolean, checkWarning: boolean, checkError: boolean, legacy_name: string }, Store>{

    constructor(props: any) {
        super(props);
        this.state = {
            pageSize: 25,
            pageBtn: ['', '', '', '', '', '', '', ''],
            countTab: 10,
            job_instance_no: '',
            legacy_id: '',
            company_code: '',
            status: [],
            job_started_on_from: '',
            job_started_on_to: '',
            jobStartedOnFrom: '',
            jobStartedOnTo: '',
            key: '',
            autoRefresh: '',
            checkAutoRefresh: true,
            AutoRefreshTime: 30000,
            checkFinish: false,
            checkRunning: false,
            checkWarning: false,
            checkError: false,
            legacy_name: ''
        }
        //this.search = this.search.bind(this)
        //this.changeFormatDate = this.changeFormatDate.bind(this)
    }

    handlechageInput = (e: any, key: any) => {
        console.log(e.target.value)
        console.log(key)
        if (key != undefined && key != "") {
            if (key == 'legacyId') {
                this.setState({
                    legacy_id: e.target.value
                })
            } else if (key == 'jobInstanceNo') {
                this.setState({
                    job_instance_no: e.target.value
                })
            } else if (key == 'status') {
                this.setState({
                    status: e.target.value
                })
            } else if (key == 'company_code') {
                this.setState({
                    company_code: e.target.value
                })
            } else if (key == 'legacyName') {
                this.setState({
                    legacy_name: e.target.value
                })
            }
            console.log(this.state.legacy_id)
            console.log(this.state.job_instance_no)
            console.log(this.state.status)
            console.log(this.state.job_started_on_from)
            console.log(this.state.job_started_on_to)
        }
    }

    handleChangeDateStart = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'
        console.log(dataRs)
        this.setState({
            jobStartedOnFrom: date,
            job_started_on_from: dataRs
        })
    }

    handleChangeDateTo = (date, event) => {
        console.log(date)
        var dates = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'
        console.log(dataRs)
        this.setState({
            jobStartedOnTo: date,
            job_started_on_to: dataRs
        })
    }

    handlecheckStatus = (e: any, key: string) => {
        console.log(e.checked + " | " + key)
        if (key == "FINISHED") {
            this.setState({
                checkFinish: Boolean(e.checked)
            })
        } else if (key == "RUNNING") {
            this.setState({
                checkRunning: Boolean(e.checked)
            })
        } else if (key == "WARNING") {
            this.setState({
                checkWarning: Boolean(e.checked)
            })
        } else if (key == "ERROR") {
            this.setState({
                checkError: Boolean(e.checked)
            })
        }
        console.log(this.state.checkFinish)
        console.log(this.state.checkRunning)
        console.log(this.state.checkWarning)
        console.log(this.state.checkError)
    }

    search(key: any) {
        if (key == 'Reset') {
            var list: any = document.getElementsByClassName('menu-form');
            for (let n = 0; n < list.length; ++n) {
                list[n].value = '';
            }

            let element: any = document.getElementsByClassName('menu-form-select');
            element.value = "";

            this.setState({
                job_instance_no: '',
                legacy_id: '',
                status: [],
                job_started_on_from: '',
                job_started_on_to: '',
                jobStartedOnFrom: '',
                jobStartedOnTo: ''
            })
        }
        if (key == 'Search') {
            clearInterval(this.state.autoRefresh);
            this.setState({
                status: [],
                checkAutoRefresh: false
            })
            var status: string[] = []
            if (this.state.checkFinish) {
                status.push('FINISHED')
            }
            if (this.state.checkRunning) {
                status.push('RUNNING')
            }
            if (this.state.checkWarning) {
                status.push('WARNING')
            }
            if (this.state.checkError) {
                status.push('ERROR')
            }

            this.setState({
                status: status
            })
        }
        this.setState({
            key: 'search'
        })
        console.log(this.state.status)
    }

    public handleSearch() {
        const { job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, company_code } = this.state
        if (company_code != "" && legacy_id != "" && job_started_on_to != "" && job_started_on_from != "") {
            var status_: string[] = []
            if (this.state.checkFinish) {
                status_.push('FINISHED')
            }
            if (this.state.checkRunning) {
                status_.push('RUNNING')
            }
            if (this.state.checkWarning) {
                status_.push('WARNING')
            }
            if (this.state.checkError) {
                status_.push('ERROR')
            }

            this.setState({
                status: status_
            })
            console.log(status)
            var companyCodes: Array<string> = [];
            if (company_code != "") {
                companyCodes.push(company_code)
            }
            this.props.requestGetLegacyTask(true, "POST", "", companyCodes, 1, 1000, job_instance_no, legacy_id, status, job_started_on_from, job_started_on_to, this.state.legacy_name)
        } else {
            Swal.fire({
                title: 'ระวัง!',
                text: 'โปรดกรอกข้อมูลในช่องค้นหาให้ครบทุกช่อง',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    handleDownload = (lisType: any) => {
        const { GetTask } = this.props

        this.props.UseLoading(true)

        const jobInstanceNOs: string[] = []
        var legacyId: string = ''
        var companyCode: string = ''
        GetTask.responseLegacyTask['result_list'].map((item: LegacyTaskModels.LegacyTask, idx: any) => {
            legacyId = item.legacyid
            companyCode = item.companycode
            jobInstanceNOs[idx] = item.job_instanceno
        })

        var body = {
            "legacy_id": legacyId,
            "company_code": companyCode,
            "lis_type": lisType,
            "job_instance_no_list": jobInstanceNOs
        }
        fetch(`/v1/lis-server/get-backup-file-with-instanceids`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                this.props.UseLoading(false)
                if (!data.ok) {
                    data.json().then(err => {
                        console.log(err.message)
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: err.message,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {

                            }
                        })
                    })
                } else {
                    console.log(data)
                    data.blob().then(result => {
                        console.log(result)
                        const downloadUrl = window.URL.createObjectURL(result);
                        const link = document.createElement('a');
                        link.setAttribute('href', downloadUrl);
                        link.setAttribute('download', this.state.legacy_id + '_' + lisType + '.txt');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    })
                }
            })
    }

    render() {
        const { GetTask } = this.props
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Download Report
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    ค้นหาข้อมูล
                                                </span>
                                            </div>
                                            <div className="mat-box">
                                                <Row>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl id="legacyId" className="menu-form require-field" size="sm" onChange={(e) => this.handlechageInput(e, "company_code")} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl id="legacyId" className="menu-form require-field" size="sm" onChange={(e) => this.handlechageInput(e, "legacyId")} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/* <Col lg={3}>
                                                       <Row>
                                                           <Col>
                                                               <Form.Label className="font-weight-bold">Job Instanceno :</Form.Label>
                                                               <InputGroup className="mb-3">
                                                                   <FormControl id="jobInstanceNo" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "jobInstanceNo")} />
                                                               </InputGroup>
                                                           </Col>
                                                       </Row>
                                                       <Row>
                                                           <Col>
                                                               <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                                               <InputGroup className="mb-3">
                                                                   <FormControl id="legacyId" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "legacyName")} />
                                                               </InputGroup>
                                                           </Col>
                                                       </Row>
                                                    </Col>
                                                    <Col lg={2}>
                                                       <Form.Label className="font-weight-bold">Status :</Form.Label>
                                                      <InputGroup className="mb-3">
                                                        <FormControl id="statusl" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "status")} />
                                                         <Form.Control as="select" onChange={(e) => this.handlechageInput(e, "status")} size="sm" className="menu-form-select">
                                                             <option value="" selected></option>
                                                             <option value="FINISHED">FINISHED</option>
                                                             <option value="RUNNING">RUNNING</option>
                                                             <option value="WARNNING">WARNNING</option>
                                                             <option value="ERROR">ERROR</option>
                                                         </Form.Control>
                                                      </InputGroup>
                                                       <Row>
                                                           <Col>
                                                               <div className="form-inline mt-1">
                                                                   <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                       <Form.Check type="checkbox" id="checkFinish" label="FINISHED" onChange={(e) => this.handlecheckStatus(document.getElementById("checkFinish"), 'FINISHED')} />
                                                                       <Form.Check type="checkbox" style={{ paddingLeft: '13px' }} className="mx-5" id="checkRunning" label="RUNNING" onChange={(e) => this.handlecheckStatus(document.getElementById("checkRunning"), 'RUNNING')} />
                                                                   </Form.Group>
                                                               </div>
                                                           </Col>
                                                       </Row>
                                                       <Row>
                                                           <Col>
                                                               <div className="form-inline mt-1">
                                                                   <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                       <Form.Check type="checkbox" id="checkWarning" label="WARNNING" onChange={(e) => this.handlecheckStatus(document.getElementById("checkWarning"), 'WARNNING')} />
                                                                       <Form.Check type="checkbox" className="mx-5" id="checkError" label="ERROR" onChange={(e) => this.handlecheckStatus(document.getElementById("checkError"), 'ERROR')} />
                                                                   </Form.Group>
                                                               </div>
                                                           </Col>
                                                       </Row>
                                                    </Col> */}
                                                    <Col lg={2}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Job Start From :</Form.Label>
                                                                <InputGroup className="mb-3 w-100">
                                                                    {/*<FormControl type="date" id="jobStartFrom" className="menu-form" size="sm" placeholder="DD/MMM/YYYY" onChange={(e) => this.handlechageInput(e, "jobStartFrom")} />*/}
                                                                    <DatePicker
                                                                        selected={setDateTimeToDatePicker(this.state.jobStartedOnFrom)}
                                                                        onChange={this.handleChangeDateStart}
                                                                        customInput={<FormControl className="menu-form custom-datepicker require-field w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Job Start To :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    {/*<FormControl type="date" id="jobTo" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "jobTo")} />*/}
                                                                    <DatePicker
                                                                        selected={setDateTimeToDatePicker(this.state.jobStartedOnTo)}
                                                                        onChange={this.handleChangeDateTo}
                                                                        customInput={<FormControl className="menu-form custom-datepicker require-field w-100" size="sm" placeholder="dd/mm/yyyy" />}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                            {/*<Button variant="primary" size="sm" onClick={() => this.search('Reset')}><FaTimes /> Reset</Button>
                                                            <Button variant="primary" size="sm" className="mx-1" onClick={() => this.search('Refresh')}><FaRedoAlt /> Refresh</Button>*/}
                                                            <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.handleSearch()}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && GetTask.responseLegacyTask['result_list'] != null &&
                                    <div>
                                        <Row className="mt-2">
                                            <Col lg={8}>

                                            </Col>
                                            <Col lg={4}>
                                                <div style={{ display: 'inline-block', float: 'right', paddingRight: '10px' }}>
                                                    <ButtonGroup>
                                                        <Button style={{ margin: '5px' }} variant="info" size="sm" onClick={() => this.handleDownload('sftp_source_backup')}><FaDownload /> Download Source File</Button>
                                                        <Button style={{ margin: '5px' }} variant="primary" size="sm" onClick={() => this.handleDownload('sftp_return_backup')}><FaDownload /> Download Response File</Button>
                                                    </ButtonGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mx-2">
                                                <Table className="table table-bordered table-hover custom-table-list table-responsive" size="sm">
                                                    <thead>
                                                        <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                                            <th style={{ width: '2%' }}>Monitor</th>
                                                            <th style={{ width: '2%' }}>รายงาน</th>
                                                            <th style={{ width: '3%' }}>Company Code</th>
                                                            <th style={{ width: '5%' }}>Legacy ID </th>
                                                            <th style={{ width: '5%' }}>Legacy Name</th>
                                                            <th style={{ width: '8%' }}>Job Instanceno</th>
                                                            <th style={{ width: '6%' }}>Status (lastest)</th>
                                                            <th style={{ width: '8%' }}>Job Start</th>
                                                            <th style={{ width: '8%' }}>Job Finished On</th>
                                                            <th style={{ width: '6%' }}>Step 1 status</th>
                                                            <th style={{ width: '8%' }}>Step 1 started on</th>
                                                            <th style={{ width: '6%' }}>Step 2 status</th>
                                                            <th style={{ width: '8%' }}>Step 2 started on</th>
                                                            <th style={{ width: '6%' }}>Step 3 status</th>
                                                            <th style={{ width: '8%' }}>Step 3 started on</th>
                                                            <th style={{ width: '6%' }}>Function status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!GetTask.isLoadLegacyTask ? <tr><td colSpan={17} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                        {GetTask.isLoadLegacyTask && (GetTask.statusLegacyTask != 200 || GetTask.responseLegacyTask['result_list'] == null) ? <tr><td colSpan={17} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                        {GetTask.statusLegacyTask == 200 && GetTask.isLoadLegacyTask && GetTask.responseLegacyTask['result_list'] != null && GetTask.responseLegacyTask['result_list'].map((item: LegacyTaskModels.LegacyTask, idx: any) => {
                                                            var srcMonitor = "/legacydetail?company_code=" + item.companycode + "&legacy_id=" + item.legacyid + "&job_no=" + item.job_instanceno
                                                            return (
                                                                <tr key={idx}>
                                                                    <td>
                                                                        <div className="text-center">
                                                                            <Link to={srcMonitor} target="_blank" className="btn btn-primary btn-sm"><FaEye /></Link>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">{idx + 1}</td>
                                                                    <td className="text-center">{item.companycode}</td>
                                                                    <td>{item.legacyid}</td>
                                                                    <td>{item.legacyname}</td>
                                                                    <td>{item.job_instanceno}</td>
                                                                    <td className={taskStatusColour(String(item.status)) + ' text-center'}>{item.status}</td>
                                                                    <td className="text-center">{formatYMDToDMY(String(item.job_started_on))}</td>
                                                                    <td className="text-center">{formatYMDToDMY(String(item.job_finished_on))}</td>
                                                                    {/*<td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status == 'ERROR' ? <span className="mx-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={(e) => this.ResetHandleClick(item.sid, item.companycode, item.legacyid, item.job_instanceno)}><FaRedoAlt style={{ cursor: 'pointer' }} color="white"/></span> : ''}{item.step1_status}</td>*/}
                                                                    <td className={taskStatusColour(String(item.step1_status)) + ' text-center'}>{item.step1_status}</td>
                                                                    <td className="text-center">{formatYMDToDMY(String(item.step1_started_on))}</td>
                                                                    <td className={taskStatusColour(String(item.step2_status)) + ' text-center'}></td>
                                                                    <td className="text-center">{formatYMDToDMY(String(item.step2_started_on))}</td>
                                                                    <td className={taskStatusColour(String(item.step3_status)) + ' text-center'}></td>
                                                                    <td className="text-center">{formatYMDToDMY(String(item.step3_started_on))}</td>
                                                                    <td className={taskStatusColour(String(item.step4_status)) + ' text-center'}>
                                                                        <div className="d-inline-block">
                                                                            {item.step4_status == 'ERROR' ?
                                                                                <div>
                                                                                    {/*<span className="mx-1" data-toggle="tooltip" data-placement="bottom" title="View Error File" onClick={(e) => this.handleshowErrorFile()}><FaFile color="white" style={{ cursor: 'pointer' }} /></span>*/}
                                                                                </div>
                                                                                : ''}
                                                                            {item.step4_status}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.legacyTask, ...state.Loading }), ({ ...LegacyTasktStore.actionCreators, ...LoadingStore.actionCreators }))(DownloadReport);