import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/config/JobConfig'

// Load
interface LoadJobConfig {
    type: 'LOAD_JOBCONFIG'
}
interface LoadedJobConfig {
    type: 'LOADEDJOBCONFIG'
    response: Models.ResponseJobConfig
    status: number,
    statusMessage: string
}
interface FailLoadedJobConfig {
    type: 'LOADJOBCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedJobConfig {
    type: 'CLEARLOADJOBCONFIG'
    status: number,
    statusMessage: any
}

interface NoUserLoadedJobConfig {
    type: 'NOUSERLOADJOBCONFIG'
}

// Save
interface AddJobConfig {
    type: 'ADD_JOBCONFIG'
}
interface AddedJobConfig {
    type: 'ADDEDJOBCONFIG'
    response: Models.JobConfig
    status: number,
    statusMessage: string
}
interface FailAddedJobConfig {
    type: 'ADDJOBCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedJobConfig {
    type: 'CLEARADDJOBCONFIG'
}

// Delete
interface DeleteJobConfig {
    type: 'DELETE_JOBCONFIG'
}
interface DeletedJobConfig {
    type: 'DELETEDJOBCONFIG'
    status: number,
    statusMessage: string
}
interface FailDeletedJobConfig {
    type: 'DELETEJOBCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeletedJobConfig {
    type: 'CLEARDELETEJOBCONFIG'
}

// Search
interface SearchJobConfig {
    type: 'SEARCH_JOBCONFIG'
}
interface SearchedJobConfig {
    type: 'SEARCHEDJOBCONFIG'
    response: Models.JobConfig
    status: number,
    statusMessage: string
}
interface FailSearchedJobConfig {
    type: 'SEARCHJOBCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchedJobConfig {
    type: 'CLEARSEARCHJOBCONFIG'
}
// edit
interface EditJobConfig {
    type: 'EDIT_JOBCONFIG'
}
interface EditedJobConfig {
    type: 'EDITEDJOBCONFIG'
    response: Models.ResponseJobConfig
    status: number,
    statusMessage: string
}
interface FailEditedJobConfig {
    type: 'EDITJOBCONFIGFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditedJobConfig {
    type: 'CLEAREDITJOBCONFIG'
}
// Load Tem
interface LoadJobTemConfig {
    type: 'LOAD_JOBTEMCONFIG'
}
interface LoadedJobTemConfig {
    type: 'LOADEDJOBTEMCONFIG'
    response: Models.ResponseJobConfig
    status: number,
    statusMessage: string
}
interface FailLoadedJobTemConfig {
    type: 'LOADJOBCONFIGTEMFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedJobTemConfig {
    type: 'CLEARLOADJOBCONFIGTEM'
}


type KnownAction = NoUserLoadedJobConfig | LoadedJobConfig | FailLoadedJobConfig | LoadJobConfig | ClearLoadedJobConfig | AddJobConfig | AddedJobConfig | FailAddedJobConfig | ClearAddedJobConfig | SearchJobConfig | SearchedJobConfig | FailSearchedJobConfig | ClearSearchedJobConfig | EditJobConfig | EditedJobConfig | FailEditedJobConfig | ClearEditedJobConfig | DeleteJobConfig | DeletedJobConfig | FailDeletedJobConfig | ClearDeletedJobConfig | LoadJobTemConfig | LoadedJobTemConfig | FailLoadedJobTemConfig | ClearLoadedJobTemConfig

export const actionCreators = {
    requestGetJobConfig: (check: boolean, method: string, sid: string, company_codes: string[], page: string, page_size: string, SearchJobReq: Models.SearchJobReq, legacy_type: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'POST') {
            
            var company_code = ""
            var legacyType = ""

            if (legacy_type != undefined) {
                legacyType = legacy_type
            }
            company_codes.forEach(element => {
                company_code += "&company_code=" + element
            });

            fetch(`/v1/config/job-config?sid=` + SearchJobReq.sid + company_code + '&page=' + page + '&page_size=' + page_size + '&legacy_id=' + SearchJobReq.legacy_id + '&legacy_name=' + SearchJobReq.legacy_name + '&api_class_name=' + SearchJobReq.api_class_name + '&api_function_name=' + SearchJobReq.api_function_name + "&legacy_type=" + legacyType, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'LOADJOBCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'LOADEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'LOADEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADJOBCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'LOAD_JOBCONFIG' });
        } else if (appState && appState.JobConfig && check && method == 'NOUSER') {
            dispatch({ type: 'NOUSERLOADJOBCONFIG'});
        }
    },

    requestAddJobConfig: (check: boolean, method: string, Formbody: Models.JobConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'POST') {
            fetch(`/v1/config/job-config`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                    //'Accept': 'application/json'
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    //debugger
                    if (!response.ok) {
                        response.json().then(err => {
                            dispatch({ type: 'ADDJOBCONFIGFAIL', status: 204, statusMessage: err.message_res });
                        })
                    }
                    else {
                        return response.json();
                    }
                })
                .then(data => {
                    dispatch({ type: 'ADDEDJOBCONFIG', response: data as Models.JobConfig, status: 200, statusMessage: '' });
                })
            dispatch({ type: 'ADD_JOBCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDJOBCONFIG' });
        }
    },

    requestDeleteJobConfig: (check: boolean, method: string, sid: string, company_code: string, legacyid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'DELETE') {
            fetch(`/v1/config/job-config?sid=` + sid + '&company_code=' + company_code + '&legacyid=' + legacyid, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'DELETEJOBCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(datas => {
                            dispatch({ type: 'DELETEDJOBCONFIG', status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'DELETEDJOBCONFIG', status: 200, statusMessage: '' });
            //dispatch({ type: 'DELETEJOBCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'DELETE_JOBCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEJOBCONFIG' });
        }
    },

    requestSearchJobConfig: (check: boolean, method: string, sid: string, company_code: string, legacyid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'GET') {
            fetch(`/v1/config/job-config/get?sid=` + sid + '&company_code=' + company_code + '&legacy_id=' + legacyid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'SEARCHJOBCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'SEARCHEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'SEARCHEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'SEARCHJOBCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'SEARCH_JOBCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHJOBCONFIG' });
        }
    },

    requestEditJobConfig: (check: boolean, method: string, Formbody: Models.ResponseJobConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var dataArr = JSON.stringify(Formbody)
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'PUT') {
            fetch(`/v1/config/job-config`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: dataArr
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'EDITJOBCONFIGFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'EDITEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })
            //dispatch({ type: 'EDITEDJOBCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'EDITJOBCONFIGFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'EDIT_JOBCONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITJOBCONFIG' });
        }
    },

    requestGetJobTemConfig: (check: boolean, method: string, sid: string, company_code: string, legacyid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.JobConfig && check && method == 'POST') {
            fetch(`/v1/config/job-config`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'LOADJOBCONFIGTEMFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'LOADEDJOBTEMCONFIG', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'LOADEDJOBTEMCONFIG', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'LOADJOBCONFIGTEMFAIL', status: 204, statusMessage: error.message });

            dispatch({ type: 'LOAD_JOBTEMCONFIG' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADJOBCONFIGTEM' });
        }
    },
}

const unloadedState: Models.GetJobConfigState = { isLoadJobConfig: false };
const unloadedAddState: Models.AddJobConfigState = { isLoadAddJobConfig: false };
const unloadedDeleteState: Models.DeleteJobConfigState = { isLoadDeleteJobConfig: false };
const unloadedSearchState: Models.SearchJobConfigState = { isLoadSearchJobConfig: false };
const unloadedEditState: Models.EditJobConfigState = { isLoadEditJobConfig: false };
const unloadedGetTemState: Models.GetJobTemConfigState = { isLoadJobTemConfig: false };

const reducerGet: Reducer<Models.GetJobConfigState> = (state: Models.GetJobConfigState | undefined, incomingAction: Action): Models.GetJobConfigState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_JOBCONFIG':
            return {
                isLoadJobConfig: false,
            }

        case 'LOADEDJOBCONFIG':
            return {
                isLoadJobConfig: true,
                responseJobConfig: action.response as any,
                statusJobConfig: action.status
            }

        case 'LOADJOBCONFIGFAIL':
            return {
                isLoadJobConfig: true,
                messageJobConfig: action.statusMessage,
                statusJobConfig: action.status
            }

        case 'CLEARLOADJOBCONFIG':
            return {
                isLoadJobConfig: false,
                messageJobConfig: "",
                statusJobConfig: 400
            }

        case 'NOUSERLOADJOBCONFIG':
            return {
                isLoadJobConfig: false,
                statusJobConfig: 400
            }
        default: return state;
    }
}
const reducerAdd: Reducer<Models.AddJobConfigState> = (state: Models.AddJobConfigState | undefined, incomingAction: Action): Models.AddJobConfigState => {
    if (state == undefined) {
        return unloadedAddState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_JOBCONFIG':
            return {
                isLoadAddJobConfig: false,
            }

        case 'ADDEDJOBCONFIG':
            return {
                isLoadAddJobConfig: true,
                responseAddJobConfig: action.response as any,
                statusAddJobConfig: action.status
            }

        case 'ADDJOBCONFIGFAIL':
            return {
                isLoadAddJobConfig: true,
                messageAddJobConfig: action.statusMessage,
                statusAddJobConfig: action.status
            }

        case 'CLEARADDJOBCONFIG':
            return {
                isLoadAddJobConfig: false,
                messageAddJobConfig: "",
                statusAddJobConfig: 400
            }
        default: return state;
    }
}
const reducerDelete: Reducer<Models.DeleteJobConfigState> = (state: Models.DeleteJobConfigState | undefined, incomingAction: Action): Models.DeleteJobConfigState => {
    if (state == undefined) {
        return unloadedDeleteState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DELETE_JOBCONFIG':
            return {
                isLoadDeleteJobConfig: false,
            }

        case 'DELETEDJOBCONFIG':
            return {
                isLoadDeleteJobConfig: true,
                statusDeleteJobConfig: action.status
            }

        case 'DELETEJOBCONFIGFAIL':
            return {
                isLoadDeleteJobConfig: true,
                messageDeleteJobConfig: action.statusMessage,
                statusDeleteJobConfig: action.status
            }

        case 'CLEARDELETEJOBCONFIG':
            return {
                isLoadDeleteJobConfig: false,
                messageDeleteJobConfig: "",
                statusDeleteJobConfig: 400
            }
        default: return state;
    }
}
const reducerSearch: Reducer<Models.SearchJobConfigState> = (state: Models.SearchJobConfigState | undefined, incomingAction: Action): Models.SearchJobConfigState => {
    if (state == undefined) {
        return unloadedSearchState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEARCH_JOBCONFIG':
            return {
                isLoadSearchJobConfig: false,
            }

        case 'SEARCHEDJOBCONFIG':
            return {
                isLoadSearchJobConfig: true,
                responseSearchJobConfig: action.response as any,
                statusSearchJobConfig: action.status
            }

        case 'SEARCHJOBCONFIGFAIL':
            return {
                isLoadSearchJobConfig: true,
                messageSearchJobConfig: action.statusMessage,
                statusSearchJobConfig: action.status
            }

        case 'CLEARSEARCHJOBCONFIG':
            return {
                isLoadSearchJobConfig: false,
                messageSearchJobConfig: "",
                statusSearchJobConfig: 400
            }
        default: return state;
    }
}
const reducerEdit: Reducer<Models.EditJobConfigState> = (state: Models.EditJobConfigState | undefined, incomingAction: Action): Models.EditJobConfigState => {
    if (state == undefined) {
        return unloadedEditState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'EDIT_JOBCONFIG':
            return {
                isLoadEditJobConfig: false,
            }

        case 'EDITEDJOBCONFIG':
            return {
                isLoadEditJobConfig: true,
                responseEditJobConfig: action.response as any,
                statusEditJobConfig: action.status
            }

        case 'EDITJOBCONFIGFAIL':
            return {
                isLoadEditJobConfig: true,
                messageEditJobConfig: action.statusMessage,
                statusEditJobConfig: action.status
            }

        case 'CLEAREDITJOBCONFIG':
            return {
                isLoadEditJobConfig: false,
                messageEditJobConfig: "",
                statusEditJobConfig: 400
            }
        default: return state;
    }
}
const reducerGetTem: Reducer<Models.GetJobTemConfigState> = (state: Models.GetJobTemConfigState | undefined, incomingAction: Action): Models.GetJobTemConfigState => {
    if (state == undefined) {
        return unloadedGetTemState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_JOBTEMCONFIG':
            return {
                isLoadJobTemConfig: false,
            }

        case 'LOADEDJOBTEMCONFIG':
            return {
                isLoadJobTemConfig: true,
                responseJobTemConfig: action.response as any,
                statusJobTemConfig: action.status
            }

        case 'LOADJOBCONFIGTEMFAIL':
            return {
                isLoadJobTemConfig: true,
                messageJobTemConfig: action.statusMessage,
                statusJobTemConfig: action.status
            }

        case 'CLEARLOADJOBCONFIGTEM':
            return {
                isLoadJobTemConfig: false,
                messageJobTemConfig: "",
                statusJobTemConfig: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetJob: reducerGet, AddJob: reducerAdd, DeleteJob: reducerDelete, SearchJob: reducerSearch, EditJob: reducerEdit, GetJobTem: reducerGetTem });