import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import * as Models from '../../models/companymaster/CompanyMaster';

interface RequestFunctionAction {
    type: 'REQUEST_COMPANYMASTER';
    status: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_COMPANYMASTER';
    model: Models.ResponseCompanyMaster;
    status: number;
}

interface ClearFunctionAction {
    type: 'CLEAR_COMPANYMASTER';
    status: number;
}

interface WhaitFunctionAction {
    type: 'WHAIT_COMPANYMASTER';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction | WhaitFunctionAction

export const actionCreators = {
    requestFunctionCompanyMaster: (check: boolean, method: string, sid: string, status: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.CompanyMaster && method == "GET" && check) {
            fetch(`/v1/company_master?sid=` + sid + `&status=` + status , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Models.ResponseCompanyMaster>
                })
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_COMPANYMASTER', model: data, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_COMPANYMASTER', status: 204 });
                });
        }

        dispatch({ type: 'WHAIT_COMPANYMASTER' });

        if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_COMPANYMASTER', status: 400 });
        }

    }
};

const unloadedState: Models.CompanyMasterState = { isLoadingCompanyMaster: false, responseCompanyMaster: undefined, statusCompanyMaster: 0 };

export const reducer: Reducer<Models.CompanyMasterState> = (state: Models.CompanyMasterState | undefined, incomingAction: Action): Models.CompanyMasterState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'WHAIT_COMPANYMASTER':
            return {
                isLoadingCompanyMaster: false,
                statusCompanyMaster: 400,
            };
        case 'REQUEST_COMPANYMASTER':
            return {
                isLoadingCompanyMaster: false,
                statusCompanyMaster: action.status,
            };
        case 'RECEIVE_COMPANYMASTER':
            return {
                isLoadingCompanyMaster: true,
                responseCompanyMaster: action.model,
                statusCompanyMaster: action.status
            };
        case 'CLEAR_COMPANYMASTER':
            return {
                isLoadingCompanyMaster: false,
                statusCompanyMaster: action.status
            };

        default: return state;
    }
};