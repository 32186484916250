import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaRedoAlt, FaSearch } from "react-icons/fa";
import LegacyReportTable from './LegacyReportTable'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import * as LegacyReportModels from '../../models/report/LegacyReport'

class LegacyReport extends React.Component<any, { filterLegacyName: string, search: string, reportStatus: LegacyReportModels.LegacyReportStatus, filterCompany: string, key: string, autoRefresh: any, checkAutoRefresh: boolean, AutoRefreshTime: number, checkActive: boolean, filter: string, checkFilter: boolean }>{
    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            autoRefresh: "",
            checkAutoRefresh: true,
            AutoRefreshTime: 30000,
            checkActive: false,
            checkFilter: false,
            filter: "",
            filterCompany: "",
            filterLegacyName: "",
            reportStatus: {
                Active: "",
                Suspend: "",
                Error: "",
                Running: "",
                Finished: "",
                Warning: ""
            },
            search: ""
        }
        this.handleRefresh = this.handleRefresh.bind(this)
        this.callbackStopAutoRefresh = this.callbackStopAutoRefresh.bind(this)
        this.callbackStartAutoRefresh = this.callbackStartAutoRefresh.bind(this)
        this.handleCheckInputChange = this.handleCheckInputChange.bind(this)
    }

    componentDidMount() {
        this.autoRefresh()
    }

    componentWillUnmount() {
        clearInterval(this.state.autoRefresh);
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (nextState.AutoRefreshTime != this.state.AutoRefreshTime) {
            console.log("Change Refresh time")
            clearInterval(this.state.autoRefresh);
            this.autoRefresh()
        }
    }

    autoRefresh() {
        console.log("Set Auto Refresh")
        const autoRefresh = setInterval(
            () => this.handleRefresh(),
            this.state.AutoRefreshTime
        );
        this.setState({ autoRefresh: autoRefresh });
    }

    handleRefresh() {
        this.setState({
            key: 'reset'
        })
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    onchangeSwitch(checked: boolean) {
        if (!checked) {
            clearInterval(this.state.autoRefresh);
            this.setState({
                checkAutoRefresh: false
            })
        } else {
            this.setState({
                checkAutoRefresh: true
            })
            this.autoRefresh()
        }
    }

    onchangeTimeAutoRefresh = (e: any) => {
        clearInterval(this.state.autoRefresh);
        console.log(e.target.value)
        var sec = Number(e.target.value)
        this.setState({
            AutoRefreshTime: sec
        })
        this.autoRefresh()
    }

    public callbackStopAutoRefresh() {
        console.log('callbackStopAutoRefresh')
        clearInterval(this.state.autoRefresh);
        this.setState({
            checkAutoRefresh: false
        })
    }

    public callbackStartAutoRefresh() {
        console.log('callbackStartAutoRefresh')
        this.autoRefresh()
    }

    handlecheckStatus(e: any, checkbox) {
        var Status = this.state.reportStatus;
        if (checkbox == "Active") {
            if (e.target.checked == true) {
                Status.Active = "Active";
            } else {
                Status.Active = "";
            }
        }
        else if (checkbox == "Suspend") {
            if (e.target.checked == true) {
                Status.Suspend = "Suspend";
            }
            else {
                Status.Suspend = "";
            }
        }
        else if (checkbox == "Error") {
            if (e.target.checked == true) {
                Status.Error = "Error";
            }
            else {
                Status.Error = "";
            }
        }
        else if (checkbox == "Running") {
            if (e.target.checked == true) {
                Status.Running = "Running";
            }
            else {
                Status.Running = "";
            }
        }
        else if (checkbox == "Finished") {
            if (e.target.checked == true) {
                Status.Finished = "Finished";
            }
            else {
                Status.Finished = "";
            }
        }
        else if (checkbox == "Warning") {
            if (e.target.checked == true) {
                Status.Warning = "Warning";
            }
            else {
                Status.Warning = "";
            }
        }
        this.setState({ reportStatus: Status })
    }

    handlecheckActive(e: any) {
        var x: any = document.getElementById("checkActive");
        if (x.checked === true) {
            this.setState({
                key: 'Active',
                checkActive: true
            })
        } else {
            this.setState({
                key: 'UnActive',
                checkActive: false
            })
        }
    }

    handleCheckFilter(e: any) {
        var x: any = document.getElementById("checkFilter");
        if (x.checked === true) {
            this.setState({
                key: 'CheckFilter',
                checkFilter: true
            })
        } else {
            this.setState({
                key: 'UnCheckFilter',
                checkFilter: false
            })
        }
    }

    handleCheckInputChange(e: any) {
        var x: any = document.getElementById("checkFilter");
        if (x.checked === true) {
            if (e == "Focus") {
                this.setState({
                    key: 'UnCheckFilter',
                    checkFilter: false
                })
            }
            else if (e == "Blur") {
                if (this.state.filter != "") {
                    this.setState({
                        key: 'CheckFilter',
                        checkFilter: true
                    })
                }
                else {
                    this.setState({
                        key: 'UnCheckFilter',
                        checkFilter: false
                    })
                }
            }
        }
    }

    handleChangeFilter(e: any, CheckInput) {
        if (CheckInput == "CompanyCode") {
            this.setState({
                filterCompany: e.target.value
            })
        }
        else if (CheckInput == "LegacyID") {
            this.setState({
                filter: e.target.value
            })
        }
        else if (CheckInput == "LegacyName") {
            this.setState({
                filterLegacyName: e.target.value
            })
        }
    }

    handlecheckError(e: any) {
        var x: any = document.getElementById("checkError");
        if (x.checked === true) {
            this.setState({
                key: 'Error',
            })
        } else {
            this.setState({
                key: 'UnError',
            })
        }
    }

    handlecheckSuspend(e: any) {
        var x: any = document.getElementById("checkSuspend");
        if (x.checked === true) {
            this.setState({
                key: 'Suspend',
            })
        } else {
            this.setState({
                key: 'UnSuspend',
            })
        }
    }

    search(key: any) {
        this.setState({ search: key })
    }

    resetSearch() {
        this.setState({ search: "" })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    รายงาน Legacy
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                {/*                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    Table name : SY_LEGACY_TASK
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>*/}
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <div className="container">
                                                    <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                        <span>
                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                            ค้นหาข้อมูล
                                                        </span>
                                                    </div>
                                                    <div className="mat-box">
                                                        <Row>
                                                            <Col lg={2}>
                                                                <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl id="companycode" className="menu-form" size="sm" value={this.state.filterCompany} onChange={(e) => this.handleChangeFilter(e, "CompanyCode")} />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control className="require-field" type="text" size="sm" value={this.state.filter} onChange={(e) => this.handleChangeFilter(e, "LegacyID")} />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control className="menu-form" type="text" size="sm" value={this.state.filterLegacyName} onChange={(e) => this.handleChangeFilter(e, "LegacyName")} />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Form.Label className="font-weight-bold">Job State :</Form.Label>
                                                                <Row>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkActive" label="ACTIVE" onChange={(e) => this.handlecheckStatus(e, "Active")} />
                                                                    </Col>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkError" label="ERROR" onChange={(e) => this.handlecheckStatus(e, "Error")} />
                                                                    </Col>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkFinished" label="FINISHED" onChange={(e) => this.handlecheckStatus(e, "Finished")} />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkSuspend" label="SUSPEND" onChange={(e) => this.handlecheckStatus(e, "Suspend")} />
                                                                    </Col>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkRunning" label="RUNNING" onChange={(e) => this.handlecheckStatus(e, "Running")} />
                                                                    </Col>
                                                                    <Col xs={4} sm={2} lg={4}>
                                                                        <Form.Check type="checkbox" id="checkWarning" label="WARNING" onChange={(e) => this.handlecheckStatus(e, "Warning")} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={{ span: 2, offset: 10 }}>
                                                                <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                                    <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.search('Search')}><FaSearch /> Search</Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/* <Col>
                                                <div style={{ display: 'inline-block', float: 'left' }}>
                                                    <div className="form-inline mt-3 mx-3">
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" className="mx-2" id="checkFilter" label="Filter By" onChange={(e) => this.handleCheckFilter(e)} />
                                                            <Form.Control className="mx-2 require-field" type="text" placeholder="Enter Filter" size="sm" value={this.state.filter} onChange={(e) => this.handleChangeFilter(e)} onBlur={() => this.handleCheckInputChange("Blur")} onKeyPress={() => this.handleCheckInputChange("Focus")} />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Col>*/}
                                            <Col>
                                                <div className="container">
                                                    <div className="d-flex flex-wrap justify-content-end align-items-center text-nowrap" style={{ gap: '12px' }}>
                                                        <div className="d-flex align-items-center">
                                                            <Form.Label style={{ margin: 0 }}>Auto Refresh : </Form.Label>
                                                            <div className="ml-2">
                                                                <BootstrapSwitchButton
                                                                    checked={this.state.checkAutoRefresh}
                                                                    onlabel='ON'
                                                                    offlabel='OFF'
                                                                    onChange={(checked: boolean) => {
                                                                        this.onchangeSwitch(checked)
                                                                    }}
                                                                    size="sm"
                                                                    onstyle="primary"
                                                                    offstyle="dark"
                                                                    width={60}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Form.Control as="select" size="sm" onChange={this.onchangeTimeAutoRefresh} value={this.state.AutoRefreshTime}>
                                                                <option value={10000}>10 s.</option>
                                                                <option value={20000}>20 s.</option>
                                                                <option value={30000}>30 s.</option>
                                                                <option value={60000}>60 s.</option>
                                                            </Form.Control>
                                                        </div>
                                                        <div>
                                                            <Button variant="info" size="sm" onClick={this.handleRefresh}>
                                                                <FaRedoAlt />
                                                                &nbsp;Refresh
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <LegacyReportTable filterLegacyName={this.state.filterLegacyName} resetSearch={this.resetSearch.bind(this)} search={this.state.search} reportStatus={this.state.reportStatus} checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} callbackStopAutoRefresh={this.callbackStopAutoRefresh} callbackStartAutoRefresh={this.callbackStartAutoRefresh} filterKey={this.state.filter} filterCompany={this.state.filterCompany} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default LegacyReport