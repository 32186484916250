import * as React from 'react';
import { Redirect } from "react-router";

class Logout extends React.Component {

    constructor(props: any) {
        super(props);
    }

    public render() {
        localStorage.removeItem('LG_APPLICATION');

        return (<Redirect to="/logout" />)
    }
}

export default Logout;