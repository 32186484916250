import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Form, FormControl, InputGroup, Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2';
//import DatePicker from "react-bootstrap-date-picker";
import { FaSearch, FaRedoAlt, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { setDateTimeToDatePicker } from '../modules/Common'
import LegacyTaskTable from './LegacyTaskTable'

interface State {
    pageSize: any,
    pageBtn: any,
    countTab: any,
    job_instance_no: any,
    legacy_id: any,
    company_code: any,
    status: string[],
    job_started_on_from: any,
    job_started_on_to: any,
    key: any,
    autoRefresh: any,
    jobStartedOnTo: any,
    jobStartedOnFrom: any,
    checkAutoRefresh: boolean,
    AutoRefreshTime: number,
    checkFinish: boolean,
    checkRunning: boolean,
    checkWarning: boolean,
    checkError: boolean,
    legacy_name: string
}

const initialState: State = {
    pageSize: 25,
    pageBtn: ['', '', '', '', '', '', '', ''],
    countTab: 10,
    job_instance_no: '',
    legacy_id: '',
    company_code: '',
    status: [],
    job_started_on_from: '',
    job_started_on_to: '',
    jobStartedOnFrom: '',
    jobStartedOnTo: '',
    key: '',
    autoRefresh: '',
    checkAutoRefresh: true,
    AutoRefreshTime: 30000,
    checkFinish: false,
    checkRunning: false,
    checkWarning: false,
    checkError: false,
    legacy_name: ''
};

class LegacyTask extends React.Component<any, State>{

    constructor(props: any) {
        super(props);

        this.state = this.getStateSnapshot();

        // this.state = {
        //     pageSize: 25,
        //     pageBtn: ['', '', '', '', '', '', '', ''],
        //     countTab: 10,
        //     job_instance_no: '',
        //     legacy_id: '',
        //     company_code: '',
        //     status: [],
        //     job_started_on_from: '',
        //     job_started_on_to: '',
        //     jobStartedOnFrom: '',
        //     jobStartedOnTo: '',
        //     key: '',
        //     autoRefresh: '',
        //     checkAutoRefresh: true,
        //     AutoRefreshTime: 30000,
        //     checkFinish: false,
        //     checkRunning: false,
        //     checkWarning: false,
        //     checkError: false,
        //     legacy_name: ''
        // }

        this.search = this.search.bind(this)
        //this.changeFormatDate = this.changeFormatDate.bind(this)
    }

    componentDidMount() {
        this.autoRefresh()
    }

    componentWillUnmount() {
        clearInterval(this.state.autoRefresh);
        this.setSnapshotState();
    }

    public getStateSnapshot(): State {
        const jsonString = localStorage.getItem('LEGACY_TASK_SEARCH');

        if (jsonString !== null) {
            const searchSnapshot: State = JSON.parse(jsonString);

            return searchSnapshot;
        }

        return initialState;
    }

    public setSnapshotState(): void {
        localStorage.setItem('LEGACY_TASK_SEARCH', JSON.stringify(this.state));
    }

    handlechageInput = (e: any, key: any) => {
        // console.log(e.target.value)
        // console.log(key)
        if (key != undefined && key != "") {
            if (key == 'legacyId') {
                this.setState({
                    legacy_id: e.target.value
                })
            } else if (key == 'jobInstanceNo') {
                this.setState({
                    job_instance_no: e.target.value
                })
            } else if (key == 'status') {
                this.setState({
                    status: e.target.value
                })
            } else if (key == 'company_code') {
                this.setState({
                    company_code: e.target.value
                })
            } else if (key == 'legacyName') {
                this.setState({
                    legacy_name: e.target.value
                })
            }
            // console.log(this.state.legacy_id)
            // console.log(this.state.job_instance_no)
            // console.log(this.state.status)
            // console.log(this.state.job_started_on_from)
            // console.log(this.state.job_started_on_to)
        }
    }

    handleChangeDateStart = (date, event) => {
        var dates = new Date(date),
            mnth = ("0" + (date?.getMonth() + 1)).slice(-2),
            day = ("0" + date?.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'

        this.setState({
            jobStartedOnFrom: date,
            job_started_on_from: dataRs
        })
    }

    handleChangeDateTo = (date, event) => {
        var dates = new Date(date),
            mnth = ("0" + (date?.getMonth() + 1)).slice(-2),
            day = ("0" + date?.getDate()).slice(-2);
        var dataRs = dates.getFullYear() + mnth + day + '000000'

        this.setState({
            jobStartedOnTo: date,
            job_started_on_to: dataRs
        })
    }

    search(key: any) {
        if (key == 'Reset') {
            var list: any = document.getElementsByClassName('menu-form');
            for (let n = 0; n < list.length; ++n) {
                list[n].value = '';
            }

            let element: any = document.getElementsByClassName('menu-form-select');
            element.value = "";

            this.setState({
                job_instance_no: '',
                legacy_id: '',
                status: [],
                job_started_on_from: '',
                job_started_on_to: '',
                jobStartedOnFrom: '',
                jobStartedOnTo: ''
            })
        }
        if (key == 'Search') {
            clearInterval(this.state.autoRefresh);
            this.setState({
                status: [],
                checkAutoRefresh: false
            })
            var status: string[] = []
            if (this.state.checkFinish) {
                status.push('FINISHED')
            }
            if (this.state.checkRunning) {
                status.push('RUNNING')
            }
            if (this.state.checkWarning) {
                status.push('WARNING')
            }
            if (this.state.checkError) {
                status.push('ERROR')
            }

            this.setState({
                status: status
            })
        }
        this.setState({
            key: 'search'
        })
        console.log(this.state.status)
    }

    callbackSearch() {
        this.setState({
            key: ''
        })
    }

    onchangeSwitch(checked: boolean) {
        if (!checked) {
            clearInterval(this.state.autoRefresh);
            this.setState({
                checkAutoRefresh: false
            })
        } else {
            this.autoRefresh()
            this.setState({
                checkAutoRefresh: true
            })
        }
    }

    autoRefresh() {
        const autoRefresh = setInterval(
            () => this.search('Search'),
            this.state.AutoRefreshTime
        );

        this.setState({ autoRefresh: autoRefresh, jobStartedOnFrom: '', jobStartedOnTo: '' });
    }

    onchangeTimeAutoRefresh = (e: any) => {
        clearInterval(this.state.autoRefresh);
        console.log(e.target.value)
        var sec = Number(e.target.value)
        this.setState({
            AutoRefreshTime: sec
        })
        this.autoRefresh()
    }
    //componentDidUpdate(nextProps: any, nextState: any) {
    //    const { jobStartedOnFrom, jobStartedOnTo } = this.state
    //    if (nextState.jobStartedOnFrom != jobStartedOnFrom) {
    //        console.log(jobStartedOnFrom)
    //        if (this.state.jobStartedOnFrom != "" || this.state.jobStartedOnTo != "") {
    //            var dataDate = this.state.jobStartedOnFrom.split('/')
    //            console.log(dataDate)
    //            var dataConvert = dataDate[0] + dataDate[1] + dataDate[2] + '000000';
    //            console.log(dataConvert)
    //            this.setState({
    //                job_started_on_from: dataConvert
    //            })
    //        }
    //    }
    //}

    handlecheckStatus = (e: any, key: string) => {
        console.log(e.checked + " | " + key)
        if (key == "FINISHED") {
            this.setState({
                checkFinish: Boolean(e.checked)
            })
        } else if (key == "RUNNING") {
            this.setState({
                checkRunning: Boolean(e.checked)
            })
        } else if (key == "WARNING") {
            this.setState({
                checkWarning: Boolean(e.checked)
            })
        } else if (key == "ERROR") {
            this.setState({
                checkError: Boolean(e.checked)
            })
        }
        console.log(this.state.checkFinish)
        console.log(this.state.checkRunning)
        console.log(this.state.checkWarning)
        console.log(this.state.checkError)
    }

    render() {
        const {
            company_code,
            legacy_id,
            job_instance_no,
            legacy_name,
            checkAutoRefresh,
            checkFinish,
            checkRunning,
            checkError,
            checkWarning,
            jobStartedOnFrom,
            jobStartedOnTo
        } = this.state;

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    Legacy Status
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    ค้นหาข้อมูล
                                                </span>
                                            </div>
                                            <div className="mat-box">
                                                <Row>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Company Code :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl
                                                                        autoComplete="none"
                                                                        id="legacyId"
                                                                        className="menu-form"
                                                                        size="sm"
                                                                        value={company_code}
                                                                        onChange={(e) => this.handlechageInput(e, "company_code")}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Legacy ID :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl
                                                                        autoComplete="none"
                                                                        id="legacyId"
                                                                        className="menu-form"
                                                                        size="sm"
                                                                        value={legacy_id}
                                                                        onChange={(e) => this.handlechageInput(e, "legacyId")}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Job Instanceno :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl
                                                                        autoComplete="none"
                                                                        id="jobInstanceNo"
                                                                        className="menu-form"
                                                                        size="sm"
                                                                        value={job_instance_no}
                                                                        onChange={(e) => this.handlechageInput(e, "jobInstanceNo")}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Legacy Name :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl
                                                                        autoComplete="none"
                                                                        id="legacyId"
                                                                        className="menu-form"
                                                                        size="sm"
                                                                        value={legacy_name}
                                                                        onChange={(e) => this.handlechageInput(e, "legacyName")}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label className="font-weight-bold">Status :</Form.Label>
                                                        {/* <InputGroup className="mb-3">
                                                            <FormControl id="statusl" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "status")} />
                                                            <Form.Control as="select" onChange={(e) => this.handlechageInput(e, "status")} size="sm" className="menu-form-select">
                                                                <option value="" selected></option>
                                                                <option value="FINISHED">FINISHED</option>
                                                                <option value="RUNNING">RUNNING</option>
                                                                <option value="WARNNING">WARNNING</option>
                                                                <option value="ERROR">ERROR</option>
                                                            </Form.Control>
                                                        </InputGroup> */}
                                                        <Row>
                                                            <Col xs={4} sm={2} lg={4}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="checkFinish"
                                                                    label="FINISHED"
                                                                    checked={checkFinish}
                                                                    onChange={(e) => this.handlecheckStatus(document.getElementById("checkFinish"), 'FINISHED')}
                                                                />
                                                            </Col>
                                                            <Col xs={4} sm={2} lg={4}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="checkRunning"
                                                                    label="RUNNING"
                                                                    checked={checkRunning}
                                                                    onChange={(e) => this.handlecheckStatus(document.getElementById("checkRunning"), 'RUNNING')}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={4} sm={2} lg={4}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="checkWarning"
                                                                    label="WARNING"
                                                                    checked={checkWarning}
                                                                    onChange={(e) => this.handlecheckStatus(document.getElementById("checkWarning"), 'WARNING')}
                                                                />
                                                            </Col>
                                                            <Col xs={4} sm={2} lg={4}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="checkError"
                                                                    label="ERROR"
                                                                    checked={checkError}
                                                                    onChange={(e) => this.handlecheckStatus(document.getElementById("checkError"), 'ERROR')}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Job Start From :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    {/*<FormControl type="date" id="jobStartFrom" className="menu-form" size="sm" placeholder="DD/MMM/YYYY" onChange={(e) => this.handlechageInput(e, "jobStartFrom")} />*/}
                                                                    <DatePicker
                                                                        autoComplete="none"
                                                                        selected={setDateTimeToDatePicker(this.state.jobStartedOnFrom)}
                                                                        onChange={this.handleChangeDateStart}
                                                                        customInput={<FormControl className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="font-weight-bold">Job Start To :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    {/*<FormControl type="date" id="jobTo" className="menu-form" size="sm" onChange={(e) => this.handlechageInput(e, "jobTo")} />*/}
                                                                    <DatePicker
                                                                        autoComplete="none"
                                                                        selected={setDateTimeToDatePicker(this.state.jobStartedOnTo)}
                                                                        onChange={this.handleChangeDateTo}
                                                                        customInput={<FormControl className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={{ span: 2, offset: 10 }}>
                                                        <div className="mb-3 w-100 mt-4 d-flex justify-content-end">
                                                            {/*<Button variant="primary" size="sm" onClick={() => this.search('Reset')}><FaTimes /> Reset</Button>
                                                            <Button variant="primary" size="sm" className="mx-1" onClick={() => this.search('Refresh')}><FaRedoAlt /> Refresh</Button>*/}
                                                            <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.search('Search')}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col>
                                                <div style={{ display: 'inline-block', float: 'left' }}>
                                                    <div className="form-inline mt-3 mx-3">
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" className="mx-2" id="checkFilter" label="Filter By" onChange={(e) => this.handleCheckFilter(e)} />
                                                            <Form.Control className="mx-2 require-field" type="text" placeholder="Enter Filter" size="sm" value={this.state.filter} onChange={(e) => this.handleChangeFilter(e)} onBlur={() => this.handleCheckInputChange("Blur")} onKeyPress={() => this.handleCheckInputChange("Focus")} />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Col>*/}
                                    <Col>
                                        <div className="container">
                                            <div className="d-flex flex-wrap justify-content-end align-items-center text-nowrap" style={{ gap: '12px' }}>
                                                <div className="d-flex align-items-center">
                                                    <Form.Label style={{ margin: 0 }}>Auto Refresh : </Form.Label>
                                                    <div className="ml-2">
                                                        <BootstrapSwitchButton
                                                            checked={this.state.checkAutoRefresh}
                                                            onlabel='ON'
                                                            offlabel='OFF'
                                                            onChange={(checked: boolean) => {
                                                                this.onchangeSwitch(checked)
                                                            }}
                                                            size="sm"
                                                            onstyle="primary"
                                                            offstyle="dark"
                                                            width={60}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <Form.Control as="select" size="sm" onChange={this.onchangeTimeAutoRefresh} value={this.state.AutoRefreshTime}>
                                                        <option value={10000}>10 s.</option>
                                                        <option value={20000}>20 s.</option>
                                                        <option value={30000}>30 s.</option>
                                                        <option value={60000}>60 s.</option>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <LegacyTaskTable countTab={this.state.countTab} job_instance_no={this.state.job_instance_no} legacy_id={this.state.legacy_id} status={this.state.status} job_started_on_from={this.state.job_started_on_from} job_started_on_to={this.state.job_started_on_to} checkkey={this.state.key} callbackkey={this.callbackSearch.bind(this)} companyCodeSearch={this.state.company_code} legacyNameSearch={this.state.legacy_name} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default LegacyTask